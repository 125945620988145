export default {
  fillMapType(state, mapType) {
    state.mapType = mapType;
  },
  fillMapList(state, list) {
    state.mapList = list;
  },
  fillUser(state, userInfo) {
    state.userInfo = userInfo;
  },
  fillBtnAuth(state, obj) {
    state.btnAuthObj = obj;
  },
  fillFullScreen(state, fullScreen) {
    state.fullScreen = fullScreen;
  },
  fillTimeFlowOpen(state, timeFlowOpen) {
    state.timeFlowOpen = timeFlowOpen;
  },
  fillNowToXHRTimestamp(state) {
    state.XHRTimestamp = Date.now();
  },
  fillCorporationVO(state, data) {
    state.corporationVO = data;
  },
  fillOverviewTabs(state, arr) {
    state.overviewTabs = arr;
  },
  fillValidCardList(state, arr) {
    state.validCardList = arr;
  },
  fillChosenCardsPerPage(state, arr) {
    state.chosenCardsPerPage = arr;
  },
  fillBusinessCodePerPage(state, str) {
    state.businessCodePerPage = str;
  },
  fillVrmapParadeName(state, name) {
    state.vrmapParadeName = name;
  },
  fillVrCameraLevel(state, name) {
    state.vrCameraLevel = name;
  },
  fillEquipmentPOIList(state, list){
    state.equipmentPOIList = list
  },
  fillCarTrialObj(state,data){
    state.carTrialObj = data
  },
  fillIsShowCarTrial(state,data){
    state.isShowCarTrial = data
  },
  fillPopShow(state,data){
    state.popShow = data
  },
  fillVideoObj(state,data){
    state.videoObj = data
  },
  fillWorkShow(state,data){
    state.workShow = data
  },
  fillproprtyworker(state,data){
    state.proprtyworker = data
  },
  fillalarmList(state,data){
    state.alarmList = data
  },
  fillIsShowCommonTrial(state,data){
    state.isShowCommonTrial = data
  },
  fillCommonTrialObj(state,data){
    state.commonTrialObj = data
  },
  fillDownSpaceCode(state,data){
    state.downSpaceCode = data
  },
  workerInfodata(state,data){
    state.workerInfodata = data
  },
  fillIsShowAlarmDetail(state,data){
    state.isShowAlarmDetail = data
  },
  fillAlarmDetail(state,data){
    state.alarmDetail = data
  },
  fillFloor(state,data){
    state.floor = data
  },
  reloadEchartFun(state,data){
    state.reloadEchart = data
  },
  fillisInFloor(state, data) {
    state.isInFloor = data;
  },
  getproprtyworker(state, val) {
    state.proprtyworker = val;
  },
  handleClickEventCenter(state, val) {
    state.eventCenter = val;
  },
  saveGraphData(state, val) {
    state.graphData = val;
  }
};
