<template>
  <div>
    <div class="cardConfiguration">
      <div class="static-dialog-title">
        {{ $t("i18nKey_cockpit_20") }}/{{ $t("i18nKey_cockpit_21") }}
        <span class="close" @click="closeSelectCard()">
          <i class="icon iconfont icon-guanbi"></i>
        </span>
      </div>
      <div class="cardConfiguration-main">
        <div class="config-middle">
          <div class="middle-left">
            <div v-for="(item, index) in configList" :key="index">
              <span
                @click="clickLeftConfigList(item)"
                class="config-text"
                :class="{ isActive: item.currentSelect }"
                >{{ item.configCn }}</span
              >
            </div>
          </div>
          <div class="middle-right">
            <mapLayer
              v-show="configCode === '1'"
              :layer="layerList"
              :layerConfigList="layerConfigList"
              @clickLayerTab="clickLayerTab"
              @clickConfigItem="clickConfigItem"
            />
            <commonLayer
              v-show="configCode === '4'"
              :layer="layerList"
              :layerConfigList="commonLayerConfigList"
              @commonClickLayerTab="commonClickLayerTab"
              @changeList="changeList"
              class="common-layer"
            />
            <mapOperation
              v-show="configCode === '2'"
              :operateConfigList="operateConfigList"
              @mouseOperationChange="mouseOperationChange"
            />
            <normalConfig
              v-show="configCode === '3'"
              :imgConfig="imgConfigList"
              @configImgListChange="configImgListChange"
            />
            <mapBoundary
              v-show="configCode === '5'"
              ref="mapBoundary"
              @closeSelectCard="closeSelectCard"
              class="map-boundary"
            />
          </div>
        </div>
        <div class="config-bottom">
          <button class="buttonBorColor buttonClass" @click="submit()">
            {{ $t("i18nKey_cockpit_25") }}
          </button>
          <button
            class="buttonBorColoeColse buttonClass"
            @click="closeSelectCard()"
          >
            {{ $t("i18nKey_cockpit_17") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/utils/request.js";
import mapLayer from "@/components/layerConfig/layer.vue";
import commonLayer from "@/components/layerConfig/commonLayer.vue";
import mapOperation from "@/components/layerConfig/mapOperation.vue";
import normalConfig from "@/components/layerConfig/normalConfig.vue";
import mapBoundary from "@/components/layerConfig/mapBoundary.vue";
import { mapMutations } from "vuex";
import { mapState } from "vuex";
export default {
  name: "mapConfiguration",
  components: {
    mapLayer,
    mapOperation,
    normalConfig,
    commonLayer,
    mapBoundary,
  },
  props: {
    isShowVrComfig: Boolean,
    isShowConfig: Boolean,
  },
  data() {
    return {
      layerList: [], // 图层列表
      commonLayerConfigList: [], // 通用配置项列表
      layerConfigList: [],
      changeConfigList: [],
      imgConfigList: [],
      operateConfigList: [],
      configImgList: [], // 图层配置
      configCode: "1",
      operationList: [],
      configList: [],
      parentVlookupCode: "",
    };
  },
  computed: {
    ...mapState(["overviewTabs"]),
  },
  mounted() {
    if (this.isShowVrComfig) {
      // 显示所有配置
      this.configList = [
        {
          configCn: this.$t("i18nKey_cockpit_22"),
          configCode: "1",
          currentSelect: true,
        },
        {
          configCn: this.$t("i18nKey_cockpit_23"),
          configCode: "2",
          currentSelect: false,
        },
        {
          configCn: this.$t("i18nKey_cockpit_24"),
          configCode: "3",
          currentSelect: false,
        },
      ];
      this.configCode = "1";
      this.layerList = this.overviewTabs
        .filter(({ notTabPage }) => !notTabPage)
        .map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              isSelect: true,
            };
          } else {
            return {
              ...item,
              isSelect: false,
            };
          }
        });
      this.clickLayerTab(this.layerList[0].path);
    } else if (this.isShowConfig) {
      // 显示所有配置
      this.configList = [
        {
          configCn: this.$t("i18nKey_cockpit_22"),
          configCode: "4",
          currentSelect: true,
        },
        {
          configCn: this.$t("i18nKey_cockpit_24"),
          configCode: "3",
          currentSelect: false,
        },
        {
          configCn: "地图描边",
          configCode: "5",
          currentSelect: false,
        },
      ];
      this.configCode = "4";
      this.layerList = this.overviewTabs
        .filter(({ notTabPage }) => !notTabPage)
        .map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              isSelect: true,
            };
          } else {
            return {
              ...item,
              isSelect: false,
            };
          }
        });
      this.commonClickLayerTab(this.layerList[0].path);
    } else {
      // 只能配置2d图片
      this.configList = [
        { configCn: this.$t("i18nKey_cockpit_24"), currentSelect: true },
      ];
      this.configCode = "3";
    }
    this.getDefaultConfig();
  },
  methods: {
    ...mapMutations(["fillPopShow"]),
    getDefaultConfig() {
      api({
        url: "/ioc/safetymgmt/config/getDefaultConfig",
        method: "post",
        data: {
          dataTypes: [
            "MOUSE_OPERATION",
            "GLOBAL_STATE_PIC",
            "PRODUCE_STATE_PIC",
            "BIM_BACKGROUND_PIC",
          ],
        },
      })
        .then((data) => {
          if (data.length) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].dataType !== "MOUSE_OPERATION") {
                this.imgConfigList.push(data[i]);
              } else {
                this.operateConfigList.push(data[i]);
              }
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    mouseOperationChange(configList) {
      this.operationList = configList;
    },
    clickLeftConfigList(item) {
      // 点击了左侧图层选项
      this.configCode = item.configCode;
      this.configList = this.configList.map((item) => {
        item.currentSelect = false;
        return item;
      });
      item.currentSelect = true;
    },
    clickLayerTab(parentVlookupCode) {
      this.layerConfigList = [];
      api({
        url: "/ioc/safetymgmt/config/getLayerSceneSubtypeConfig",
        method: "post",
        data: { parentVlookupCode },
      })
        .then((data) => {
          this.layerConfigList = data;
          this.layerConfigList[0].layerSceneSubtype.forEach((d) => {
            if (d.vlookupCn === "执法仪") {
              window.GLOBAL_OBJ.eventHub.publish("instrumentflag", d.isShow);
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 通用配置
    commonClickLayerTab(parentVlookupCode) {
      this.parentVlookupCode = parentVlookupCode;
      this.layerConfigList = [];
      api({
        url: "/ioc/safetymgmt/config/queryCoverageConfig",
        method: "post",
        data: {
          parentVlookupCode: parentVlookupCode,
          source: "1",
        },
      })
        .then((data) => {
          this.commonLayerConfigList = data;
          let isShow = null;
          this.commonLayerConfigList.forEach((firstLevel) => {
            firstLevel.children.forEach((secondLevel) => {
              secondLevel.children.forEach((thirdLevel) => {
                if (thirdLevel.code === "IGS_002_022") {
                  isShow = thirdLevel.isShow;
                }
              });
            });
          });
          window.GLOBAL_OBJ.eventHub.publish("instrumentflag", isShow);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    clickConfigItem(configItem) {
      if (this.changeConfigList.length) {
        let isInclude = false;
        for (let i = 0; i < this.changeConfigList.length; i++) {
          if (this.changeConfigList[i].id === configItem.id) {
            isInclude = true;
            this.changeConfigList.splice(i, 1, configItem);
          }
        }
        !isInclude && this.changeConfigList.push(configItem);
      } else {
        this.changeConfigList.push(configItem);
      }
    },
    // 选项改变
    changeList(data) {
      this.changeConfigList = [];
      this.commonLayerConfigList.forEach((firstLevel) => {
        firstLevel.children.forEach((secondLevel) => {
          secondLevel.children.forEach((thirdLevel) => {
            if (data.list.includes(thirdLevel.code)) {
              this.changeConfigList.push(thirdLevel);
            }
          });
        });
      });
    },
    configImgListChange(configImgList) {
      this.configImgList = configImgList;
    },
    submit() {
      let url = "",
        params = {};
      if (this.configCode === "1") {
        url = "/ioc/safetymgmt/config/updateLayerSceneConfig";
        params = this.changeConfigList;
      } else if (this.configCode === "2") {
        url = "/ioc/safetymgmt/config/addDefaultConfig";
        params.defaultConfigList = this.operationList;
      } else if (this.configCode === "3") {
        url = "/ioc/safetymgmt/config/addDefaultConfig";
        params.defaultConfigList = this.configImgList;
      } else if (this.configCode === "4") {
        url = "/ioc/safetymgmt/config/updateCoverageConfig";
        params = {
          children: this.changeConfigList,
          parentVlookupCode: this.parentVlookupCode,
          source: "1",
        };
      } else if (this.configCode === "5") {
        this.$refs.mapBoundary.submitBoundary();
        return;
      }
      if (
        this.changeConfigList ||
        this.operationList.length ||
        this.configImgList.length
      ) {
        api(
          {
            url,
            method: "post",
            data: params,
          },
          { handleErr: false }
        ).then((data) => {
          if (data.code === 100000) {
            this.$message({
              message: this.$t("i18nKey_cockpit_26"),
              type: "success",
              customClass: "statisConfigTipClass",
              duration: 3000,
            });
            if (this.configCode === "1") {
              // 通知页面图层更新配置
              this.$vuePubSub.$emit("layerConfigChange");
            } else if (this.configCode === "4") {
              this.fillPopShow(false);
              // 通用配置通知页面图层更新配置
              this.$vuePubSub.$emit("commonLayerConfigChange");
            }
            if (window.sessionStorage.getItem("gisType") === "vrbim") {
              window.location.reload();
            }
          } else {
            this.$message({
              message: this.$t("i18nKey_cockpit_27"),
              type: "error",
              customClass: "statisConfigTipClass",
              duration: 3000,
            });
          }
          this.$emit("closeSelectCard");
        });
      } else {
        this.$message({
          message: this.$t("i18nKey_cockpit_28"),
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 2000,
        });
      }
    },
    closeSelectCard() {
      this.$emit("closeSelectCard");
    },
  },
};
</script>

<style lang="less" scoped>
.cardConfiguration {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1360px;
  height: 744px;
  // background: #202c4e;
  color: var(--common-white);
  backdrop-filter: blur(10px);
  &::before{
    border: 1.5px solid transparent;
    border-radius: 8px;
    pointer-events: none;
    content :'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)), linear-gradient(to bottom, #05F5DF, #43ADF9);
    opacity: 0.6;
    z-index: -1;
  }
  .static-dialog-title {
    font-family: var(--font-title);
    font-size: 20px;
    line-height: 2.5;
    font-weight: normal;
    text-align: center;
    width: 100%;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: -2px;
      width: 100%;
      left: 0;
      height: 1px;
      background: linear-gradient(to right, transparent, var(--common-white), transparent)
    }

    .close {
      position: absolute;
      top: 6px;
      right: 14px;
      font-size: 12px;
      cursor: pointer;

      .iconfont {
        font-size: 12px;
      }
    }
  }

  .cardConfiguration-main {
    display: flex;
    flex-direction: column;
    height: 93%;
    .config-middle {
      display: flex;
      flex-direction: row;
      height: 88%;
      .middle-left {
        width: 120px;
        border-right: 1px solid var(--border-common-color);
        .config-text {
          padding: 18px 0;
          display: block;
          width: 100%;
          text-align: center;
          height: 100%;
          border-bottom: 1px solid #343f62;
          cursor: pointer;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #a2b0cb;
          &:hover {
            color: #3a7aea;
            border-bottom: 1px solid #3a7aea;
          }
          &.isActive {
            color: #3a7aea;
            border-bottom: 1px solid #3a7aea;
          }
        }
      }
      .middle-right {
        width: 100%;
        .common-layer{
          padding: 1.56rem 2rem;
        }
        .map-boundary{
          padding: 40px;
        }
      }
    }
    .config-bottom {
      margin: auto;
      width: 100%;
      height: 3.75rem;
      padding: 20px 0;
      text-align: center;
      .buttonClass {
        margin: 0 10px;
        width: 110px;
        height: 35px;
        line-height: 30px;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
      }

      .buttonClass:focus {
        outline: none;
      }

      .buttonBorColor {
        background-color: #3a7aea;
        border: 1px solid #3a7aea;
        color: #fff;
      }

      .buttonBorColoeColse {
        border: 1px solid #54617b;
        background: transparent;
        color: #fff;
      }
    }
  }
}
</style>
