<template>
  <div class="map-boundary">
    <div class="eachtable">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="120"> </el-table-column>
        <el-table-column prop="longitude" label="经度">
          <template slot-scope="scope">
            <el-input v-model="scope.row.longitude" placeholder="请输入经度"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="latitude" label="维度">
          <template slot-scope="scope">
            <el-input v-model="scope.row.latitude" placeholder="请输入维度"></el-input> </template
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="action">
              <div class="left">
                <i
                  class="iconfont icon-add-new"
                  v-if="tableData.length === scope.$index + 1"
                  @click="addItem"
                ></i>
                <i class="iconfont icon-jianhao" @click="removeItem(scope.$index)"></i>
              </div>
              <div class="right">
                <span class="up" @click="up(scope.$index)">上移</span>
                <span class="down" @click="down(scope.$index)">下移</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Ajax from "@/dataApi/operationReport.js";
export default {
  name: "mapBoundary",
  props: {},
  data() {
    return {
      tableData: [
        {
          latitude: "",
          longitude: "",
        },
      ],
    };
  },
  methods: {
    // 上移
    up(index) {
      if (index === 0) {
        return;
      }
      let newList = JSON.parse(JSON.stringify(this.tableData));
      [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
      this.tableData = newList;
    },
    // 下移
    down(index) {
      if (index === this.tableData.length - 1) {
        return;
      }
      let newList = JSON.parse(JSON.stringify(this.tableData));
      [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
      this.tableData = newList;
    },
    // 新增一条数据
    addItem() {
      if (this.tableData.length < 20) {
        this.tableData.push({
          longitude: "",
          latitude: "",
        });
      } else {
        this.$message({
          message: "最多20个点位",
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 2000,
        });
      }
    },
    // 移除一条数据
    removeItem(index) {
      this.tableData.splice(index, 1);
      if (this.tableData.length === 0) {
        this.addItem();
      }
    },
    // 查询
    getData() {
      let params = {};
      Ajax.getMapBoundaryData(params)
        .then((res) => {
          if (res.length > 0) {
            this.tableData = [];
            res.forEach((item) => {
              this.tableData.push({
                longitude: item.longitude,
                latitude: item.latitude,
              });
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 保存
    submitBoundary() {
      let next = this.tableData.every((item) => {
        return item.longitude && item.latitude;
      });
      if (!next) {
        this.$message({
          message: "请完善经纬度！",
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 2000,
        });
        return;
      }
      let check = this.tableData.every((item) => {
        return /^(-?\d+)(\.\d+)?$/.test(item.longitude) && /^(-?\d+)(\.\d+)?$/.test(item.latitude);
      });
      if (!check) {
        this.$message({
          message: "经纬度只能为数字！",
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 2000,
        });
        return;
      }
      let params = {
        list: [...this.tableData],
      };
      Ajax.saveMapBoundaryData(params)
        .then(() => {
          this.$message({
            message: "保存成功！",
            type: "success",
            customClass: "statisConfigTipClass",
            duration: 3000,
          });
          this.$emit("closeSelectCard");
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.map-boundary {
  max-height: 500px;
  overflow: auto;
  /deep/.eachtable .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: transparent;
  }
  /deep/.eachtable .el-table td {
    padding: 11px 0 !important;
  }
  /deep/.eachtable .el-table th {
    padding: 11px 0 !important;
  }
  /deep/.eachtable .el-table__row {
    background-color: #1e284b !important;
  }
  /deep/.eachtable .el-table,
  .eachtable .el-table__expanded-cell {
    background: none;
  }
  /deep/.eachtable .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.eachtable .el-table tr {
    background: #101b35 !important;
    color: #a2b0cb;
  }
  /deep/.eachtable .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #1e284b;
    color: #a2b0cb;
  }
  /deep/.eachtable .el-table td,
  .el-table th.is-leaf {
    border-bottom: none;
  }
  /deep/.eachtable .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background: none;
  }
  /deep/.eachtable .el-table th,
  .el-table tr {
    color: #a2b0cb;
  }
  /deep/ .el-input {
    border: 1px solid #2e3859;
    border-radius: 5px;
  }
  /deep/ .el-input__inner {
    background: #101b35;
    color: #c8cad0;
  }
  /deep/ .el-table th,
  .el-table tr {
    background-color: #112763 !important;
  }
  .iconfont,
  .up,
  .down {
    cursor: pointer;
    margin: 0 5px;
  }
  .action {
    display: flex;
    flex-flow: row;
    justify-content: left;
    .left {
      flex: 1;
    }
    .right {
      flex: 2;
      .up {
        color: #3165c1;
        margin-left: 10px;
      }
      .down {
        color: #3165c1;
        margin-left: 10px;
      }
    }
  }
}
</style>
