<template>
  <div class="layer-wapper">
    <div class="layer-tab" v-if="layerList.length > 0">
      <div
        class="tab-item"
        :class="{ isSelect: item.isSelect }"
        @click="commonClickLayerTab(item)"
        v-for="(item, index) in layerList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="layer-content" v-if="layerConfigList.length > 0">
      <div v-for="firstLevel in layerConfigList" :key="firstLevel.code">
        <!-- 设备 -->
        <div class="second-content" v-if="firstLevel.code === 'EQ'">
          <div class="first-level">
            <el-checkbox
              v-model="equipChecked"
              @change="handleChangeFirstLevel($event, firstLevel.code)"
              >{{ firstLevel.name }}</el-checkbox
            >
            <i
              class="iconfont icon-icon_nextpage1 img"
              @click="changeShow('1')"
            ></i>
          </div>
          <div
            class="third-content"
            v-show="
              equipShow && firstLevel.children && firstLevel.children.length > 0
            "
          >
            <div
              v-for="secondLevel in firstLevel.children"
              :key="secondLevel.code"
            >
              <!-- 给排水系统 -->
              <div class="detail-content" v-if="secondLevel.code === 'WSS'">
                <div class="second-level">
                  <el-checkbox
                    v-model="wssCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="wssChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 电梯系统 -->
              <div class="detail-content" v-if="secondLevel.code === 'ELS'">
                <div class="second-level">
                  <el-checkbox
                    v-model="elsCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="elsChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 消防系统 -->
              <div class="detail-content" v-if="secondLevel.code === 'FFS'">
                <div class="second-level">
                  <el-checkbox
                    v-model="ffsCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="ffsChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 暖通空调系统 -->
              <div class="detail-content" v-if="secondLevel.code === 'HVAC'">
                <div class="second-level">
                  <el-checkbox
                    v-model="hvacCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="hvacChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 智能化系统 -->
              <div class="detail-content" v-if="secondLevel.code === 'IGS'">
                <div class="second-level">
                  <el-checkbox
                    v-model="igsCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="igsChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 供配电系统 -->
              <div class="detail-content" v-if="secondLevel.code === 'PSS'">
                <div class="second-level">
                  <el-checkbox
                    v-model="pssCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="pssChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 人员 -->
        <div class="second-content" v-if="firstLevel.code === 'PL'">
          <div class="first-level">
            <el-checkbox
              v-model="personChecked"
              @change="handleChangeFirstLevel($event, firstLevel.code)"
              >{{ firstLevel.name }}</el-checkbox
            >
            <i
              class="iconfont icon-icon_nextpage1 img"
              @click="changeShow('2')"
            ></i>
          </div>
          <div
            class="third-content"
            v-show="
              personShow &&
              firstLevel.children &&
              firstLevel.children.length > 0
            "
          >
            <div
              v-for="secondLevel in firstLevel.children"
              :key="secondLevel.code"
            >
              <!-- 人员 -->
              <div
                class="detail-content"
                v-if="secondLevel.code === 'LSL02002'"
              >
                <div class="second-level">
                  <el-checkbox
                    v-model="lslCheckedAll"
                    @change="handleCheckAllChange($event, secondLevel.code)"
                    >{{ secondLevel.name }}</el-checkbox
                  >
                </div>
                <div class="third-level">
                  <el-checkbox-group
                    v-model="lslChecked"
                    @change="
                      handleCheckedCitiesChange($event, secondLevel.code)
                    "
                  >
                    <el-checkbox
                      v-for="thirdLevel in secondLevel.children"
                      :key="thirdLevel.code"
                      :label="thirdLevel.code"
                      >{{ thirdLevel.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="nothingMore">
        <div class="nothingMoreContent">
          <div class="tipBgImg"></div>
          <br />{{ $t("i18nKey_cockpit_103") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonLayer",
  props: {
    layer: Array,
    layerConfigList: Array,
  },
  watch: {
    layer(val) {
      this.layerList = val;
    },
    layerConfigList(val) {
      val.forEach((firstLevel) => {
        firstLevel.children.forEach((secondLevel) => {
          secondLevel.children.forEach((thirdLevel) => {
            if (thirdLevel.parentCode === "WSS") {
              this.wssList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.wssChecked.push(thirdLevel.code);
              }
            }
            if (thirdLevel.parentCode === "ELS") {
              this.elsList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.elsChecked.push(thirdLevel.code);
              }
            }
            if (thirdLevel.parentCode === "FFS") {
              this.ffsList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.ffsChecked.push(thirdLevel.code);
              }
            }
            if (thirdLevel.parentCode === "HVAC") {
              this.hvacList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.hvacChecked.push(thirdLevel.code);
              }
            }
            if (thirdLevel.parentCode === "IGS") {
              this.igsList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.igsChecked.push(thirdLevel.code);
              }
            }
            if (thirdLevel.parentCode === "PSS") {
              this.pssList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.pssChecked.push(thirdLevel.code);
              }
            }
            if (thirdLevel.parentCode === "LSL02002") {
              this.lslList.push(thirdLevel.code);
              if (thirdLevel.isShow === 1) {
                this.lslChecked.push(thirdLevel.code);
              }
            }
          });
        });
      });

      this.wssCheckedAll = this.wssList.length === this.wssChecked.length;
      this.elsCheckedAll = this.elsList.length === this.elsChecked.length;
      this.ffsCheckedAll = this.ffsList.length === this.ffsChecked.length;
      this.hvacCheckedAll = this.hvacList.length === this.hvacChecked.length;
      this.igsCheckedAll = this.igsList.length === this.igsChecked.length;
      this.pssCheckedAll = this.pssList.length === this.pssChecked.length;
      this.lslCheckedAll = this.lslList.length === this.lslChecked.length;
      this.checkIsAllchoose();
    },
  },
  data() {
    return {
      layerList: [],
      equipShow: true, // 是否展开设备
      personShow: false, // 是否展开人员
      equipChecked: false, // 设备是否选择
      personChecked: false, // 人员是否选择

      wssList: [], // 所有的给排水系统
      wssCheckedAll: false, // 给排水系统是否全选
      wssChecked: [], // 已选给排水系统

      elsList: [], // 所有的电梯系统
      elsCheckedAll: false, // 电梯系统是否全选
      elsChecked: [], // 已选电梯系统

      ffsList: [], // 所有的消防系统
      ffsCheckedAll: false, // 消防系统是否全选
      ffsChecked: [], // 已选消防系统

      hvacList: [], // 所有的暖通空调系统
      hvacCheckedAll: false, // 暖通空调系统是否全选
      hvacChecked: [], // 已选暖通空调系统

      igsList: [], // 所有的智能化系统
      igsCheckedAll: false, // 智能化系统是否全选
      igsChecked: [], // 已选智能化系统

      pssList: [], // 所有的供配电系统
      pssCheckedAll: false, // 供配电系统是否全选
      pssChecked: [], // 已选供配电系统

      lslList: [], // 所有的人员
      lslCheckedAll: false, // 人员是否全选
      lslChecked: [], // 已选人员
    };
  },
  methods: {
    handleCheckedCitiesChange(data, code) {
      switch (code) {
        case "WSS":
          this.wssChecked = data;
          this.wssCheckedAll = data.length === this.wssList.length;
          break;
        case "ELS":
          this.elsChecked = data;
          this.elsCheckedAll = data.length === this.elsList.length;
          break;
        case "FFS":
          this.ffsChecked = data;
          this.ffsCheckedAll = data.length === this.ffsList.length;
          break;
        case "HVAC":
          this.hvacChecked = data;
          this.hvacCheckedAll = data.length === this.hvacList.length;
          break;
        case "IGS":
          this.igsChecked = data;
          this.igsCheckedAll = data.length === this.igsList.length;
          break;
        case "PSS":
          this.pssChecked = data;
          this.pssCheckedAll = data.length === this.pssList.length;
          break;
        case "LSL02002":
          this.lslChecked = data;
          this.lslCheckedAll = data.length === this.lslList.length;
          break;
      }
      this.$emit("changeList", {
        list: [
          ...this.wssChecked,
          ...this.elsChecked,
          ...this.ffsChecked,
          ...this.hvacChecked,
          ...this.igsChecked,
          ...this.pssChecked,
          ...this.lslChecked,
        ],
      });
      this.checkIsAllchoose();
    },
    handleCheckAllChange(data, code) {
      switch (code) {
        case "WSS":
          this.wssCheckedAll = data;
          this.wssChecked = data ? this.wssList : [];
          break;
        case "ELS":
          this.elsCheckedAll = data;
          this.elsChecked = data ? this.elsList : [];
          break;
        case "FFS":
          this.ffsCheckedAll = data;
          this.ffsChecked = data ? this.ffsList : [];
          break;
        case "HVAC":
          this.hvacCheckedAll = data;
          this.hvacChecked = data ? this.hvacList : [];
          break;
        case "IGS":
          this.igsCheckedAll = data;
          this.igsChecked = data ? this.igsList : [];
          break;
        case "PSS":
          this.pssCheckedAll = data;
          this.pssChecked = data ? this.pssList : [];
          break;
        case "LSL02002":
          this.lslCheckedAll = data;
          this.lslChecked = data ? this.lslList : [];
          break;
      }
      this.$emit("changeList", {
        list: [
          ...this.wssChecked,
          ...this.elsChecked,
          ...this.ffsChecked,
          ...this.hvacChecked,
          ...this.igsChecked,
          ...this.pssChecked,
          ...this.lslChecked,
        ],
      });
      this.checkIsAllchoose();
    },
    commonClickLayerTab(layerItem) {
      this.layerList = this.layerList.map((item) => {
        item.isSelect = false;
        return item;
      });
      layerItem.isSelect = true;

      this.equipShow = false; // 是否展开设备
      this.personShow = false; // 是否展开人员
      this.equipChecked = false; // 设备是否选择
      this.personChecked = false; // 人员是否选择
      this.wssList = []; // 所有的给排水系统
      this.wssCheckedAll = false; // 给排水系统是否全选
      this.wssChecked = []; // 已选给排水系统
      this.elsList = []; // 所有的电梯系统
      this.elsCheckedAll = false; // 电梯系统是否全选
      this.elsChecked = []; // 已选电梯系统
      this.ffsList = []; // 所有的消防系统
      this.ffsCheckedAll = false; // 消防系统是否全选
      this.ffsChecked = []; // 已选消防系统
      this.hvacList = []; // 所有的暖通空调系统
      this.hvacCheckedAll = false; // 暖通空调系统是否全选
      this.hvacChecked = []; // 已选暖通空调系统
      this.igsList = []; // 所有的智能化系统
      this.igsCheckedAll = false; // 智能化系统是否全选
      this.igsChecked = []; // 已选智能化系统
      this.pssList = []; // 所有的供配电系统
      this.pssCheckedAll = false; // 供配电系统是否全选
      this.pssChecked = []; // 已选供配电系统
      this.lslList = []; // 所有的人员
      this.lslCheckedAll = false; // 人员是否全选
      this.lslChecked = []; // 已选人员
      this.$emit("commonClickLayerTab", layerItem.path);
    },
    changeShow(type) {
      switch (type) {
        case "1":
          this.personShow = false;
          this.equipShow = !this.equipShow;
          break;
        case "2":
          this.equipShow = false;
          this.personShow = !this.personShow;
          break;
      }
    },
    handleChangeFirstLevel(data, code) {
      switch (code) {
        case "EQ":
          this.equipChecked = data;
          if (data) {
            this.handleCheckAllChange(true, "WSS");
            this.handleCheckAllChange(true, "ELS");
            this.handleCheckAllChange(true, "FFS");
            this.handleCheckAllChange(true, "HVAC");
            this.handleCheckAllChange(true, "IGS");
            this.handleCheckAllChange(true, "PSS");
          } else {
            this.handleCheckAllChange(false, "WSS");
            this.handleCheckAllChange(false, "ELS");
            this.handleCheckAllChange(false, "FFS");
            this.handleCheckAllChange(false, "HVAC");
            this.handleCheckAllChange(false, "IGS");
            this.handleCheckAllChange(false, "PSS");
          }
          break;
        case "PL":
          this.personChecked = data;
          if (data) {
            this.handleCheckAllChange(true, "LSL02002");
          } else {
            this.handleCheckAllChange(false, "LSL02002");
          }
          break;
      }
    },
    checkIsAllchoose() {
      if (
        this.wssCheckedAll &&
        this.elsCheckedAll &&
        this.ffsCheckedAll &&
        this.hvacCheckedAll &&
        this.igsCheckedAll &&
        this.pssCheckedAll
      ) {
        this.equipChecked = true;
      } else {
        this.equipChecked = false;
      }
      if (this.lslCheckedAll) {
        this.personChecked = true;
      } else {
        this.personChecked = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layer-wapper {
  .layer-tab {
    display: flex;
    flex-direction: row;
    .tab-item {
      font-size: 14px;
      color: #a2b0cb;
      min-width: 8.875rem;
      height: 2.4375rem;
      background: #1d2745;
      border: 1px solid #343f62;
      line-height: 2.4375rem;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #3a7aea;
      }
      &:focus {
        color: #fff;
      }
      &.isSelect {
        color: #f2f6fd;
        background: #3a7aea;
      }
    }
  }
  .layer-content {
    margin-top: 30px;
    height: 31rem;
    overflow: auto;
    border-radius: 5px;
    /** 多选框样式修改 */
    /deep/ .el-checkbox {
      color: #64738d;
      min-width: 280px;
      margin-left: 0;
    }
    /deep/ .el-checkbox__input {
      padding-left: 20px;
    }
    /deep/ .el-checkbox__input .el-checkbox__inner {
      background-color: #232d4a;
      border-color: #64738d;
    }
    /deep/ .is-checked .el-checkbox__inner {
      background-color: #275ff4;
      border-color: #275ff4;
    }
    /deep/ .el-checkbox__label {
      color: var(--common-secondary);
    }
    /** 多选框样式修改结束 */
    .second-content {
      background:  rgba(30,150,250,0.07);
      display: flexbox;
      .first-level {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 50px;
        border: 1px solid var(--border-common-color);
        .img {
          flex: 1;
          text-align: right;
          padding-right: 20px;
          cursor: pointer;
          color: var(--common-secondary);
        }
      }
      .first-level:nth-last-child(0) {
        border: none;
      }
      .third-content {
        .detail-content {
          border-top: 1px solid #343f62;
          border-right: 1px solid #343f62;
          border-left: 1px solid #343f62;
          display: flex;
          flex-flow: row;
          .second-level {
            border-right: 1px solid #343f62;
            display: flex;
            align-items: center;
          }
          .third-level {
            padding-bottom: 10px;
            /deep/ .el-checkbox {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .nothingMore {
    margin-top: 5%;
    font-size: 14px;
    .tipBgImg {
      width: 3.625rem;
      height: 3.625rem;
      background-size: 100%;
    }
  }
}
</style>