export default {
  CAR_POINT: "/security-gis/static/imagesMap/car.png",
  EQUIPMENT: "/security-gis/static/imagesMap/equipmentBlue.png",
  SAFETY_INCIDENT: "/security-gis/static/imagesMap/SAFETY_INCIDENT.png",
  SECURITY_STAFF: "/security-gis/static/imagesMap/patrol.png",
  PROPERTY: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING1: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING2: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING3: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING4: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING5: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING6: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING12: "/security-gis/static/imagesMap/CLEANING12.png",
  CLEANING13: "/security-gis/static/imagesMap/inspector.svg",
  CLEANING14: "/security-gis/static/imagesMap/CLEANING14.png",
  CLEANING15: "/security-gis/static/imagesMap/CLEANING15.png",
  CLEANING16: "/security-gis/static/imagesMap/CLEANING16.png",
  CLEANING17: "/security-gis/static/imagesMap/CLEANING17.png",
  LSL02002_001: "/security-gis/static/imagesMap/inspector.svg", // 保洁
  MEETING: "/security-gis/static/imagesMap/meeting.png", // 会议室

  PSS_001_001: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_002: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_003: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_004: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_005: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_006: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_007: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",
  PSS_001_008: "/security-gis/static/imagesMap/high-voltage-distribution-equipment.png",

  PSS_002_001: "/security-gis/static/imagesMap/transformer.png",
  PSS_002_002: "/security-gis/static/imagesMap/transformer.png",

  PSS_003_001: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_002: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_003: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_004: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_005: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_006: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_007: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_008: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_009: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_003_010: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",

  PSS_004_001: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_002: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_003: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_004: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_005: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_006: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_007: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_008: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_009: "/security-gis/static/imagesMap/emergency-supply.png",
  PSS_004_010: "/security-gis/static/imagesMap/emergency-supply.png",

  PSS_005_001: "/security-gis/static/imagesMap/power-distribution-box.png",
  PSS_005_002: "/security-gis/static/imagesMap/power-distribution-box.png",
  PSS_005_003: "/security-gis/static/imagesMap/power-distribution-box.png",
  PSS_005_004: "/security-gis/static/imagesMap/power-distribution-box.png",
  PSS_005_005: "/security-gis/static/imagesMap/power-distribution-box.png",

  PSS_006_001: "/security-gis/static/imagesMap/meter-box.png",
  PSS_006_002: "/security-gis/static/imagesMap/meter-box.png",

  PSS_007_001: "/security-gis/static/imagesMap/entry-box.png",
  PSS_007_002: "/security-gis/static/imagesMap/entry-box.png",

  PSS_008_001: "/security-gis/static/imagesMap/lighting.png",
  PSS_008_002: "/security-gis/static/imagesMap/lighting.png",
  PSS_008_003: "/security-gis/static/imagesMap/lighting.png",
  PSS_008_004: "/security-gis/static/imagesMap/lighting.png",

  PSS_009_001: "/security-gis/static/imagesMap/power-supply-line.png",
  PSS_009_002: "/security-gis/static/imagesMap/power-supply-line.png",
  PSS_009_003: "/security-gis/static/imagesMap/power-supply-line.png",

  FFS_001_001: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_002: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_003: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_004: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_005: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_006: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_007: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_008: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_009: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_010: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_011: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_012: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",

  FFS_002_001: "/security-gis/static/imagesMap/electrical-fire-monitoring.png",
  FFS_002_002: "/security-gis/static/imagesMap/electrical-fire-monitoring.png",
  FFS_002_003: "/security-gis/static/imagesMap/electrical-fire-monitoring.png",
  FFS_002_004: "/security-gis/static/imagesMap/electrical-fire-monitoring.png",

  FFS_003_001: "/security-gis/static/imagesMap/combustible-gas-detection-and-alarm.png",
  FFS_003_002: "/security-gis/static/imagesMap/combustible-gas-detection-and-alarm.png",

  FFS_004_001: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",
  FFS_004_002: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",
  FFS_004_003: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",
  FFS_004_004: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",
  FFS_004_005: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",
  FFS_004_006: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",
  FFS_004_007: "/security-gis/static/imagesMap/fire-broadcasting-and-intercom.png",

  FFS_005_001: "/security-gis/static/imagesMap/fire-separation.png",
  FFS_005_002: "/security-gis/static/imagesMap/fire-separation.png",
  FFS_005_003: "/security-gis/static/imagesMap/fire-separation.png",
  FFS_005_004: "/security-gis/static/imagesMap/fire-separation.png",

  FFS_006_001: "/security-gis/static/imagesMap/gas-fire-extinguishing.png",
  FFS_006_002: "/security-gis/static/imagesMap/gas-fire-extinguishing.png",
  FFS_006_003: "/security-gis/static/imagesMap/gas-fire-extinguishing.png",
  FFS_006_004: "/security-gis/static/imagesMap/gas-fire-extinguishing.png",
  FFS_006_005: "/security-gis/static/imagesMap/gas-fire-extinguishing.png",
  FFS_006_006: "/security-gis/static/imagesMap/gas-fire-extinguishing.png",

  FFS_007_001: "/security-gis/static/imagesMap/foam-fire-extinguishing.png",
  FFS_007_002: "/security-gis/static/imagesMap/foam-fire-extinguishing.png",
  FFS_007_003: "/security-gis/static/imagesMap/foam-fire-extinguishing.png",
  FFS_007_004: "/security-gis/static/imagesMap/foam-fire-extinguishing.png",

  FFS_008_001: "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing.png",
  FFS_008_002: "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing.png",
  FFS_008_003: "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing.png",
  FFS_008_004: "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing.png",

  FFS_009_001: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_002: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_003: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_004: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_005: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_006: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_007: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_008: "/security-gis/static/imagesMap/smoke-control.png",
  FFS_009_009: "/security-gis/static/imagesMap/smoke-control.png",

  FFS_010_001: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_002: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_003: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_004: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_005: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_006: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_007: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_008: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_009: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_010: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_011: "/security-gis/static/imagesMap/automatic-sprinkler.png",
  FFS_010_012: "/security-gis/static/imagesMap/automatic-sprinkler.png",

  FFS_011_001: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_002: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_003: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_004: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_005: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_006: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_007: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_008: "/security-gis/static/imagesMap/fire-hydrant.png",
  FFS_011_009: "/security-gis/static/imagesMap/fire-hydrant.png",

  FFS_012_001: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_002: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_003: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_004: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_005: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_006: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_007: "/security-gis/static/imagesMap/fire-extinguisher.png",
  FFS_012_008: "/security-gis/static/imagesMap/fire-extinguisher.png",

  FFS_013_001:
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication.png",
  FFS_013_002:
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication.png",
  FFS_013_003:
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication.png",
  FFS_013_004:
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication.png",

  HVAC_001_001: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_002: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_003: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_004: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_005: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_006: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_007: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_008: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_009: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_010: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_011: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_012: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_013: "/security-gis/static/imagesMap/heating-system.png",
  HVAC_001_014: "/security-gis/static/imagesMap/heating-system.png",

  HVAC_002_001: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_002: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_003: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_004: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_005: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_006: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_007: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_008: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_009: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_010: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_011: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_012: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_013: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_014: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_015: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_016: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_017: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_018: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_019: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_020: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_021: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_022: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_023: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_024: "/security-gis/static/imagesMap/central-air-conditioning.png",
  HVAC_002_025: "/security-gis/static/imagesMap/central-air-conditioning.png",

  HVAC_003_001: "/security-gis/static/imagesMap/heat-pump.png",
  HVAC_003_002: "/security-gis/static/imagesMap/heat-pump.png",
  HVAC_003_003: "/security-gis/static/imagesMap/heat-pump.png",
  HVAC_003_004: "/security-gis/static/imagesMap/heat-pump.png",
  HVAC_003_005: "/security-gis/static/imagesMap/heat-pump.png",
  HVAC_003_006: "/security-gis/static/imagesMap/heat-pump.png",
  HVAC_003_007: "/security-gis/static/imagesMap/heat-pump.png",

  HVAC_004_001: "/security-gis/static/imagesMap/VRV-air-conditioning.png",
  HVAC_004_002: "/security-gis/static/imagesMap/VRV-air-conditioning.png",
  HVAC_004_003: "/security-gis/static/imagesMap/VRV-air-conditioning.png",
  HVAC_004_004: "/security-gis/static/imagesMap/VRV-air-conditioning.png",
  HVAC_004_005: "/security-gis/static/imagesMap/VRV-air-conditioning.png",
  HVAC_004_006: "/security-gis/static/imagesMap/VRV-air-conditioning.png",

  HVAC_005_001: "/security-gis/static/imagesMap/precision-air-conditioning-system.png",
  HVAC_005_002: "/security-gis/static/imagesMap/precision-air-conditioning-system.png",
  HVAC_005_003: "/security-gis/static/imagesMap/precision-air-conditioning-system.png",

  HVAC_006_001: "/security-gis/static/imagesMap/split-air-conditioning.png",
  HVAC_006_002: "/security-gis/static/imagesMap/split-air-conditioning.png",

  HVAC_007_001: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",
  HVAC_007_002: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",
  HVAC_007_003: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",
  HVAC_007_004: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",
  HVAC_007_005: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",
  HVAC_007_006: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",

  HVAC_008_001: "/security-gis/static/imagesMap/electric-control -system.png",
  HVAC_008_002: "/security-gis/static/imagesMap/electric-control -system.png",

  ELS_001_001: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_002: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_003: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_004: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_005: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_006: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_007: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_001_008: "/security-gis/static/imagesMap/vertical-elevator.png",

  ELS_001_009: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  ELS_001_010: "/security-gis/static/imagesMap/video-monitoring.png",
  ELS_001_011: "/security-gis/static/imagesMap/lighting.png",
  ELS_001_012: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",

  ELS_002_001: "/security-gis/static/imagesMap/vertical-elevator.png",
  ELS_002_002: "/security-gis/static/imagesMap/vertical-elevator.png",

  ELS_002_003: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  ELS_002_004: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",

  IGS_001_001: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_002: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_003: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_004: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_005: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_006: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_007: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_008: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_009: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_010: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_011: "/security-gis/static/imagesMap/water-immersion-sensor.png",
  IGS_001_012: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_013: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_014: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_015: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_016: "/security-gis/static/imagesMap/building-equipment-automation.png",

  IGS_002_001: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_002: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_003: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_004: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_005: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_006: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_007: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_008: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_009: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_010: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_011: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_012: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_013: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_014: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_015: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_016: "/security-gis/static/imagesMap/video-monitoring.png",

  IGS_003_001: "/security-gis/static/imagesMap/perimeter-prevention.png",
  IGS_003_002: "/security-gis/static/imagesMap/perimeter-prevention.png",
  IGS_003_003: "/security-gis/static/imagesMap/perimeter-prevention.png",
  IGS_003_004: "/security-gis/static/imagesMap/perimeter-prevention.png",

  IGS_004_001: "/security-gis/static/imagesMap/anti-theft-alarm.png",
  IGS_004_002: "/security-gis/static/imagesMap/anti-theft-alarm.png",
  IGS_004_003: "/security-gis/static/imagesMap/anti-theft-alarm.png",
  IGS_004_004: "/security-gis/static/imagesMap/anti-theft-alarm.png",

  IGS_005_001: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_002: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_003: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_004: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_005: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_006: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_007: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_008: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_009: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_010: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_011: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_012: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_013: "/security-gis/static/imagesMap/access-control-equipment.png",
  IGS_005_014: "/security-gis/static/imagesMap/access-control-equipment.png",

  IGS_006_001: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_002: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_003: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_004: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_005: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_006: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_007: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_008: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_009: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_010: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_011: "/security-gis/static/imagesMap/home-automation-system.png",
  IGS_006_012: "/security-gis/static/imagesMap/home-automation-system.png",

  IGS_007_001: "/security-gis/static/imagesMap/guard-tour-system.png",
  IGS_007_002: "/security-gis/static/imagesMap/guard-tour-system.png",
  IGS_007_003: "/security-gis/static/imagesMap/guard-tour-system.png",
  IGS_007_004: "/security-gis/static/imagesMap/guard-tour-system.png",
  IGS_007_005: "/security-gis/static/imagesMap/guard-tour-system.png",

  IGS_008_001: "/security-gis/static/imagesMap/elevator-access-control-system.png",
  IGS_008_002: "/security-gis/static/imagesMap/elevator-access-control-system.png",
  IGS_008_003: "/security-gis/static/imagesMap/elevator-access-control-system.png",
  IGS_008_004: "/security-gis/static/imagesMap/elevator-access-control-system.png",
  IGS_008_005: "/security-gis/static/imagesMap/elevator-access-control-system.png",
  IGS_008_006: "/security-gis/static/imagesMap/elevator-access-control-system.png",

  IGS_009_001: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_002: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_003: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_004: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_005: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_006: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_007: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_008: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_009: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_010: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_011: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_012: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_013: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_014: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_015: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_016: "/security-gis/static/imagesMap/parking-lot-management.png",
  IGS_009_017: "/security-gis/static/imagesMap/parking-lot-management.png",

  IGS_010_001: "/security-gis/static/imagesMap/information-release.png",
  IGS_010_002: "/security-gis/static/imagesMap/information-release.png",
  IGS_010_003: "/security-gis/static/imagesMap/information-release.png",
  IGS_010_004: "/security-gis/static/imagesMap/information-release.png",

  IGS_011_001: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_002: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_003: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_004: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_005: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_006: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_007: "/security-gis/static/imagesMap/visitor-management.png",
  IGS_011_008: "/security-gis/static/imagesMap/visitor-management.png",

  IGS_012_001: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_002: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_003: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_004: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_005: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_006: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_007: "/security-gis/static/imagesMap/network-communication.png",

  IGS_013_001: "/security-gis/static/imagesMap/wireless-intercom-system.png",
  IGS_013_002: "/security-gis/static/imagesMap/wireless-intercom-system.png",
  IGS_013_003: "/security-gis/static/imagesMap/wireless-intercom-system.png",
  IGS_013_004: "/security-gis/static/imagesMap/wireless-intercom-system.png",
  IGS_013_005: "/security-gis/static/imagesMap/wireless-intercom-system.png",

  IGS_014_001: "/security-gis/static/imagesMap/cable-TV-system.png",
  IGS_014_002: "/security-gis/static/imagesMap/cable-TV-system.png",
  IGS_014_003: "/security-gis/static/imagesMap/cable-TV-system.png",
  IGS_014_004: "/security-gis/static/imagesMap/cable-TV-system.png",
  IGS_014_005: "/security-gis/static/imagesMap/cable-TV-system.png",
  IGS_014_006: "/security-gis/static/imagesMap/cable-TV-system.png",
  IGS_014_007: "/security-gis/static/imagesMap/cable-TV-system.png",

  IGS_015_001: "/security-gis/static/imagesMap/satellite-receiving-system.png",
  IGS_015_002: "/security-gis/static/imagesMap/satellite-receiving-system.png",
  IGS_015_003: "/security-gis/static/imagesMap/satellite-receiving-system.png",
  IGS_015_004: "/security-gis/static/imagesMap/satellite-receiving-system.png",
  IGS_015_005: "/security-gis/static/imagesMap/satellite-receiving-system.png",
  IGS_015_006: "/security-gis/static/imagesMap/satellite-receiving-system.png",

  IGS_016_001: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_002: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_003: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_004: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_005: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_006: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_007: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_008: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_009: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_010: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_011: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_012: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_013: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_014: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_015: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_016: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_017: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_018: "/security-gis/static/imagesMap/conference-equipment-system.png",
  IGS_016_019: "/security-gis/static/imagesMap/conference-equipment-system.png",

  IGS_017_001: "/security-gis/static/imagesMap/stage-lighting-system.png",
  IGS_017_002: "/security-gis/static/imagesMap/stage-lighting-system.png",
  IGS_017_003: "/security-gis/static/imagesMap/stage-lighting-system.png",
  IGS_017_004: "/security-gis/static/imagesMap/stage-lighting-system.png",

  IGS_019_001: "/security-gis/static/imagesMap/equipmentBlue.png",
  IGS_020_001: "/security-gis/static/imagesMap/water-immersion-sensor.png",
  IGS_020_002: "/security-gis/static/imagesMap/liquid-level-sensor.png",

  WSS_001_001: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_002: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_003: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_004: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_005: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_006: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_007: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_008: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_009: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_010: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_011: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_012: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_013: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_014: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_015: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",

  WSS_002_001: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_002_002: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_002_003: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",

  WSS_003_001: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_002: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_003: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_004: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_005: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_006: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_007: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_008: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",
  WSS_003_009: "/security-gis/static/imagesMap/reclaimed-water-equipment-system.png",

  WSS_004_001: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_002: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_003: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_004: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_005: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_006: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_007: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_008: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_009: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_010: "/security-gis/static/imagesMap/swimming-pool-equipment.png",
  WSS_004_011: "/security-gis/static/imagesMap/swimming-pool-equipment.png",

  WSS_005_001: "/security-gis/static/imagesMap/waterscape-system.png",
  WSS_005_002: "/security-gis/static/imagesMap/waterscape-system.png",
  WSS_005_003: "/security-gis/static/imagesMap/lighting.png",
  WSS_005_004: "/security-gis/static/imagesMap/waterscape-system.png",

  IGS_002_017: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_018: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_019: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_020: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_021: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_023: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_024: "/security-gis/static/imagesMap/video-monitoring.png",
  IGS_002_025: "/security-gis/static/imagesMap/patrol-car.png",

  IGS_010_005: "/security-gis/static/imagesMap/information-release.png",
  IGS_001_017: "/security-gis/static/imagesMap/building-equipment-automation.png",
  IGS_001_018: "/security-gis/static/imagesMap/building-equipment-automation.png",

  WSS_001_016: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_001_017: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",

  HVAC_002_026: "/security-gis/static/imagesMap/central-air-conditioning.png",

  IGS_012_008: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_009: "/security-gis/static/imagesMap/network-communication.png",
  IGS_012_010: "/security-gis/static/imagesMap/network-communication.png",

  PSS_003_011: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",
  PSS_001_009: "/security-gis/static/imagesMap/low-voltage-distribution-equipment.png",

  FFS_001_013: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",
  FFS_001_014: "/security-gis/static/imagesMap/automatic-alarm-and-linkage.png",

  IGS_002_022: "/security-gis/static/imagesMap/a-Law-online.svg",
  "IGS_002_022-2": "/security-gis/static/imagesMap/a-Law-unline.svg",
  HVAC_007_007: "/security-gis/static/imagesMap/air-conditioning-ventilation.png",

  WSS_002_004: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",
  WSS_002_005: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",

  PSS_008_005: "/security-gis/static/imagesMap/lighting.png",
  IGS_001_019: "/security-gis/static/imagesMap/lighting.png",
  IGS_005_015: "/security-gis/static/imagesMap/access-control-equipment.png",
  WSS_002_006: "/security-gis/static/imagesMap/domestic-water-supply-and-drainage.png",

  HVAC_009_001: "/security-gis/static/imagesMap/cooling-capacity-meter.png",
  HVAC_009_002: "/security-gis/static/imagesMap/cooling-capacity-meter.png",

  // 下面是设备告警图标

  "PSS_001_001-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_002-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_003-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_004-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_005-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_006-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_007-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",
  "PSS_001_008-alarm":
    "/security-gis/static/imagesMap/high-voltage-distribution-equipment-alarm.png",

  "PSS_002_001-alarm": "/security-gis/static/imagesMap/transformer-alarm.png",
  "PSS_002_002-alarm": "/security-gis/static/imagesMap/transformer-alarm.png",

  "PSS_003_001-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_002-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_003-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_004-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_005-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_006-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_007-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_008-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_009-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_003_010-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",

  "PSS_004_001-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_002-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_003-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_004-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_005-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_006-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_007-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_008-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_009-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",
  "PSS_004_010-alarm": "/security-gis/static/imagesMap/emergency-supply-alarm.png",

  "PSS_005_001-alarm": "/security-gis/static/imagesMap/power-distribution-box-alarm.png",
  "PSS_005_002-alarm": "/security-gis/static/imagesMap/power-distribution-box-alarm.png",
  "PSS_005_003-alarm": "/security-gis/static/imagesMap/power-distribution-box-alarm.png",
  "PSS_005_004-alarm": "/security-gis/static/imagesMap/power-distribution-box-alarm.png",
  "PSS_005_005-alarm": "/security-gis/static/imagesMap/power-distribution-box-alarm.png",

  "PSS_006_001-alarm": "/security-gis/static/imagesMap/meter-box-alarm.png",
  "PSS_006_002-alarm": "/security-gis/static/imagesMap/meter-box-alarm.png",

  "PSS_007_001-alarm": "/security-gis/static/imagesMap/entry-box-alarm.png",
  "PSS_007_002-alarm": "/security-gis/static/imagesMap/entry-box-alarm.png",

  "PSS_008_001-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "PSS_008_002-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "PSS_008_003-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "PSS_008_004-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",

  "PSS_009_001-alarm": "/security-gis/static/imagesMap/power-supply-line-alarm.png",
  "PSS_009_002-alarm": "/security-gis/static/imagesMap/power-supply-line-alarm.png",
  "PSS_009_003-alarm": "/security-gis/static/imagesMap/power-supply-line-alarm.png",

  "FFS_001_001-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_002-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_003-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_004-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_005-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_006-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_007-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_008-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_009-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_010-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_011-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_012-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",

  "FFS_002_001-alarm": "/security-gis/static/imagesMap/electrical-fire-monitoring-alarm.png",
  "FFS_002_002-alarm": "/security-gis/static/imagesMap/electrical-fire-monitoring-alarm.png",
  "FFS_002_003-alarm": "/security-gis/static/imagesMap/electrical-fire-monitoring-alarm.png",
  "FFS_002_004-alarm": "/security-gis/static/imagesMap/electrical-fire-monitoring-alarm.png",

  "FFS_003_001-alarm":
    "/security-gis/static/imagesMap/combustible-gas-detection-and-alarm-alarm.png",
  "FFS_003_002-alarm":
    "/security-gis/static/imagesMap/combustible-gas-detection-and-alarm-alarm.png",

  "FFS_004_001-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",
  "FFS_004_002-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",
  "FFS_004_003-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",
  "FFS_004_004-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",
  "FFS_004_005-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",
  "FFS_004_006-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",
  "FFS_004_007-alarm": "/security-gis/static/imagesMap/fire-broadcasting-and-intercom-alarm.png",

  "FFS_005_001-alarm": "/security-gis/static/imagesMap/fire-separation-alarm.png",
  "FFS_005_002-alarm": "/security-gis/static/imagesMap/fire-separation-alarm.png",
  "FFS_005_003-alarm": "/security-gis/static/imagesMap/fire-separation-alarm.png",
  "FFS_005_004-alarm": "/security-gis/static/imagesMap/fire-separation-alarm.png",

  "FFS_006_001-alarm": "/security-gis/static/imagesMap/gas-fire-extinguishing-alarm.png",
  "FFS_006_002-alarm": "/security-gis/static/imagesMap/gas-fire-extinguishing-alarm.png",
  "FFS_006_003-alarm": "/security-gis/static/imagesMap/gas-fire-extinguishing-alarm.png",
  "FFS_006_004-alarm": "/security-gis/static/imagesMap/gas-fire-extinguishing-alarm.png",
  "FFS_006_005-alarm": "/security-gis/static/imagesMap/gas-fire-extinguishing-alarm.png",
  "FFS_006_006-alarm": "/security-gis/static/imagesMap/gas-fire-extinguishing-alarm.png",

  "FFS_007_001-alarm": "/security-gis/static/imagesMap/foam-fire-extinguishing-alarm.png",
  "FFS_007_002-alarm": "/security-gis/static/imagesMap/foam-fire-extinguishing-alarm.png",
  "FFS_007_003-alarm": "/security-gis/static/imagesMap/foam-fire-extinguishing-alarm.png",
  "FFS_007_004-alarm": "/security-gis/static/imagesMap/foam-fire-extinguishing-alarm.png",

  "FFS_008_001-alarm": "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing-alarm.png",
  "FFS_008_002-alarm": "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing-alarm.png",
  "FFS_008_003-alarm": "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing-alarm.png",
  "FFS_008_004-alarm": "/security-gis/static/imagesMap/fire-monitor-fire-extinguishing-alarm.png",

  "FFS_009_001-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_002-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_003-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_004-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_005-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_006-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_007-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_008-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",
  "FFS_009_009-alarm": "/security-gis/static/imagesMap/smoke-control-alarm.png",

  "FFS_010_001-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_002-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_003-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_004-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_005-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_006-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_007-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_008-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_009-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_010-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_011-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",
  "FFS_010_012-alarm": "/security-gis/static/imagesMap/automatic-sprinkler-alarm.png",

  "FFS_011_001-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_002-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_003-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_004-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_005-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_006-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_007-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_008-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",
  "FFS_011_009-alarm": "/security-gis/static/imagesMap/fire-hydrant-alarm.png",

  "FFS_012_001-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_002-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_003-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_004-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_005-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_006-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_007-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",
  "FFS_012_008-alarm": "/security-gis/static/imagesMap/fire-extinguisher-alarm.png",

  "FFS_013_001-alarm":
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication-alarm.png",
  "FFS_013_002-alarm":
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication-alarm.png",
  "FFS_013_003-alarm":
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication-alarm.png",
  "FFS_013_004-alarm":
    "/security-gis/static/imagesMap/fire-emergency-lighting-and-evacuation-indication-alarm.png",

  "HVAC_001_001-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_002-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_003-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_004-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_005-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_006-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_007-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_008-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_009-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_010-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_011-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_012-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_013-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",
  "HVAC_001_014-alarm": "/security-gis/static/imagesMap/heating-system-alarm.png",

  "HVAC_002_001-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_002-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_003-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_004-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_005-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_006-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_007-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_008-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_009-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_010-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_011-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_012-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_013-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_014-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_015-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_016-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_017-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_018-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_019-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_020-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_021-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_022-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_023-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_024-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",
  "HVAC_002_025-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",

  "HVAC_003_001-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",
  "HVAC_003_002-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",
  "HVAC_003_003-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",
  "HVAC_003_004-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",
  "HVAC_003_005-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",
  "HVAC_003_006-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",
  "HVAC_003_007-alarm": "/security-gis/static/imagesMap/heat-pump-alarm.png",

  "HVAC_004_001-alarm": "/security-gis/static/imagesMap/VRV-air-conditioning-alarm.png",
  "HVAC_004_002-alarm": "/security-gis/static/imagesMap/VRV-air-conditioning-alarm.png",
  "HVAC_004_003-alarm": "/security-gis/static/imagesMap/VRV-air-conditioning-alarm.png",
  "HVAC_004_004-alarm": "/security-gis/static/imagesMap/VRV-air-conditioning-alarm.png",
  "HVAC_004_005-alarm": "/security-gis/static/imagesMap/VRV-air-conditioning-alarm.png",
  "HVAC_004_006-alarm": "/security-gis/static/imagesMap/VRV-air-conditioning-alarm.png",

  "HVAC_005_001-alarm":
    "/security-gis/static/imagesMap/precision-air-conditioning-system-alarm.png",
  "HVAC_005_002-alarm":
    "/security-gis/static/imagesMap/precision-air-conditioning-system-alarm.png",
  "HVAC_005_003-alarm":
    "/security-gis/static/imagesMap/precision-air-conditioning-system-alarm.png",

  "HVAC_006_001-alarm": "/security-gis/static/imagesMap/split-air-conditioning-alarm.png",
  "HVAC_006_002-alarm": "/security-gis/static/imagesMap/split-air-conditioning-alarm.png",

  "HVAC_007_001-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",
  "HVAC_007_002-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",
  "HVAC_007_003-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",
  "HVAC_007_004-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",
  "HVAC_007_005-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",
  "HVAC_007_006-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",

  "HVAC_008_001-alarm": "/security-gis/static/imagesMap/electric-control -system-alarm.png",
  "HVAC_008_002-alarm": "/security-gis/static/imagesMap/electric-control -system-alarm.png",

  "ELS_001_001-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_002-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_003-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_004-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_005-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_006-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_007-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_001_008-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",

  "ELS_001_009-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "ELS_001_010-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "ELS_001_011-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "ELS_001_012-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",

  "ELS_002_001-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",
  "ELS_002_002-alarm": "/security-gis/static/imagesMap/vertical-elevator-alarm.png",

  "ELS_002_003-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "ELS_002_004-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",

  "IGS_001_001-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_002-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_003-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_004-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_005-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_006-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_007-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_008-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_009-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_010-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_011-alarm": "/security-gis/static/imagesMap/water-immersion-sensor-alarm.png",
  "IGS_001_012-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_013-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_014-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_015-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_016-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",

  "IGS_002_001-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_002-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_003-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_004-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_005-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_006-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_007-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_008-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_009-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_010-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_011-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_012-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_013-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_014-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_015-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_016-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",

  "IGS_003_001-alarm": "/security-gis/static/imagesMap/perimeter-prevention-alarm.png",
  "IGS_003_002-alarm": "/security-gis/static/imagesMap/perimeter-prevention-alarm.png",
  "IGS_003_003-alarm": "/security-gis/static/imagesMap/perimeter-prevention-alarm.png",
  "IGS_003_004-alarm": "/security-gis/static/imagesMap/perimeter-prevention-alarm.png",

  "IGS_004_001-alarm": "/security-gis/static/imagesMap/anti-theft-alarm-alarm.png",
  "IGS_004_002-alarm": "/security-gis/static/imagesMap/anti-theft-alarm-alarm.png",
  "IGS_004_003-alarm": "/security-gis/static/imagesMap/anti-theft-alarm-alarm.png",
  "IGS_004_004-alarm": "/security-gis/static/imagesMap/anti-theft-alarm-alarm.png",

  "IGS_005_001-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_002-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_003-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_004-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_005-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_006-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_007-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_008-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_009-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_010-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_011-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_012-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_013-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "IGS_005_014-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",

  "IGS_006_001-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_002-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_003-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_004-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_005-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_006-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_007-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_008-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_009-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_010-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_011-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",
  "IGS_006_012-alarm": "/security-gis/static/imagesMap/home-automation-system-alarm.png",

  "IGS_007_001-alarm": "/security-gis/static/imagesMap/guard-tour-system-alarm.png",
  "IGS_007_002-alarm": "/security-gis/static/imagesMap/guard-tour-system-alarm.png",
  "IGS_007_003-alarm": "/security-gis/static/imagesMap/guard-tour-system-alarm.png",
  "IGS_007_004-alarm": "/security-gis/static/imagesMap/guard-tour-system-alarm.png",
  "IGS_007_005-alarm": "/security-gis/static/imagesMap/guard-tour-system-alarm.png",

  "IGS_008_001-alarm": "/security-gis/static/imagesMap/elevator-access-control-system-alarm.png",
  "IGS_008_002-alarm": "/security-gis/static/imagesMap/elevator-access-control-system-alarm.png",
  "IGS_008_003-alarm": "/security-gis/static/imagesMap/elevator-access-control-system-alarm.png",
  "IGS_008_004-alarm": "/security-gis/static/imagesMap/elevator-access-control-system-alarm.png",
  "IGS_008_005-alarm": "/security-gis/static/imagesMap/elevator-access-control-system-alarm.png",
  "IGS_008_006-alarm": "/security-gis/static/imagesMap/elevator-access-control-system-alarm.png",

  "IGS_009_001-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_002-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_003-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_004-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_005-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_006-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_007-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_008-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_009-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_010-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_011-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_012-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_013-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_014-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_015-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_016-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",
  "IGS_009_017-alarm": "/security-gis/static/imagesMap/parking-lot-management-alarm.png",

  "IGS_010_001-alarm": "/security-gis/static/imagesMap/information-release-alarm.png",
  "IGS_010_002-alarm": "/security-gis/static/imagesMap/information-release-alarm.png",
  "IGS_010_003-alarm": "/security-gis/static/imagesMap/information-release-alarm.png",
  "IGS_010_004-alarm": "/security-gis/static/imagesMap/information-release-alarm.png",

  "IGS_011_001-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_002-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_003-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_004-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_005-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_006-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_007-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",
  "IGS_011_008-alarm": "/security-gis/static/imagesMap/visitor-management-alarm.png",

  "IGS_012_001-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_002-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_003-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_004-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_005-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_006-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_007-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",

  "IGS_013_001-alarm": "/security-gis/static/imagesMap/wireless-intercom-system-alarm.png",
  "IGS_013_002-alarm": "/security-gis/static/imagesMap/wireless-intercom-system-alarm.png",
  "IGS_013_003-alarm": "/security-gis/static/imagesMap/wireless-intercom-system-alarm.png",
  "IGS_013_004-alarm": "/security-gis/static/imagesMap/wireless-intercom-system-alarm.png",
  "IGS_013_005-alarm": "/security-gis/static/imagesMap/wireless-intercom-system-alarm.png",

  "IGS_014_001-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",
  "IGS_014_002-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",
  "IGS_014_003-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",
  "IGS_014_004-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",
  "IGS_014_005-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",
  "IGS_014_006-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",
  "IGS_014_007-alarm": "/security-gis/static/imagesMap/cable-TV-system-alarm.png",

  "IGS_015_001-alarm": "/security-gis/static/imagesMap/satellite-receiving-system-alarm.png",
  "IGS_015_002-alarm": "/security-gis/static/imagesMap/satellite-receiving-system-alarm.png",
  "IGS_015_003-alarm": "/security-gis/static/imagesMap/satellite-receiving-system-alarm.png",
  "IGS_015_004-alarm": "/security-gis/static/imagesMap/satellite-receiving-system-alarm.png",
  "IGS_015_005-alarm": "/security-gis/static/imagesMap/satellite-receiving-system-alarm.png",
  "IGS_015_006-alarm": "/security-gis/static/imagesMap/satellite-receiving-system-alarm.png",

  "IGS_016_001-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_002-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_003-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_004-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_005-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_006-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_007-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_008-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_009-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_010-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_011-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_012-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_013-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_014-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_015-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_016-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_017-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_018-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",
  "IGS_016_019-alarm": "/security-gis/static/imagesMap/conference-equipment-system-alarm.png",

  "IGS_017_001-alarm": "/security-gis/static/imagesMap/stage-lighting-system-alarm.png",
  "IGS_017_002-alarm": "/security-gis/static/imagesMap/stage-lighting-system-alarm.png",
  "IGS_017_003-alarm": "/security-gis/static/imagesMap/stage-lighting-system-alarm.png",
  "IGS_017_004-alarm": "/security-gis/static/imagesMap/stage-lighting-system-alarm.png",

  "IGS_020_001-alarm": "/security-gis/static/imagesMap/water-immersion-sensor-alarm.png",
  "IGS_020_002-alarm": "/security-gis/static/imagesMap/liquid-level-sensor-alarm.png",

  "WSS_001_001-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_002-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_003-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_004-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_005-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_006-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_007-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_008-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_009-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_010-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_011-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_012-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_013-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_014-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_015-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",

  "WSS_002_001-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_002_002-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_002_003-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",

  "WSS_003_001-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_002-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_003-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_004-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_005-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_006-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_007-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_008-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",
  "WSS_003_009-alarm": "/security-gis/static/imagesMap/reclaimed-water-equipment-system-alarm.png",

  "WSS_004_001-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_002-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_003-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_004-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_005-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_006-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_007-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_008-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_009-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_010-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",
  "WSS_004_011-alarm": "/security-gis/static/imagesMap/swimming-pool-equipment-alarm.png",

  "WSS_005_001-alarm": "/security-gis/static/imagesMap/waterscape-system-alarm.png",
  "WSS_005_002-alarm": "/security-gis/static/imagesMap/waterscape-system-alarm.png",
  "WSS_005_003-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "WSS_005_004-alarm": "/security-gis/static/imagesMap/waterscape-system-alarm.png",

  "IGS_002_017-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_018-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_019-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_020-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_021-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_023-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_024-alarm": "/security-gis/static/imagesMap/video-monitoring-alarm.png",
  "IGS_002_025-alarm": "/security-gis/static/imagesMap/patrol-car.png",

  "IGS_010_005-alarm": "/security-gis/static/imagesMap/information-release-alarm.png",
  "IGS_001_017-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",
  "IGS_001_018-alarm": "/security-gis/static/imagesMap/building-equipment-automation-alarm.png",

  "WSS_001_016-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_001_017-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",

  "HVAC_002_026-alarm": "/security-gis/static/imagesMap/central-air-conditioning-alarm.png",

  "IGS_012_008-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_009-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",
  "IGS_012_010-alarm": "/security-gis/static/imagesMap/network-communication-alarm.png",

  "PSS_003_011-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",
  "PSS_001_009-alarm":
    "/security-gis/static/imagesMap/low-voltage-distribution-equipment-alarm.png",

  "FFS_001_013-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",
  "FFS_001_014-alarm": "/security-gis/static/imagesMap/automatic-alarm-and-linkage-alarm.png",

  "IGS_002_022-alarm": "/security-gis/static/imagesMap/a-Law-online-alarm.png",
  "IGS_002_022-2-alarm": "/security-gis/static/imagesMap/a-Law-unline-alarm.png",
  "HVAC_007_007-alarm": "/security-gis/static/imagesMap/air-conditioning-ventilation-alarm.png",

  "WSS_002_004-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",
  "WSS_002_005-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",

  "PSS_008_005-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "IGS_001_019-alarm": "/security-gis/static/imagesMap/lighting-alarm.png",
  "IGS_005_015-alarm": "/security-gis/static/imagesMap/access-control-equipment-alarm.png",
  "WSS_002_006-alarm":
    "/security-gis/static/imagesMap/domestic-water-supply-and-drainage-alarm.png",

  "HVAC_009_001-alarm": "/security-gis/static/imagesMap/cooling-capacity-meter-alarm.png",
  "HVAC_009_002-alarm": "/security-gis/static/imagesMap/cooling-capacity-meter-alarm.png",
};
