<template>
  <div
    :class="['footer-nav', hoverFlag && 'padding-in']"
    @mouseleave.stop="mouseOut"
  >
    <div class="footer-arrow"></div>
    <ul
      :class="['nav-wrapper', hoverFlag ? 'animation-in' : 'animation-out']"
      @mouseenter.stop="mouseIn"
    >
      <li
        v-for="(item, key) in navList"
        :key="key"
        :class="['nav-item', item.isActive && 'active-nav']"
      >
        <div
          @click="clickFooterNav(item)"
          tag="div"
          class="title"
          active-class="activeClass"
        >
          <span :class="['title-icon']">
            <i :class="[item.icon, 'iconfont']"></i>
          </span>
          <span class="title-name">{{ item.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
const ICONPOSITION = [
  {
    iconCount: 1,
    iconPosition: [{ left: "46%", top: "-11%" }],
  },
  {
    iconCount: 2,
    iconPosition: [
      { left: "26%", top: "-7%" },
      { left: "66%", top: "-7%" },
    ],
  },
  {
    iconCount: 3,
    iconPosition: [
      { left: "26%", top: "-7%" },
      { left: "46%", top: "-11%" },
      { left: "66%", top: "-7%" },
    ],
  },
  {
    iconCount: 4,
    iconPosition: [
      { left: "9%", top: "2%" },
      { left: "32%", top: "-8%" },
      { left: "60%", top: "-8%" },
      { left: "83%", top: "2%" },
    ],
  },
  {
    iconCount: 5,
    iconPosition: [
      { left: "9%", top: "2%" },
      { left: "26%", top: "-7%" },
      { left: "46%", top: "-11%" },
      { left: "66%", top: "-7%" },
      { left: "83%", top: "2%" },
    ],
  },
  {
    iconCount: 6,
    iconPosition: [
      { left: "9%", top: "2%" },
      { left: "23%", top: "-5%" },
      { left: "38%", top: "-9%" },
      { left: "54%", top: "-9%" },
      { left: "69%", top: "-5%" },
      { left: "83%", top: "2%" },
    ],
  },
  {
    iconCount: 7,
    iconPosition: [
      { left: "9%", top: "2%" },
      { left: "20%", top: "-4%" },
      { left: "33%", top: "-8%" },
      { left: "46%", top: "-10%" },
      { left: "59%", top: "-8%" },
      { left: "72%", top: "-4%" },
      { left: "83%", top: "2%" },
    ],
  },
  {
    iconCount: 8,
    iconPosition: [
      { left: "9%", top: "2%" },
      { left: "18%", top: "-3%" },
      { left: "29%", top: "-7%" },
      { left: "40%", top: "-10%" },
      { left: "51%", top: "-10%" },
      { left: "63%", top: "-7%" },
      { left: "73%", top: "-3%" },
      { left: "82%", top: "2%" },
    ],
  },
];

export default {
  name: "footerNav",
  props: {
    menuList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      hoverFlag: false,
    };
  },
  computed: {
    ...mapState(["validCardList"]),
    navList() {
      this.validCardList.forEach((item) => {
        this.menuList.forEach((menu) => {
          if (item.businessCode === menu.businessCode) {
            menu.altitude = item.altitude;
            menu.latitude = item.latitude;
            menu.longitude = item.longitude;
          }
        });
      });
      let iconList = [];
      iconList = this.menuList.filter(({ notTabPage }) => !notTabPage);
      iconList.forEach((item) => {
        if (this.$route.path === item.path) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
      if (window.DATIAN) {
        let idx = null;
        let sliceObj = null;
        iconList.forEach((item, index) => {
          if (item.path === "/show/event-center") {
            idx = index + 1;
          }
        });
        if (idx) {
          sliceObj = iconList.splice(idx - 1, 1);
        }
        iconList.splice(1, 0, ...sliceObj);
      }
      return iconList;
    },
  },
  watch: {
    $route: {
      handler: function(val) {
        this.setIconActive(val.path);
      },
      deep: true,
    },
  },
  mounted() {
    this.setIconPostition();
  },
  activated() {
    let href = this.$route.path;
    this.setIconActive(href);
  },
  methods: {
    ...mapMutations(["fillPopShow"]),
    clickFooterNav(item) {
      //  this.$emit("onload")
      if (sessionStorage.getItem("gisType") === "gaode") {
        if (item.longitude && item.latitude) {
          this.$emit("setCenter", [item.longitude, item.latitude]);
        }
      }
      let mapType = this.$route.query.mapType;
      this.fillPopShow(false);
      if (mapType) {
        this.$router.push({ path: item.path, query: { mapType } });
      } else {
        this.$router.push({ path: item.path });
      }
    },
    mouseIn() {
      this.hoverFlag = true;
    },
    mouseOut() {
      this.hoverFlag = false;
    },
    setIconActive(val) {
      this.navList.forEach((item) => {
        if (val === item.path) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
    },
    setIconPostition() {
      let navListLength = this.navList.length;
      ICONPOSITION.forEach((item) => {
        if (item.iconCount === navListLength) {
          for (let i = 0; i < navListLength; i++) {
            this.navList[i].left = item.iconPosition[i].left;
            this.navList[i].top = item.iconPosition[i].top;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.animation-out {
  // animation: animationOut linear 0.6s forwards !important;
}
@keyframes animationOut {
  0% {
    bottom: 2rem;
    transform: rotate(0deg);
  }
  50% {
    bottom: 1rem;
    transform: rotate(-20deg);
  }
  100% {
    bottom: 0;
    transform: rotate(-360deg); // 设置白点转回原位置
  }
}
.footer-nav {
  width: 44%;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  margin-left: 42px;
  color: var(--common-secondary);
  transition: all linear 0.2s;

  transition: all 0.3s;
  border-radius: 20px 20px 0 0;
  &:hover {
    .nav-wrapper {
      height: 64px;
      .nav-item {
        display: block;
      }
    }
  }

  .footer-arrow {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 394px;
    background: url(../assets/images/footer-arr.svg) no-repeat center center;
    height: 48px;
    cursor: pointer;
  }
  .nav-wrapper {
    width: 100%;
    height: 10px;
    line-height: 64px;
    display: flex;
    flex-wrap: wrap;
    backdrop-filter: blur(20px);
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 20%,
        #36e3e3 50%,
        rgba(255, 255, 255, 0) 80%
      );
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background: linear-gradient(
        90deg,
        rgba(0, 104, 162, 0) 0%,
        rgba(0, 104, 162, 0.25) 50%,
        rgba(0, 54, 236, 0) 100%
      );
      border-radius: 40px;
    }
    .nav-item {
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-family: var(--font-title);
      font-weight: 400;
      display: none;
      .title {
        cursor: pointer;
        margin: 0 0.4rem;
        position: relative;
        &:hover {
          color: var(--common-white);
        }
      }
      .title-icon {
        margin-right: 5px;
        display: none;
        .iconfont {
          font-size: 110%;
        }
      }
      &.active-nav {
        .title {
          text-shadow: 0 0 4px #7effe5;
          color: var(--common-white);
          &:before {
            content: "";
            position: absolute;
            z-index: -1;
            background: url(../assets/images/footer-active.png) no-repeat center
              center;
            height: 40px;
            width: 136px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.collapse-leftnav:not(.full-screen) .footer-nav {
  margin-left: 100px;
  width: 40%;
  .nav-wrapper {
    .nav-item {
      .title {
        margin: 0 0.5rem;
      }
    }
  }
}
</style>
