<template>
  <div class="tipdiv2">
    <el-cascader-panel
      ref="panel"
      :props="cateListProps"
      v-show="dialogVisible === true"
      :options="options"
      @change="cascaderfun"
    >
      <template slot-scope="{ node }">
        <div class="flexs">
          <img :src="funclass(node).classsimg" />
          <div :class="funclass(node).classsname">
            {{ node.label }}({{ node.data.deviceNumber }})
          </div>
        </div>
      </template>
    </el-cascader-panel>

    <el-dialog
      append-to-body
      v-if="dialogVisible2"
      :modal-append-to-body="true"
      class="g-dialog-big"
      width="600px"
      :visible.sync="dialogVisible2"
    >
      <div class="flexequip">
        <div class="leftwarp">
          <div
            @click="equipmentfun(d, i)"
            :class="i === ativenum ? 'equson' : 'equson2'"
            v-for="(d, i) in equipmentarr"
            :key="i"
            :title="d.equipCn"
          >
            <div class="equtext">{{ d.equipCn }}</div>
            <div :class="funclass3(d)" v-if="funclass3(d)"></div>
          </div>
        </div>

        <div class="rightwarp">
          <div class="padding">
            <i @click="closepadding" class="el-icon-close"></i>
            <div class="flex">
              <div class="column"></div>
              <div class="shu">
                {{ equipmentobj.basEquipmentInfoEntity.equipCn }}
              </div>
            </div>
            <div class="double">
              <img class="img1" src="/security-gis/static/imagesMap/dianwei.png" alt="" />
              <div
                class="doudiv"
                :title="equipmentobj.basEquipmentInfoEntity.locationFullPathCn || ''"
              >
                {{ equipmentobj.basEquipmentInfoEntity.locationFullPathCn || "" }}
              </div>
              <div class="text" @click="toDetail({ dialogVisible: true })">设备详情</div>
            </div>
            <div class="double">
              <img class="img2" src="/security-gis/static/imagesMap/shebei2.png" alt="" />
              <div class="doudiv">
                {{ equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv1Cn }}/{{
                  equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv2Cn
                }}/{{ equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn }}
              </div>
              <div
                class="but"
                v-if="
                  equipmentarr[0].equipStatusCode === '1' &&
                  (equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '高清监控摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '人脸识别摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '车牌识别摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '视频周界摄像头')
                "
                @click="moving"
              >
                实时视频
              </div>
              <div
                v-else-if="
                  equipmentarr[0].equipStatusCode !== '1' &&
                  (equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '高清监控摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '人脸识别摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '车牌识别摄像头' ||
                    equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '视频周界摄像头')
                "
                class="but noClick"
              >
                实时视频
              </div>
            </div>
            <div class="double" style="justify-content: space-between">
              <span class="tetxspan"
                >今日告警数<span style="color: red" class="number">{{
                  equipmentobj.todayNumber || 0
                }}</span
                >个</span
              >
              <span class="tetxspan2"
                >未处理告警数<span style="color: red" class="number">{{
                  equipmentobj.untreatedNumber || 0
                }}</span
                >个</span
              >
            </div>
          </div>

          <div class="doublebottom">
            <div class="flex" v-for="(d, i) in equipmentobj.alarmList" :key="i">
              <div>
                <div>
                  <div class="centertext">{{ d.incidentTitle }}</div>
                </div>
                <div class="flexclass">
                  <div class="flexdiv" v-if="d.incidentLevelCode === 10041001">紧急</div>
                  <div
                    class="flexdiv"
                    style="color: #ff7e05; border: solid 1px #ff7e05"
                    v-if="d.incidentLevelCode === 10041002"
                  >
                    重要
                  </div>
                  <div
                    class="flexdiv"
                    style="color: #ffde73; border: solid 1px #ffde73"
                    v-if="d.incidentLevelCode === 10041003"
                  >
                    一般
                  </div>
                  <div
                    class="flexdiv"
                    style="color: #32e0a6; border: solid 1px #32e0a6"
                    v-if="d.incidentLevelCode === 10041004"
                  >
                    提示
                  </div>
                  <div>
                    <div class="centertime">{{ d.openDate }}</div>
                  </div>
                </div>
              </div>

              <div class="righttext" v-if="showDisPatch" @click="toworke(d)">派发</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 工单派发 -->
    <ioc-dispatch-workorder
      title="工单派发"
      width="30%"
      :eventInfo="eventInfo"
      :incidentType="incidentType"
      :isDispatchWorkorderDialogShow="isDispatchWorkorderDialogShow"
      @handleListInquiry="handleListInquiry"
      @handleDialogClose="handleDialogClose"
    ></ioc-dispatch-workorder>
    <el-dialog
      class="g-dialog"
      title="实时视频"
      :visible.sync="movingDialogVisibleTririga"
      width="30%"
      append-to-body
      :modal-append-to-body="true"
      :before-close="handleClose2"
    >
      <ioc-video
        class="default-camera"
        :cameraCode="equipmentobj.basEquipmentInfoEntity.equipCode"
        :videoParams="videoParams"
        :hash="hash"
        ref="videoRef"
      ></ioc-video>
    </el-dialog>

    <equipDetails></equipDetails>
  </div>
</template>

<script>
import api from "@/utils/request.js";
import equipDetails from "@/components/commonMarker/equipDetail.vue"; // 消防栓其他设备弹框详情
import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  components: { equipDetails },
  data() {
    return {
      equipmentarr: [],
      checkList: [],
      equipmentobj: { basEquipmentInfoEntity: {} },
      options: [],
      cateListProps: {
        value: "iocEquipTypeCode", //匹配响应数据中的id
        label: "iocEquipTypeName", //匹配响应数据中的name
        children: "children", //匹配响应数据中的children
      },

      dialogVisible: false,
      dialogVisible2: false,
      ativenum: 0,
      inequipCode: "",
      // 任务派发弹窗
      isDispatchWorkorderDialogShow: false,
      movingDialogVisibleTririga: false,
      eventInfo: {},
      incidentType: "SAFETY",
      incidentDocuments: [], // 图片集合
      videoParams: {
        urlparams: {},
      },
      hash: new Date().getTime(),
    };
  },
  created() {
    this.checkList = JSON.parse(sessionStorage.getItem("checkList"));
    this.getequmentList({
      centerLatitude: this.centerLatitude,
      centerLongitude: this.centerLongitude,
      equipTypeLv3Codes: this.checkList.equipTypeLv3Codes,
    });
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapState(["userInfo", "btnAuthObj"]),
    parkCode() {
      return this.positionInfo.posCode;
    },
    showDisPatch() {
      return true;
      // if (localStorage.lay_selectedPosCode === "CN_44_003_SZGJCXG_P1") {
      //   return false;
      // } else {
      //   return true;
      // }
    },
  },
  props: {
    centerLatitude: {
      type: String,
      default: "",
    },
    centerLongitude: {
      type: String,
      default: "",
    },
    equipTypeLv1Code: null,
  },

  methods: {
    ...mapMutations(["fillVideoObj"]),
    ...mapMutations("equipInfo", ["equipDetail"]),
    closepadding() {
      this.dialogVisible2 = false;
    },
    moving() {
      this.videoParams.urlparams = {
        posCode: this.parkCode,
        userCode: this.userInfo.uid,
      };
      this.hash = new Date().getTime();
      this.movingDialogVisibleTririga = true;
    },
    toDetail(val) {
      this.getEquipDetail(val);
    },
    getEquipDetail(val) {
      let params = {
        flag: true,
        equipCode: this.equipmentobj.basEquipmentInfoEntity.equipCode,
      };
      api(
        {
          url: "/ioc/equipmentmgmt/equipmentInfo/selectEquipByEquipCode",
          method: "get",
          data: params,
        },
        { handleErr: false }
      )
        .then((res) => {
          this.equipDetail({
            ...val,
            ...res.data.entity,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    funclass3(node) {
      var classsname = "";
      var tag = 0;
      if (node.emergencyAlarmNums > 0) {
        tag = 1;
        classsname = "serious";
      }
      if (node.importantAlarmNums > 0 && tag === 0) {
        tag = 1;
        classsname = "serious2";
      }
      if (node.commonlyAlarmNums > 0 && tag === 0) {
        tag = 1;
        classsname = "serious3";
      }
      if (node.tipsAlarmNums > 0 && tag === 0) {
        tag = 1;
        classsname = "serious4";
      }
      return classsname;
    },
    funclass(node) {
      var classsname = "";
      var classsimg = `/security-gis/static/polymerizationMapImg/moren/${node.value}.png`;
      var tag = 0;
      if (node.data.list) {
        node.data.list.forEach((d) => {
          if (d.emergencyAlarmNums > 0) {
            tag = 1;
            classsname = "red";
            classsimg = `/security-gis/static/polymerizationMapImg/jinji/${node.value}.png`;
            return;
          }
        });
        if (tag === 0) {
          node.data.list.forEach((d) => {
            if (d.importantAlarmNums > 0) {
              tag = 1;

              classsname = "yellow";
              classsimg = `/security-gis/static/polymerizationMapImg/zhongyao/${node.value}.png`;
              return;
            }
          });
        }
        if (tag === 0) {
          node.data.list.forEach((d) => {
            if (d.commonlyAlarmNums > 0) {
              tag = 1;

              classsname = "ordinary";
              classsimg = `/security-gis/static/polymerizationMapImg/yiban/${node.value}.png`;
              return;
            }
          });
        }
        if (tag === 0) {
          node.data.list.forEach((d) => {
            if (d.tipsAlarmNums > 0) {
              tag = 1;

              classsname = "commonly";
              classsimg = `/security-gis/static/polymerizationMapImg/tishi/${node.value}.png`;
              return;
            }
          });
        }
      }
      return { classsname, classsimg };
    },
    getequmentList(params) {
      api(
        {
          url: "/ioc/equipmentmgmt/polymerization/equipmentTypeLv1List",
          method: "post",
          data: params,
        },
        { handleErr: false }
      )
        .then((res) => {
          this.options = res.data;
          if (this.options.length === 1) {
            api(
              {
                url: "/ioc/equipmentmgmt/polymerization/getEquipmentList",
                method: "post",
                data: {
                  equipTypeCode: this.options[0].iocEquipTypeCode,
                  centerLatitude: this.centerLatitude,
                  centerLongitude: this.centerLongitude,
                  equipTypeLv3Codes: this.checkList.equipTypeLv3Codes,
                },
              },
              { handleErr: false }
            )
              .then((res) => {
                this.equipmentarr = res.data;
                this.inequipCode = this.equipmentarr[0].equipCode;
                if (res.data.length) {
                  this.dialogVisible2 = true;
                  api(
                    {
                      url: "/ioc/equipmentmgmt/polymerization/getEquipAndAlarm",
                      method: "post",
                      data: { equipCode: this.equipmentarr[0].equipCode },
                    },
                    { handleErr: false }
                  )
                    .then((res) => {
                      this.equipmentobj = res.data;
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            this.dialogVisible = true;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    toworke(node) {
      if (node.incidentType === "equip") {
        this.incidentType = "EQUIP";
      } else if (node.incidentType === "safety") {
        this.incidentType = "SAFETY";
      } else {
        this.incidentType = "REPAIR";
      }
      this.eventInfo = node;
      this.addNewTask();
    },
    equipmentfun(d, i) {
      this.ativenum = i;
      this.inequipCode = d.equipCode;
      api(
        {
          url: "/ioc/equipmentmgmt/polymerization/getEquipAndAlarm",
          method: "post",
          data: { equipCode: d.equipCode },
        },
        { handleErr: false }
      )
        .then((res) => {
          this.equipmentobj = res.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    cascaderfun(node) {
      if (!this.$refs.panel.getCheckedNodes().children) {
        api(
          {
            url: "/ioc/equipmentmgmt/polymerization/getEquipmentList",
            method: "post",
            data: {
              equipTypeCode: node[0],
              centerLatitude: this.centerLatitude,
              centerLongitude: this.centerLongitude,
              equipTypeLv3Codes: this.checkList.equipTypeLv3Codes,
            },
          },
          { handleErr: false }
        )
          .then((res) => {
            this.equipmentarr = res.data;
            if (res.data.length) {
              this.dialogVisible2 = true;
              api(
                {
                  url: "/ioc/equipmentmgmt/polymerization/getEquipAndAlarm",
                  method: "post",
                  data: { equipCode: this.equipmentarr[0].equipCode },
                },
                { handleErr: false }
              )
                .then((res) => {
                  this.equipmentobj = res.data;
                  // this.dialogVisible2 = true;
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    handleListInquiry() {
      this.isDispatchWorkorderDialogShow = false;
      api(
        {
          url: "/ioc/equipmentmgmt/polymerization/getEquipAndAlarm",
          method: "post",
          data: { equipCode: this.inequipCode },
        },
        { handleErr: false }
      )
        .then((res) => {
          this.equipmentobj = res.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    handleDialogClose() {
      this.isDispatchWorkorderDialogShow = false;
    },
    handleClose2() {
      this.fillVideoObj({
        showStatus: false,
      });
      this.movingDialogVisibleTririga = false;
      this.$refs.videoRef.closeVideo();
    },
    addNewTask() {
      var params = { pageNumber: 1, pageSize: 500, roleType: 6, type: 0 };
      api(
        {
          url: "/ioc/property/v1/workorder/workOption",
          method: "post",
          data: params,
        },
        { handleErr: false }
      )
        .then((res) => {
          sessionStorage.setItem("workOrder", JSON.stringify(res.data));
          this.$store.commit("getproprtyworker", res.data);
          this.isDispatchWorkorderDialogShow = true;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .vanke-dialog-bodycontent {
  height: 400px !important;
  width: 100% !important;
}
.default-camera {
  height: 400px;
  width: 100%;
}
.tipdiv2 {
  /deep/ .el-cascader-menu__empty-text {
    color: var(--common-white);
    line-height: 200px;
    text-align: center;
  }

  /deep/ .el-cascader-menu {
    border-radius: 5px;
    color: var(--common-white);
    backdrop-filter: blur(10px);
    background: transparent;
    &::before {
      border: 1.5px solid transparent;
      border-radius: 8px;
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
        linear-gradient(to bottom, #05f5df, #43adf9);
      opacity: 0.6;
      z-index: -1;
    }
    .el-cascader-node:hover {
      background-color: transparent !important;
    }
  }
  /deep/ .el-cascader-menu__wrap {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    overflow: hidden;
  }
  /deep/ .el-icon-arrow-right {
    display: none;
  }
  .flexs {
    height: 50px;
    line-height: 50px;
    font-family: PingFangSC-Regular;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    display: flex;
    text-align: center;

    img {
      width: 20px;
      height: 20px;
      margin-top: 14px;
      margin-left: 10px;
      margin-right: 5px;
    }
    .red {
      color: #fa5252;
    }
    .yellow {
      color: #ff7e05;
    }
    .ordinary {
      color: #ffde73;
    }
    .commonly {
      color: #32e0a6;
    }
  }
  /deep/.in-active-path {
    background: #0075ff;
  }
  /deep/ .el-cascader-node__prefix {
    display: none;
  }
}
</style>
<style lang="less">
.g-dialog-big {
  background-color: transparent !important;
  .el-dialog {
    background-color: transparent !important;
    margin-top: 10% !important;
    .el-dialog__body {
      padding: 0 0 !important;
      background-color: transparent !important;
    }
    .el-dialog__header {
      display: none !important;
      background-color: transparent !important;
    }
  }
}
.flexequip {
  display: flex;
  height: 450px;
  .leftwarp {
    width: 170px;
    height: 100%;
    border-radius: 5px;
    border-right: solid 1px var(--button-bg-color);
    overflow-y: auto;
    overflow-x: hidden;
    .equtext {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 150px;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .equson {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: var(--button-bg-color);
      font-family: var(--font-title);
      text-align: left;
      box-sizing: border-box;
      padding-left: 10px;

      width: 160px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid var(--button-bg-color);
      text-overflow: ellipsis !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      cursor: pointer;
    }
    .equson2 {
      width: 160px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: var(--common-white);
      font-family: var(--font-title);
      text-align: left;
      padding-left: 10px;
      height: 50px;
      line-height: 50px;
    }
  }
  .rightwarp {
    .padding {
      .el-icon-close {
        float: right;
        margin-right: -15px;
        margin-top: -5px;
        font-size: 14px;
        cursor: pointer;
      }
      padding: 10px 20px;
      .flex {
        margin-bottom: 10px;
        .shu {
          border-left: var(--button-bg-color) 5px solid;
          padding-left: 10px;
          font-family: var(--font-title);
        }
      }
      .double {
        display: flex;
        line-height: 30px;
        .tetxspan2 {
          float: right;
        }
        .number {
          font-family: var(--font-number);
          font-size: 110%;
          padding-left: 2px;
          padding-right: 2px;
        }

        .doudiv {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 320px;
        }
        .text {
          width: 80px;
          height: 30px;
          margin-left: 15px;
          text-align: right;
          color: var(--button-bg-color);
          float: right;
          cursor: pointer;
        }
        .but {
          width: 80px;
          height: 30px;
          background-color: var(--button-bg-color);
          border-radius: 4px;
          float: right;
          text-align: center;
          line-height: 30px;
          margin-left: 14px;
          font-size: 12px;
          cursor: pointer;
        }
        .noClick {
          background: #999;
          color: #eee;
        }
        .img1 {
          margin-right: 3px;
          margin-left: 0.0625rem;
          width: 14px;
          height: 16px;
          margin-top: 8px;
        }

        .img2 {
          margin-right: 3px;
          margin-left: 0.0625rem;
          width: 14px;
          height: 16px;
          margin-top: 8px;
        }
      }
    }

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: var(--common-white);
    width: calc(100% - 160px);
    .doublebottom {
      padding: 20px 20px;
      width: 100%;
      border-top: solid 1px #2e59b9;
      margin-top: 4px;
      box-sizing: border-box;
      overflow: auto;
      height: 300px;
      .flexclass {
        display: flex;
      }
      .flex {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .righttext {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
          width: 50px;
          line-height: 25px;
          width: 53px;
          height: 25px;
          background-color: #0075ff;
          border-radius: 4px;
          opacity: 0.8;
          text-align: center;
          margin-top: 8px;
        }
        .flextoo {
          display: flex;
        }
        .flexdiv {
          width: 36px;
          height: 16px;
          line-height: 16px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          text-align: center;
          margin-right: 10px;
          margin-top: 2px;
          border: 1px solid #fa5252;
          color: #fa5252;
        }

        .centertext {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .centertime {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: rgba(162, 176, 203, 0.81);
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
