<template>
  <div>
    <div class="time-flow-top">
      <span class="exit-btn" @click="exitPlay">
        <i class="icon el-icon-arrow-left"></i>
        {{ $t('i18nKey_cockpit_120') }}
      </span>
      <span class="exit-btn exit-btn2" @click="isShowselectModuleFn">
        <i class="icon iconfont icon-xingzhuang2"></i>
        {{ $t('i18nKey_cockpit_121') }}
      </span>
      <span class="exit-btn exit-btn3" v-if="!stateRoam" @click="roam">
        <i class="icon iconfont icon-zidong2"></i>
        {{ $t('i18nKey_cockpit_122') }}
      </span>
      <span class="exit-btn exit-btn3" v-else >
        <p v-if="roamStart" @click="PauseParade(true)">
          <i class="icon iconfont icon---icon_bofang2"></i>
          {{ $t('i18nKey_cockpit_123') }}
        </p>
        <p @click="PauseParade(false)" v-else>
          <i class="icon iconfont icon-icon_pause"></i>
          {{ $t('i18nKey_cockpit_124') }}
        </p>
      </span>
    </div>
    <streetScapeModule @selectModuleFn="selectModuleFn" :visible.sync="canSelectModule" v-if="canSelectModule"></streetScapeModule>
  </div>
</template>
<script>
// 1小时播放5s,总共120s
// 1秒走10次 共1200 次
// import moment from 'moment'
import { mapMutations, mapState } from "vuex";
import streetScapeModule from "@/layout/components/streetScapeModule"

const STEPS = 1200
// let prevHours = -1;
export default {
  components:{
    streetScapeModule
  }, 
  data() {
    return {
      curTime: 0,
      isStop: false,
      steps: STEPS,
      stateRoam: false,
      roamStart: false,
      canSelectModule: false,
      curModule: null
    };
  },
  computed: {
    ...mapState(['vrmapParadeName'])
    // currentTime(){
    //   return moment('2020-01-01 00:00:00').add(this.curTime *1.2, 'minutes').format('HH:mm:ss')
    // },
  },
  watch: {
    // curTime(newVal) {
    //   let time = Math.round((newVal * 1.2) / 60);
      
    //   if( time !== prevHours){
    //     this.$emit("timeChange", time);
    //     prevHours = time;
    //   }
    // }
  },
  created(){
    this.curModule = this.vrmapParadeName
  },
  mounted() {
    // this.startTravel();
  },
  beforeDestroy() {
    // this.stopTravel();
  },
  methods: {
    ...mapMutations(["fillFullScreen", "fillTimeFlowOpen"]),
    exitPlay() {
      this.fillTimeFlowOpen(false);
      // this.stopTravel();
      this.fillFullScreen(false);
      this.$vuePubSub.$emit("modifyParadeModel", {model: "Pawn"});
      

      document.body.classList.remove("time-flow-open");
      document.body.classList.remove("full-screen");
      // 离开的时候重置为默认时间
      // this.$emit("timeChange", 13);
      // this.$emit("exitTimeFlowPlay");
      this.$vuePubSub.$emit("mapToolBarChange", "30rem");
      this.$vuePubSub.$emit("layerShowFn", true);
      // 恢复隐藏的poi点位
      // 恢复视角
      sessionStorage.removeItem("selectModuleInfo");
    },
    stopTravel() {
      clearInterval(this.timerId);
    },
    roam() {
      this.stateRoam = true;
      this.$vuePubSub.$emit("startParade", this.curModule);
    },
    PauseParade(bool) {
      // this.stateRoam = false;
      this.roamStart = !this.roamStart;
      this.$vuePubSub.$emit("pauseParade", bool);
    },
    isShowselectModuleFn() {
      this.canSelectModule = true;
      this.$vuePubSub.$emit("pauseParade", false);
    },
    selectModuleFn(type) {
      this.stateRoam = false;
      this.canSelectModule = false;
      this.curModule = type;
      this.$vuePubSub.$emit("modifyParadeModel", {model: "Character", paradeName: type});
    }    
  }
};
</script>
<style lang="less" scoped>
.current-time-txt {
  color: #fff; 
  margin-left: 14px; 
  letter-spacing: 2px;
}
.time-flow-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  line-height: 48px;
  z-index: 10000;
  background: url(../../assets/images/overview/top.png);
  background-size: contain;
  background-repeat: no-repeat;
  .exit-btn {
    font-size: 16px;
    color: #a2b0cb;
    position: absolute;
    left: 44%;
    top: 15%;
    transform: translate(-50%, 50%);
    cursor: pointer;
  }
  .exit-btn2 {
    left: 50%;
  }
  .exit-btn3 {
    left: 56%;
  }
}
.time-flow-bottom {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 10000;
  transform: translateX(-50%);
  width: 1042px;
  height: 67px;
  background: #1d2745;
  border-radius: 8px;
  padding: 0 27px 0 39px;
  display: flex;
  align-items: center;
  .control-btn {
    background: #101b35;
    border: 1px solid #2e3859;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  .m-slider {
    flex: 1;
  }
}
</style>
<style lang="less">
.time-flow-bottom {
  .m-slider {
    .el-slider__runway {
      background: #101b35;
      .el-slider__bar {
        background-image: linear-gradient(90deg, #0075ff 0%, #3239cd 100%);
      }
    }
    .el-slider__button {
      width: 12px;
      height: 12px;
    }
  }
}
</style>