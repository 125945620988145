<template>
  <div ref="popup" class="other-equip-pop">
    <!-- 多条数据 -->
    <div v-if="selectList.length > 0">
      <div class="details">
        <div class="code-m">
          {{ selectList[0].equipmentCn ? selectList[0].equipmentCn : "" }}
        </div>
        <div class="name-m">{{ selectList[0].eqpType3Cn }}</div>
        <div class="address-m">{{ selectList[0].locationPathFullCn }}</div>
      </div>
      <div class="list">
        <div class="lists-m" v-for="(d, i) in selectList" :key="i">
          <div class="left">
            <div class="status">{{ d.incidentLevelCn }}</div>
            <div class="titleAndTime">
              <span>{{ d.equipmentCn }}</span>
              <span>{{ d.createDate }}</span>
            </div>
          </div>
          <div class="right">
            <span @click="distribute(d)" v-show="d.incidentStatusEn !== 'treated' && showDisPatch"
              >派发</span
            >
            <span @click="toDetail({ dialogVisible: true }, d)">详情</span>
          </div>
        </div>
      </div>
      <div class="toFloor-m">
        <el-button type="primary" v-show="intoFloorShow" @click="intoFloor">进入楼层</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import Api from "@/dataApi/operationReport.js";
export default {
  name: "otherEquipAbnormalPop",
  components: {},
  data() {
    return {
      evenObj: {},
      selectList: [],
    };
  },
  props: {
    equipmentCode: {
      type: String,
      default: "",
    },
    downSpaceCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapState(["isInFloor"]),
    intoFloorShow() {
      if (window.sessionStorage.getItem("gisType") === "vrbim" && !this.isInFloor) {
        return true;
      } else {
        return false;
      }
    },
    showDisPatch() {
      return true;
      // if (localStorage.lay_selectedPosCode === "CN_44_003_SZGJCXG_P1") {
      //   return false;
      // } else {
      //   return true;
      // }
    },
  },
  watch: {
    equipmentCode: {
      immediate: true,
      handler(v) {
        if (v) {
          this.detailData(v);
        }
      },
    },
  },
  methods: {
    ...mapMutations(["fillWorkShow", "fillPopShow", "fillproprtyworker", "fillDownSpaceCode"]),
    ...mapMutations("equipInfo", ["equipAbnormalDetail"]),
    toDetail(val, item) {
      this.equipAbnormalDetail({
        ...val,
        ...item,
        downSpaceCode: this.downSpaceCode,
      });
    },
    detailData(v) {
      //用新接口查询
      let page = `parkCode=${this.positionInfo.posCode}&pageNumber=1&pageSize=1000`;
      // Api.listData({ keyword: v, incidentStatusCodeList: ["10051001"] }, page)
      Api.listData({ keyword: v }, page)
        .then((data) => {
          if (data.list.length > 0) {
            this.selectList = data.list;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getWorkOption() {
      let params = {
        pageNumber: 1,
        pageSize: 500,
        roleType: 6,
      };
      Api.workOption(params)
        .then((res) => {
          sessionStorage.setItem("workOrder", JSON.stringify(res));
          this.fillproprtyworker(res);
          this.fillPopShow(false);
          this.fillWorkShow(true);
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    distribute(d) {
      this.getWorkOption();
      this.$store.commit("workerInfodata", d);
    },
    intoFloor() {
      this.fillPopShow(false);
      this.fillDownSpaceCode(this.downSpaceCode);
    },
  },
};
</script>
<style lang="less" scoped>
.other-equip-pop {
  background: #1a2441;
  color: #fff;
  padding: 25px 15px 0 15px;
  font-size: 14px;
  .details {
    font-size: 14px;
    color: #fff;
    border-bottom: 3px solid #465278;
    padding-bottom: 10px;
    .code-m,
    .name-m {
      margin-bottom: 10px;
    }
    .address-m {
      font-size: 12px;
      color: rgba(162, 176, 203, 0.81);
    }
  }
  .list {
    padding-bottom: 15px;
    max-height: 300px;
    overflow: auto;
  }
  .lists-m {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .left {
      display: flex;
      .status {
        font-size: 14px;
        width: 80px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background-color: #fa5252;
        border-radius: 11px;
        border: solid 1px #fa5252;
        margin-right: 5px;
      }
      .titleAndTime {
        display: flex;
        flex-direction: column;
        span {
          &:last-child {
            font-size: 12px;
            color: rgba(162, 176, 203, 0.81);
          }
        }
      }
    }
    .right {
      display: flex;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #3a7aea;
      width: 30%;
      span {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
  .toFloor-m {
    border-top: 1px solid #465278;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
    /deep/.el-button {
      width: 77px;
      height: 33px;
      line-height: 33px;
      font-size: 12px;
    }
  }
  .titleTop {
    display: flex;
    justify-content: space-between;
    .jinji {
      width: 80px;
      height: 23px;
      line-height: 23px;
      text-align: center;
      background-color: #fa5252;
      border-radius: 11px;
      border: solid 1px #fa5252;
    }
  }
  .other-equip-content {
    border-top: 3px solid #465278;
    margin-top: 10px;
    padding: 10px 0;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: rgba(162, 176, 203, 0.81);
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      span {
        &:last-child {
          font-family: PingFangSC-Semibold;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #3a7aea;
        }
      }
    }
    .time,
    .address {
      margin-bottom: 5px;
    }
  }
  .tofllorAndDetail {
    border-top: 3px solid #465278;
    padding: 10px 10px;
    display: flex;
    justify-content: flex-end;
    .el-button {
      width: 76px;
      height: 33px;
      line-height: 33px;
      font-size: 14px;
    }
    .el-button--primary.is-plain {
      background-color: transparent;
      color: #3a7aea;
      border-color: #3a7aea;
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
</style>
