<template>
  <div class="full-screen-header-container">
    <div class="full-header-bg"></div>
    <div class="content">
      <div class="content-main" v-show="largeScreen">
        <div class="left">{{ dateTime }}</div>
        <div class="right-title">{{ posCn }}</div>
        <div class="center">
          <div class="full-header-title">
            <img :src="corporationInfo.logoImg || corporationInfo.corporationIcon" />
            <span class="item-title font-24">{{ corporationInfo.logoTitle || corporationInfo.corporationTitle }}</span>
            
          </div>
        </div>

      </div>
      <div class="largeScreen" v-show="routerFlag > -1">
        <!-- <div class="select">
          <img src="@/assets/images/largeScrrenImg/logo.png" alt="" />
          <el-cascader
            ref="cascader"
            v-model="chosenArr"
            popper-class="lay-position-casc"
            :change-on-select="true"
            :show-all-levels="false"
            :options="options"
            :filterable="false"
            :props="{ label: 'label', value: 'value', checkStrictly: true }"
            @change="handleChange"
            @visible-change="visibleChange"
          >
          </el-cascader>
        </div>
        <div class="title">
          <span>深国际经营数据大屏</span>
        </div> -->
        <div class="time">
          <!-- <div class="date">{{ dateTime }}</div> -->
          <div class="outScreen" @click="toFullScreen($event)">
            <span v-show="fullScreen">退出全屏</span>
            <span v-show="!fullScreen">全屏</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
let timer = null;
moment.locale("zh-cn");
export default {
  props: {
    largeScreen: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      dateTime: "",
      corporationInfo: this.$store.state.corporationVO,
      posCn: this.$store.getters["basePosition/positionInfo"].posCn,
      options: [
        {
          value: "sgj",
          label: "深国际控股集团",
          children: [
            {
              value: "szbk",
              label: "商置版块",
              children: [
                {
                  value: "qhydds",
                  label: "前海颐都大厦",
                },
              ],
            },
          ],
        },
      ],
      fullScreen: false,
      chosenArr: ["sgj"],
    };
  },
  computed: {
    ...mapState("largeScreenData", ["type"]),
    routerFlag() {
      return window.location.href.indexOf("largeDataScreen");
    },
  },
  created() {
    this.dateTime = moment().format("YYYY-MM-DD dddd LTS");
    timer = setInterval(() => {
      this.dateTime = moment().format("YYYY-MM-DD dddd LTS");
    }, 1000);
  },
  mounted() {
    setInterval(function () {
      document.querySelectorAll(".el-cascader-node__label").forEach((el) => {
        el.onclick = function () {
          if (this.previousElementSibling) this.previousElementSibling.click();
        };
      });
    }, 1000);
  },
  methods: {
    ...mapMutations("largeScreenData", ["typeChange"]),
    handleChange(value = []) {
      this.chosenV = value;
      switch (value.length) {
        case 1:
          this.typeChange(1);
          break;
        case 2:
          this.typeChange(2);
          break;
        case 3:
          this.typeChange(3);
          break;
      }
    },
    // 监听下拉框展开或收起
    visibleChange(e) {
      setTimeout(() => {
        this.canTriggerPositonChange = !!e;
      }, 500);
    },
    toFullScreen() {
      if (window.location.href.indexOf("largeDataScreen") > -1) {
        let className = document.body.className;
        if (this.fullScreen) {
          this.fullScreen = false;
          localStorage.setItem("isFullScreen", false);
          document.body.className = className.replace(/\s*(full-screen)/g, "");
          document.webkitCancelFullScreen && document.webkitCancelFullScreen();
          document.cancelFullScreen && document.cancelFullScreen();
        } else {
          this.fullScreen = true;
          localStorage.setItem("isFullScreen", true);
          document.body.className += `${className && " "}full-screen`;
          document.body.focus();
          document.documentElement.webkitRequestFullScreen &&
            document.documentElement.webkitRequestFullScreen();
          document.documentElement.requestFullScreen &&
            document.documentElement.requestFullScreen();
        }
        this.$emit("fullScreenClick", this.fullScreen);
      } else {
        if (this.fullScreen) {
          this.fullScreen = false;
          localStorage.setItem("isFullScreen", false);
        } else {
          this.fullScreen = true;
        }
        this.$emit("fullScreenClick", this.fullScreen);
      }
    },
  },
  beforeDestroy() {
    clearInterval(timer);
  },
};
</script>
<style lang="less" scoped>
.full-screen-header-container {
  position: fixed;
  top: -18px;
  z-index: 9;
  height: 120px;
  left: 0;
  width: 100%;
  background: url(../assets/images/overview/fullscreen-header.svg) no-repeat center center;
  background-size:100% auto;
  min-height: auto;
  color:#fff;
  line-height: 60px;
  font-size: 16px;
  font-family: var(--font-title);
  backdrop-filter: blur(2px);
  .right-title{
    position: absolute;
    left: 68%;
    line-height: 60px;
  }
  /deep/.el-cascader {
    line-height: 120px;
    margin-left: 20px;
    .el-cascader__label {
      color: #a2b0cb;
    }
    .el-cascader-menu__item {
      color: #a2b0cb;
    }
    .el-input__inner {
      background: transparent;
    }
  }
  .content-main {
    padding: 1rem 1.6rem;
    .left {
      float: left;
    }
    .right {
      float: right;
    }
    .center {
      text-align: center;
      .full-header-title {
        img {
          max-height: 30px;
        }
        display: inline-block;
        margin-left: -220px;
      }
    }
  }
  .largeScreen {
    display: flex;
    justify-content: flex-end;
    .select {
      display: flex;
      align-items: center;
      width: 30%;
      img {
        width: 136px;
        height: 30px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    .title {
      width: 30%;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      padding-top: 30px;
    }
    .time {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .outScreen {
        line-height: 20px;
        width: 136px;
        height: 43px;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-image: url("../assets/images/largeScrrenImg/outFullScreen.png");
        background-size: 136px 38px;
        cursor: pointer;
        span {
          cursor: pointer;
          display: inline-block;
          position: relative;
          top: 10px;
          left: 60px;
        }
      }
    }
  }
  .font-24 {
    font-size: 24px;
  }
  .font-20 {
    margin-top: 9px;
    font-size: 20px;
  }
  .item-sub-title {
    margin-left: 20px;
  }
}
</style>
