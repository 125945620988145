<template>
  <div class="config-wrapper">
    <div class="stiuation">
      <div class="stiuation-box">
        <div class="box-left">
          <span class="img-category">{{ $t("i18nKey_cockpit_113") }}</span>
        </div>
        <div class="box-center">
          <div v-if="generalImgUrl" class="upload-img">
            <img :src="generalImgUrl" class="img-general" alt />
            <span class="icon-remove" @click="deleteGeneralImageAction()">
              <img src="../../assets/images/common/icon-remove.png" alt />
            </span>
          </div>
          <el-upload
            v-else
            class="upload-demo"
            ref="upload"
            action="/"
            multiple
            :before-upload="doGeneralPostsure"
            list-type="picture-card"
            :show-file-list="false"
            :auto-upload="true"
          >
            <div
              v-loading="generalLoading"
              element-loading-spinner="el-icon-loading"
            >
              <img src="../../assets/images/common/icon_addImage.jpg" alt />
            </div>
          </el-upload>
        </div>
      </div>
      <div class="stiuation-box">
        <div class="box-left">
          <span class="img-category">{{ $t("i18nKey_cockpit_114") }}</span>
        </div>
        <div class="box-center">
          <div v-if="productImgUrl" class="upload-img">
            <img :src="productImgUrl" class="img-general" alt />
            <span class="icon-remove" @click="deleteProductImageAction()">
              <img src="../../assets/images/common/icon-remove.png" alt />
            </span>
          </div>
          <el-upload
            v-else
            class="upload-demo"
            ref="upload"
            action="/"
            multiple
            :before-upload="doProductPostsure"
            list-type="picture-card"
            :show-file-list="false"
            :auto-upload="true"
          >
            <div
              v-loading="productLoading"
              element-loading-spinner="el-icon-loading"
            >
              <img src="../../assets/images/common/icon_addImage.jpg" alt />
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <!-- BIM模型 -->
    <div class="bim-box">
      <div class="title">BIM模型背景图配置</div>
      <div class="imgContain">
        <div class="static-dialog-imglist">
          <ul class="static-imglist-items">
            <li
              v-for="(item, index) in allCardList"
              :key="index"
              class="static-imglist-item"
              v-show="!item.isHide"
              :class="{ isChecked: item.isChecked }"
              :title="`${item.statisticalCardName}`"
              @click="checkboxSelectFunc(index)"
            >
              <div class="static-imglist-items-div">
                <el-checkbox
                  :value="item.isChecked"
                  @change="checkboxSelectFunc(index)"
                ></el-checkbox>
              </div>
              <div
                :class="[
                  'static-imglist-items-img',
                  item.isChecked && 'active',
                ]"
              >
                <img :src="item.imgurl" alt />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "normalConfig",
  props: {
    imgConfig: Array,
  },
  watch: {
    imgConfig(val) {
      this.configImgList = val;
      if (val.length) {
        for (let i = 0; i < val.length; i++) {
          if (val[i].fileName && val[i].bucketName) {
            if (val[i].dataType === "PRODUCE_STATE_PIC") {
              try {
                if (val[i].fileName && val[i].bucketName) {
                  window.GLOBAL_OBJ.obsApi
                    .obsDownload(val[i].fileName, val[i].bucketName)
                    .then((url) => {
                      this.productImgUrl = url;
                    });
                  setTimeout(() => {
                    window.GLOBAL_OBJ.obsApi
                      .obsDownload(val[i].fileName, val[i].bucketName)
                      .then((url) => {
                        this.productImgUrl = url;
                      });
                  }, 100);
                }
              } catch (err) {
                console.log("err", err);
              }
            } else if (val[i].dataType === "GLOBAL_STATE_PIC") {
              try {
                window.GLOBAL_OBJ.obsApi
                  .obsDownload(val[i].fileName, val[i].bucketName)
                  .then((url) => {
                    this.generalImgUrl = url;
                  });
              } catch (err) {
                console.log("err", err);
              }
            }
          }
        }
        try {
          let ALLBGIMG = [];
          val.forEach((item) => {
            if (item.dataType === "BIM_BACKGROUND_PIC") {
              ALLBGIMG.push(item);
            }
          });
          if (ALLBGIMG.length > 0) {
            this.allCardList.forEach((item) => {
              if (ALLBGIMG[0].bucketName === item.imgurl) {
                item.isChecked = true;
                this.chosenKeys.push(item.imgurl);
              }
            });
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    },
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapGetters(["tabsAndCardsList", "globalChosenCardAuthKeys"]),
    ...mapState(["btnAuthObj", "userInfo", "validCardList"]),
  },
  data() {
    return {
      generalImgUrl: "", // 存放总体态势图片
      generalLoading: false, // general上传的loading
      productImgUrl: "", // 存放总体态势图片
      productLoading: false, // general上传的loading
      configImgList: [], // 配置图片列表
      loading: false,
      allCardList: [
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg1.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection01",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg2.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection02",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg3.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection03",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg4.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection04",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg5.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection05",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg6.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection06",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg7.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection07",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg8.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection08",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg9.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection09",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg10.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection10",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg11.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection11",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg12.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection12",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg13.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection13",
          statisticalCardName: "视频巡逻统计",
        },
        {
          componentName: "IocChartVideoPatrolStatistics",
          imgurl: "/security-gis/static/imagesMap/bimBg14.jpg",
          isChecked: false,
          sortIndex: 0,
          statisticalCardCode: "cardProtection14",
          statisticalCardName: "视频巡逻统计",
        },
      ],
      chosenKeys: []
    };
  },
  methods: {
    deleteProductImageAction() {
      this.productImgUrl = "";
      if (!this.configImgList.length) return;
      let isInclude = false;
      let includeIndex = 0;
      for (let i = 0; i < this.configImgList.length; i++) {
        if (this.configImgList[i].dataType === "PRODUCE_STATE_PIC") {
          // 包含修改
          isInclude = true;
          includeIndex = i;
          break;
        }
      }
      if (isInclude) {
        this.configImgList[includeIndex].bucketName = "";
        this.configImgList[includeIndex].fileName = "";
        this.$emit("configImgListChange", this.configImgList);
      }
      this.$emit("configImgListChange", this.configImgList);
    },
    doProductPostsure(file) {
      let name = file.name;
      var reg = /(\.jpeg|\.png|\.jpg|\.bmp)/i;
      if (reg.test(name)) {
        if (file.size > 20971520) {
          this.$message({
            message: this.$t("i18nKey_cockpit_115") + "M",
            type: "error",
            customClass: "statisConfigTipClass",
            duration: 3000,
          });
        } else {
          this.productLoading = true;
          window.GLOBAL_OBJ.obsApi
            .obsUpload({ businessType: "safetymgmt", file })
            .then(async (res) => {
              this.productLoading = false;
              if (res && res.status === 200) {
                if (this.configImgList.length) {
                  let isInclude = false;
                  let includeIndex = 0;
                  for (let i = 0; i < this.configImgList.length; i++) {
                    if (
                      this.configImgList[i].dataType === "PRODUCE_STATE_PIC"
                    ) {
                      // 包含修改
                      isInclude = true;
                      includeIndex = i;
                      break;
                    }
                  }
                  if (isInclude) {
                    this.configImgList[includeIndex].bucketName =
                      res.bucketName;
                    this.configImgList[includeIndex].fileName = res.fileName;
                    this.$emit("configImgListChange", this.configImgList);
                  } else {
                    // 添加
                    let item = {
                      bucketName: res.bucketName,
                      fileName: res.fileName,
                      dataType: "PRODUCE_STATE_PIC",
                    };
                    this.configImgList.push(item);
                    this.$emit("configImgListChange", this.configImgList);
                  }
                } else {
                  // 添加
                  let item = {
                    bucketName: res.bucketName,
                    fileName: res.fileName,
                    dataType: "PRODUCE_STATE_PIC",
                  };
                  this.configImgList.push(item);
                  this.$emit("configImgListChange", this.configImgList);
                }
                try {
                  await window.GLOBAL_OBJ.obsApi
                    .obsDownload(res.fileName, res.bucketName)
                    .then((url) => {
                      this.productImgUrl = url;
                    });
                } catch (err) {
                  console.log("err", err);
                }
              } else {
                this.$message({
                  message: this.$t("i18nKey_cockpit_116"),
                  type: "error",
                  customClass: "statisConfigTipClass",
                  duration: 3000,
                });
              }
            })
            .catch(() => {
              this.generalLoading = false;
            });
        }
      } else {
        this.$message({
          message:
            this.$t("i18nKey_cockpit_117") +
            "jpeg/png/jpg/bmp" +
            this.$t("i18nKey_cockpit_118"),
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 3000,
        });
      }
    },
    // 删除手动上传General图
    deleteGeneralImageAction() {
      this.generalImgUrl = "";
      if (!this.configImgList.length) return;
      let isInclude = false;
      let includeIndex = 0;
      for (let i = 0; i < this.configImgList.length; i++) {
        if (this.configImgList[i].dataType === "GLOBAL_STATE_PIC") {
          // 包含修改
          isInclude = true;
          includeIndex = i;
          break;
        }
      }
      if (isInclude) {
        this.configImgList[includeIndex].bucketName = "";
        this.configImgList[includeIndex].fileName = "";
        this.$emit("configImgListChange", this.configImgList);
      }
      this.$emit("configImgListChange", this.configImgList);
    },
    doGeneralPostsure(file) {
      let name = file.name;
      var reg = /(\.jpeg|\.png|\.jpg|\.bmp)/i;
      if (reg.test(name)) {
        if (file.size > 20971520) {
          this.$message({
            message: "图片大小不能超过20M",
            type: "error",
            customClass: "statisConfigTipClass",
            duration: 3000,
          });
        } else {
          this.generalLoading = true;
          window.GLOBAL_OBJ.obsApi
            .obsUpload({ businessType: "safetymgmt", file })
            .then(async (res) => {
              this.generalLoading = false;
              if (res && res.status === 200) {
                if (this.configImgList.length) {
                  // 更新
                  let isInclude = false;
                  let includeIndex = 0;
                  for (let i = 0; i < this.configImgList.length; i++) {
                    if (this.configImgList[i].dataType === "GLOBAL_STATE_PIC") {
                      // 包含修改
                      isInclude = true;
                      includeIndex = i;
                      break;
                    }
                  }
                  if (isInclude) {
                    this.configImgList[includeIndex].bucketName =
                      res.bucketName;
                    this.configImgList[includeIndex].fileName = res.fileName;
                    this.$emit("configImgListChange", this.configImgList);
                  } else {
                    // 添加
                    let item = {
                      bucketName: res.bucketName,
                      fileName: res.fileName,
                      dataType: "GLOBAL_STATE_PIC",
                    };
                    this.configImgList.push(item);
                    this.$emit("configImgListChange", this.configImgList);
                  }
                } else {
                  // 添加
                  let item = {
                    bucketName: res.bucketName,
                    fileName: res.fileName,
                    dataType: "GLOBAL_STATE_PIC",
                  };
                  this.configImgList.push(item);
                  this.$emit("configImgListChange", this.configImgList);
                }
                try {
                  await window.GLOBAL_OBJ.obsApi
                    .obsDownload(res.fileName, res.bucketName)
                    .then((url) => {
                      this.generalImgUrl = url;
                    });
                } catch (err) {
                  console.log("err", err);
                }
              } else {
                this.$message({
                  message: "上传失败！",
                  type: "error",
                  customClass: "statisConfigTipClass",
                  duration: 3000,
                });
              }
            })
            .catch(() => {
              this.generalLoading = false;
            });
        }
      } else {
        this.$message({
          message: "只能上传jpeg/png/jpg/bmp格式的图片",
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 3000,
        });
      }
    },
    checkboxSelectFunc(index) {
      let i = 0;
      this.allCardList.forEach(({ isChecked }) => {
        isChecked && i++;
      });
      let thatCheckStatus = !this.allCardList[index].isChecked;
      let thatCard = {
        ...this.allCardList[index],
        isChecked: thatCheckStatus,
      };
      this.allCardList.forEach((item, index2) => {
        item.isChecked = false;
        if (index2 === index) {
          this.allCardList.splice(index, 1, thatCard);
        }
      });
      if (this.allCardList[index] && this.allCardList[index].isChecked) {
        // 添加
        let bgList = {
          bucketName: this.allCardList[index].imgurl,
          fileName: this.allCardList[index].imgurl,
          BackGroundName: this.allCardList[index].imgurl,
          dataType: "BIM_BACKGROUND_PIC",
        };
        this.configImgList.forEach((item) => {
          if (item.dataType === "BIM_BACKGROUND_PIC") {
            bgList.id = item.id;
          }
        });

        let paramArr = [];
        this.configImgList.forEach((item) => {
          if (
            item.dataType === "PRODUCE_STATE_PIC" ||
            item.dataType === "GLOBAL_STATE_PIC"
          ) {
            paramArr.push(item);
          }
        });
        if (bgList.BackGroundName) {
          paramArr.push(bgList);
        }
        this.$emit("configImgListChange", paramArr);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 20px;
  color: #a2b0cb;
}
.static-dialog-imglist {
  height: 17.5rem;
  margin: 0 25px;
  padding-right: 3.6px;
  overflow: hidden;
  overflow-y: auto;
}

.static-imglist-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .ischecked {
    border: 1px solid #2f549c !important;
  }

  .static-imglist-item {
    background-color: #1d2745;
    width: 23rem;
    height: 8rem;
    margin-bottom: 8px;
    margin-right: 5px;
    position: relative;
    border: 1px solid #1d2338;

    .static-imglist-items-div {
      line-height: 22px;
    }

    .static-imglist-items-img {
      height: 100%;
      &.active {
        border: 2px solid #409eff;
      }
      img {
        margin-top: 1px;
        max-width: 99%;
        max-height: 99%;
      }
    }

    .el-checkbox__input {
      &.is-checked {
        .el-checkbox__inner {
          border-color: #388bff;
          background-color: #388bff;
        }
      }
    }

    .el-checkbox__inner {
      border-color: #495873;
      background-color: transparent;
    }

    .el-checkbox {
      position: absolute;
      right: 0;
      bottom: -6px;
    }

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.configuration-bottom {
  margin: auto;
  width: 100%;
  padding: 20px 0;
  text-align: center;

  .buttonClass {
    margin: 0 10px;
    width: 110px;
    height: 35px;
    line-height: 30px;
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
  }

  .buttonClass:focus {
    outline: none;
  }

  .buttonBorColor {
    background-color: #3a7aea;
    border: 1px solid #3a7aea;
    color: #fff;
  }

  .buttonBorColoeColse {
    border: 1px solid #54617b;
    background: transparent;
    color: #fff;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #1a213a;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #1a213a;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #404a70;
}
.config-wrapper {
  padding-left: 2.0625rem;
  padding-top: 1.875rem;
  .stiuation {
    display: flex;
  }
  .content-header {
    font-size: 1.25rem;
    color: #ffffff;
  }
  .stiuation-box {
    &:first-child {
      margin-right: 50px;
    }
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    .box-left {
      display: flex;
      flex-direction: column;
      .img-category {
        color: #a2b0cb;
        font-size: 1rem;
      }
    }
    .box-center {
      .upload-demo {
        margin-left: 16px;
      }
      .upload-img {
        margin-left: 16px;
        position: relative;
      }
      .img-general {
        height: 12rem;
        max-width: 575px;
      }
      .icon-remove {
        cursor: pointer;
        position: absolute;
        right: 4px;
        top: 6px;
      }
      /deep/ .el-upload--picture-card {
        background-color: transparent;
        border: none;
      }
      /deep/ .el-loading-spinner {
        top: 50%;
        width: 100%;
        text-align: center;
        position: initial;
        margin: auto;
      }
    }
  }
}
</style>
