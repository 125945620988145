export default {
  executionTime: '执行总时长',
  cumulativeHappened: '累计发生',
  cumulativeProcessing: '累计处理',
  treatmentRate: '处理完成率',
  processingTimeliness: '处理及时率',
  timeoutProcessed: '超时未处理',
  processedTimeout: '超时后已处理',
  energyAIDiagnostics: '能效AI诊断',
};
