import singleSpaVue from "single-spa-vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import comComponents from "comComponents";
import errorMessage from "./components/errorMessage.vue";
import "./init";
import { vueI18n } from "@/i18n.js";
import axios from "axios";
import mysip from "./js/mysip";
import './assets/fonts/iconfont.min.js';
import './assets/fonts/iconfont.css';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$mysip = mysip;
Vue.prototype.youquanApi = axios;
Vue.$httpRequestList = [];
for (let name in comComponents.IocChart) {
  Vue.component(name, comComponents.IocChart[name]);
}
Vue.component("errorMessage", errorMessage);
Vue.config.productionTip = false;
Vue.prototype.$vuePubSub = new Vue();
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    store,
    i18n: vueI18n,
  },
});
let winWidth;
if (window.innerWidth) {
  winWidth = window.innerWidth;
} else if ((document.body) && (document.body.clientWidth)) {
  winWidth = document.body.clientWidth;
}
window.GLOBAL_OBJ.winWidth = winWidth;
Vue.prototype.isSmallWin = winWidth <= 1366;
Vue.prototype.rem = (winWidth / 120 / 16) < 1 ? 1 : (winWidth / 120 / 16);


/**
 * 丹田项目获取数据
 */
const laySelectedPosCode = localStorage.getItem("lay_selectedPosCode");
// eslint-disable-next-line
if (isDanTianPark && isDanTianPark(laySelectedPosCode)) {
  let scriptEvent = document.createElement("script");
  scriptEvent.type = "text/javascript";
  scriptEvent.src = `/js/dantian/${laySelectedPosCode}/eventData1.js`;
  document.getElementById("indexHead").appendChild(scriptEvent);
}

// 机场卫星厅数据
if (laySelectedPosCode === 'CN_SC_44_003_PARK000028') {
  let scriptEvent = document.createElement("script");
  scriptEvent.type = "text/javascript";
  scriptEvent.src = `/js/airPort/${laySelectedPosCode}.js`;
  document.getElementById("indexHead").appendChild(scriptEvent);
}


// 打上gitcommit信息
if (!window.$WANRUI) {
  window.$WANRUI = {}
}
window.$WANRUI.IOCWEBCOCKPIT_GITCOMMIT = process.env.VUE_APP_GITCOMMIT;
window.$WANRUI.IOCWEBCOCKPIT_GITADDRESS = 'https://git.vankeservice.com/ioc/ioc-web-cockpit.git';


export const bootstrap = vueLifecycles.bootstrap;
export const mount = (props) => vueLifecycles.mount(props).then(() => {});
export const unmount = vueLifecycles.unmount;
