import { I18N } from "../i18n.js";
const i18nData = Object.freeze({
  cockpit1: I18N.common.i18nKey_cockpit_1,
  cockpit2: I18N.common.i18nKey_cockpit_2,
  cockpit3: I18N.common.i18nKey_cockpit_3,
  cockpit4: I18N.common.i18nKey_cockpit_4,
  cockpit6: I18N.common.i18nKey_cockpit_6,
  cockpit8: I18N.common.i18nKey_cockpit_8,
  cockpit10: I18N.common.i18nKey_cockpit_10,
  cockpit11: I18N.common.i18nKey_cockpit_11,
  cockpit12: I18N.common.i18nKey_cockpit_12,
});
/**
 * 数据格式化
 * @param {Number} val 需要转换的值
 * @param {Number} num 需保留几位小数
 */
export function moreFn(val, num = 1) {
  if (val > 10000) {
    return (val / 10000).toFixed(num) + i18nData.cockpit1;
  } else {
    return val;
  }
}
/**
 * 数字转成“千”
 * @param {Number} val 需要转换的值
 * @param {Number} num 需保留几位小数
 */
export function numberConvertK(val, maxVal = 0, point = 1) {
  if (val >= maxVal) {
    // 特殊要求需转成"K"的
    return parseFloat((val / 1000).toFixed(point)) + "K";
  } else {
    return val;
  }
}
export function numberConvert(val, point = 2) {
  if (val >= 1000000000) {
    return parseFloat((Math.round(val / 10000000) / 100).toFixed(point)) + "B";
  } else if (val >= 1000000) {
    return parseFloat((Math.round(val / 10000) / 100).toFixed(point)) + "M";
  } else if (val >= 1000) {
    return parseFloat((Math.round(val / 10) / 100).toFixed(point)) + "K";
  } else {
    return val;
  }
}
// 大于minNum且小于maxNum转单位为K，大于maxNum就分别转成"M"和"B"，其他不转换
export const convertUnit = (val, minNum = 100000, maxNum = 1000000) => {
  let total = 0;
  if (val >= minNum && val < maxNum) {
    total = numberConvertK(val, minNum);
  } else if (val >= maxNum) {
    total = numberConvert(val, 1);
  } else {
    total = val;
  }
  return total;
};
// 数值类型转换--亿-万-保留两位小数点
export const numberConvertFunc = (value) => {
  if (value >= 100000000) {
    value = parseFloat((Math.round(value / 1000000) / 100).toFixed(2)) + i18nData.cockpit2;
  } else if (value >= 10000000) {
    value = parseFloat((Math.round(value / 100000) / 100).toFixed(2)) + i18nData.cockpit3;
  } else if (value >= 1000000) {
    value = parseFloat((Math.round(value / 10000) / 100).toFixed(2)) + i18nData.cockpit4;
  } else if (value >= 10000) {
    value = parseFloat((Math.round(value / 100) / 100).toFixed(2)) + i18nData.cockpit1;
  }
  return value;
};
/**
 *排序--降序
 * @param {str} property
 */
export const compareDown = (property) => {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value2 - value1;
  };
};
/**
 *排序--升序
 * @param {str} property
 */
export const compareUp = (property) => {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value1 - value2;
  };
};
/**
 * 计算数组对象的百分比
 * @param {*}arr 原数组
 * @param {*} field 用来计算百分比的字段
 */
export const getPercent = (arr, field) => {
  let res = arr.slice();
  if (Array.isArray(res)) {
    let num = 0;
    let typeNum = 100;
    for (let i = 0; i < res.length; i++) {
      num += Number(res[i][field]);
    }
    const maxNum = Math.max(...res.map((item) => item[field]));
    let maxIdx = 0;
    res.forEach((item, idx) => {
      // 找出最大值的索引值
      if (item[field] === maxNum) maxIdx = idx;
      if (num > 0) {
        if (idx < res.length - 1) {
          item.percent = Math.round((item[field] * 100) / num);
          typeNum -= Math.round((item[field] * 100) / num);
        } else {
          // 特殊处理最后一个值
          if (typeNum < 0) {
            item.percent = "0";
            // 最大数重新赋值（需要减去最后一个负数值）
            res[maxIdx].percent = Math.round((maxNum * 100) / num) + typeNum;
          } else {
            item.percent = typeNum;
          }
        }
      } else {
        item.percent = "0";
      }
    });
  }
  return res;
};
/**
 * 计算总数
 * @param {Array} list
 */
export const totalNum = (list) => {
  let num = 0;
  if (list && list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      num += Number(list[i].value);
    }
  }
  return moreFn(Math.round(num));
};
// 转换大于10000的数值
export const processData = (val = 0, decimal0 = 2, decimal1) => {
  val = Number(val);
  if (Math.abs(val) >= 10000) {
    return (val / 10000).toFixed(decimal0) + i18nData.cockpit1;
  } else if (val === 0) {
    return val;
  } else {
    const decimal = decimal1 !== null && decimal1 !== undefined ? decimal1 : decimal0;
    return val.toFixed(decimal).toLocaleString();
  }
};
// 判断数值类型并转换数值
export const processDataType = (val, decimal0 = 2, decimal1) => {
  if (val === null || val === undefined) {
    return "--";
  } else {
    return processData(val, decimal0, decimal1);
  }
};
/**
 * 将分钟转成天、时、分
 *  @param {Number} time
 *  @param {Boolean} html
 */
export const calcTimeHtml = (time, html = true) => {
  let avgHandleTime = time || 0;
  var day = parseInt(avgHandleTime / 60 / 24);
  var hour = parseInt((avgHandleTime / 60) % 24);
  var min = parseInt(avgHandleTime % 60);
  let val = "";
  if (avgHandleTime === 0) {
    val = html
      ? `0<span style='color:#8091ac;font-size:14px;'>${i18nData.cockpit5}</span>`
      : `0${i18nData.cockpit6}`;
  }
  if (avgHandleTime > 0 && avgHandleTime < 1) {
    val = html
      ? `1<span style='color:#8091ac;font-size:14px;'>${i18nData.cockpit5}</span>`
      : `1${i18nData.cockpit6}`;
  }
  if (day > 0) {
    val =
      day +
      (html
        ? `<span style='color:#8091ac;font-size:14px;'>${i18nData.cockpit7}</span>`
        : i18nData.cockpit7);
  }
  if (hour > 0) {
    val +=
      hour +
      (html
        ? `<span style='color:#8091ac;font-size:14px;'>${i18nData.cockpit8}</span>`
        : i18nData.cockpit9);
  }
  if (min > 0) {
    val +=
      parseFloat(min) +
      (html
        ? `<span style='color:#8091ac;font-size:14px;'>${i18nData.cockpit5}</span>`
        : i18nData.cockpit6);
  }
  return val;
};
/**
 * 获取本周周一的日期
 */
export const getDateLastWeek = () => {
  // 设置本周星期一的毫秒数
  const start = new Date();
  const days = new Date().getDay() || 7; // 当前时间的星期几
  start.setTime(start.getTime() - 3600 * 1000 * 24 * (days - 1));
  let year = start.getFullYear();
  let month = start.getMonth() + 1;
  let date = start.getDate();
  month = month > 9 ? month : `0${month}`;
  date = date > 9 ? date : `0${date}`;
  return `${year}-${month}-${date}`;
};
/**
 * 获取本月第一天的日期
 */
export const getMonthOne = () => {
  // 设置本周星期一的毫秒数
  const start = new Date();
  let year = start.getFullYear();
  let month = start.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  return `${year}-${month}-01`;
};
/**
 * 获取本年第一天的日期
 */
export const getYearOne = () => {
  // 设置本周星期一的毫秒数
  const start = new Date();
  let year = start.getFullYear();
  return `${year}-01-01`;
};
/**
 * 获取近monthNum个月的日期
 * @param {Number} monthNum
 * @param {Boolean} type 获取的日期用途类型 ，true为获取禁用日期，false为获取显示日期
 */
export const getDateLastMonth = (monthNum, type = false) => {
  const start = new Date();
  let strYear = start.getFullYear();
  let currentDay = start.getDate();
  let strDay = start.getDate();
  let strMonth = start.getMonth() + 1;
  const lastMonthCount = strMonth - monthNum;
  if (lastMonthCount > 0) {
    strMonth = strMonth - monthNum;
  } else if (lastMonthCount < 0) {
    strMonth = 12 + (lastMonthCount % 12);
    strYear = strYear - 1 + Math.ceil(lastMonthCount / 12);
  } else {
    strYear = strYear - 1;
    strMonth = 12;
  }
  const maxDays = new Date(strYear, strMonth, 0).getDate(); // 得到新的日期月份最大有几天
  if (type) {
    // 选择的日期类型为“禁用”
    if (currentDay > maxDays) {
      // "号数"不存在 mytodo 去掉等号：
      strDay = maxDays;
    }
  } else {
    // 选择的日期类型为“显示日期”
    // if (currentDay <= maxDays) { // "号数"存在
    //   strDay = strDay + 1; // 当天不选
    // } else { //"号数"不存在， 日期设置为两者较小的一个。
    //   strDay = maxDays;
    // }
    if (currentDay < maxDays) {
      // "号数"存在
      strDay = strDay + 1; // 当天不选
    } else {
      //"号数"不存在， 日期设置为两者较小的一个。
      strDay = 1;
      strMonth = strMonth + 1;
    }
  }
  if (strMonth < 10) {
    strMonth = `0${strMonth}`;
  }
  if (strDay < 10) {
    strDay = `0${strDay}`;
  }
  return `${strYear}-${strMonth}-${strDay}`;
};

/**
 * 禁用monthNum个月以前的日期
 * @param {Date} time
 * @param {Number} monthNum
 */
export const disableDateLastMonth = (time, monthNum = 3) => {
  const lastMonthTime = new Date(getDateLastMonth(monthNum, true)).getTime();
  return lastMonthTime >= time.getTime();
};

export const lastWeekDate = {
  text: i18nData.cockpit10,
  onClick(picker) {
    const end = new Date();
    // 设置本周星期一的毫秒数
    const start = new Date();
    const days = new Date().getDay() || 7; // 当前时间的星期几
    start.setTime(start.getTime() - 3600 * 1000 * 24 * (days - 1));
    picker.$emit("pick", [start, end]);
  },
};

export const lastThreeMonthsDate = {
  text: i18nData.cockpit11,
  onClick(picker) {
    const end = new Date();
    const lastMonthTime = new Date(getDateLastMonth(3, false));
    picker.$emit("pick", [lastMonthTime, end]);
  },
};

export const currentMonthDate = {
  text: i18nData.cockpit12,
  onClick(picker) {
    const end = new Date();
    // 设置本月第一天
    const start = new Date().setDate(1);
    picker.$emit("pick", [new Date(start), end]);
  },
};

// 获取数值中最大值的索引
export const getMaxIndex = (arr) => {
  let maxIndex = 0;
  if (arr.length > 0) {
    maxIndex = arr.indexOf(Math.max(...arr));
  }
  return maxIndex;
};

/**
 * 计算表格高度
 * @param { dom } outerDom 最外层元素
 * @param  { dom | number } innerDoms 需减掉高度的元素
 * @return { Number } 计算后的高度
 */
export const calcTableHeight = (outerDom, ...innerDoms) => {
  const outerHeight = outerDom.offsetHeight;
  let [innerHeightSum, res] = [0, 0, 0];
  let innerHeightList = [];
  innerDoms.forEach((item) => {
    if (typeof item === "number") {
      innerHeightList.push(item);
    } else {
      innerHeightList.push(item.offsetHeight);
    }
  });
  innerHeightSum = innerHeightList.reduce((a, b) => a + b);
  res = outerHeight - innerHeightSum;
  return res;
};

/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;

    if (timeout) clearTimeout(timeout);
    if (immediate) {
      let callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    }
  };
};

/**
 * 获取前后xx天的日期
 * num如果为正数则今天之后num天，如果为负数则为今天之前日期
 * num代表天数
 */
export const getDetailDay = (num) => {
  let now = new Date();
  let time = now.getTime() + num * 3600 * 1000 * 24;
  let rsTime = new Date(time);
  let year = rsTime.getFullYear();
  let month = rsTime.getMonth() + 1;
  let date = rsTime.getDate();
  month = month > 9 ? month : `0${month}`;
  date = date > 9 ? date : `0${date}`;
  return `${year}-${month}-${date}`;
};

