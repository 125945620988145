export default {
  PSS_008_001: "/security-gis/static/polymerizationMapImg/PSS_008_001.svg",
  HVAC_003_001: "/security-gis/static/polymerizationMapImg/HVAC_003_001.svg",
  HVAC_002_011: "/security-gis/static/polymerizationMapImg/HVAC_002_011.svg",
  HVAC_007_007: "/security-gis/static/polymerizationMapImg/HVAC_007_007.svg",
  HVAC_005_001: "/security-gis/static/polymerizationMapImg/HVAC_005_001.svg",
  HVAC_007_001: "/security-gis/static/polymerizationMapImg/HVAC_007_001.svg",
  HVAC_001_001: "/security-gis/static/polymerizationMapImg/HVAC_001_001.svg",
  HVAC_007_002: "/security-gis/static/polymerizationMapImg/HVAC_007_002.svg",
  PSS_006_002: "/security-gis/static/polymerizationMapImg/PSS_006_002.svg",
  WSS_001_016: "/security-gis/static/polymerizationMapImg/WSS_001_016.svg",
  PSS_006_001: "/security-gis/static/polymerizationMapImg/PSS_006_001.svg",
  HVAC_002_009: "/security-gis/static/polymerizationMapImg/HVAC_002_009.svg",
  HVAC_003_002: "/security-gis/static/polymerizationMapImg/HVAC_003_002.svg",
  HVAC_002_008: "/security-gis/static/polymerizationMapImg/HVAC_002_008.svg",
  HVAC_002_002: "/security-gis/static/polymerizationMapImg/HVAC_002_002.svg",
  HVAC_002_003: "/security-gis/static/polymerizationMapImg/HVAC_002_003.svg",
  HVAC_002_001: "/security-gis/static/polymerizationMapImg/HVAC_002_001.svg",
  IGS_001_013: "/security-gis/static/polymerizationMapImg/IGS_001_013.svg",
  IGS_001_006: "/security-gis/static/polymerizationMapImg/IGS_001_006.svg",
  IGS_001_015: "/security-gis/static/polymerizationMapImg/IGS_001_015.svg",
  IGS_001_017: "/security-gis/static/polymerizationMapImg/IGS_001_017.svg",
  WSS_002_006: "/security-gis/static/polymerizationMapImg/WSS_002_006.svg",
  IGS_020_002: "/security-gis/static/polymerizationMapImg/IGS_020_002.svg",
  HVAC_002_025: "/security-gis/static/polymerizationMapImg/HVAC_002_025.svg",
  HVAC_002_026: "/security-gis/static/polymerizationMapImg/HVAC_002_026.svg",
  HVAC_002_010: "/security-gis/static/polymerizationMapImg/HVAC_002_010.svg",
  HVAC_002_021: "/security-gis/static/polymerizationMapImg/HVAC_002_021.svg",
  HVAC_002_023: "/security-gis/static/polymerizationMapImg/HVAC_002_023.svg",
  HVAC_002_006: "/security-gis/static/polymerizationMapImg/HVAC_002_006.svg",
  IGS_001_020: "/security-gis/static/polymerizationMapImg/IGS_001_020.svg",
  WSS_001_001: "/security-gis/static/polymerizationMapImg/WSS_001_001.svg",
  IGS_003_002: "/security-gis/static/polymerizationMapImg/IGS_003_002.svg",
  HVAC_009_002: "/security-gis/static/polymerizationMapImg/HVAC_009_002.svg",
  WSS_001_013: "/security-gis/static/polymerizationMapImg/WSS_001_013.svg",
  HVAC_002_007: "/security-gis/static/polymerizationMapImg/HVAC_002_007.svg",
  HVAC_002_016: "/security-gis/static/polymerizationMapImg/HVAC_002_016.svg",
  IGS_018_001: "/security-gis/static/polymerizationMapImg/IGS_018_001.svg",
  IGS_018_002: "/security-gis/static/polymerizationMapImg/IGS_018_002.svg",
  IGS_018_003: "/security-gis/static/polymerizationMapImg/IGS_018_003.svg",
  IGS_018_004: "/security-gis/static/polymerizationMapImg/IGS_018_004.svg",
  IGS_018_005: "/security-gis/static/polymerizationMapImg/IGS_018_005.svg",
  IGS_018_006: "/security-gis/static/polymerizationMapImg/IGS_018_006.svg",
  ELS_001_001: "/security-gis/static/polymerizationMapImg/ELS_001_001.svg",
  HVAC_007_008: "/security-gis/static/polymerizationMapImg/HVAC_007_008.svg",
  HVAC_002_015: "/security-gis/static/polymerizationMapImg/HVAC_002_015.svg",
  IGS_001_012: "/security-gis/static/polymerizationMapImg/IGS_001_012.svg",
  IGS_001_005: "/security-gis/static/polymerizationMapImg/IGS_001_005.svg",
  IGS_020_001: "/security-gis/static/polymerizationMapImg/IGS_020_001.svg",
  IGS_011_003: "/security-gis/static/polymerizationMapImg/IGS_011_003.svg",
  IGS_016_013: "/security-gis/static/polymerizationMapImg/IGS_016_013.svg",
  IGS_002_017: "/security-gis/static/polymerizationMapImg/IGS_002_017.svg",
  IGS_002_018: "/security-gis/static/polymerizationMapImg/IGS_002_018.svg",
  IGS_002_019: "/security-gis/static/polymerizationMapImg/IGS_002_019.svg",
  IGS_002_020: "/security-gis/static/polymerizationMapImg/IGS_002_020.svg",
  IGS_002_023: "/security-gis/static/polymerizationMapImg/IGS_002_020.svg",
  IGS_002_024: "/security-gis/static/polymerizationMapImg/IGS_002_020.svg",

  PSS_008_001_alarm: "/security-gis/static/polymerizationMapImg/PSS_008_001-alarm.svg",
  HVAC_003_001_alarm: "/security-gis/static/polymerizationMapImg/HVAC_003_001-alarm.svg",
  HVAC_002_011_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_011-alarm.svg",
  HVAC_007_007_alarm: "/security-gis/static/polymerizationMapImg/HVAC_007_007-alarm.svg",
  HVAC_005_001_alarm: "/security-gis/static/polymerizationMapImg/HVAC_005_001-alarm.svg",
  HVAC_007_001_alarm: "/security-gis/static/polymerizationMapImg/HVAC_007_001-alarm.svg",
  HVAC_001_001_alarm: "/security-gis/static/polymerizationMapImg/HVAC_001_001-alarm.svg",
  HVAC_007_002_alarm: "/security-gis/static/polymerizationMapImg/HVAC_007_002-alarm.svg",
  PSS_006_002_alarm: "/security-gis/static/polymerizationMapImg/PSS_006_002-alarm.svg",
  WSS_001_016_alarm: "/security-gis/static/polymerizationMapImg/WSS_001_016-alarm.svg",
  PSS_006_001_alarm: "/security-gis/static/polymerizationMapImg/PSS_006_001-alarm.svg",
  HVAC_002_009_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_009-alarm.svg",
  HVAC_003_002_alarm: "/security-gis/static/polymerizationMapImg/HVAC_003_002-alarm.svg",
  HVAC_002_008_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_008-alarm.svg",
  HVAC_002_002_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_002-alarm.svg",
  HVAC_002_003_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_003-alarm.svg",
  HVAC_002_001_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_001-alarm.svg",
  IGS_001_013_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_013-alarm.svg",
  IGS_001_006_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_006-alarm.svg",
  IGS_001_015_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_015-alarm.svg",
  IGS_001_017_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_017-alarm.svg",
  WSS_002_006_alarm: "/security-gis/static/polymerizationMapImg/WSS_002_006-alarm.svg",
  IGS_020_002_alarm: "/security-gis/static/polymerizationMapImg/IGS_020_002-alarm.svg",
  HVAC_002_025_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_025-alarm.svg",
  HVAC_002_026_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_026-alarm.svg",
  HVAC_002_010_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_010-alarm.svg",
  HVAC_002_021_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_021-alarm.svg",
  HVAC_002_023_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_023-alarm.svg",
  HVAC_002_006_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_006-alarm.svg",
  IGS_001_020_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_020-alarm.svg",
  WSS_001_001_alarm: "/security-gis/static/polymerizationMapImg/WSS_001_001-alarm.svg",
  IGS_003_002_alarm: "/security-gis/static/polymerizationMapImg/IGS_003_002-alarm.svg",
  HVAC_009_002_alarm: "/security-gis/static/polymerizationMapImg/HVAC_009_002-alarm.svg",
  WSS_001_013_alarm: "/security-gis/static/polymerizationMapImg/WSS_001_013-alarm.svg",
  HVAC_002_007_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_007-alarm.svg",
  HVAC_002_016_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_016-alarm.svg",
  IGS_018_001_alarm: "/security-gis/static/polymerizationMapImg/IGS_018_001-alarm.svg",
  IGS_018_002_alarm: "/security-gis/static/polymerizationMapImg/IGS_018_002-alarm.svg",
  IGS_018_003_alarm: "/security-gis/static/polymerizationMapImg/IGS_018_003-alarm.svg",
  IGS_018_004_alarm: "/security-gis/static/polymerizationMapImg/IGS_018_004-alarm.svg",
  IGS_018_005_alarm: "/security-gis/static/polymerizationMapImg/IGS_018_005-alarm.svg",
  IGS_018_006_alarm: "/security-gis/static/polymerizationMapImg/IGS_018_006-alarm.svg",
  ELS_001_001_alarm: "/security-gis/static/polymerizationMapImg/ELS_001_001-alarm.svg",
  HVAC_007_008_alarm: "/security-gis/static/polymerizationMapImg/HVAC_007_008-alarm.svg",
  HVAC_002_015_alarm: "/security-gis/static/polymerizationMapImg/HVAC_002_015-alarm.svg",
  IGS_001_012_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_012-alarm.svg",
  IGS_001_005_alarm: "/security-gis/static/polymerizationMapImg/IGS_001_005-alarm.svg",
  IGS_020_001_alarm: "/security-gis/static/polymerizationMapImg/IGS_020_001-alarm.svg",
  IGS_011_003_alarm: "/security-gis/static/polymerizationMapImg/IGS_011_003-alarm.svg",
  IGS_016_013_alarm: "/security-gis/static/polymerizationMapImg/IGS_016_013-alarm.svg",
  IGS_002_017_alarm: "/security-gis/static/polymerizationMapImg/IGS_002_017-alarm.svg",
  IGS_002_018_alarm: "/security-gis/static/polymerizationMapImg/IGS_002_018-alarm.svg",
  IGS_002_019_alarm: "/security-gis/static/polymerizationMapImg/IGS_002_019-alarm.svg",
  IGS_002_020_alarm: "/security-gis/static/polymerizationMapImg/IGS_002_020-alarm.svg",
  IGS_002_023_alarm: "/security-gis/static/polymerizationMapImg/IGS_002_020-alarm.svg",
  IGS_002_024_alarm: "/security-gis/static/polymerizationMapImg/IGS_002_020-alarm.svg",

  PSS_008_001_off_line: "/security-gis/static/polymerizationMapImg/PSS_008_001-off-line.svg",
  HVAC_003_001_off_line: "/security-gis/static/polymerizationMapImg/HVAC_003_001-off-line.svg",
  HVAC_002_011_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_011-off-line.svg",
  HVAC_007_007_off_line: "/security-gis/static/polymerizationMapImg/HVAC_007_007-off-line.svg",
  HVAC_005_001_off_line: "/security-gis/static/polymerizationMapImg/HVAC_005_001-off-line.svg",
  HVAC_007_001_off_line: "/security-gis/static/polymerizationMapImg/HVAC_007_001-off-line.svg",
  HVAC_001_001_off_line: "/security-gis/static/polymerizationMapImg/HVAC_001_001-off-line.svg",
  HVAC_007_002_off_line: "/security-gis/static/polymerizationMapImg/HVAC_007_002-off-line.svg",
  PSS_006_002_off_line: "/security-gis/static/polymerizationMapImg/PSS_006_002-off-line.svg",
  WSS_001_016_off_line: "/security-gis/static/polymerizationMapImg/WSS_001_016-off-line.svg",
  PSS_006_001_off_line: "/security-gis/static/polymerizationMapImg/PSS_006_001-off-line.svg",
  HVAC_002_009_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_009-off-line.svg",
  HVAC_003_002_off_line: "/security-gis/static/polymerizationMapImg/HVAC_003_002-off-line.svg",
  HVAC_002_008_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_008-off-line.svg",
  HVAC_002_002_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_002-off-line.svg",
  HVAC_002_003_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_003-off-line.svg",
  HVAC_002_001_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_001-off-line.svg",
  IGS_001_013_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_013-off-line.svg",
  IGS_001_006_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_006-off-line.svg",
  IGS_001_015_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_015-off-line.svg",
  IGS_001_017_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_017-off-line.svg",
  WSS_002_006_off_line: "/security-gis/static/polymerizationMapImg/WSS_002_006-off-line.svg",
  IGS_020_002_off_line: "/security-gis/static/polymerizationMapImg/IGS_020_002-off-line.svg",
  HVAC_002_025_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_025-off-line.svg",
  HVAC_002_026_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_026-off-line.svg",
  HVAC_002_010_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_010-off-line.svg",
  HVAC_002_021_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_021-off-line.svg",
  HVAC_002_023_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_023-off-line.svg",
  HVAC_002_006_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_006-off-line.svg",
  IGS_001_020_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_020-off-line.svg",
  WSS_001_001_off_line: "/security-gis/static/polymerizationMapImg/WSS_001_001-off-line.svg",
  IGS_003_002_off_line: "/security-gis/static/polymerizationMapImg/IGS_003_002-off-line.svg",
  HVAC_009_002_off_line: "/security-gis/static/polymerizationMapImg/HVAC_009_002-off-line.svg",
  WSS_001_013_off_line: "/security-gis/static/polymerizationMapImg/WSS_001_013-off-line.svg",
  HVAC_002_007_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_007-off-line.svg",
  HVAC_002_016_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_016-off-line.svg",
  IGS_018_001_off_line: "/security-gis/static/polymerizationMapImg/IGS_018_001-off-line.svg",
  IGS_018_002_off_line: "/security-gis/static/polymerizationMapImg/IGS_018_002-off-line.svg",
  IGS_018_003_off_line: "/security-gis/static/polymerizationMapImg/IGS_018_003-off-line.svg",
  IGS_018_004_off_line: "/security-gis/static/polymerizationMapImg/IGS_018_004-off-line.svg",
  IGS_018_005_off_line: "/security-gis/static/polymerizationMapImg/IGS_018_005-off-line.svg",
  IGS_018_006_off_line: "/security-gis/static/polymerizationMapImg/IGS_018_006-off-line.svg",
  ELS_001_001_off_line: "/security-gis/static/polymerizationMapImg/ELS_001_001-off-line.svg",
  HVAC_007_008_off_line: "/security-gis/static/polymerizationMapImg/HVAC_007_008-off-line.svg",
  HVAC_002_015_off_line: "/security-gis/static/polymerizationMapImg/HVAC_002_015-off-line.svg",
  IGS_001_012_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_012-off-line.svg",
  IGS_001_005_off_line: "/security-gis/static/polymerizationMapImg/IGS_001_005-off-line.svg",
  IGS_020_001_off_line: "/security-gis/static/polymerizationMapImg/IGS_020_001-off-line.svg",

  IGS_011_003_off_line: "/security-gis/static/polymerizationMapImg/IGS_011_003-off-line.svg",
  IGS_016_013_off_line: "/security-gis/static/polymerizationMapImg/IGS_016_013-off-line.svg",
  IGS_002_017_off_line: "/security-gis/static/polymerizationMapImg/IGS_002_017-off-line.svg",
  IGS_002_018_off_line: "/security-gis/static/polymerizationMapImg/IGS_002_018-off-line.svg",
  IGS_002_019_off_line: "/security-gis/static/polymerizationMapImg/IGS_002_019-off-line.svg",
  IGS_002_020_off_line: "/security-gis/static/polymerizationMapImg/IGS_002_020-off-line.svg",
  IGS_002_023_off_line: "/security-gis/static/polymerizationMapImg/IGS_002_020-off-line.svg",
  IGS_002_024_off_line: "/security-gis/static/polymerizationMapImg/IGS_002_020-off-line.svg",
};
