import { render, staticRenderFns } from "./alarmPop.vue?vue&type=template&id=bea3962c&scoped=true"
import script from "./alarmPop.vue?vue&type=script&lang=js"
export * from "./alarmPop.vue?vue&type=script&lang=js"
import style0 from "./alarmPop.vue?vue&type=style&index=0&id=bea3962c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bea3962c",
  null
  
)

export default component.exports