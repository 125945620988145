import { I18N } from "@/i18n.js";

let language = localStorage.IOC_WEB_LANG;
let cards = [];
if (language === "zh_CN") {
  // 中文
  cards = [
    {
      name: I18N.common.i18nKey_cockpit_46,
      notTabPage: true,
      businessCode: "CARD_SITUATION_2D",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_48,
      path: "/show/global",
      icon: "icon-tongji",
      businessCode: "CARD_SITUATION",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_49, // 安全态势
      path: "/show/security",
      icon: "icon-anquandunpai",
      businessCode: "CARD_PROTECTION",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_50, // 视频巡逻统计
          statisticalCardCode: "cardProtection04",
          imgurl: require("@/assets/images/globalSecurity/videoPatrolStatistics.png"),
          componentName: "IocChartVideoPatrolStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_51, // 巡逻告警
          statisticalCardCode: "cardProtection05",
          imgurl: require("@/assets/images/globalSecurity/patrolAlarm.png"),
          componentName: "IocChartPatrolWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_52, // 安防告警状态
          statisticalCardCode: "cardProtection06",
          imgurl: require("@/assets/images/globalSecurity/securityEventStatus.png"),
          componentName: "IocChartSecurityAlarmStatus",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection07",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarning.png"),
          componentName: "IocChartEquipmentWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection14",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarning.png"),
          componentName: "IocChartEquipmentWarning2",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_54, // 安防告警统计
          statisticalCardCode: "cardProtection08",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmStatus.png"),
          componentName: "IocChartSecurityAlarmStatistical",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_55, // 安防告警趋势
          statisticalCardCode: "cardProtection09",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmTrend.png"),
          componentName: "IocChartSecurityAlarmTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_56, // 安防工单趋势
          statisticalCardCode: "cardProtection10",
          imgurl: require("@/assets/images/globalSecurity/securityOrderTrend.png"),
          componentName: "IocChartSecurityOrderTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57, // 设备统计
          statisticalCardCode: "cardProtection11",
          imgurl: require("@/assets/images/globalSecurity/equipmentStatistics.png"),
          componentName: "IocChartDeviceStatistics",
        },
        {
          statisticalCardName: "执法仪统计", // 执法仪统计
          statisticalCardCode: "cardProtection12",
          imgurl: require("@/assets/images/globalSecurity/zhifayi.png"),
          componentName: "IocChartEnforcementInstrument",
        },
        {
          statisticalCardName: "执法仪维度", // 执法仪维度统计
          statisticalCardCode: "cardProtection13",
          imgurl: require("@/assets/images/globalSecurity/zfytj.png"),
          componentName: "instrumentdimension",
        },
        {
          statisticalCardName: "保洁出勤和出勤率", // 保洁出勤和出勤率
          statisticalCardCode: "cardProtection15",
          imgurl: require("@/assets/images/globalSecurity/cleaningAttendanceStatistics.png"),
          componentName: "IocCleaningAttendanceStatistics",
        },
        {
          statisticalCardName: "保洁设备告警和覆盖率", // 保洁设备告警和覆盖率
          statisticalCardCode: "cardProtection16",
          imgurl: require("@/assets/images/globalSecurity/cleaningEquipmentStatistics.png"),
          componentName: "IocCleaningEquipmentStatistics",
        },
        {
          statisticalCardName: "部门工单统计", // 部门工单统计
          statisticalCardCode: "cardProtection17",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatistics.png"),
          componentName: "IocDepartmentWorkOrderStatistics",
        },
        {
          statisticalCardName: "部门工单状态", // 部门工单状态
          statisticalCardCode: "cardProtection18",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatus.png"),
          componentName: "IocDepartmentWorkOrderStatus",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_58,
      path: "/show/event-center",
      icon: "icon-zonghetaishi",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_59, // 能耗态势
      path: "/show/efficiency",
      icon: "icon-energy",
      businessCode: "CARD_ENERGY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_64, // 分项用能
          statisticalCardCode: "cardEnergy01",
          imgurl: require("@/assets/images/envFriendly/energySubitem.png"),
          componentName: "IocChartEnergySubitem",
        },
        // {
        //   statisticalCardName: I18N.common.i18nKey_cockpit_62, // 能效AI诊断
        //   statisticalCardCode: "cardEnergy02",
        //   imgurl: require("@/assets/images/envFriendly/energyAIDiagnosis.png"),
        //   componentName: "IocChartAIDiagnosis",
        // },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_65, // 能耗告警
          statisticalCardCode: "cardEnergy03",
          imgurl: require("@/assets/images/envFriendly/energyConsumptionAlarm.png"),
          componentName: "IocChartEnergyConsumptionAlarm",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_66, // 能耗监测
          statisticalCardCode: "cardEnergy04",
          imgurl: require("@/assets/images/envFriendly/energyEfficiency.png"),
          componentName: "IocChartEnergyEfficiency",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_67, // 环境监测
          statisticalCardCode: "cardEnergy05",
          imgurl: require("@/assets/images/envFriendly/environmentalMonitoring.png"),
          componentName: "IocChartEmp",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_68, // 设备用能排名
          statisticalCardCode: "cardEnergy06",
          imgurl: require("@/assets/images/envFriendly/equipmentRanking.png"),
          componentName: "IocChartEquipmentRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_69, // 区域每平方米能耗排名
          statisticalCardCode: "cardEnergy07",
          imgurl: require("@/assets/images/envFriendly/regionSquareRanking.png"),
          componentName: "IocChartRegionSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_70, // 商/租户每平方米用能排名
          statisticalCardCode: "cardEnergy08",
          imgurl: require("@/assets/images/envFriendly/tenantSquareRanking.png"),
          componentName: "IocChartTenantSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_72, // 水能
          statisticalCardCode: "cardEnergy10",
          imgurl: require("@/assets/images/envFriendly/hydroenergy.png"),
          componentName: "IocChartHydroenergy",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_73, // 设施态势
      path: "/show/facilities",
      icon: "icon-shipinjiankong",
      businessCode: "CARD_FACILITY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57, // 设备统计
          statisticalCardCode: "cardFacility01",
          imgurl: require("@/assets/images/facilities/deviceStatistics.png"),
          componentName: "IocChartEquipmentStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_74, // 故障统计
          statisticalCardCode: "cardFacility02",
          imgurl: require("@/assets/images/facilities/faultStatistics.png"),
          componentName: "IocChartFaultStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardFacility03",
          imgurl: require("@/assets/images/facilities/deviceAlarmStatistics.png"),
          componentName: "IocChartDeviceAlarmStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_75, // 设施事件状态统计
          statisticalCardCode: "cardFacility04",
          imgurl: require("@/assets/images/facilities/deviceEventStatusStatistics.png"),
          componentName: "IocChartDeviceEventStatusStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_76, // 设施报修
          statisticalCardCode: "cardFacility05",
          imgurl: require("@/assets/images/facilities/deviceRepair.png"),
          componentName: "IocChartDeviceRepair",
        },
        {
          statisticalCardName: "实时空气指数质量",
          statisticalCardCode: "cardFacility06",
          imgurl: require("@/assets/images/facilities/airQuality.png"),
          componentName: "IocChartAirQuality",
        },
        {
          statisticalCardName: "蓝牙信标设备统计",
          statisticalCardCode: "cardFacility07",
          imgurl: require("@/assets/images/facilities/beaconEquipmentStatistics.png"),
          componentName: "IocBeaconEquipmentStatistics",
        },
        {
          statisticalCardName: "液位设备统计",
          statisticalCardCode: "cardFacility08",
          imgurl: require("@/assets/images/facilities/levelEquipmentStatistics.png"),
          componentName: "IocLevelEquipmentStatistics",
        },
        {
          statisticalCardName: "水浸设备统计",
          statisticalCardCode: "cardFacility09",
          imgurl: require("@/assets/images/facilities/waterEquipmentStatistics.png"),
          componentName: "IocWaterEquipmentStatistics",
        },
        {
          statisticalCardName: "物业工单统计",
          statisticalCardCode: "cardFacility10",
          imgurl: require("@/assets/images/facilities/workOrderStatistics.png"),
          componentName: "IocWorkOrderStatistics",
        },
        {
          statisticalCardName: "物业工单概览",
          statisticalCardCode: "cardFacility11",
          imgurl: require("@/assets/images/facilities/workOrderOverview.png"),
          componentName: "IocWorkOrderOverview",
        },
        {
          statisticalCardName: "充电桩使用统计", // 充电桩使用统计
          statisticalCardCode: "cardThrough11",
          imgurl: require("@/assets/images/passthrough/chargingPile.png"),
          componentName: "IocChartChargingPileStatistics",
        },
        {
          statisticalCardName: '在岗人员统计', // 综合态势展示--设施态势--在岗人员统计
          statisticalCardCode: "cardFacility12",
          imgurl: require("@/assets/images/facilities/onDutyPeopleStatistics.png"),
          componentName: "IocChatOnDutyPeopleStatistics",
        },
        {
          statisticalCardName: '设备房温湿度监测', // 综合态势展示--设施态势--设备房温湿度监测
          statisticalCardCode: "cardFacility13",
          imgurl: require("@/assets/images/facilities/IocEquipmentRoomChart.png"),
          componentName: "IocEquipmentRoomChart",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_77, // 通行态势
      path: "/show/passthrough",
      icon: "icon-chepai",
      businessCode: "CARD_THROUGH",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_78, // 人员通行概况
          statisticalCardCode: "cardThrough01",
          imgurl: require("@/assets/images/passthrough/passingOverview.png"),
          componentName: "IocChartPassingOverview",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_79, // 员工通行统计
          statisticalCardCode: "cardThrough02",
          imgurl: require("@/assets/images/passthrough/employeeStatistics.png"),
          componentName: "IocChartEmployeeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_80, // 访客通行统计
          statisticalCardCode: "cardThrough03",
          imgurl: require("@/assets/images/passthrough/visitorStatistics.png"),
          componentName: "IocChartVisitorStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_81, // 通行方式统计
          statisticalCardCode: "cardThrough04",
          imgurl: require("@/assets/images/passthrough/accessTypeStatistics.png"),
          componentName: "IocChartAccessTypeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_82, // 车流统计
          statisticalCardCode: "cardThrough05",
          imgurl: require("@/assets/images/passthrough/trafficStatistics.png"),
          componentName: "IocChartTrafficStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_83, // 车位统计
          statisticalCardCode: "cardThrough06",
          imgurl: require("@/assets/images/passthrough/parkingStatistics.png"),
          componentName: "IocChartParkingStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_84, // 临停收费
          statisticalCardCode: "cardThrough07",
          imgurl: require("@/assets/images/passthrough/chargingFees.png"),
          componentName: "IocChartChargingFees",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_85, // 停车时长
          statisticalCardCode: "cardThrough08",
          imgurl: require("@/assets/images/passthrough/parkingTime.png"),
          componentName: "IocChartParkingTime",
        },
        {
          statisticalCardName: "科技馆客流统计", // 科技馆客流统计
          statisticalCardCode: "cardThrough09",
          imgurl: require("@/assets/images/passthrough/scienceVisitor.png"),
          componentName: "IocChartScienceVisitorStatistics",
        },
        {
          statisticalCardName: "会议室使用统计", // 会议室使用统计
          statisticalCardCode: "cardThrough10",
          imgurl: require("@/assets/images/passthrough/meetingRoom.png"),
          componentName: "IocChartMeetingRoomStatistics",
        },
        {
          statisticalCardName: "楼栋出入统计", // 楼栋出入统计
          statisticalCardCode: "cardThrough12",
          imgurl: require("@/assets/images/passthrough/buildingAccessStatistics.png"),
          componentName: "IocChartBuildingAccessStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_86, // 智慧商业
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87, // 到访客流趋势
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88, // 驻留情况
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89, // 热门区域
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90, // 商业事件统计
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91, // 客流
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93, // 年龄性别
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94, // 回头客
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95, // 节日客流统计
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
    {
      name: "资产态势",
      path: "/show/assetManagement",
      icon: "icon-lease-tab",
      businessCode: "CARD_ASSET_MANAGEMENT",
      allCardList: [
        {
          statisticalCardName: "楼层签约率",
          statisticalCardCode: "cardAssetManagement01",
          imgurl:
            "/security-gis/static/imagesMap/dataCockpit/lease/signingRate.png",
          componentName: "IocFloorSigningRate",
        },
        {
          statisticalCardName: "租户行业分布",
          statisticalCardCode: "cardAssetManagement02",
          imgurl:
            "/security-gis/static/imagesMap/dataCockpit/lease/distribution.png",
          componentName: "IocDistribution",
        },
        {
          statisticalCardName: "逾期住户排行",
          statisticalCardCode: "cardAssetManagement03",
          imgurl:
            "/security-gis/static/imagesMap/dataCockpit/lease/dueTenantRank.png",
          componentName: "IocOverdueTenantRank",
        },
        {
          statisticalCardName: "租赁面积",
          statisticalCardCode: "cardAssetManagement04",
          imgurl:
            "/security-gis/static/imagesMap/dataCockpit/lease/leasedArea.png",
          componentName: "IocLeasedArea",
        },
        {
          statisticalCardName: "租赁结构",
          statisticalCardCode: "cardAssetManagement05",
          imgurl:
            "/security-gis/static/imagesMap/dataCockpit/lease/leaseTermStructure.png",
          componentName: "IocLeaseTermStructure",
        },
        {
          statisticalCardName: "合同到期分布",
          statisticalCardCode: "cardAssetManagement06",
          imgurl:
            "/security-gis/static/imagesMap/dataCockpit/lease/maturityDistribution.png",
          componentName: "IocContractMaturityDistribution",
        },
      ],
    },
    //  丹田
    {
      name: "综合态势",
      path: "/show/comprehensiveSituation",
      icon: "icon-tongji",
      businessCode: "CARD_SITUATION_DT",
      allCardList: [
        {
          statisticalCardName: "数据汇总分析",
          statisticalCardCode: "cardSituationDt01",
          imgurl: require("@/assets/images/globalSecurity/IocChartDataSummaryAnalysis.png"),
          componentName: "IocChartDataSummaryAnalysis",
        },
        {
          statisticalCardName: "年度用电能耗",
          statisticalCardCode: "cardSituationDt02",
          imgurl: require("@/assets/images/globalSecurity/IocChartPowerConsumption.png"),
          componentName: "IocChartAnnualElectricityConsumption",
        },
        {
          statisticalCardName: "传感器检测",
          statisticalCardCode: "cardSituationDt03",
          imgurl: require("@/assets/images/globalSecurity/iocSensorMonitoring.png"),
          componentName: "IocChartSensorDetection",
        },
        {
          statisticalCardName: "公寓管理-人员性别比例",
          statisticalCardCode: "cardSituationDt04",
          imgurl: require("@/assets/images/globalSecurity/IocChartGenderRatioPersonnel.png"),
          componentName: "IocChartApartmentManagementSex",
        },
        {
          statisticalCardName: "人员巡逻统计",
          statisticalCardCode: "cardSituationDt05",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolStatistics.png"),
          componentName: "IocChartPatrolStatistics",
        },
        {
          statisticalCardName: "公寓管理-人员分类",
          statisticalCardCode: "cardSituationDt06",
          imgurl: require("@/assets/images/globalSecurity/IocChartPersonnelClassification.png"),
          componentName: "IocChartApartmentManagementCategory",
        },
        {
          statisticalCardName: "车辆巡逻",
          statisticalCardCode: "cardSituationDt07",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolVehicles.png"),
          componentName: "IocChartPatrolVehicles",
        },
        {
          statisticalCardName: "设备数量统计",
          statisticalCardCode: "cardSituationDt08",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceNumberStatis.png"),
          componentName: "IocChartEquipmentQuantityStatistics",
        },
      ],
    },
    {
      name: "在线报修",
      path: "/show/onlineReport",
      icon: "icon-onlineRepair",
      businessCode: "CARD_REPORT",
      allCardList: [
        {
          statisticalCardName: "数据汇总分析",
          statisticalCardCode: "cardReport01",
          imgurl: require("@/assets/images/globalSecurity/IocChartDataSummaryAnalysis.png"),
          componentName: "IocChartDataSummaryAnalysis",
        },
        {
          statisticalCardName: "工单来源",
          statisticalCardCode: "cardReport02",
          imgurl: require("@/assets/images/globalSecurity/IocChartServiceCategory.png"),
          componentName: "IocChartServiceCategory",
        },
        {
          statisticalCardName: "受理效率排名",
          statisticalCardCode: "cardReport03",
          imgurl: require("@/assets/images/globalSecurity/IocChartAcceptanceEfficiencyRanking.png"),
          componentName: "IocChartAcceptanceEfficiencyRanking",
        },
        {
          statisticalCardName: "报修项目分析",
          statisticalCardCode: "cardReport04",
          imgurl: require("@/assets/images/globalSecurity/IocChartAnalysisOfRepairProject.png"),
          componentName: "IocChartAnalysisOfRepairProject",
        },
        {
          statisticalCardName: "超时异常",
          statisticalCardCode: "cardReport05",
          imgurl: require("@/assets/images/globalSecurity/IocChartTimeoutException.png"),
          componentName: "IocChartTimeoutException",
        },
        {
          statisticalCardName: "报修区域TOP5",
          statisticalCardCode: "cardReport06",
          imgurl: require("@/assets/images/globalSecurity/IocChartTop5RepairArea.png"),
          componentName: "IocChartTop5RepairArea",
        },
        {
          statisticalCardName: "工作量统计",
          statisticalCardCode: "cardReport07",
          imgurl: require("@/assets/images/globalSecurity/IocChartWorkloadStatistics.png"),
          componentName: "IocChartWorkloadStatistics",
        },
        {
          statisticalCardName: "维修人员管理",
          statisticalCardCode: "cardReport08",
          imgurl: require("@/assets/images/globalSecurity/IocChartMaintenancePersonnelManagement.png"),
          componentName: "IocChartMaintenancePersonnelManagement",
        },
      ],
    },
    {
      name: "公寓管理",
      path: "/show/apartmentManagement",
      icon: "icon-gongyuguanli",
      businessCode: "CARD_APARTMENT",
      allCardList: [
        {
          statisticalCardName: "住宿人员管理",
          statisticalCardCode: "cardApartment01",
          imgurl: require("@/assets/images/globalSecurity/IocChartMaAccommodationPersonnel.png"),
          componentName: "IocChartMaAccommodationPersonnel",
        },
        {
          statisticalCardName: "人员分类",
          statisticalCardCode: "cardApartment02",
          imgurl: require("@/assets/images/globalSecurity/IocChartPersonnelClassification.png"),
          componentName: "IocChartPersonnelClassification",
        },
        {
          statisticalCardName: "人员性别比例",
          statisticalCardCode: "cardApartment03",
          imgurl: require("@/assets/images/globalSecurity/IocChartGenderRatioPersonnel.png"),
          componentName: "IocChartGenderRatioPersonnel",
        },
        {
          statisticalCardName: "房源统计",
          statisticalCardCode: "cardApartment04",
          imgurl: require("@/assets/images/globalSecurity/IocChartHousingStatistics.png"),
          componentName: "IocChartHousingStatistics",
        },
        {
          statisticalCardName: "大学各阶段人员",
          statisticalCardCode: "cardApartment05",
          imgurl: require("@/assets/images/globalSecurity/IocChartUniversityPersonnelStages.png"),
          componentName: "IocChartUniversityPersonnelStages",
        },
        {
          statisticalCardName: "研究生各阶段人员",
          statisticalCardCode: "cardApartment06",
          imgurl: require("@/assets/images/globalSecurity/IocChartGraduatePersonnelStages.png"),
          componentName: "IocChartGraduatePersonnelStages",
        },
        {
          statisticalCardName: "床位管理",
          statisticalCardCode: "cardApartment07",
          imgurl: require("@/assets/images/globalSecurity/IocChartBedManagement.png"),
          componentName: "IocChartBedManagement",
        },
        {
          statisticalCardName: "安全管理",
          statisticalCardCode: "cardApartment08",
          imgurl: require("@/assets/images/globalSecurity/IocChartSecurityManagement.png"),
          componentName: "IocChartSecurityManagement",
        },
      ],
    },
    {
      name: "设备管理",
      path: "/show/deviceManagement",
      icon: "icon-shipinjiankong",
      businessCode: "CARD_DEVICE",
      allCardList: [
        {
          statisticalCardName: "设备数量统计",
          statisticalCardCode: "cardDevice01",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceNumberStatis.png"),
          componentName: "IocChartDeviceNumberStatis",
        },
        {
          statisticalCardName: "工作任务",
          statisticalCardCode: "cardDevice02",
          imgurl: require("@/assets/images/globalSecurity/IocChartTasks.png"),
          componentName: "IocChartTasks",
        },
        {
          statisticalCardName: "设备运行状态",
          statisticalCardCode: "cardDevice03",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceRunstatus.png"),
          componentName: "IocChartDeviceRunstatus",
        },
        {
          statisticalCardName: "工程人员管理",
          statisticalCardCode: "cardDevice04",
          imgurl: require("@/assets/images/globalSecurity/IocChartEnPersonManage.png"),
          componentName: "IocChartEnPersonManage",
        },
        {
          statisticalCardName: "设备管理工单",
          statisticalCardCode: "cardDevice05",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceWokOder.png"),
          componentName: "IocChartDeviceWokOder",
        },
        {
          statisticalCardName: "工单完成情况",
          statisticalCardCode: "cardDevice06",
          imgurl: require("@/assets/images/globalSecurity/IocChartWorkorderCompletion.png"),
          componentName: "IocChartWorkorderCompletion",
        },
      ],
    },
    {
      name: "校园安保",
      path: "/show/campusSecurity",
      icon: "icon-xiaoyuananbao",
      businessCode: "CARD_SECURITY",
      allCardList: [
        {
          statisticalCardName: "人员巡逻完成率",
          statisticalCardCode: "cardSecurity01",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolCompletionRate.png"),
          componentName: "IocChartPatrolCompletionRate",
        },
        {
          statisticalCardName: "车辆巡逻完成率",
          statisticalCardCode: "cardSecurity02",
          imgurl: require("@/assets/images/globalSecurity/IocChartVehiclePatrol.png"),
          componentName: "IocChartVehiclePatrol",
        },
        {
          statisticalCardName: "车辆巡逻排行",
          statisticalCardCode: "cardSecurity03",
          imgurl: require("@/assets/images/globalSecurity/IocChartVehiclePatrolRanking.png"),
          componentName: "IocChartVehiclePatrolRanking",
        },
        {
          statisticalCardName: "人员巡逻计划",
          statisticalCardCode: "cardSecurity04",
          imgurl: require("@/assets/images/globalSecurity/IocChartPersonnelPatrolPlan.png"),
          componentName: "IocChartPersonnelPatrolPlan",
        },
        {
          statisticalCardName: "车辆巡逻计划",
          statisticalCardCode: "cardSecurity05",
          imgurl: require("@/assets/images/globalSecurity/IocChartVehiclePatrolPlan.png"),
          componentName: "IocChartVehiclePatrolPlan",
        },
        {
          statisticalCardName: "人员巡逻统计",
          statisticalCardCode: "cardSecurity06",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolStatistics.png"),
          componentName: "IocChartPatrolStatistics",
        },
        {
          statisticalCardName: "车辆巡逻",
          statisticalCardCode: "cardSecurity07",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolVehicles.png"),
          componentName: "IocChartPatrolVehicles",
        },
        {
          statisticalCardName: "人员巡逻对比分析",
          statisticalCardCode: "cardSecurity08",
          imgurl: require("@/assets/images/globalSecurity/IocChartComparativeAnalysisPersonnelPatrol.png"),
          componentName: "IocChartComparativeAnalysisPersonnelPatrol",
        },
      ],
    },
    {
      name: "绿化管理",
      path: "/show/greeningManagement",
      icon: "icon-lvhuaguanli",
      businessCode: "CARD_GREEN",
      allCardList: [
        {
          statisticalCardName: "温度情况",
          statisticalCardCode: "cardGreen01",
          imgurl: require("@/assets/images/globalSecurity/iocTemperature.png"),
          componentName: "IocTemperature",
        },
        {
          statisticalCardName: "作业调度",
          statisticalCardCode: "cardGreen02",
          imgurl: require("@/assets/images/globalSecurity/iocJobScheduling.png"),
          componentName: "IocJobScheduling",
        },
        {
          statisticalCardName: "空气湿度情况",
          statisticalCardCode: "cardGreen03",
          imgurl: require("@/assets/images/globalSecurity/iocAirHumidity.png"),
          componentName: "IocAirHumidity",
        },
        {
          statisticalCardName: "异常阙值",
          statisticalCardCode: "cardGreen04",
          imgurl: require("@/assets/images/globalSecurity/iocAbnormalThreshold.png"),
          componentName: "IocAbnormalThreshold",
        },
        {
          statisticalCardName: "传感器监测（温湿度）",
          statisticalCardCode: "cardGreen05",
          imgurl: require("@/assets/images/globalSecurity/iocSensorMonitoring.png"),
          componentName: "IocSensorMonitoring",
        },
        {
          statisticalCardName: "植物百科",
          statisticalCardCode: "cardGreen06",
          imgurl: require("@/assets/images/globalSecurity/iocEncyclopediaOfPlants.png"),
          componentName: "IocEncyclopediaOfPlants",
        },
        {
          statisticalCardName: "传感器监测（光电量）",
          statisticalCardCode: "cardGreen07",
          imgurl: require("@/assets/images/globalSecurity/iocPhotoelectricSensor.png"),
          componentName: "IocPhotoelectricSensor",
        },
      ],
    },
    {
      name: "能源管理",
      path: "/show/energyManagement",
      icon: "icon-nengyuanguanli",
      businessCode: "CARD_ENERGY_MANAGE",
      allCardList: [
        {
          statisticalCardName: "年度用电能耗",
          statisticalCardCode: "cardEnergyManage01",
          imgurl: require("@/assets/images/globalSecurity/IocChartPowerConsumption.png"),
          componentName: "IocChartPowerConsumption",
        },
        {
          statisticalCardName: "年度用热能耗",
          statisticalCardCode: "cardEnergyManage02",
          imgurl: require("@/assets/images/globalSecurity/IocChartHeatConsumption.png"),
          componentName: "IocChartHeatConsumption",
        },
        {
          statisticalCardName: "实时用电分析",
          statisticalCardCode: "cardEnergyManage03",
          imgurl: require("@/assets/images/globalSecurity/IocChartpowerConsumptionAnalysis.png"),
          componentName: "IocChartpowerConsumptionAnalysis",
        },
        {
          statisticalCardName: "实时用热分析",
          statisticalCardCode: "cardEnergyManage04",
          imgurl: require("@/assets/images/globalSecurity/IocChartThermalAnalysis.png"),
          componentName: "IocChartThermalAnalysis",
        },
        {
          statisticalCardName: "年度用水能耗",
          statisticalCardCode: "cardEnergyManage05",
          imgurl: require("@/assets/images/globalSecurity/IocChartWaterConsumption.png"),
          componentName: "IocChartWaterConsumption",
        },
        {
          statisticalCardName: "预测量",
          statisticalCardCode: "cardEnergyManage06",
          imgurl: require("@/assets/images/globalSecurity/IocChartPreMeasurement.png"),
          componentName: "IocChartPreMeasurement",
        },
        {
          statisticalCardName: "实时用水分析",
          statisticalCardCode: "cardEnergyManage07",
          imgurl: require("@/assets/images/globalSecurity/IocChartWaterAnalysis.png"),
          componentName: "IocChartWaterAnalysis",
        },
      ],
    },
  ];
} else if (language === "en_US") {
  // 英文
  cards = [
    {
      name: I18N.common.i18nKey_cockpit_46,
      notTabPage: true,
      businessCode: "CARD_SITUATION_2D",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_48,
      path: "/show/global",
      icon: "icon-tongji",
      businessCode: "CARD_SITUATION",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_49, // 安全态势
      path: "/show/security",
      icon: "icon-anquandunpai",
      businessCode: "CARD_PROTECTION",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_50, // 视频巡逻统计
          statisticalCardCode: "cardProtection04",
          imgurl: require("@/assets/images/globalSecurity/videoPatrolStatisticsEn.png"),
          componentName: "IocChartVideoPatrolStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_51, // 巡逻告警
          statisticalCardCode: "cardProtection05",
          imgurl: require("@/assets/images/globalSecurity/patrolAlarmEn.png"),
          componentName: "IocChartPatrolWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_52, // 安防告警状态
          statisticalCardCode: "cardProtection06",
          imgurl: require("@/assets/images/globalSecurity/securityEventStatusEn.png"),
          componentName: "IocChartSecurityAlarmStatus",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection07",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarningEn.png"),
          componentName: "IocChartEquipmentWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection14",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarningEn.png"),
          componentName: "IocChartEquipmentWarning2",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_54, // 安防告警统计
          statisticalCardCode: "cardProtection08",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmStatusEn.png"),
          componentName: "IocChartSecurityAlarmStatistical",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_55, // 安防告警趋势
          statisticalCardCode: "cardProtection09",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmTrendEn.png"),
          componentName: "IocChartSecurityAlarmTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_56, // 安防工单趋势
          statisticalCardCode: "cardProtection10",
          imgurl: require("@/assets/images/globalSecurity/securityOrderTrendEn.png"),
          componentName: "IocChartSecurityOrderTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57, // 设备统计
          statisticalCardCode: "cardProtection11",
          imgurl: require("@/assets/images/globalSecurity/equipmentStatisticsEn.png"),
          componentName: "IocChartDeviceStatistics",
        },
        {
          statisticalCardName: "执法仪统计", // 执法仪统计
          statisticalCardCode: "cardProtection12",
          imgurl: require("@/assets/images/globalSecurity/zhifayi.png"),
          componentName: "IocChartEnforcementInstrument",
        },
        {
          statisticalCardName: "执法仪维度", // 执法仪维度统计
          statisticalCardCode: "cardProtection13",
          imgurl: require("@/assets/images/globalSecurity/zhifayi.png"),
          componentName: "instrumentdimension",
        },
        {
          statisticalCardName: "保洁出勤和出勤率", // 保洁出勤和出勤率
          statisticalCardCode: "cardProtection15",
          imgurl: require("@/assets/images/globalSecurity/cleaningAttendanceStatistics.png"),
          componentName: "IocCleaningAttendanceStatistics",
        },
        {
          statisticalCardName: "保洁设备告警和覆盖率", // 保洁设备告警和覆盖率
          statisticalCardCode: "cardProtection16",
          imgurl: require("@/assets/images/globalSecurity/cleaningEquipmentStatistics.png"),
          componentName: "IocCleaningEquipmentStatistics",
        },
        {
          statisticalCardName: "部门工单统计", // 部门工单统计
          statisticalCardCode: "cardProtection17",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatistics.png"),
          componentName: "IocDepartmentWorkOrderStatistics",
        },
        {
          statisticalCardName: "部门工单状态", // 部门工单状态
          statisticalCardCode: "cardProtection18",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatus.png"),
          componentName: "IocDepartmentWorkOrderStatus",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_58,
      path: "/show/event-center",
      icon: "icon-zonghetaishi",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_59, // 能耗态势
      path: "/show/efficiency",
      icon: "icon-energy",
      businessCode: "CARD_ENERGY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_64, // 分项用能
          statisticalCardCode: "cardEnergy01",
          imgurl: require("@/assets/images/envFriendly/energySubitemEn.png"),
          componentName: "IocChartEnergySubitem",
        },
        // {
        //   statisticalCardName: I18N.common.i18nKey_cockpit_62, // 能效AI诊断
        //   statisticalCardCode: "cardEnergy02",
        //   imgurl: require("@/assets/images/envFriendly/energyAIDiagnosisEn.png"),
        //   componentName: "IocChartAIDiagnosis",
        // },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_65, // 能耗告警
          statisticalCardCode: "cardEnergy03",
          imgurl: require("@/assets/images/envFriendly/energyConsumptionAlarmEn.png"),
          componentName: "IocChartEnergyConsumptionAlarm",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_66, // 能耗监测
          statisticalCardCode: "cardEnergy04",
          imgurl: require("@/assets/images/envFriendly/energyEfficiencyEn.png"),
          componentName: "IocChartEnergyEfficiency",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_67, // 环境监测
          statisticalCardCode: "cardEnergy05",
          imgurl: require("@/assets/images/envFriendly/environmentalMonitoringEn.png"),
          componentName: "IocChartEmp",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_68, // 设备用能排名
          statisticalCardCode: "cardEnergy06",
          imgurl: require("@/assets/images/envFriendly/equipmentRankingEn.png"),
          componentName: "IocChartEquipmentRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_69, // 区域每平方米能耗排名
          statisticalCardCode: "cardEnergy07",
          imgurl: require("@/assets/images/envFriendly/regionSquareRankingEn.png"),
          componentName: "IocChartRegionSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_70, // 商/租户每平方米用能排名
          statisticalCardCode: "cardEnergy08",
          imgurl: require("@/assets/images/envFriendly/tenantSquareRankingEn.png"),
          componentName: "IocChartTenantSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_72, // 水能
          statisticalCardCode: "cardEnergy10",
          imgurl: require("@/assets/images/envFriendly/hydroenergyEn.png"),
          componentName: "IocChartHydroenergy",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_73,
      path: "/show/facilities",
      icon: "icon-shipinjiankong",
      businessCode: "CARD_FACILITY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57, // 设备统计
          statisticalCardCode: "cardFacility01",
          imgurl: require("@/assets/images/facilities/deviceStatistics.png"),
          componentName: "IocChartEquipmentStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_74, // 故障统计
          statisticalCardCode: "cardFacility02",
          imgurl: require("@/assets/images/facilities/faultStatistics.png"),
          componentName: "IocChartFaultStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardFacility03",
          imgurl: require("@/assets/images/facilities/deviceAlarmStatistics.png"),
          componentName: "IocChartDeviceAlarmStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_75, // 设施事件状态统计
          statisticalCardCode: "cardFacility04",
          imgurl: require("@/assets/images/facilities/deviceEventStatusStatistics.png"),
          componentName: "IocChartDeviceEventStatusStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_76, // 设施工单
          statisticalCardCode: "cardFacility05",
          imgurl: require("@/assets/images/facilities/deviceRepair.png"),
          componentName: "IocChartDeviceRepair",
        },
        {
          statisticalCardName: "实时空气指数质量",
          statisticalCardCode: "cardFacility06",
          imgurl: require("@/assets/images/facilities/airQuality.png"),
          componentName: "IocChartAirQuality",
        },
        {
          statisticalCardName: "蓝牙信标设备统计",
          statisticalCardCode: "cardFacility07",
          imgurl: require("@/assets/images/facilities/beaconEquipmentStatistics.png"),
          componentName: "IocBeaconEquipmentStatistics",
        },
        {
          statisticalCardName: "液位设备统计",
          statisticalCardCode: "cardFacility08",
          imgurl: require("@/assets/images/facilities/levelEquipmentStatistics.png"),
          componentName: "IocLevelEquipmentStatistics",
        },
        {
          statisticalCardName: "水浸设备统计",
          statisticalCardCode: "cardFacility09",
          imgurl: require("@/assets/images/facilities/waterEquipmentStatistics.png"),
          componentName: "IocWaterEquipmentStatistics",
        },
        {
          statisticalCardName: "物业工单统计",
          statisticalCardCode: "cardFacility10",
          imgurl: require("@/assets/images/facilities/workOrderStatistics.png"),
          componentName: "IocWorkOrderStatistics",
        },
        {
          statisticalCardName: "物业工单概览",
          statisticalCardCode: "cardFacility11",
          imgurl: require("@/assets/images/facilities/workOrderOverview.png"),
          componentName: "IocWorkOrderOverview",
        },
        {
          statisticalCardName: "充电桩使用统计", // 充电桩使用统计
          statisticalCardCode: "cardThrough11",
          imgurl: require("@/assets/images/passthrough/chargingPile.png"),
          componentName: "IocChartChargingPileStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_77,
      path: "/show/passthrough",
      icon: "icon-chepai",
      businessCode: "CARD_THROUGH",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_78, // 人员通行概况
          statisticalCardCode: "cardThrough01",
          imgurl: require("@/assets/images/passthrough/passingOverviewEn.png"),
          componentName: "IocChartPassingOverview",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_79, // 员工通行统计
          statisticalCardCode: "cardThrough02",
          imgurl: require("@/assets/images/passthrough/employeeStatisticsEn.png"),
          componentName: "IocChartEmployeeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_80, // 访客通行统计
          statisticalCardCode: "cardThrough03",
          imgurl: require("@/assets/images/passthrough/visitorStatisticsEn.png"),
          componentName: "IocChartVisitorStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_81, // 通行方式统计
          statisticalCardCode: "cardThrough04",
          imgurl: require("@/assets/images/passthrough/accessTypeStatisticsEn.png"),
          componentName: "IocChartAccessTypeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_82, // 车流统计
          statisticalCardCode: "cardThrough05",
          imgurl: require("@/assets/images/passthrough/trafficStatisticsEn.png"),
          componentName: "IocChartTrafficStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_83, // 车位统计
          statisticalCardCode: "cardThrough06",
          imgurl: require("@/assets/images/passthrough/parkingStatisticsEn.png"),
          componentName: "IocChartParkingStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_84, // 临停收费
          statisticalCardCode: "cardThrough07",
          imgurl: require("@/assets/images/passthrough/chargingFeesEn.png"),
          componentName: "IocChartChargingFees",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_85, // 停车时长
          statisticalCardCode: "cardThrough08",
          imgurl: require("@/assets/images/passthrough/parkingTimeEn.png"),
          componentName: "IocChartParkingTime",
        },
        {
          statisticalCardName: "科技馆客流统计", // 科技馆客流统计
          statisticalCardCode: "cardThrough09",
          imgurl: require("@/assets/images/passthrough/scienceVisitor.png"),
          componentName: "IocChartScienceVisitorStatistics",
        },
        {
          statisticalCardName: "会议室使用统计", // 会议室使用统计
          statisticalCardCode: "cardThrough10",
          imgurl: require("@/assets/images/passthrough/meetingRoom.png"),
          componentName: "IocChartMeetingRoomStatistics",
        },
        {
          statisticalCardName: "楼栋出入统计", // 楼栋出入统计
          statisticalCardCode: "cardThrough12",
          imgurl: require("@/assets/images/passthrough/buildingAccessStatistics.png"),
          componentName: "IocChartBuildingAccessStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_86,
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87,
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88,
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89,
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90,
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91,
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93,
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94,
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95,
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
    {
      name: "资产经营",
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87,
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88,
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89,
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90,
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91,
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93,
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94,
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95,
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
  ];
} else if (language === "th_TH") {
  // 泰文
  cards = [
    {
      name: I18N.common.i18nKey_cockpit_46,
      notTabPage: true,
      businessCode: "CARD_SITUATION_2D",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_48,
      path: "/show/global",
      icon: "icon-tongji",
      businessCode: "CARD_SITUATION",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_49, // 安全态势
      path: "/show/security",
      icon: "icon-anquandunpai",
      businessCode: "CARD_PROTECTION",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_50, // 视频巡逻统计
          statisticalCardCode: "cardProtection04",
          imgurl: require("@/assets/images/globalSecurity/videoPatrolStatisticsTh.png"),
          componentName: "IocChartVideoPatrolStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_51, // 巡逻告警
          statisticalCardCode: "cardProtection05",
          imgurl: require("@/assets/images/globalSecurity/patrolAlarmTh.png"),
          componentName: "IocChartPatrolWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_52, // 安防告警状态
          statisticalCardCode: "cardProtection06",
          imgurl: require("@/assets/images/globalSecurity/securityEventStatusTh.png"),
          componentName: "IocChartSecurityAlarmStatus",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection07",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarningTh.png"),
          componentName: "IocChartEquipmentWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection14",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarningTh.png"),
          componentName: "IocChartEquipmentWarning2",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_54, // 安防告警统计
          statisticalCardCode: "cardProtection08",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmStatusTh.png"),
          componentName: "IocChartSecurityAlarmStatistical",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_55, // 安防告警趋势
          statisticalCardCode: "cardProtection09",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmTrendTh.png"),
          componentName: "IocChartSecurityAlarmTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_56, // 安防工单趋势
          statisticalCardCode: "cardProtection10",
          imgurl: require("@/assets/images/globalSecurity/securityOrderTrendTh.png"),
          componentName: "IocChartSecurityOrderTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57, // 设备统计
          statisticalCardCode: "cardProtection11",
          imgurl: require("@/assets/images/globalSecurity/equipmentStatisticsTh.png"),
          componentName: "IocChartDeviceStatistics",
        },
        {
          statisticalCardName: "执法仪统计", // 执法仪统计
          statisticalCardCode: "cardProtection12",
          imgurl: require("@/assets/images/globalSecurity/zhifayi.png"),
          componentName: "IocChartEnforcementInstrument",
        },
        {
          statisticalCardName: "执法仪使用统计", // 执法仪使用统计
          statisticalCardCode: "cardProtection13",
          imgurl: require("@/assets/images/globalSecurity/zhifayi.png"),
          componentName: "instrumentdimension",
        },
        {
          statisticalCardName: "保洁出勤和出勤率", // 保洁出勤和出勤率
          statisticalCardCode: "cardProtection15",
          imgurl: require("@/assets/images/globalSecurity/cleaningAttendanceStatistics.png"),
          componentName: "IocCleaningAttendanceStatistics",
        },
        {
          statisticalCardName: "保洁设备告警和覆盖率", // 保洁设备告警和覆盖率
          statisticalCardCode: "cardProtection16",
          imgurl: require("@/assets/images/globalSecurity/cleaningEquipmentStatistics.png"),
          componentName: "IocCleaningEquipmentStatistics",
        },
        {
          statisticalCardName: "部门工单统计", // 部门工单统计
          statisticalCardCode: "cardProtection17",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatistics.png"),
          componentName: "IocDepartmentWorkOrderStatistics",
        },
        {
          statisticalCardName: "部门工单状态", // 部门工单状态
          statisticalCardCode: "cardProtection18",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatus.png"),
          componentName: "IocDepartmentWorkOrderStatus",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_58,
      path: "/show/event-center",
      icon: "icon-zonghetaishi",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_59, // 能耗态势
      path: "/show/efficiency",
      icon: "icon-energy",
      businessCode: "CARD_ENERGY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_64, // 分项用能
          statisticalCardCode: "cardEnergy01",
          imgurl: require("@/assets/images/envFriendly/energySubitemTh.png"),
          componentName: "IocChartEnergySubitem",
        },
        // {
        //   statisticalCardName: I18N.common.i18nKey_cockpit_62, // 能效AI诊断
        //   statisticalCardCode: "cardEnergy02",
        //   imgurl: require("@/assets/images/envFriendly/energyAIDiagnosisTh.png"),
        //   componentName: "IocChartAIDiagnosis",
        // },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_65, // 能耗告警
          statisticalCardCode: "cardEnergy03",
          imgurl: require("@/assets/images/envFriendly/energyConsumptionAlarmTh.png"),
          componentName: "IocChartEnergyConsumptionAlarm",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_66, // 能耗监测
          statisticalCardCode: "cardEnergy04",
          imgurl: require("@/assets/images/envFriendly/energyEfficiencyTh.png"),
          componentName: "IocChartEnergyEfficiency",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_67, // 环境监测
          statisticalCardCode: "cardEnergy05",
          imgurl: require("@/assets/images/envFriendly/environmentalMonitoringTh.png"),
          componentName: "IocChartEmp",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_68, // 设备用能排名
          statisticalCardCode: "cardEnergy06",
          imgurl: require("@/assets/images/envFriendly/equipmentRankingTh.png"),
          componentName: "IocChartEquipmentRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_69, // 区域每平方米能耗排名
          statisticalCardCode: "cardEnergy07",
          imgurl: require("@/assets/images/envFriendly/regionSquareRankingTh.png"),
          componentName: "IocChartRegionSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_70, // 商/租户每平方米用能排名
          statisticalCardCode: "cardEnergy08",
          imgurl: require("@/assets/images/envFriendly/tenantSquareRankingTh.png"),
          componentName: "IocChartTenantSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_72, // 水能
          statisticalCardCode: "cardEnergy10",
          imgurl: require("@/assets/images/envFriendly/hydroenergyTh.png"),
          componentName: "IocChartHydroenergy",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_73,
      path: "/show/facilities",
      icon: "icon-shipinjiankong",
      businessCode: "CARD_FACILITY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57,
          statisticalCardCode: "cardFacility01",
          imgurl: require("@/assets/images/facilities/deviceStatistics.png"),
          componentName: "IocChartEquipmentStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_74,
          statisticalCardCode: "cardFacility02",
          imgurl: require("@/assets/images/facilities/faultStatistics.png"),
          componentName: "IocChartFaultStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53,
          statisticalCardCode: "cardFacility03",
          imgurl: require("@/assets/images/facilities/deviceAlarmStatistics.png"),
          componentName: "IocChartDeviceAlarmStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_75,
          statisticalCardCode: "cardFacility04",
          imgurl: require("@/assets/images/facilities/deviceEventStatusStatistics.png"),
          componentName: "IocChartDeviceEventStatusStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_76,
          statisticalCardCode: "cardFacility05",
          imgurl: require("@/assets/images/facilities/deviceRepair.png"),
          componentName: "IocChartDeviceRepair",
        },
        {
          statisticalCardName: "实时空气指数质量",
          statisticalCardCode: "cardFacility06",
          imgurl: require("@/assets/images/facilities/airQuality.png"),
          componentName: "IocChartAirQuality",
        },
        {
          statisticalCardName: "蓝牙信标设备统计",
          statisticalCardCode: "cardFacility07",
          imgurl: require("@/assets/images/facilities/beaconEquipmentStatistics.png"),
          componentName: "IocBeaconEquipmentStatistics",
        },
        {
          statisticalCardName: "液位设备统计",
          statisticalCardCode: "cardFacility08",
          imgurl: require("@/assets/images/facilities/levelEquipmentStatistics.png"),
          componentName: "IocLevelEquipmentStatistics",
        },
        {
          statisticalCardName: "水浸设备统计",
          statisticalCardCode: "cardFacility09",
          imgurl: require("@/assets/images/facilities/waterEquipmentStatistics.png"),
          componentName: "IocWaterEquipmentStatistics",
        },
        {
          statisticalCardName: "物业工单统计",
          statisticalCardCode: "cardFacility10",
          imgurl: require("@/assets/images/facilities/workOrderStatistics.png"),
          componentName: "IocWorkOrderStatistics",
        },
        {
          statisticalCardName: "物业工单概览",
          statisticalCardCode: "cardFacility11",
          imgurl: require("@/assets/images/facilities/workOrderOverview.png"),
          componentName: "IocWorkOrderOverview",
        },
        {
          statisticalCardName: "充电桩使用统计", // 充电桩使用统计
          statisticalCardCode: "cardThrough11",
          imgurl: require("@/assets/images/passthrough/chargingPile.png"),
          componentName: "IocChartChargingPileStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_77,
      path: "/show/passthrough",
      icon: "icon-chepai",
      businessCode: "CARD_THROUGH",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_78, // 人员通行概况
          statisticalCardCode: "cardThrough01",
          imgurl: require("@/assets/images/passthrough/passingOverviewTh.png"),
          componentName: "IocChartPassingOverview",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_79, // 员工通行统计
          statisticalCardCode: "cardThrough02",
          imgurl: require("@/assets/images/passthrough/employeeStatisticsTh.png"),
          componentName: "IocChartEmployeeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_80, // 访客通行统计
          statisticalCardCode: "cardThrough03",
          imgurl: require("@/assets/images/passthrough/visitorStatisticsTh.png"),
          componentName: "IocChartVisitorStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_81, // 通行方式统计
          statisticalCardCode: "cardThrough04",
          imgurl: require("@/assets/images/passthrough/accessTypeStatisticsTh.png"),
          componentName: "IocChartAccessTypeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_82, // 车流统计
          statisticalCardCode: "cardThrough05",
          imgurl: require("@/assets/images/passthrough/trafficStatisticsTh.png"),
          componentName: "IocChartTrafficStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_83, // 车位统计
          statisticalCardCode: "cardThrough06",
          imgurl: require("@/assets/images/passthrough/parkingStatisticsTh.png"),
          componentName: "IocChartParkingStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_84, // 临停收费
          statisticalCardCode: "cardThrough07",
          imgurl: require("@/assets/images/passthrough/chargingFeesTh.png"),
          componentName: "IocChartChargingFees",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_85, // 停车时长
          statisticalCardCode: "cardThrough08",
          imgurl: require("@/assets/images/passthrough/parkingTimeTh.png"),
          componentName: "IocChartParkingTime",
        },
        {
          statisticalCardName: "科技馆客流统计", // 科技馆客流统计
          statisticalCardCode: "cardThrough09",
          imgurl: require("@/assets/images/passthrough/scienceVisitor.png"),
          componentName: "IocChartScienceVisitorStatistics",
        },
        {
          statisticalCardName: "会议室使用统计", // 会议室使用统计
          statisticalCardCode: "cardThrough10",
          imgurl: require("@/assets/images/passthrough/meetingRoom.png"),
          componentName: "IocChartMeetingRoomStatistics",
        },
        {
          statisticalCardName: "楼栋出入统计", // 楼栋出入统计
          statisticalCardCode: "cardThrough12",
          imgurl: require("@/assets/images/passthrough/buildingAccessStatistics.png"),
          componentName: "IocChartBuildingAccessStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_86,
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87,
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88,
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89,
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90,
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91,
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93,
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94,
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95,
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
    {
      name: "资产经营",
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87,
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88,
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89,
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90,
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91,
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93,
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94,
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95,
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
  ];
} else {
  cards = [
    {
      name: I18N.common.i18nKey_cockpit_46,
      notTabPage: true,
      businessCode: "CARD_SITUATION_2D",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_48,
      path: "/show/global",
      icon: "icon-tongji",
      businessCode: "CARD_SITUATION",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_49, // 安全态势
      path: "/show/security",
      icon: "icon-anquandunpai",
      businessCode: "CARD_PROTECTION",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_50, // 视频巡逻统计
          statisticalCardCode: "cardProtection04",
          imgurl: require("@/assets/images/globalSecurity/videoPatrolStatistics.png"),
          componentName: "IocChartVideoPatrolStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_51, // 巡逻告警
          statisticalCardCode: "cardProtection05",
          imgurl: require("@/assets/images/globalSecurity/patrolAlarm.png"),
          componentName: "IocChartPatrolWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_52, // 安防告警状态
          statisticalCardCode: "cardProtection06",
          imgurl: require("@/assets/images/globalSecurity/securityEventStatus.png"),
          componentName: "IocChartSecurityAlarmStatus",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection07",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarning.png"),
          componentName: "IocChartEquipmentWarning",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53, // 设备告警统计
          statisticalCardCode: "cardProtection14",
          imgurl: require("@/assets/images/globalSecurity/equipmentWarning.png"),
          componentName: "IocChartEquipmentWarning2",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_54, // 安防告警统计
          statisticalCardCode: "cardProtection08",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmStatus.png"),
          componentName: "IocChartSecurityAlarmStatistical",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_55, // 安防告警趋势
          statisticalCardCode: "cardProtection09",
          imgurl: require("@/assets/images/globalSecurity/securityAlarmTrend.png"),
          componentName: "IocChartSecurityAlarmTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_56, // 安防工单趋势
          statisticalCardCode: "cardProtection10",
          imgurl: require("@/assets/images/globalSecurity/securityOrderTrend.png"),
          componentName: "IocChartSecurityOrderTrend",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57, // 设备统计
          statisticalCardCode: "cardProtection11",
          imgurl: require("@/assets/images/globalSecurity/equipmentStatistics.png"),
          componentName: "IocChartDeviceStatistics",
        },
        {
          statisticalCardName: "执法仪统计", // 执法仪统计
          statisticalCardCode: "cardProtection12",
          imgurl: require("@/assets/images/globalSecurity/zhifayi.png"),
          componentName: "IocChartEnforcementInstrument",
        },
        {
          statisticalCardName: "执法仪维度", // 执法仪维度统计
          statisticalCardCode: "cardProtection13",
          imgurl: require("@/assets/images/globalSecurity/zfytj.png"),
          componentName: "instrumentdimension",
        },
        {
          statisticalCardName: "保洁出勤和出勤率", // 保洁出勤和出勤率
          statisticalCardCode: "cardProtection15",
          imgurl: require("@/assets/images/globalSecurity/cleaningAttendanceStatistics.png"),
          componentName: "IocCleaningAttendanceStatistics",
        },
        {
          statisticalCardName: "保洁设备告警和覆盖率", // 保洁设备告警和覆盖率
          statisticalCardCode: "cardProtection16",
          imgurl: require("@/assets/images/globalSecurity/cleaningEquipmentStatistics.png"),
          componentName: "IocCleaningEquipmentStatistics",
        },
        {
          statisticalCardName: "部门工单统计", // 部门工单统计
          statisticalCardCode: "cardProtection17",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatistics.png"),
          componentName: "IocDepartmentWorkOrderStatistics",
        },
        {
          statisticalCardName: "部门工单状态", // 部门工单状态
          statisticalCardCode: "cardProtection18",
          imgurl: require("@/assets/images/globalSecurity/departmentWorkOrderStatus.png"),
          componentName: "IocDepartmentWorkOrderStatus",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_58,
      path: "/show/event-center",
      icon: "icon-zonghetaishi",
      allCardList: [],
    },
    {
      name: I18N.common.i18nKey_cockpit_59, // 能耗态势
      path: "/show/efficiency",
      icon: "icon-energy",
      businessCode: "CARD_ENERGY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_64, // 分项用能
          statisticalCardCode: "cardEnergy01",
          imgurl: require("@/assets/images/envFriendly/energySubitem.png"),
          componentName: "IocChartEnergySubitem",
        },
        // {
        //   statisticalCardName: I18N.common.i18nKey_cockpit_62, // 能效AI诊断
        //   statisticalCardCode: "cardEnergy02",
        //   imgurl: require("@/assets/images/envFriendly/energyAIDiagnosis.png"),
        //   componentName: "IocChartAIDiagnosis",
        // },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_65, // 能耗告警
          statisticalCardCode: "cardEnergy03",
          imgurl: require("@/assets/images/envFriendly/energyConsumptionAlarm.png"),
          componentName: "IocChartEnergyConsumptionAlarm",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_66, // 能耗监测
          statisticalCardCode: "cardEnergy04",
          imgurl: require("@/assets/images/envFriendly/energyEfficiency.png"),
          componentName: "IocChartEnergyEfficiency",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_67, // 环境监测
          statisticalCardCode: "cardEnergy05",
          imgurl: require("@/assets/images/envFriendly/environmentalMonitoring.png"),
          componentName: "IocChartEmp",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_68, // 设备用能排名
          statisticalCardCode: "cardEnergy06",
          imgurl: require("@/assets/images/envFriendly/equipmentRanking.png"),
          componentName: "IocChartEquipmentRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_69, // 区域每平方米能耗排名
          statisticalCardCode: "cardEnergy07",
          imgurl: require("@/assets/images/envFriendly/regionSquareRanking.png"),
          componentName: "IocChartRegionSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_70, // 商/租户每平方米用能排名
          statisticalCardCode: "cardEnergy08",
          imgurl: require("@/assets/images/envFriendly/tenantSquareRanking.png"),
          componentName: "IocChartTenantSquareRanking",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_72, // 水能
          statisticalCardCode: "cardEnergy10",
          imgurl: require("@/assets/images/envFriendly/hydroenergy.png"),
          componentName: "IocChartHydroenergy",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_73,
      path: "/show/facilities",
      icon: "icon-shipinjiankong",
      businessCode: "CARD_FACILITY",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_57,
          statisticalCardCode: "cardFacility01",
          imgurl: require("@/assets/images/facilities/deviceStatistics.png"),
          componentName: "IocChartEquipmentStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_74,
          statisticalCardCode: "cardFacility02",
          imgurl: require("@/assets/images/facilities/faultStatistics.png"),
          componentName: "IocChartFaultStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_53,
          statisticalCardCode: "cardFacility03",
          imgurl: require("@/assets/images/facilities/deviceAlarmStatistics.png"),
          componentName: "IocChartDeviceAlarmStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_75,
          statisticalCardCode: "cardFacility04",
          imgurl: require("@/assets/images/facilities/deviceEventStatusStatistics.png"),
          componentName: "IocChartDeviceEventStatusStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_76,
          statisticalCardCode: "cardFacility05",
          imgurl: require("@/assets/images/facilities/deviceRepair.png"),
          componentName: "IocChartDeviceRepair",
        },
        {
          statisticalCardName: "实时空气指数质量",
          statisticalCardCode: "cardFacility06",
          imgurl: require("@/assets/images/facilities/airQuality.png"),
          componentName: "IocChartAirQuality",
        },
        {
          statisticalCardName: "蓝牙信标设备统计",
          statisticalCardCode: "cardFacility07",
          imgurl: require("@/assets/images/facilities/beaconEquipmentStatistics.png"),
          componentName: "IocBeaconEquipmentStatistics",
        },
        {
          statisticalCardName: "液位设备统计",
          statisticalCardCode: "cardFacility08",
          imgurl: require("@/assets/images/facilities/levelEquipmentStatistics.png"),
          componentName: "IocLevelEquipmentStatistics",
        },
        {
          statisticalCardName: "水浸设备统计",
          statisticalCardCode: "cardFacility09",
          imgurl: require("@/assets/images/facilities/waterEquipmentStatistics.png"),
          componentName: "IocWaterEquipmentStatistics",
        },
        {
          statisticalCardName: "物业工单统计",
          statisticalCardCode: "cardFacility10",
          imgurl: require("@/assets/images/facilities/workOrderStatistics.png"),
          componentName: "IocWorkOrderStatistics",
        },
        {
          statisticalCardName: "物业工单概览",
          statisticalCardCode: "cardFacility11",
          imgurl: require("@/assets/images/facilities/workOrderOverview.png"),
          componentName: "IocWorkOrderOverview",
        },
        {
          statisticalCardName: "充电桩使用统计", // 充电桩使用统计
          statisticalCardCode: "cardThrough11",
          imgurl: require("@/assets/images/passthrough/chargingPile.png"),
          componentName: "IocChartChargingPileStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_77,
      path: "/show/passthrough",
      icon: "icon-chepai",
      businessCode: "CARD_THROUGH",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_78, // 人员通行概况
          statisticalCardCode: "cardThrough01",
          imgurl: require("@/assets/images/passthrough/passingOverview.png"),
          componentName: "IocChartPassingOverview",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_79, // 员工通行统计
          statisticalCardCode: "cardThrough02",
          imgurl: require("@/assets/images/passthrough/employeeStatistics.png"),
          componentName: "IocChartEmployeeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_80, // 访客通行统计
          statisticalCardCode: "cardThrough03",
          imgurl: require("@/assets/images/passthrough/visitorStatistics.png"),
          componentName: "IocChartVisitorStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_81, // 通行方式统计
          statisticalCardCode: "cardThrough04",
          imgurl: require("@/assets/images/passthrough/accessTypeStatistics.png"),
          componentName: "IocChartAccessTypeStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_82, // 车流统计
          statisticalCardCode: "cardThrough05",
          imgurl: require("@/assets/images/passthrough/trafficStatistics.png"),
          componentName: "IocChartTrafficStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_83, // 车位统计
          statisticalCardCode: "cardThrough06",
          imgurl: require("@/assets/images/passthrough/parkingStatistics.png"),
          componentName: "IocChartParkingStatistics",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_84, // 临停收费
          statisticalCardCode: "cardThrough07",
          imgurl: require("@/assets/images/passthrough/chargingFees.png"),
          componentName: "IocChartChargingFees",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_85, // 停车时长
          statisticalCardCode: "cardThrough08",
          imgurl: require("@/assets/images/passthrough/parkingTime.png"),
          componentName: "IocChartParkingTime",
        },
        {
          statisticalCardName: "科技馆客流统计", // 科技馆客流统计
          statisticalCardCode: "cardThrough09",
          imgurl: require("@/assets/images/passthrough/scienceVisitor.png"),
          componentName: "IocChartScienceVisitorStatistics",
        },
        {
          statisticalCardName: "会议室使用统计", // 会议室使用统计
          statisticalCardCode: "cardThrough10",
          imgurl: require("@/assets/images/passthrough/meetingRoom.png"),
          componentName: "IocChartMeetingRoomStatistics",
        },
        {
          statisticalCardName: "楼栋出入统计", // 楼栋出入统计
          statisticalCardCode: "cardThrough12",
          imgurl: require("@/assets/images/passthrough/buildingAccessStatistics.png"),
          componentName: "IocChartBuildingAccessStatistics",
        },
      ],
    },
    {
      name: I18N.common.i18nKey_cockpit_86,
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87,
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88,
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89,
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90,
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91,
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93,
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94,
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95,
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
    {
      name: "资产经营",
      path: "/show/smartBusiness",
      icon: "icon-busin",
      businessCode: "CARD_BUSINESS",
      allCardList: [
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_87,
          statisticalCardCode: "cardBussiness01",
          imgurl: require("@/assets/images/wisdomBusiness/visitorFlowTrends.png"),
          componentName: "IocChartVisitPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_88,
          statisticalCardCode: "cardBussiness02",
          imgurl: require("@/assets/images/wisdomBusiness/resideSituation.png"),
          componentName: "IocChartResideSituation",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_89,
          statisticalCardCode: "cardBussiness03",
          imgurl: require("@/assets/images/wisdomBusiness/hotRegion.png"),
          componentName: "IocChartHotRegion",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_90,
          statisticalCardCode: "cardBussiness04",
          imgurl: require("@/assets/images/wisdomBusiness/businessEventStatistics.png"),
          componentName: "IocChartStatisticOfBussiness",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_91,
          statisticalCardCode: "cardBussiness05",
          imgurl: require("@/assets/images/wisdomBusiness/passengerFlow.png"),
          componentName: "IocChartPassengerFlowPeople",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_93,
          statisticalCardCode: "cardBussiness06",
          imgurl: require("@/assets/images/wisdomBusiness/ageSex.png"),
          componentName: "IocChartAgeSex",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_94,
          statisticalCardCode: "cardBussiness07",
          imgurl: require("@/assets/images/wisdomBusiness/repeatCustomers.png"),
          componentName: "IocChartReturnedCustomer",
        },
        {
          statisticalCardName: I18N.common.i18nKey_cockpit_95,
          statisticalCardCode: "cardBussiness08",
          imgurl: require("@/assets/images/wisdomBusiness/FestivalPassengerFlowStatistics.png"),
          componentName: "IocChartHolidayTraffic",
        },
      ],
    },
    //  丹田
    {
      name: "综合态势",
      path: "/show/comprehensiveSituation",
      icon: "icon-tongji",
      businessCode: "CARD_SITUATION_DT",
      allCardList: [
        {
          statisticalCardName: "数据汇总分析",
          statisticalCardCode: "cardSituationDt01",
          imgurl: require("@/assets/images/globalSecurity/IocChartDataSummaryAnalysis.png"),
          componentName: "IocChartDataSummaryAnalysis",
        },
        {
          statisticalCardName: "年度用电能耗",
          statisticalCardCode: "cardSituationDt02",
          imgurl: require("@/assets/images/globalSecurity/IocChartPowerConsumption.png"),
          componentName: "IocChartAnnualElectricityConsumption",
        },
        {
          statisticalCardName: "传感器检测",
          statisticalCardCode: "cardSituationDt03",
          imgurl: require("@/assets/images/globalSecurity/iocSensorMonitoring.png"),
          componentName: "IocChartSensorDetection",
        },
        {
          statisticalCardName: "公寓管理-人员性别比例",
          statisticalCardCode: "cardSituationDt04",
          imgurl: require("@/assets/images/globalSecurity/IocChartGenderRatioPersonnel.png"),
          componentName: "IocChartApartmentManagementSex",
        },
        {
          statisticalCardName: "人员巡逻统计",
          statisticalCardCode: "cardSituationDt05",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolStatistics.png"),
          componentName: "IocChartPatrolStatistics",
        },
        {
          statisticalCardName: "公寓管理-人员分类",
          statisticalCardCode: "cardSituationDt06",
          imgurl: require("@/assets/images/globalSecurity/IocChartPersonnelClassification.png"),
          componentName: "IocChartApartmentManagementCategory",
        },
        {
          statisticalCardName: "车辆巡逻",
          statisticalCardCode: "cardSituationDt07",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolVehicles.png"),
          componentName: "IocChartPatrolVehicles",
        },
        {
          statisticalCardName: "设备数量统计",
          statisticalCardCode: "cardSituationDt08",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceNumberStatis.png"),
          componentName: "IocChartEquipmentQuantityStatistics",
        },
      ],
    },
    {
      name: "在线报修",
      path: "/show/onlineReport",
      icon: "icon-onlineRepair",
      businessCode: "CARD_REPORT",
      allCardList: [
        {
          statisticalCardName: "数据汇总分析",
          statisticalCardCode: "cardReport01",
          imgurl: require("@/assets/images/globalSecurity/IocChartDataSummaryAnalysis.png"),
          componentName: "IocChartDataSummaryAnalysis",
        },
        {
          statisticalCardName: "工单来源",
          statisticalCardCode: "cardReport02",
          imgurl: require("@/assets/images/globalSecurity/IocChartServiceCategory.png"),
          componentName: "IocChartServiceCategory",
        },
        {
          statisticalCardName: "受理效率排名",
          statisticalCardCode: "cardReport03",
          imgurl: require("@/assets/images/globalSecurity/IocChartAcceptanceEfficiencyRanking.png"),
          componentName: "IocChartAcceptanceEfficiencyRanking",
        },
        {
          statisticalCardName: "报修项目分析",
          statisticalCardCode: "cardReport04",
          imgurl: require("@/assets/images/globalSecurity/IocChartAnalysisOfRepairProject.png"),
          componentName: "IocChartAnalysisOfRepairProject",
        },
        {
          statisticalCardName: "超时异常",
          statisticalCardCode: "cardReport05",
          imgurl: require("@/assets/images/globalSecurity/IocChartTimeoutException.png"),
          componentName: "IocChartTimeoutException",
        },
        {
          statisticalCardName: "报修区域TOP5",
          statisticalCardCode: "cardReport06",
          imgurl: require("@/assets/images/globalSecurity/IocChartTop5RepairArea.png"),
          componentName: "IocChartTop5RepairArea",
        },
        {
          statisticalCardName: "工作量统计",
          statisticalCardCode: "cardReport07",
          imgurl: require("@/assets/images/globalSecurity/IocChartWorkloadStatistics.png"),
          componentName: "IocChartWorkloadStatistics",
        },
        {
          statisticalCardName: "维修人员管理",
          statisticalCardCode: "cardReport08",
          imgurl: require("@/assets/images/globalSecurity/IocChartMaintenancePersonnelManagement.png"),
          componentName: "IocChartMaintenancePersonnelManagement",
        },
      ],
    },
    {
      name: "公寓管理",
      path: "/show/apartmentManagement",
      icon: "icon-gongyuguanli",
      businessCode: "CARD_APARTMENT",
      allCardList: [
        {
          statisticalCardName: "住宿人员管理",
          statisticalCardCode: "cardApartment01",
          imgurl: require("@/assets/images/globalSecurity/IocChartMaAccommodationPersonnel.png"),
          componentName: "IocChartMaAccommodationPersonnel",
        },
        {
          statisticalCardName: "人员分类",
          statisticalCardCode: "cardApartment02",
          imgurl: require("@/assets/images/globalSecurity/IocChartPersonnelClassification.png"),
          componentName: "IocChartPersonnelClassification",
        },
        {
          statisticalCardName: "人员性别比例",
          statisticalCardCode: "cardApartment03",
          imgurl: require("@/assets/images/globalSecurity/IocChartGenderRatioPersonnel.png"),
          componentName: "IocChartGenderRatioPersonnel",
        },
        {
          statisticalCardName: "房源统计",
          statisticalCardCode: "cardApartment04",
          imgurl: require("@/assets/images/globalSecurity/IocChartHousingStatistics.png"),
          componentName: "IocChartHousingStatistics",
        },
        {
          statisticalCardName: "大学各阶段人员",
          statisticalCardCode: "cardApartment05",
          imgurl: require("@/assets/images/globalSecurity/IocChartUniversityPersonnelStages.png"),
          componentName: "IocChartUniversityPersonnelStages",
        },
        {
          statisticalCardName: "研究生各阶段人员",
          statisticalCardCode: "cardApartment06",
          imgurl: require("@/assets/images/globalSecurity/IocChartGraduatePersonnelStages.png"),
          componentName: "IocChartGraduatePersonnelStages",
        },
        {
          statisticalCardName: "床位管理",
          statisticalCardCode: "cardApartment07",
          imgurl: require("@/assets/images/globalSecurity/IocChartBedManagement.png"),
          componentName: "IocChartBedManagement",
        },
        {
          statisticalCardName: "安全管理",
          statisticalCardCode: "cardApartment08",
          imgurl: require("@/assets/images/globalSecurity/IocChartSecurityManagement.png"),
          componentName: "IocChartSecurityManagement",
        },
      ],
    },
    {
      name: "设备管理",
      path: "/show/deviceManagement",
      icon: "icon-shipinjiankong",
      businessCode: "CARD_DEVICE",
      allCardList: [
        {
          statisticalCardName: "设备数量统计",
          statisticalCardCode: "cardDevice01",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceNumberStatis.png"),
          componentName: "IocChartDeviceNumberStatis",
        },
        {
          statisticalCardName: "工作任务",
          statisticalCardCode: "cardDevice02",
          imgurl: require("@/assets/images/globalSecurity/IocChartTasks.png"),
          componentName: "IocChartTasks",
        },
        {
          statisticalCardName: "设备运行状态",
          statisticalCardCode: "cardDevice03",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceRunstatus.png"),
          componentName: "IocChartDeviceRunstatus",
        },
        {
          statisticalCardName: "工程人员管理",
          statisticalCardCode: "cardDevice04",
          imgurl: require("@/assets/images/globalSecurity/IocChartEnPersonManage.png"),
          componentName: "IocChartEnPersonManage",
        },
        {
          statisticalCardName: "设备管理工单",
          statisticalCardCode: "cardDevice05",
          imgurl: require("@/assets/images/globalSecurity/IocChartDeviceWokOder.png"),
          componentName: "IocChartDeviceWokOder",
        },
        {
          statisticalCardName: "工单完成情况",
          statisticalCardCode: "cardDevice06",
          imgurl: require("@/assets/images/globalSecurity/IocChartWorkorderCompletion.png"),
          componentName: "IocChartWorkorderCompletion",
        },
      ],
    },
    {
      name: "校园安保",
      path: "/show/campusSecurity",
      icon: "icon-xiaoyuananbao",
      businessCode: "CARD_SECURITY",
      allCardList: [
        {
          statisticalCardName: "人员巡逻完成率",
          statisticalCardCode: "cardSecurity01",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolCompletionRate.png"),
          componentName: "IocChartPatrolCompletionRate",
        },
        {
          statisticalCardName: "车辆巡逻完成率",
          statisticalCardCode: "cardSecurity02",
          imgurl: require("@/assets/images/globalSecurity/IocChartVehiclePatrol.png"),
          componentName: "IocChartVehiclePatrol",
        },
        {
          statisticalCardName: "车辆巡逻排行",
          statisticalCardCode: "cardSecurity03",
          imgurl: require("@/assets/images/globalSecurity/IocChartVehiclePatrolRanking.png"),
          componentName: "IocChartVehiclePatrolRanking",
        },
        {
          statisticalCardName: "人员巡逻计划",
          statisticalCardCode: "cardSecurity04",
          imgurl: require("@/assets/images/globalSecurity/IocChartPersonnelPatrolPlan.png"),
          componentName: "IocChartPersonnelPatrolPlan",
        },
        {
          statisticalCardName: "车辆巡逻计划",
          statisticalCardCode: "cardSecurity05",
          imgurl: require("@/assets/images/globalSecurity/IocChartVehiclePatrolPlan.png"),
          componentName: "IocChartVehiclePatrolPlan",
        },
        {
          statisticalCardName: "人员巡逻统计",
          statisticalCardCode: "cardSecurity06",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolStatistics.png"),
          componentName: "IocChartPatrolStatistics",
        },
        {
          statisticalCardName: "车辆巡逻",
          statisticalCardCode: "cardSecurity07",
          imgurl: require("@/assets/images/globalSecurity/IocChartPatrolVehicles.png"),
          componentName: "IocChartPatrolVehicles",
        },
        {
          statisticalCardName: "人员巡逻对比分析",
          statisticalCardCode: "cardSecurity08",
          imgurl: require("@/assets/images/globalSecurity/IocChartComparativeAnalysisPersonnelPatrol.png"),
          componentName: "IocChartComparativeAnalysisPersonnelPatrol",
        },
      ],
    },
    {
      name: "绿化管理",
      path: "/show/greeningManagement",
      icon: "icon-lvhuaguanli",
      businessCode: "CARD_GREEN",
      allCardList: [
        {
          statisticalCardName: "温度情况",
          statisticalCardCode: "cardGreen01",
          imgurl: require("@/assets/images/globalSecurity/iocTemperature.png"),
          componentName: "IocTemperature",
        },
        {
          statisticalCardName: "作业调度",
          statisticalCardCode: "cardGreen02",
          imgurl: require("@/assets/images/globalSecurity/iocJobScheduling.png"),
          componentName: "IocJobScheduling",
        },
        {
          statisticalCardName: "空气湿度情况",
          statisticalCardCode: "cardGreen03",
          imgurl: require("@/assets/images/globalSecurity/iocAirHumidity.png"),
          componentName: "IocAirHumidity",
        },
        {
          statisticalCardName: "异常阙值",
          statisticalCardCode: "cardGreen04",
          imgurl: require("@/assets/images/globalSecurity/iocAbnormalThreshold.png"),
          componentName: "IocAbnormalThreshold",
        },
        {
          statisticalCardName: "传感器监测（温湿度）",
          statisticalCardCode: "cardGreen05",
          imgurl: require("@/assets/images/globalSecurity/iocSensorMonitoring.png"),
          componentName: "IocSensorMonitoring",
        },
        {
          statisticalCardName: "植物百科",
          statisticalCardCode: "cardGreen06",
          imgurl: require("@/assets/images/globalSecurity/iocEncyclopediaOfPlants.png"),
          componentName: "IocEncyclopediaOfPlants",
        },
        {
          statisticalCardName: "传感器监测（光电量）",
          statisticalCardCode: "cardGreen07",
          imgurl: require("@/assets/images/globalSecurity/iocPhotoelectricSensor.png"),
          componentName: "IocPhotoelectricSensor",
        },
      ],
    },
    {
      name: "能源管理",
      path: "/show/energyManagement",
      icon: "icon-nengyuanguanli",
      businessCode: "CARD_ENERGY_MANAGE",
      allCardList: [
        {
          statisticalCardName: "年度用电能耗",
          statisticalCardCode: "cardEnergyManage01",
          imgurl: require("@/assets/images/globalSecurity/IocChartPowerConsumption.png"),
          componentName: "IocChartPowerConsumption",
        },
        {
          statisticalCardName: "年度用热能耗",
          statisticalCardCode: "cardEnergyManage02",
          imgurl: require("@/assets/images/globalSecurity/IocChartHeatConsumption.png"),
          componentName: "IocChartHeatConsumption",
        },
        {
          statisticalCardName: "实时用电分析",
          statisticalCardCode: "cardEnergyManage03",
          imgurl: require("@/assets/images/globalSecurity/IocChartpowerConsumptionAnalysis.png"),
          componentName: "IocChartpowerConsumptionAnalysis",
        },
        {
          statisticalCardName: "实时用热分析",
          statisticalCardCode: "cardEnergyManage04",
          imgurl: require("@/assets/images/globalSecurity/IocChartThermalAnalysis.png"),
          componentName: "IocChartThermalAnalysis",
        },
        {
          statisticalCardName: "年度用水能耗",
          statisticalCardCode: "cardEnergyManage05",
          imgurl: require("@/assets/images/globalSecurity/IocChartWaterConsumption.png"),
          componentName: "IocChartWaterConsumption",
        },
        {
          statisticalCardName: "预测量",
          statisticalCardCode: "cardEnergyManage06",
          imgurl: require("@/assets/images/globalSecurity/IocChartPreMeasurement.png"),
          componentName: "IocChartPreMeasurement",
        },
        {
          statisticalCardName: "实时用水分析",
          statisticalCardCode: "cardEnergyManage07",
          imgurl: require("@/assets/images/globalSecurity/IocChartWaterAnalysis.png"),
          componentName: "IocChartWaterAnalysis",
        },
      ],
    },
  ];
}

export default cards;
