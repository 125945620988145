import Vue from "vue";
import Vuex from "vuex";

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

// import plugins from './plugins'
import basePosition from "./modules/base-position";
import largeScreenData from "./modules/largeScreenData/largeScreenData";
import security from "./modules/security";
import equipInfo from "./modules/eventCenter/equipInfo";
import perimeterInfo from "./modules/eventCenter/perimeterInfo"
import device from "./modules/device.js"
import cockpitHome from "./modules/cockpitHome.js"

import liveDailyPatrol from "./modules/globalSecurity/liveDailyPatrol";
import publicCloudVideo from "./modules/publicCloudVideo/publicCloudVideo";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  // plugins,
  actions,
  getters,
  modules: {
    basePosition,
    security,
    perimeterInfo,
    equipInfo,
    largeScreenData,
    device,
    cockpitHome,
    liveDailyPatrol,
    publicCloudVideo,
  }
});
