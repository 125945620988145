import Ajax from "@/utils/request.js";

export default {
  //得到卡片的集合
  async getCardList({ state, commit, getters }) {
    let result = await Ajax({
      url: "/ioc/atomiccommon/bascardparkconfig/getvalidcardlist",
      method: "get",
      data: {
        posCode: getters['basePosition/positionInfo'].posCode,
        userCode: state.userInfo.uid
      }
    }, {
      showErrTip: true
    });
    commit('fillValidCardList', result || [])
  },
  getGraphData({ state, commit }) {
    let btnAuth = state.btnAuthObj["/overview/dataCockpit"] || {};
    let arr = ['home', 'safe', 'parking', 'energy', 'business', 'lease', 'property', 'device', 'person']
    let chartRole = []
    Object.keys(btnAuth).map(el => {
      if(arr.includes(el)) {
        chartRole.push(el);
      }
    })
    let data = {
      chartRole: chartRole
    }
    Ajax({
      url: `/ioc/report/visualSense/graphSettings/query`,
      method: "POST",
      data,
    }).then(res => {
      commit('saveGraphData', res);
    });
  },
  exchangeCardSort({ state, dispatch }, option) {
    let data = state.graphData[option.moduleCode];
    let sortOne, sortTwo;
    for(let i = 0; i < data.length; i++) {
      if (data[i].reportCode === option.sortOne) {
        sortOne = data[i];
      }
      if (data[i].reportCode === option.sortTwo) {
        sortTwo = data[i];
      }
      if (sortOne && sortTwo) {
        break;
      }
    }
    let tempSort = sortOne.sortIndex;
    sortOne.sortIndex = sortTwo.sortIndex;
    sortTwo.sortIndex = tempSort;
    dispatch('saveGraphData');
  },
  saveGraphData({state, dispatch}) {
    let homeReportCodes = [{
      moduleCode: 'home',
      reportProperty: []
    }];
    let reportCodes = []
    let graphData = state.graphData;
    Object.keys(graphData).forEach(key => {
      if (key === 'home') {
        graphData[key].forEach(el => {
          homeReportCodes[0].reportProperty.push({
            reportCode: el.reportCode,
            isCheck: el.isCheck,
            sortIndex: el.sortIndex 
          });
        })
      } else {
        let authObj = {
          moduleCode: key,
          reportProperty: []
        }
        graphData[key].forEach(el => {
          authObj.reportProperty.push({
            reportCode: el.reportCode,
            isCheck: el.isCheck,
            sortIndex: el.sortIndex 
          })
        });
        reportCodes.push(authObj);
      }
    })
    let data = {
      homeReportCodes,
      reportCodes
    }
    Ajax({
      url: `/ioc/report/visualSense/graphSettings/update`,
      method: "POST",
      data
    }).then(() => {
      dispatch('getGraphData');
    });
  }
}