<template>
  <div
    class="page-box dark"
    :class="{
      'page-box2': $route.path === '/futureBigScreen',
      'page-box3': $route.path.includes('/airPortTerminal') && isFullScreen,
      'page-box-scroll': $route.path.includes('/airPortTerminal')
    }"
    id="cockpit-page-box"
    v-if="hasLayoutMounted"
  >
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
// import { getSearchObj } from "@/utils/utils";
export default {
  name: "CockpitApp",
  created() {
    window.GLOBAL_OBJ.eventHub.publish("layoutMounted", (data) => {
      this.hasLayoutMounted = true;
      this.handleLayoutMounted(data);
    });
    window.GLOBAL_OBJ.eventHub.subscribe("isFullScreen", (val) => {
      this.isFullScreen = val
    });
  },
  data() {
    return {
      isFullScreen: false,
      hasLayoutMounted: false,
    };
  },
  methods: {
    ...mapMutations("basePosition", ["fillPositionInfo"]),
    ...mapMutations([
      "fillMapList",
      "fillUser",
      "fillBtnAuth",
      "fillCorporationVO",
    ]),

    handleLayoutMounted(data = {}) {
      let {
        parkConfigList = {},
        node = {},
        userPerm = {},
        routeMap = {},
        corporationVO = {},
      } = data;
      let { mapConfig = "" } = parkConfigList;

      let mapList = mapConfig.split(",");
      this.fillCorporationVO(corporationVO);
      this.fillPositionInfo(node);
      this.fillMapList(mapList);
      this.fillBtnAuth(routeMap);
      this.fillUser(userPerm);
      window.GLOBAL_OBJ.eventHub.publish("mapListChange", mapList);
    },
  },
};
</script>
<style lang="less">
@import "~@/less/App.less";
.page-box2 {
  top: 6.325rem;
  height: calc(100% - 105px) !important;
  overflow: hidden;
}
.page-box-scroll {
  overflow: scroll;
}
.page-box3 {
  top: 0 !important;
  height: 100vh !important;
  overflow: hidden;
}
// 控制时间控件vue2-datepicker-old 时分秒滚动的样式
.mx-time-column .mx-time-item:hover {
  background: #101b35 !important;
}
</style>
