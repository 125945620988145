<template>
  <div>
    <div class="onDutyPersonnel">
      <div>
        <div class="end">
          <div>
            在岗人员<span class="quan"
              >(在线{{ this.quantity.online }}; 总数{{
                this.quantity.online + this.quantity.offline
              }})</span
            >
          </div>
          <i class="el-icon-close" @click="close"></i>
        </div>
        <el-input placeholder="请输入人员名称" v-model="deviceobj.name" @keyup.enter.native="search()">
          <i
            slot="suffix"
            @click="search()"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
        <div class="devflex">
          <div class="devclass" v-for="(d, i) in mydeviceList" :key="i">
            <div class="texeone">{{ d.deviceName }}</div>
            <div class="address" v-if="d.networkStatus === 1">
              <div class="dian" v-if="d.networkStatus === 1"></div>
              <div class="text" v-if="d.networkStatus === 1">在线</div>
            </div>
            <div class="address" v-if="d.networkStatus === 0">
              <div class="dian2" v-if="d.networkStatus === 0"></div>
              <div class="text2" v-if="d.networkStatus === 0">离线</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "onDutyPersonnel",
  data() {
    return {
      mydeviceList: [],
      deviceobj: { name: "" },
      quantity: {
        offline: 0,
        online: 0,
      },
    };
  },
  computed: {
  },
  props: {
    onDutyPersonnelDevicelList: {
      type: Array,
      default: new Array(),
    },
  },
  watch: {
    onDutyPersonnelDevicelList: {
      deep: true,
      immediate: true,
      handler(v) {
        this.quantity = {
          offline: 0,
          online: 0,
        };
        this.mydeviceList = v;
        this.mydeviceList.forEach((d) => {
          if (d.networkStatus === 0) {
            this.quantity.offline++;
          } else {
            this.quantity.online++;
          }
        });
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    search() {
      var arr = [];
      if (this.deviceobj.name) {
        this.onDutyPersonnelDevicelList.forEach((d) => {
          if (d.deviceName.indexOf(this.deviceobj.name) !== -1) {
            arr.push(d);
          }
        });
        this.mydeviceList = arr;
      } else {
        this.mydeviceList = this.onDutyPersonnelDevicelList;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.onDutyPersonnel {
  height: 430px;
  width: 199px;
  background-color: #1d2745;
  border-radius: 6px;
  border: solid 1px #2e3859;
  padding: 10px 10px;

  .devflex {
    height: 350px;
    overflow: auto;
  }
  .end {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #fff;
    .quan {
      font-size: 12px;
    }
  }
  .el-icon-close {
    color: #fff;
    cursor: pointer;
  }
  /deep/ .el-input__inner {
    height: 32px;
    margin-bottom: 10px;
    background: #121b37;
    color: #fff;
  }
  /deep/.el-input__icon {
    line-height: 32px;
    height: 32px;
    cursor: pointer;
  }

  .devclass {
    .texeone {
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    display: flex;
    color: #a2b0cb;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    padding: 10px 0px;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      cursor: pointer;
    }
    .address {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      .dian {
        width: 5px;
        height: 5px;
        border-radius: 50% 50%;
        background: #32e0a6;
        margin-top: 8px;
      }
      .text {
        width: 30px;
        color: #32e0a6;
        margin-left: 8px;
        font-size: 8px;
        line-height: 20px;
      }
      .dian2 {
        width: 5px;
        height: 5px;
        border-radius: 50% 50%;
        background: #607390;
        margin-top: 8px;
      }

      .text2 {
        width: 30px;
        color: #607390;
        margin-left: 8px;
        font-size: 8px;
        line-height: 20px;
      }
      color: #607390;
    }
  }
}
</style>
