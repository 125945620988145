<template>
  <div ref="popup" class="car-trial-pop">
    <div class="car-content">
      <div class="name">{{ title }}</div>
      <div class="border"></div>
      <div class="address-title">位置</div>
      <div class="address">{{ address }}</div>
      <div class="btn" @click="checkDetail">
        <img class="img" src="@/assets/images/common/car_trial.png" alt="" />{{
          buttonText
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "carTrialPop",
  components: {},
  props: {

    type: {
      type: String,
      default: "",
    }, // 货车类型
    plateNumber: {
      type: String,
      default: "",
    }, // 货车拍照
    address: {
      type: String,
      default: "",
    }, // 地址
    // 事件属性
    // longitude: null,
    // latitude: null,
    // 通用属性
    buttonText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["fillCarTrialObj", "fillIsShowCarTrial","fillPopShow"]),
    // 查看轨迹详情
    checkDetail() {
      this.fillIsShowCarTrial(true);
      this.fillCarTrialObj({
        plateNumber: this.plateNumber,
        type: this.type,
      });
      this.fillPopShow(false)
      this.$emit("closePopup");
    },
  },
  computed: {
    title() {
      return `${this.type} ${this.plateNumber}`;
    },
  }

};
</script>
<style lang="less" scoped>
.car-trial-pop {
  width: 360px;
  height: 176px;
  color: var(--common-white);
  backdrop-filter: blur(10px);
  position: relative;
  text-align: left;
  line-height: 1.2;
  &::before{
    border: 1.5px solid transparent;
    border-radius: 8px;
    pointer-events: none;
    content :'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)), linear-gradient(to bottom, #05F5DF, #43ADF9);
    opacity: 0.6;
    z-index: -1;
  }
  .car-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 16px;
    .name {
      font-size: 16px;
      color: var(--common-white);
      font-family: var(--font-title);
    }
    .border {
      width: 321px;
      height: 3px;
      background: #2e3859;
      margin-top: 6px;
    }
    .address-title {
      font-size: 14px;
      color: var(--common-white);
      font-family: var(--font-title);
      padding-top: 18px;
    }
    .address {
      font-size: 14px;
      color: var(--common-secondary);
      padding-top: 10px;
    }
    .btn {
      margin-top: 15px;
      width: 96px;
      height: 26px;
      background-color: #3a7aea;
      border-radius: 2px;
      line-height: 26px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      .img {
        width: 18px;
        height: 18px;
        padding-right: 3px;
      }
    }
  }
  /deep/ .amap-info-sharp {
    display: none;
  }
  /deep/ .amap-info-content {
    padding: 0;
  }
}
</style>
