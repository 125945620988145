<template>
  <div>
    <div class="instrument" v-if="dialogFlag === 0">
      <div>
        <div class="end">
          <div>
            执法仪<span class="quan"
              >(在线{{ this.quantity.online }}; 总数{{
                this.quantity.online + this.quantity.offline
              }})</span
            >
          </div>
          <i class="el-icon-close" @click="close"></i>
        </div>
        <el-input placeholder="请输入执法仪名称" v-model="deviceobj.name">
          <i slot="suffix" @click="search()" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="devflex">
          <div class="devclass" v-for="(d, i) in mydeviceList" :key="i">
            <el-tooltip class="item" effect="light" :content="d.deviceName" placement="top-start">
              <span class="texeone">{{ d.deviceName }}</span>
            </el-tooltip>
            <div class="address" v-if="d.networkStatus === 1">
              <div class="dian" v-if="d.networkStatus === 1"></div>
              <div class="text" v-if="d.networkStatus === 1">在线</div>
              <img @click="callphone(d.deviceSip, 1)" src="../img/dianhua.png" alt="" />
              <img @click="callphone(d.deviceSip, 2)" src="../img/shiping.png" alt="" />
            </div>
            <div class="address" v-if="d.networkStatus === 0">
              <div class="dian2" v-if="d.networkStatus === 0"></div>
              <div class="text2" v-if="d.networkStatus === 0">离线</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="videoDiv" v-if="dialogFlag === 1">
      <div class="betwen">
        <div class="title">
          <img class="img2" src="/security-gis/static/imagesMap/a-tong.png" alt="" />
          <span>通话</span>
        </div>
        <div>
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>

      <div class="content">
        <div v-if="phoneFlag === 0">等待接听中...</div>
        <div v-if="phoneFlag === 1">通话中...</div>
      </div>
      <div class="but">
        <div v-if="phoneFlag === 1" class="overline" @click="handup()">
          <img class="img" src="/security-gis/static/imagesMap/guaduan.png" alt="" />
          挂断
        </div>
      </div>
    </div>
    <div class="videoDiv" v-if="dialogFlag === 2">
      <div class="betwen">
        <div class="title">
          <img class="img" src="/security-gis/static/imagesMap/shiping.png" alt="" />
          <span>视频</span>
        </div>
        <div>
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>

      <div class="content">
        <div>
          <div class="main-video">
            <video ref="remoteVideo" id="remoteVideo" autoplay class="main-video__remote"></video>
          </div>
        </div>
      </div>
      <div class="but">
        <div v-if="phoneFlag === 1" class="overline" @click="handup()">
          <img class="img" src="/security-gis/static/imagesMap/guaduan.png" alt="" />
          挂断
        </div>
      </div>
    </div>
    <div class="videoDiv" v-if="dialogFlag === 3">
      <div class="betwen">
        <div class="title">
          <img src="/security-gis/static/imagesMap/chagan.png" alt="" />
          <span>查岗</span>
        </div>
        <div>
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>

      <div class="content">
        <div>
          <div class="main-video">
            <video ref="remoteVideo" id="remoteVideo" autoplay class="main-video__remote"></video>
          </div>
        </div>
      </div>
      <div class="but">
        <div v-if="phoneFlag === 1" class="overline" @click="handup()">
          <img class="img" src="/security-gis/static/imagesMap/guaduan.png" alt="" />
          挂断
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Ajax from "@/dataApi/operationReport.js";
import { mapGetters } from "vuex";
export default {
  name: "instrument",
  data() {
    return {
      ua: null,
      info: {},
      communicateMode: "", // talk：喊话，listen：监听，phone：点对点，check：查岗，conference：会议
      isShowHangup: false, //挂断按钮
      session: null,
      ruixunHost: "http://172.16.84.11:9012/ruixun",
      testGroupCode: "TP6660110001506", // yika专用测试
      muteFlag: false,
      target: "88180410000743", // 输入对讲机号（睿讲app设备）
      dialogFlag: 0,
      popupcalss: "alarm-pop",
      textdata: {},
      phoneFlag: 0, //0是未接通，1是已接通
      isFront: true, // 前后置摄像头标识
      mydeviceList: [],
      deviceobj: { name: "" },
      quantity: {
        offline: 0,
        online: 0,
      },
    };
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
  },
  props: {
    did: {
      type: String,
      default: "",
    },
    deviceList: {
      type: Array,
      default: new Array(),
    },
  },
  watch: {
    deviceList: {
      deep: true,
      immediate: true,
      handler(v) {
        this.quantity = {
          offline: 0,
          online: 0,
        };
        this.mydeviceList = v;
        this.mydeviceList.forEach((d) => {
          if (d.networkStatus === 0) {
            this.quantity.offline++;
          } else {
            this.quantity.online++;
          }
        });
      },
    },
  },
  created() {
    //初始化执法仪,查询用户sipcode
    this.getUserSipCode();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    // 挂断
    search() {
      var arr = [];
      if (this.deviceobj.name) {
        this.deviceList.forEach((d) => {
          if (d.deviceName.indexOf(this.deviceobj.name) !== -1) {
            arr.push(d);
          }
        });
        this.mydeviceList = arr;
      } else {
        this.mydeviceList = this.deviceList;
      }
    },
    handup() {
      if (this.session.terminate) {
        this.session.terminate();
      }

      this.resetMedia();
    },
    timestamp(time) {
      if (time) {
        let d = new Date(time);
        let date =
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1) +
          "-" +
          d.getDate() +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes() +
          ":" +
          d.getSeconds();
        return date || "";
      } else {
        return " ";
      }
    },

    // 查岗
    checkDevice(flag) {
      var data = {
        useNumber: 1,
        useType: 3,
        posCode: this.positionInfo.posCode,
      };
      Ajax.addUseNumber(data).then(() => {});
      this.dialogFlag = 3;
      if (this.session) {
        this.$message("请先挂断当前通话");
        return false;
      }
      this.communicateMode = "check";
      this.isFront = flag;
      // 前后置摄像头标识
      let header = flag ? "X-VR-CallType:monitor-front" : "X-VR-CallType:monitor-back";
      // 本质上是一通点对点通话，对本地的音视频在配置项中进行禁用，然后在rtcOfferConstraints配置项中开启接收远端的音视频
      // DS7770110000316 设备[yika测试]
      this.ua.wrCheckDevice(this.target, {
        extraHeaders: [header],
      });
    },

    //获取用户SipCode
    getUserSipCode(did) {
      Ajax.getUserSipCode(did).then((res) => {
        this.info = res;
        this.initSetting();
      });
    },
    // 获取执法仪详情
    getSipDeviceDetails(did) {
      Ajax.getSipDeviceDetails(did).then((res) => {
        this.textdata = res;
        this.title = res.watcher;
        this.getDeviceSipCode(did);
      });
    },
    //获取执法仪SipCode
    getDeviceSipCode(did) {
      Ajax.getDeviceSipCode(did).then((res) => {
        this.target = res.sipCode;
      });
    },

    // 初始化配置
    initSetting() {
      var url = "";
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "ioc-uat.icloudcity"
      ) {
        url = "api-ihw-stg.vanrui.com:5068";
      } else {
        url = "rx-ihw.vanrui.com:5068";
      }
      // 远端获取sipIp,sipCode,sipPassword组成下列参数
      // ua配置项
      const configuration = {
        ws: `wss://${url}`,
        uri: `sip:${this.info.sipCode}@${url}`,
        password: this.info.password,
        display_name: this.info.sipCode,
        contact_uri: `sip:${this.info.sipCode}@${url};transport=wss`, // 运行项目以及环境
        client: "测试项目-dev",
      };
      this.ua = this.$mysip(configuration);

      const ua = this.ua;

      ua.on("connected", (e) => {
        console.log("---------------------------websocket成功---------------------------", e);
      });
      ua.on("disconnected", (e) => {
        console.log("---------------------------websocket断连---------------------------", e);
      });
      ua.on("registered", (e) => {
        this.connected = true;
        if (e.response.status_code !== 200) {
          this.$notify({
            title: "账号注册失败",
            message: "原因：" + e.cause,
            type: "error",
            position: "bottom-right",
          });
        }
        console.log("---------------------------ua注册成功---------------------------", e);
      });
      ua.on("unregistered", (e) => {
        console.log("---------------------------ua未注册---------------------------", e);
      });
      ua.on("registrationFailed", (e) => {
        this.$notify({
          title: "账号注册失败",
          message: "原因：用户名或者密码输入错误",
          type: "error",
          position: "bottom-right",
        });
        console.log("---------------------------ua注册失败---------------------------", e);
      });

      ua.on("wlAccepted", (session, data) => {
        // this.loading = false;
        // this.phoneFlag = 2;
        console.log(
          "--------------------------session接受通话-------------------------------",
          session,
          data
        );
        this.phoneFlag = 1;
        this.session = session;
      });
      // 暂无用
      ua.on("wlConfirmed", (session, data) => {
        console.log(
          "--------------------------session确认通话-------------------------------",
          data
        );
        this.session = session;
      });
      ua.on("wlEnded", (session, data) => {
        this.session = null;
        this.phoneFlag = 0;
        this.resetMedia();
        this.$message.info("电话已结束");
        console.log("--------------------------session结束-------------------------------", data);
      });
      ua.on("wlFailed", (session, data) => {
        this.session = null;
        this.resetMedia();
        this.$message.info("呼叫失败");
        console.log(
          "--------------------------session呼叫失败-------------------------------",
          data
        );
      });
      ua.on("wlInfo", (session, data) => {
        console.log(
          "--------------------------session收到info-------------------------------",
          data
        );
      });
      ua.on("wlReceive", (e) => {
        console.log("--------------------------session收到通话-------------------------------", e);
        this.session = e.session;
        this.incallVisible = true;
      });
      ua.on("wlReceiveStream", (session, connection) => {
        console.log(
          "--------------------------session收到通话stream-------------------------------",
          connection
        );
        this.isShowHangup = true; // 显示挂断按钮
        document.getElementById("remoteVideo").srcObject = connection.getRemoteStreams()[0];
      });
      ua.on("wlCall", (e) => {
        console.log("--------------------------拨出电话回调-------------------------------");
        this.session = e.session;
      });
      ua.on("wlCallStream", (session, connection) => {
        console.log(
          "--------------------------播出流媒体回调-------------------------------",
          session
        );
        // // 喊话状态不渲染远端媒体，业务上不用听到设备在讲什么，也可不渲染本地媒体，本地没必要听到自己说什么
        if (this.communicateMode !== "talk") {
          document.getElementById("remoteVideo").srcObject = connection.getRemoteStreams()[0];
        }
      });
      ua.on("wlNewMessage", (e) => {
        console.log(
          "-------------------------------收到message回调（全局）-------------------------------",
          e
        );
      });
    },
    callphone(deviceSip, index) {
      this.target = deviceSip;
      this.call(index);
    },
    // 拨打电话
    call(videoCall) {
      var _this = this;
      this.communicateMode = "phone";
      this.muteFlag = false;
      this.loading = true;
      if (videoCall === 2) {
        let data = {
          useNumber: 1,
          useType: 2,
          posCode: _this.positionInfo.posCode,
        };
        Ajax.addUseNumber(data).then(() => {});

        this.dialogFlag = 2;
        this.ua.wrCallVideo(this.target, {});
      } else {
        let data = {
          useNumber: 1,
          useType: 1,
          posCode: _this.positionInfo.posCode,
        };
        Ajax.addUseNumber(data).then(() => {});
        this.dialogFlag = 1;
        this.ua.wrCallPhone(this.target, {});
      }
    },

    // 查看轨迹详情
    checkDetail() {
      this.fillPopShow(false);
      this.fillIsShowCommonTrial(true);
      this.fillCommonTrialObj({
        title: "执法仪",
        type: "安保",
        name: this.title,
        trialType: "instrument",
        did: this.did,
      });
    },
    resetMedia() {
      if (document.getElementById("remoteVideo")) {
        document.getElementById("remoteVideo").srcObject = null;
      }

      this.dialogFlag = 0;
    },
  },
  beforeDestroy() {
    if (this.session && this.session.terminate) this.session.terminate();
    if (this.ua) this.ua.stop();
  },
};
</script>
<style lang="less" scoped>
.instrument {
  height: 430px;
  width: 199px;
  background-color: #1d2745;
  border-radius: 6px;
  border: solid 1px #2e3859;
  padding: 10px 10px;

  .devflex {
    height: 350px;
    overflow: auto;
  }
  .end {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #fff;
    .quan {
      font-size: 12px;
    }
  }
  .el-icon-close {
    color: #fff;
    cursor: pointer;
  }
  /deep/ .el-input__inner {
    height: 32px;
    margin-bottom: 10px;
    background: #121b37;
    color: #fff;
  }
  /deep/.el-input__icon {
    line-height: 32px;
    height: 32px;
    cursor: pointer;
  }

  .devclass {
    .texeone {
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    display: flex;
    color: #a2b0cb;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    padding: 10px 0px;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      cursor: pointer;
    }
    .address {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      .dian {
        width: 5px;
        height: 5px;
        border-radius: 50% 50%;
        background: #32e0a6;
        margin-top: 8px;
      }
      .text {
        width: 30px;
        color: #32e0a6;
        margin-left: 8px;
        font-size: 8px;
        line-height: 20px;
      }
      .dian2 {
        width: 5px;
        height: 5px;
        border-radius: 50% 50%;
        background: #607390;
        margin-top: 8px;
      }

      .text2 {
        width: 30px;
        color: #607390;
        margin-left: 8px;
        font-size: 8px;
        line-height: 20px;
      }
      color: #607390;
    }
  }
}
.videoDiv {
  position: fixed;
  top: 15%;
  right: 40%;
  width: 374px;
  height: 320px;
  background-color: #2f3853;
  border-radius: 2px;
  padding: 15px 10px;
  box-sizing: border-box;
  .content {
    width: 340px;
    height: 222px;
    background: #000;
    margin-top: 15px;
    text-align: center;
    line-height: 222px;
  }
  .betwen {
    display: flex;
    justify-content: space-between;
  }
  .title {
    img {
      width: 17px;
      height: 17px;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -3px;
    }
    .img {
      width: 18px;
      height: 13px;
    }
    .img2 {
      width: 17px;
      height: 17px;
    }
    width: 66px;
    height: 25px;
    background-color: #3a7aea;
    border-radius: 12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 25px;
  }
  .but {
    width: 340px;
    text-align: center;
    line-height: 30px;
    height: 30px;
    margin-top: 5px;

    .overline {
      .img {
        width: 25px;
        height: 8px;
      }
      font-size: 14px;
      background: rgb(231, 79, 79);
      width: 75px;
      height: 33px;
      text-align: center;
      margin: auto;
      cursor: pointer;
    }
  }
  .main-video {
    width: 340px;
    height: 222px;
    background: #000;
    margin-top: 15px;
    text-align: center;
    line-height: 222px;
    .main-video__remote {
      width: 340px;
      height: 222px;
    }
  }
}
</style>
