/* eslint-disable */
const JsSIP = require('jssip');
const SDPTransform = require('sdp-transform');
// 重写session失效时间，不然打不出去
JsSIP.C.SESSION_EXPIRES = 120;
JsSIP.C.MIN_SESSION_EXPIRES = 120;
/**
 * 语音应答
 *
 * @param {RTCSession} session 通话的session
 */

 JsSIP.UA.prototype.wrAnswerPhone = function (session) {
  try {
    session.answer({
      mediaConstraints: {
        audio: true,
        video: false
      },
      pcConfig: {
        iceServers: []
      }
    });
  } catch (error) {
    throw error;
  }
};

/**
 * 视频应答
 *
 * @param {RTCSession} session 通话的session
 */
JsSIP.UA.prototype.wrAnswerVideo = function (session) {
  try {
    session.answer({
      mediaConstraints: {
        audio: true,
        video: true
      },
      pcConfig: {
        iceServers: []
      }
    });
  } catch (error) {
    throw error;
  }
};
/**
 * 拨打语音
 *
 * @param {String} target 通话的目标
 */
JsSIP.UA.prototype.wrCallPhone = function (target, params = null) {
  try {
    // 保存本次呼叫自定义配置
    // this.callOption = params;
    // 通话呼叫配置
    const options = {
      mediaConstraints: {
        audio: true,
        video: false
      },
      pcConfig: {
        iceServers: []
      },
      ...params
    };

    return this.call(target, options);
  } catch (error) {
    throw error;
  }
};
/**
 * 查岗
 *
 * @param {String} target 通话的目标
 */
JsSIP.UA.prototype.wrCheckDevice = function (target, params = null) {
  try {
    // 保存本次呼叫自定义配置
    // this.callOption = params;
    // 通话呼叫配置
    const options = {
      mediaConstraints: {
        audio: false,
        video: false
      },
      rtcOfferConstraints: {
        offerToReceiveVideo: true,
        offerToReceiveAudio: true
      },
      pcConfig: {
        iceServers: []
      },
      ...params
    };

    return this.call(target, options);
  } catch (error) {
    throw error;
  }
};
/**
 * 拨打视频
 *
 * @param {String} target 通话的目标
 */
JsSIP.UA.prototype.wrCallVideo = function (target, params = null) {
  try {
    // 保存本次呼叫自定义配置
    // this.callOption = params;
    // 通话呼叫配置
    const options = {
      mediaConstraints: {
        audio: true,
        video: true
      },
      pcConfig: {
        iceServers: []
      },
      ...params
    };
    return this.call(target, options);
  } catch (error) {
    throw error;
  }
};
/**
 * 拒绝来电或者挂断
 *
 * @param {RTCSession} session 通话的session
 */
JsSIP.UA.prototype.wrReject = function (session) {
  try {
    session.terminate();
  } catch (error) {
    throw error;
  }
};
/**
 * 关闭麦克风
 *
 * @param {RTCSession} session 通话的session
 */
JsSIP.UA.prototype.wrMute = function (session) {
  try {
    session.mute();
  } catch (error) {
    throw error;
  }
};
/**
 * 开启麦克风
 *
 * @param {RTCSession} session 通话的session
 */
JsSIP.UA.prototype.wrUnmute = function (session) {
  try {
    session.unmute();
  } catch (error) {
    throw error;
  }
};
/**
 * hold住通话
 *
 * @param {RTCSession} session 通话的session
 */
JsSIP.UA.prototype.wrHold = function (session) {
  try {
    session.hold();
  } catch (error) {
    throw error;
  }
};
/**
 * unhold住通话
 *
 * @param {RTCSession} session 通话的session
 */
JsSIP.UA.prototype.wrUnhold = function (session) {
  try {
    session.unhold();
  } catch (error) {
    throw error;
  }
};
/**
 *  发送DTMF（网页端互相通话支持，如果与设备通讯需利用peerconnection的getSenders）
 *
 * @param {RTCSession} session 通话的session
 * @param {String} dtmf 要发送的dmtf字符串
 */
JsSIP.UA.prototype.wrSendDTMF = function (session, dtmf) {
  try {
    session.sendDTMF(dtmf.tone, {
      duration: dtmf.duration,
      interToneGap: dtmf.interToneGap
    });
  } catch (error) {
    throw error;
  }
};
// 添加自定义监听器
JsSIP.UA.prototype.addListener('wlFailed', function () {});
JsSIP.UA.prototype.addListener('wlInfo', function () {});
JsSIP.UA.prototype.addListener('wlAccepted', function () {});
JsSIP.UA.prototype.addListener('wlConfirmed', function () {});
JsSIP.UA.prototype.addListener('wlEnded', function () {});
JsSIP.UA.prototype.addListener('wlReceive', function () {});
JsSIP.UA.prototype.addListener('wlReceiveStream', function () {});
JsSIP.UA.prototype.addListener('wlCall', function () {});
JsSIP.UA.prototype.addListener('wlCallStream', function () {});
JsSIP.UA.prototype.addListener('wlNewMessage', function () {});

/**
 * 重写UA对象newRTCSession方法
 *
 * @param {RTCSession} session
 * @param {Object} e
 */
// JsSIP.UA.prototype.newRTCSession = function (session, e) {

// };
/**
 *
 *
 * @param {Object} config 通话配置
 * @returns {Object} 返回通话对象ua
 */
function mysip (config) {
  // 默认参数
  const params = Object.assign({
    ws: 'ws://ucweb.vanrui.com:5066',
    sdpServer: '112.74.178.87',
    user_agent: `community-${process.env.NODE_ENV}-JsSIP${JsSIP.version}`
  }, config);
  const socket = new JsSIP.WebSocketInterface(params.ws);
  // ua参数
  const uaParams = {
    sockets: [socket],
    ...params
  };
  const ua = new JsSIP.UA(uaParams);
  ua.customConfig = params;
  // 暂存通话自定义配置
  // ua.callOptions = {};
  ua.on('newMessage', function (e) {
    this.emit('wlNewMessage', e);
  });
  ua.on('newRTCSession', function (e) {
    // 来电话了
    if (e.originator === 'remote') {
      // 触发收到通话回调
      this.emit('wlReceive', e);
      // 触发收到通话stream回调
      // e.session.connection.onaddstream = (ev) => {
      //   debugger;
      //   this.emit('wlReceiveStream', e.session, e.session.connection);
      // };
    } else {
      // 保留本次呼出配置
      // this.callOptions[session.id] = this.callOption;
      // 触发打电话回调
      this.emit('wlCall', e.session);
      // 触发打电话stream回调
      e.session.connection.onaddstream = (ev) => {
        this.emit('wlCallStream', e.session, e.session.connection);
      };
    }

    e.session.on('failed', (data) => {
      // 触发通话失败回调
      this.emit('wlFailed', e.session, data);
      // 删除通话的自定义配置
      // delete this.callOptions[session.id];
    });

    e.session.on('ended', (data) => {
      // 触发通话结束回调
      this.emit('wlEnded', e.session, data);
      // 删除通话的自定义配置
      // delete this.callOptions[session.id];
    });

    e.session.on('accepted', (data) => {
      // 触发接受通话回调
      this.emit('wlAccepted', e.session, data);
    });

    e.session.on('newInfo', (data) => {
      // 触发收到info回调
      this.emit('wlInfo', e.session, data);
    });

    e.session.on('sdp', (data) => {
      // 本地的接听拨打最小化sdp
      if ((data.type === 'offer' || data.type === 'answer') && data.originator === 'local') {
        const sdpObj = SDPTransform.parse(data.sdp);
        data.sdp = minSDP(sdpObj, data, this.customConfig.sdpServer);
      }
    });
  });

  // 建立连接
  ua.start();

  return ua;
}

export default mysip;

/**
 * 压缩sdp报文，删除无用属性
 * @param {Array} res 源sdp
 * @param {Object} origin sdp回调原始参数
 * @param {String} sdpServer  sdp服务器地址
 * @returns
 */
// callOptions = {}
var minSDP = function (res, origin, sdpServer) {
  res.media.forEach(el => {
    let rtp = [];
    // 通讯服务器地址
    el.connection.ip = sdpServer;
    if (el.candidates) {
      el.candidates.forEach(el => {
        el.ip = sdpServer;
      });
    }

    // 过滤出音频流所需属性
    if (el.type === 'audio') {
      // 音频流通讯方向
      // if (callOptions) el.direction = callOptions.audioDir || 'sendrecv';
      el.rtp.forEach(el => {
        if (el.payload === 8 || (el.codec === 'telephone-event' && el.rate === 8000) || el.codec.indexOf('opus') > -1) {
          rtp.push(el);
        }
      });
      el.rtp = rtp;
      el.payloads = rtp.map(el => {
        return el.payload;
      }).join(' ');
      el.fmtp = [];
      el.rtcpFb = [];
    } else if (el.type === 'video') { // 过滤出视频流所需属性
      // 视频流通讯方向
      // if (callOptions) el.direction = callOptions.videoDir || 'sendrecv';
      // 筛选出264
      el.rtp.forEach(el => {
        if (el.codec === 'H264') {
          rtp.push(el);
        }
      });
      // H264的payloads
      let payloads = rtp.map(el => {
        return el.payload;
      });

      // fmtp中mode为1和level-id为42e01
      const fmtp = [];
      el.fmtp.forEach(el => {
        // 本地下发的sdp需要过滤该属性
        if (origin.type === 'offer' && origin.originator === 'local') {
          if (payloads.includes(el.payload) && el.config.indexOf(
              'packetization-mode=1;profile-level-id=42e01f') > 0) {
            fmtp.push(el);
            // 设置码率区间
            // fmtp.push({
            //   payload: el.payload,
            //   config: 'x-google-max-bitrate=10240000;x-google-min-bitrate=5120000;x-google-start-bitrate=5120000'
            // });
          }
        } else {
          if (payloads.includes(el.payload)) {
            fmtp.push(el);
          }
        }
      });

      // 根据fmtp筛选出rtp
      const fmtpPayloads = fmtp.map(el => {
        return el.payload;
      });

      rtp.forEach((el, index) => {
        if (!fmtpPayloads.includes(el.payload)) {
          delete rtp[index];
        }
      });

      let rtcpFb = el.rtcpFb;

      rtcpFb.forEach((el, index) => {
        if (!fmtpPayloads.includes(el.payload)) {
          delete rtcpFb[index];
        } else {
          if (el.type === 'transport-cc' || el.type === 'goog-remb') {
            delete rtcpFb[index];
          }
        }
      });

      rtcpFb = rtcpFb.filter(el => {
        return el;
      });
      rtp = rtp.filter(el => {
        return el;
      });
      payloads = rtp.map(el => {
        return el.payload;
      });

      el.fmtp = fmtp;
      el.rtp = rtp;
      el.payloads = payloads.join(' ');
    }

    // 删除大部分无需属性
    // el.ext = [];
    // el.ssrcGroups = [];
    // delete el.ssrcs;
    // delete el.iceOptions;
    // delete el.rtcp;
    // delete el.mid;
    // delete el.msid;
    // delete el.rtcpRsize;
    // delete el.setup;

    // delete el.rtcpMux
    // el.candidates = []
    // delete el.fingerprint
    // delete el.iceUfrag
    // delete el.icePwd
  });
  // 清空无用的
  res.groups = [];
  delete res.msidSemantic;
  const str = SDPTransform.write(res);
  return str;
};
/* eslint-enable */