<template>
  <div class="operation-wrapper">
    <span class="check-title">{{ $t('i18nKey_cockpit_104') }}</span>
    <div class="check-content">
      <span class="content-text">{{ $t('i18nKey_cockpit_105') }}</span>
      <!-- <span class="content-text">鼠标双击放大</span> -->
    </div>
    <span class="check-title mg-16">{{ $t('i18nKey_cockpit_106') }}/{{ $t('i18nKey_cockpit_107') }}</span>
    <div class="check-content">
      <el-radio-group v-model="mapMove" @change="mouseOperationChange">
        <el-radio class="content-text" label="RIGHT_MOVE">{{ $t('i18nKey_cockpit_108') }},{{ $t('i18nKey_cockpit_109') }}</el-radio>
        <el-radio class="content-text" label="LEFT_MOVE">{{ $t('i18nKey_cockpit_110') }},{{ $t('i18nKey_cockpit_111') }}</el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "mapOperation",
  props: {
    operateConfigList:Array
  },
  watch: {
    operateConfigList(val) {
      this.configList = val;
      this.mapMove = val[0].operationMethod
    }
  },
  data() {
    return {
      mapMove: "RIGHT_MOVE",
      configList: []
    };
  },
  mounted() {},
  methods: {
    mouseOperationChange(changeLabel) {
        if (this.configList.length) {
            // 更新
            this.configList[0].operationMethod = changeLabel;
        } else { //  添加
            let item = {operationMethod: changeLabel,  dataType: "MOUSE_OPERATION"}
            this.configList = [item]
        }
        this.$emit("mouseOperationChange", this.configList);
    }
  }
};
</script>
<style lang="less" scoped>
.operation-wrapper {
  padding-left: 2.0625rem;
  padding-top: 1.875rem;
  .check-title {
    font-size: 16px;
    color: #ffffff;
  }
  .mg-16 {
    display: block;
    margin-top: 1rem;
  }
  .check-content {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-top: 1rem;
    .content-text {
      font-size: 16px;
      color: #a2b0cb;
      &:not(:first-child) {
        margin-left: 46px;
      }
    }
  }
}
</style>

