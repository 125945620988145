<template>
  <div class="car-trial">
    <div class="top">
      <div class="left">
        <div class="btn">
          <img class="img" src="@/assets/images/common/car_trial.png" alt="" />
          车辆轨迹
        </div>
        <div class="name">
          {{ carTrialObj.type }} {{ carTrialObj.plateNumber }}
        </div>
      </div>
      <i class="iconfont icon-guanbi" @click="goback"></i>
    </div>
    <div class="right-content">
      <div class="name">查看明细</div>
      <div class="middle-right-bottom">
        <div class="right-bottom-content" v-if="trackInformation.length > 0">
          <div
            v-for="(item, index) in trackInformation"
            :key="index"
            class="bottom-content-content"
          >
            <div class="bottom-content-content-left">
              <div
                v-if="isFirstEvent(index)"
                class="bottom-content-content-left-line"
              ></div>
              <div class="bottom-content-content-left-icon"></div>
            </div>
            <div class="bottom-content-content-right">
              <div class="time">
                {{ item.currentTrailRecordTime | timeFormat }}
              </div>
              <div class="address">
                <i class="iconfont icon-weizhi"></i>
                <div class="location">
                  <p>{{ item.longitude }},{{ item.latitude }}</p>
                  <p>{{ item.locationFullPathCn }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nothingMore" v-else>
          <div class="nothingMoreContent">
            <i class="iconfont icon-juxing"></i>
            <br />
            无符合查询条件的数据
          </div>
        </div>
      </div>
    </div>
    <div class="time-bottom">
      <div>
        <span class="demonstration">追踪时间</span>
        <el-date-picker
          v-model="datatime"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
          @change="changetime"
        >
        </el-date-picker>

        <!-- <span class="start-date">开始时间</span>
        <date-picker
          class="date"
          v-model="form.startTime"
          placeholder="请选择开始日期"
          type="datetime"
          lang="zh"
          value-type="format"
          format="YYYY-MM-DD HH:mm:ss"
          confirm
          @confirm="confirm"
          :disabled-date="disabledBeforeFifteenDay"
        >
          <template slot="calendar-icon">
            <i class="iconfont icon-icon_calendar"></i>
          </template>
        </date-picker>
      </div>
      <div>
        <span class="end-date">结束时间</span>
        <date-picker
          class="date"
          v-model="form.endTime"
          placeholder="请选择结束时间"
          type="datetime"
          lang="zh"
          value-type="format"
          format="YYYY-MM-DD HH:mm:ss"
          confirm
          @confirm="confirm"
          :disabled-date="disabledBeforeFifteenDay"
        >
          <template slot="calendar-icon">
            <i class="iconfont icon-icon_calendar"></i>
          </template>
        </date-picker> -->
      </div>
      <div class="picker">
        <el-time-picker
          @change="changetime2"
          type="datetime"
          value-format="HH:mm:ss"
          is-range
          v-model="dataarr"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
      </div>
      <el-button class="button" @click="getCurrentDate">当天</el-button>
      <el-button v-if="flagswitch" type="primary" @click="track"
        >开始追踪</el-button
      >
      <el-button v-if="!flagswitch" type="primary" @click="pauseAnimation"
        >暂停</el-button
      >
    </div>
  </div>
</template>

<script>
import Ajax from "@/dataApi/operationReport.js";
// import DatePicker from "@/components/datePicker";
import mapInit from "@/mixins/mapInit.js";
export default {
  mixins: [mapInit],
  name: "carTrail",
  components: {
    // DatePicker,
  },
  props: {
    isShowCarTrial: {
      type: Boolean,
      default: false,
    },
    carTrialObj: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      dataarr: ["00:00:00", "23:59:59"],
      datatime: "",
      form: {
        startTime: "",
        endTime: "",
      },
      trackInformation: [],
      flagswitch: true,
      pickerOptions: {
        // 限制预约时间
        // - 24 * 60 * 60 * 1000
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < Date.now() - 24 * 60 * 60 * 1000 * 15
          );
        },
      },
    };
  },
  mounted() {
    if (this.isShowCarTrial) {
      this.handerTime();
      this.getVehcleTrail();
    }
  },
  beforeDestroy() {},
  methods: {
    changetime() {
      this.form.startTime = this.datatime + " " + this.dataarr[0];
      this.form.endTime = this.datatime + " " + this.dataarr[1];
      this.getVehcleTrail();
    },
    changetime2() {
      this.form.startTime = this.datatime + " " + this.dataarr[0];
      this.form.endTime = this.datatime + " " + this.dataarr[1];
      this.getVehcleTrail();
    },
    // 控制
    disabledBeforeFifteenDay() {},
    // 当天日期行车轨迹
    getCurrentDate() {
      this.handerTime();
      this.getVehcleTrail();
    },
    pauseAnimation() {
      this.$emit("pauseAnimation");
      this.flagswitch = true;
    },
    // 开始追踪
    track() {
      this.flagswitch = false;
      if (this.trackInformation.length > 0) {
        let data = [];
        this.trackInformation.forEach((item, index) => {
          data[index] = [item.longitude, item.latitude];
        });
        this.$emit("trackPlayback", data);
      }
    },
    // 是否为第一个事件
    isFirstEvent(index) {
      return this.trackInformation.length !== index + 1;
    },
    // 获取当天时间
    handerTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let day = date.getDate();
      this.form.startTime = `${year}-${month}-${day} 00:00:00`;
      this.form.endTime = `${year}-${month}-${day} 23:59:59`;
      this.datatime = `${year}-${month}-${day} 00:00:00`;
      this.dataarr = ["00:00:00", "23:59:59"];
    },
    // 返回
    goback() {
      this.$emit("closeTrial");
    },
    // 获取轨迹详情
    getVehcleTrail() {
      let { plateNumber } = this.carTrialObj;
      let params = {
        plateNumber: plateNumber,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
      };
      Ajax.getVehcleTrail(params).then((res) => {
        if (res.length > 0) {
          let geocoder = new AMap.Geocoder({});
          res.forEach((item) => {
            geocoder.getAddress(
              [item.longitude, item.latitude],
              function (status, result) {
                if (status === "complete" && result.info === "OK") {
                  item.locationFullPathCn = result.regeocode.formattedAddress;
                }
              }
            );
          });
          setTimeout(() => {
            this.trackInformation = res;
            if (this.trackInformation.length > 0) {
              let data = [];
              this.trackInformation.forEach((item, index) => {
                data[index] = [item.longitude, item.latitude];
              });
              this.$emit("createPath", data);
            }
          }, 300);
        } else {
          this.$emit("clearAll");
        }
      });
    },
    // 日期选择确认
    confirm() {
      if (this.form.startTime && this.form.endTime) {
        this.getVehcleTrail();
      }
    },
  },
  filters: {
    timeFormat(time) {
      return time.substr(11);
    },
  },
};
</script>

<style lang="less" scoped>
.car-trial {
  .top {
    height: 48px;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    color: var(--common-white);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    .left {
      padding-left: 32px;
      display: flex;
      flex-flow: row;
      align-items: center;
      .btn {
        width: 96px;
        height: 26px;
        background-color: #3a7aea;
        border-radius: 2px;
        line-height: 26px;
        text-align: center;
        font-size: 14px;
        .img {
          width: 18px;
          height: 18px;
        }
      }
      .name {
        font-size: 18px;
        padding-left: 32px;
      }
    }
    .icon-guanbi {
      padding-right: 27px;
      cursor: pointer;
    }
  }
  .right-content {
    width: 450px;
    height: 92%;
    border-radius: 4px;
    z-index: 99;
    position: absolute;
    right: 24px;
    top: 58px;
    color: var(--common-white);
    backdrop-filter: blur(10px);
    &::before{
      border: 1.5px solid transparent;
      border-radius: 8px;
      pointer-events: none;
      content :'';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)), linear-gradient(to bottom, #05F5DF, #43ADF9);
      opacity: 0.6;
      z-index: -1;
    }
    .name {
      font-size: 16px;
      color: var(--common-white);
      font-family: var(--font-title);
      padding: 15px;
    }
    .middle-right-bottom {
      // height: 100%;
      .right-bottom-content::-webkit-scrollbar {
        width: 6px; // 高宽分别对应横竖滚动条的尺寸
        height: 4px;
      }
      .right-bottom-content::-webkit-scrollbar-thumb {
        border-radius: 6px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
      /*滚动条里面轨道*/
      .right-bottom-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.1);
      }
      .right-bottom-content {
        padding: 32px;
        font-size: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        margin: 5px 5px 0 0;
        /*滚动条样式*/
        .bottom-content-content {
          display: flex;
          flex-basis: 50%;
          .bottom-content-content-left {
            position: relative;
            height: 6rem;
            flex-grow: 0;
            .bottom-content-content-left-line {
              background-color: #506bdc;
              position: absolute;
              width: 4px;
              top: 0;
              bottom: 0;
              left: 7px;
            }
            .bottom-content-content-left-icon {
              width: 18px;
              height: 18px;
              z-index: 1;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: relative;
              text-align: center;
              background-color: #506bdc;
              border-radius: 50%;
            }
          }
          .bottom-content-content-right {
            padding-left: 10px;
            flex-grow: 1;
            width: 0;
            margin-bottom: 15px;
            font-size: 14px;
            color: #8091ac;
            display: flex;
            flex-flow: row;
            .time {
              color: #fff;
            }
            .address {
              display: flex;
              flex-flow: row;
              padding-left: 27px;
              .location {
                padding-left: 10px;
                p {
                  width: 260px;
                  overflow-wrap: anywhere;
                }
              }
            }
          }
        }
      }
    }
  }
  .time-bottom {
    .demonstration {
      margin-left: 20px;
      margin-right: 20px;
    }
    /deep/ .picker {
      .el-range-editor .el-range-input {
        color: #fff;
      }
      .el-date-editor .el-range-separator {
        color: #fff;
        // padding: 10px 10px;
        line-height: 2rem;
        font-size: 14px;
        // width: 50px;
      }
      .el-input__inner {
        padding: 0 0;
        color: #fff;
        border-radius: 4px;
        //  margin-top: 3px;
      }
      .el-date-editor--daterange.el-input,
      .el-date-editor--daterange.el-input__inner,
      .el-date-editor--timerange.el-input,
      .el-date-editor--timerange.el-input__inner {
        width: 250px;
        margin-right: 20px;
      }
      .el-range-editor .el-range-input {
        background-color: #121933;
        width: 100px;
      }
      .el-date-editor .el-range__icon {
        display: none;
      }
    }
    background-color: #1d2745;
    color: #8091ac;
    font-size: 14px;
    border-radius: 8px;
    z-index: 99;
    position: fixed;
    left: 150px;
    bottom: 42px;
    width: 1048px;
    height: 64px;
    display: flex;
    flex-flow: row;
    align-items: center;

    /deep/ .mx-datepicker {
      width: 265px;
      cursor: pointer;
    }
    /deep/ .el-button {
      height: 34px;
      line-height: 34px;
    }
    /deep/.el-input__inner {
      background: #121933;
      height: 32px;
      // line-height: 32px;
      padding: 0 0 0 35px;
      color: #fff;
    }
    /deep/.el-date-editor.el-input__inner {
      margin-left: 20px;
    }
  }
}
</style>
