export default {
    namespaced: true,
    state: {
      type: 1
    },
    mutations: {
      typeChange(state, data) {
        state.type = data;
      }
    }
  };