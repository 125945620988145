<template>
  <div class="rightwrap">
    <div class="divtext">推送人员</div>
    <div class="search">
      <el-input
        placeholder="请输入姓名和手机号"
        :disabled="justChecked"
        v-model="searchVal"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          style="cursor: pointer"
          @click="inputChange(searchVal)"
        ></i>
      </el-input>
    </div>

    <div class="tit" v-if="workerobj.workName !== 'KINDDEE'">
      <div class="titbtn">
        <el-radio-group
          v-model="roleType"
          @change="
            getRole({ pageNumber: 1, pageSize: 500, roleType: roleType })
          "
        >
          <el-radio-button
            v-for="(item, index) in roleTypeListShow"
            :key="index"
            :label="item.roleCode"
            >{{ item.roleName }}</el-radio-button
          >
          <span v-show="showMore">
            <el-radio-button
              v-for="(item, index) in roleTypeListHide"
              :key="index"
              :label="item.roleCode"
              >{{ item.roleName }}</el-radio-button
            >
          </span>
        </el-radio-group>
      </div>
    </div>
    <div class="facilityInfoMgt">
      <el-table
        width="320"
        @select="handleSelecChange"
        class="table"
        ref="multipleTable"
        @select-all="handleSelecAllChange"
        :data="tableData2"
        stripe
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          v-if="workerobj.workName !== 'KINDDEE'"
          prop="date"
          label="头像"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="head-url">
              <img class="imgurl" :src="scope.row.accountIcon" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="personCn"
          label="姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          v-if="workerobj.workName !== 'KINDDEE'"
          prop="personId"
          label="工号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="电话" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.mobilePhone }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Api from "@/dataApi/operationReport.js";
import { mapMutations } from "vuex";
export default {
  name: "proprtyWorkRight",
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      showMore: false,
      searchVal: "",
      roleTypeListShow: [
        { roleName: "安防", roleCode: "6" },
        { roleName: "物业", roleCode: "7" },
        { roleName: "保洁", roleCode: "8" },
      ], //
      roleTypeListHide: [], //
      roleType: "6", // 角色类型，
      justChecked: false,
      selectedpeoples: {
        selected6: [],
        selected7: [],
        selected8: [],
      },
      tableData2: [],
      workerobj: {},
    };
  },
  methods: {
    ...mapMutations(["fillproprtyworker"]),
    // 重置
    reset() {
      this.selectedpeoples = {
        selected6: [],
        selected7: [],
        selected8: [],
      };
    },
    inputChange(row) {
      this.getRole({
        pageNumber: 1,
        pageSize: 500,
        roleType: this.roleType,
        searchPhrase: row,
        type: 0,
      });
    },
    // 获取角色列表
    getRole(params) {
      Api.workOption(params)
        .then((data) => {
          this.tableData2 = data.persionList;
          sessionStorage.setItem("workOrder", JSON.stringify(data));
          this.fillproprtyworker(data);
          if (this.roleType === "6") {
            if (this.selectedpeoples.selected6) {
              this.selectedpeoples.selected6.forEach((row) => {
                this.tableData2.forEach((e, i) => {
                  if (row.personId === e.personId) {
                    this.$nextTick(() => {
                      this.$refs.multipleTable.toggleRowSelection(
                        this.tableData2[i]
                      );
                    });
                  }
                });
              });
            }
          } else if (this.roleType === "7") {
            if (this.selectedpeoples.selected7) {
              this.selectedpeoples.selected7.forEach((row) => {
                this.tableData2.forEach((e, i) => {
                  if (row.personId === e.personId) {
                    this.$nextTick(() => {
                      this.$refs.multipleTable.toggleRowSelection(
                        this.tableData2[i]
                      );
                    });
                  }
                });
              });
            }
          } else {
            if (this.selectedpeoples.selected8) {
              this.selectedpeoples.selected8.forEach((row) => {
                this.tableData2.forEach((e, i) => {
                  if (row.personId === e.personId) {
                    this.$nextTick(() => {
                      this.$refs.multipleTable.toggleRowSelection(
                        this.tableData2[i]
                      );
                    });
                  }
                });
              });
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 单行选择
    handleSelecChange(row) {
      if (this.roleType === "6") {
        this.selectedpeoples.selected6 = row;
      } else if (this.roleType === "7") {
        this.selectedpeoples.selected7 = row;
      } else {
        this.selectedpeoples.selected8 = row;
      }
    },
    // 全选
    handleSelecAllChange(row) {
      if (this.roleType === "6") {
        this.selectedpeoples.selected6 = row;
      } else if (this.roleType === "7") {
        this.selectedpeoples.selected7 = row;
      } else {
        this.selectedpeoples.selected8 = row;
      }
    },
  },
  watch: {
    worker: {
      immediate: true,
      deep: true,
      handler(v) {
        this.tableData2 = v.persionList;
        // this.roleType = "6";
        this.workerobj = v;
      },
    },
  },
  computed: {
    worker() {
      return this.$store.state.proprtyworker;
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
  height: 250px;
  background: #101b35;
}
/deep/ .el-table__body-wrapper {
  background: #1a213a;
}
/deep/ .el-table__body-wrapper {
  overflow: auto !important;
  height: 250px !important;
}
/deep/.el-table__empty-block {
  width: unset !important;
  background: #101b35;
}
/deep/.el-table__header {
  width: unset !important;
}
.imgurl {
  height: 30px;
  width: 30px;
}
/deep/.el-table {
  color: #607390;
}
.el-table tr {
  background: #101b35;
}
/deep/ .el-table::before {
  display: none;
}
/deep/ .el-table th,
.el-table tr {
  background: #1d2745;
  border-bottom: none;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #607390;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 10px;
  border: solid 1px #2e3859;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #101b35;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #1d2745;
  border: none;
}
/deep/.el-table td,
.el-table th.is-leaf {
  background: #101b35;
  border: none;
}
.rightwrap {
  padding: 0 0 0 20px;
}
.tit {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
/deep/ .is-active .el-radio-button__inner {
  height: 21px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #fff;
  padding: 2px 2px;
}
/deep/ .el-radio-button__inner {
  width: 61px;
  height: 21px;
  background-color: #121b37;
  border-radius: 10px;
  border: solid 1px #2e3859;
  padding: 2px 2px;
  margin-right: 20px;
  font-size: 12px;
}
/deep/ .el-checkbox__inner {
  background: none;
  border: solid 1px #607390;
}
/deep/ .el-input__inner {
  height: 34px;
  margin-top: 20px;
  &::placeholder {
    color: #4d5a74;
  }

  &::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    color: #4d5a74;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 适配火狐 */
    color: #4d5a74;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ 适配火狐 */
    color: #4d5a74;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+  适配ie*/
    color: #4d5a74;
  }
}
/deep/ .el-input__icon {
  margin-top: 10px;
  color: #343f62;
}
.check-all {
  float: right;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #607390;
  .total-num {
    color: #3a7aea;
  }
}
.divtext {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}
</style>
