<template>
  <div ref="popup" class="alarm-pop">
    <div class="details">
      <div class="code-m">
        {{ list[0].incidentTitle ? list[0].incidentTitle : title }}
      </div>
      <div class="name-m">{{ list[0].typeNameCn }}</div>
      <div class="address-m">{{ list[0].locationPathFullCn }}</div>
    </div>
    <div class="list">
      <div class="lists-m" v-for="(item, i) in list" :key="i">
        <div class="left">
          <div class="status">{{ item.levelNameCn }}</div>
          <div class="titleAndTime">
            <span>{{ item.incidentTitle || title }}</span>
            <span>{{ item.lastUpdateDate }}</span>
          </div>
        </div>
        <div class="right">
          <span
            v-if="item.incidentStatusCn === '10051001' && showDisPatch"
            @click="distribute(item, i)"
            >派发</span
          >
          <span class="text" v-else>已派发</span>
          <span @click="toDetail(item)">详情</span>
        </div>
      </div>
    </div>
    <div class="toFloor-m">
      <el-button type="primary" v-show="intoFloorShow" @click="intoFloor">进入楼层</el-button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Api from "@/dataApi/operationReport.js";
export default {
  name: "alarmPop",
  components: {},
  props: {
    title: "", // 标题
    address: "",
    latitude: "",
    longitude: "",
    buttonText: null,
    downSpaceCode: "",
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapState(["alarmList", "isInFloor"]),
    intoFloorShow() {
      if (window.sessionStorage.getItem("gisType") === "vrbim" && !this.isInFloor) {
        return true;
      } else {
        return false;
      }
    },
    showDisPatch() {
     return true;
      // if (localStorage.lay_selectedPosCode === "CN_44_003_SZGJCXG_P1") {
      //   return false;
      // } else {
      //   return true;
      // }
    },
  },
  methods: {
    ...mapMutations([
      "fillWorkShow",
      "fillPopShow",
      "fillproprtyworker",
      "fillIsShowAlarmDetail",
      "fillAlarmDetail",
    ]),
    // 派发工单
    distribute(item, i) {
      this.getWorkOption();
    },
    // 去详情页
    toDetail(item) {
      this.fillIsShowAlarmDetail(true);
      this.fillAlarmDetail(item);
    },
    getWorkOption() {
      let params = {
        pageNumber: 1,
        pageSize: 500,
        roleType: 6,
      };
      Api.workOption(params)
        .then((res) => {
          sessionStorage.setItem("workOrder", JSON.stringify(res));
          this.fillproprtyworker(res);
          this.fillPopShow(false);
          this.fillWorkShow(true);
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    getList() {
      this.list = this.alarmList.filter((item) => {
        return item.latitude === this.latitude && item.longitude === this.longitude;
      });
    },
    intoFloor() {
      this.fillPopShow(false);
      this.fillDownSpaceCode(this.downSpaceCode);
    },
  },
  created() {
    this.getList();
  },
  // mounted() {

  // },
};
</script>
<style lang="less" scoped>
.alarm-pop {
  // height: 360px;
  padding: 25px 10px 0 10px;
  position: relative;
  text-align: left;
  line-height: 1.2;
  color: var(--common-white);
  backdrop-filter: blur(10px);
  &::before {
    border: 1.5px solid transparent;
    border-radius: 8px;
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
      linear-gradient(to bottom, #05f5df, #43adf9);
    opacity: 0.6;
    z-index: -1;
  }
  .details {
    font-size: 14px;
    color: #fff;
    border-bottom: 3px solid #465278;
    padding-bottom: 10px;
    .code-m,
    .name-m {
      margin-bottom: 10px;
    }
    .address-m {
      font-size: 12px;
      color: rgba(162, 176, 203, 0.81);
    }
  }
  .list {
    padding-bottom: 15px;
    max-height: 300px;
    overflow: auto;
    .lists-m {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .left {
        display: flex;
        width: 70%;
        .status {
          font-size: 14px;
          width: 80px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          background-color: #fa5252;
          border-radius: 11px;
          border: solid 1px #fa5252;
          margin-right: 5px;
          // width: 35%;
        }
        .titleAndTime {
          width: 70%;
          display: flex;
          flex-direction: column;
          span {
            &:last-child {
              font-size: 12px;
              color: rgba(162, 176, 203, 0.81);
            }
          }
        }
      }
      .right {
        display: flex;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #3a7aea;
        width: 30%;
        .text {
          color: #999;
        }
        span {
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }

  .toFloor-m {
    border-top: 1px solid #465278;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
    /deep/.el-button {
      width: 77px;
      height: 33px;
      line-height: 33px;
      font-size: 12px;
    }
  }
  .alarm-content {
    box-sizing: border-box;
    height: 100%;
    margin: 16px;
    border-bottom: 1px solid rgba(128, 145, 172, 0.4);
    overflow-y: auto;
    .name {
      font-size: 16px;
      color: #fff;
    }
    .address {
      font-size: 14px;
      color: #607390;
      padding-top: 10px;
    }
    .btn-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .btn {
        margin-top: 15px;
        width: 96px;
        height: 26px;
        background-color: #3a7aea;
        border-radius: 2px;
        line-height: 26px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
/deep/ .amap-info-close {
  display: none;
}
</style>
