<template>
  <div ref="popup" class="alarm-pop">
    <div v-if="networkStatus === '0'" class="noData">用户已离线</div>
    <div v-if="networkStatus === '1' && noData" class="noData">暂无数据</div>
    <div v-else-if="networkStatus === '1' && !noData">
      <div class="alarm-content" v-if="dialogFlag === 0">
        <div class="title">
          <div class="name">{{ textdata.deviceName }}</div>
          <div class="address" v-if="textdata.status === 1">
            <div class="dian"></div>
            <div class="text">在线</div>
          </div>
          <div class="address" v-if="textdata.status === 0">
            <div class="dian2"></div>
            <div class="text2">离线</div>
          </div>
        </div>
        <div class="divflexborder">
          <div class="divflex">
            <div class="lable">当前位置</div>
            <div class="text">{{ textdata.address }}</div>
          </div>
          <div class="divflex">
            <div class="lable">值班人员</div>
            <div class="text">{{ textdata.watcher }}</div>
          </div>
          <div class="divflex">
            <div class="lable">最近更新时间</div>
            <div class="text">{{ timestamp(textdata.updateTime) }}</div>
          </div>
        </div>

        <div class="operation" v-if="textdata.status === 1">
          <div class="between">
            <img
              class="img"
              src="/security-gis/static/imagesMap/conversation.png"
              alt=""
              @click="call(false)"
            />
            <img
              class="img2"
              src="/security-gis/static/imagesMap/visualTelephone.png"
              alt=""
              @click="call(true)"
            />
          </div>
          <div class="between">
            <div class="text" @click="checkDevice(true)">查岗</div>
            <div class="text2" @click="checkDetail()">轨迹回放</div>
          </div>
        </div>
      </div>
      <div class="videoDiv" v-if="dialogFlag === 1">
        <div class="title">
          <img src="/security-gis/static/imagesMap/a-tong.png" alt="" />
          <span>通话</span>
        </div>
        <div class="content">
          <div v-if="phoneFlag === 0">等待接听中...</div>
          <div v-if="phoneFlag === 1">通话中...</div>
        </div>
        <div class="but">
          <div v-if="phoneFlag === 1" class="overline" @click="handup()">
            <img class="img" src="/security-gis/static/imagesMap/guaduan.png" alt="" />
            挂断
          </div>
        </div>
      </div>
      <div class="videoDiv" v-if="dialogFlag === 3">
        <div class="title">
          <img src="/security-gis/static/imagesMap/chagan.png" alt="" />
          <span>查岗</span>
          <div :class="phoneFlag === 1 ? 'coer' : 'coer2'"></div>
        </div>
        <div class="content">
          <div>
            <div class="main-video">
              <video ref="remoteVideo" id="remoteVideo" autoplay class="main-video__remote"></video>
            </div>
          </div>
        </div>
        <div class="but">
          <div v-if="phoneFlag === 1" class="overline" @click="handup()">
            <img class="img" src="/security-gis/static/imagesMap/guaduan.png" alt="" />
            挂断
          </div>
        </div>
      </div>
      <div class="videoDiv" v-if="dialogFlag === 2">
        <div class="title">
          <img class="img2" src="/security-gis/static/imagesMap/shiping.png" alt="" />
          <span>视频</span>
        </div>
        <div class="content">
          <div>
            <div class="main-video">
              <video ref="remoteVideo" id="remoteVideo" autoplay class="main-video__remote"></video>
            </div>
          </div>
        </div>
        <div class="but">
          <div v-if="phoneFlag === 1" class="overline" @click="handup()">
            <img class="img" src="/security-gis/static/imagesMap/guaduan.png" alt="" />
            挂断
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Ajax from "@/dataApi/operationReport.js";
export default {
  components: {},
  data() {
    return {
      ua: null,
      info: {},
      communicateMode: "", // talk：喊话，listen：监听，phone：点对点，check：查岗，conference：会议
      session: null,
      target: "", // 输入对讲机号（睿讲app设备）
      dialogFlag: null,
      textdata: {},
      phoneFlag: 0, //0是未接通，1是已接通
      title: "",
      noData: false,
    };
  },
  props: {
    did: {
      type: String,
      default: "",
    },
    networkStatus: {
      type: String,
      default: "",
    },
  },
  created() {
    console.log("this.did", this.did);
    console.log("this.networkStatus", this.networkStatus);
    //查询执法仪详情
    this.getSipDeviceDetails({ did: this.did });
  },
  methods: {
    ...mapMutations(["fillPopShow", "fillIsShowCommonTrial", "fillCommonTrialObj"]),
    // 挂断
    handup() {
      if (this.session.terminate) {
        this.session.terminate();
      }

      this.resetMedia();
    },
    timestamp(time) {
      if (time) {
        let d = new Date(time);
        let date =
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1) +
          "-" +
          d.getDate() +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes() +
          ":" +
          d.getSeconds();
        return date || "";
      } else {
        return " ";
      }
    },

    // 查岗
    checkDevice(flag) {
      var data = { useNumber: 1, useType: 3 };
      Ajax.addUseNumber(data).then(() => {});
      this.dialogFlag = 3;
      if (this.session) {
        this.$message("请先挂断当前通话");
        return false;
      }
      this.communicateMode = "check";
      // 前后置摄像头标识
      let header = flag ? "X-VR-CallType:monitor-front" : "X-VR-CallType:monitor-back";
      // 本质上是一通点对点通话，对本地的音视频在配置项中进行禁用，然后在rtcOfferConstraints配置项中开启接收远端的音视频
      // DS7770110000316 设备[yika测试]
      this.ua.wrCheckDevice(this.target, {
        extraHeaders: [header],
      });
    },
    getUserSipCode(did) {
      Ajax.getUserSipCode(did).then((res) => {
        this.info = res;
        this.initSetting();
      });
    },
    getSipDeviceDetails(did) {
      Ajax.getSipDeviceDetails(did)
        .then((res) => {
          console.log("res", res);
          this.noData = false;
          this.dialogFlag = 0;
          this.textdata = res;
          this.title = res.watcher;
          //初始化执法仪,查询用户sipcode
          this.getUserSipCode();
          this.getDeviceSipCode(did);
        })
        .catch((err) => {
          this.noData = true;
          console.log("err", err);
        });
    },
    getDeviceSipCode(did) {
      Ajax.getDeviceSipCode(did).then((res) => {
        this.target = res.sipCode;
      });
    },
    // 初始化配置
    initSetting() {
      var url = "";
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname.indexOf("ioc-uat.icloudcity.com") > -1
      ) {
        url = "api-ihw-stg.vanrui.com:5068";
      } else {
        url = "rx-ihw.vanrui.com:5068";
      }
      // 远端获取sipIp,sipCode,sipPassword组成下列参数
      // ua配置项
      const configuration = {
        ws: `wss://${url}`,
        uri: `sip:${this.info.sipCode}@${url}`,
        password: this.info.password,
        display_name: this.info.sipCode,
        contact_uri: `sip:${this.info.sipCode}@${url};transport=wss`, // 运行项目以及环境
        client: "测试项目-dev",
      };
      this.ua = this.$mysip(configuration);

      const ua = this.ua;

      ua.on("connected", (e) => {
        console.log("---------------------------websocket成功---------------------------", e);
      });
      ua.on("disconnected", (e) => {
        console.log("---------------------------websocket断连---------------------------", e);
      });
      ua.on("registered", (e) => {
        this.connected = true;
        if (e.response.status_code !== 200) {
          this.$notify({
            title: "账号注册失败",
            message: "原因：" + e.cause,
            type: "error",
            position: "bottom-right",
          });
        }
        console.log("---------------------------ua注册成功---------------------------", e);
      });
      ua.on("unregistered", (e) => {
        console.log("---------------------------ua未注册---------------------------", e);
      });
      ua.on("registrationFailed", (e) => {
        this.$notify({
          title: "账号注册失败",
          message: "原因：用户名或者密码输入错误",
          type: "error",
          position: "bottom-right",
        });
        console.log("---------------------------ua注册失败---------------------------", e);
      });

      ua.on("wlAccepted", (session, data) => {
        console.log(
          "--------------------------session接受通话-------------------------------",
          session,
          data
        );
        this.phoneFlag = 1;
        this.session = session;
      });
      // 暂无用
      ua.on("wlConfirmed", (session, data) => {
        console.log(
          "--------------------------session确认通话-------------------------------",
          data
        );
        this.session = session;
      });
      ua.on("wlEnded", (session, data) => {
        this.session = null;
        this.phoneFlag = 0;
        this.resetMedia();
        this.$message.info("电话已结束");
        console.log("--------------------------session结束-------------------------------", data);
      });
      ua.on("wlFailed", (session, data) => {
        this.session = null;
        this.resetMedia();
        this.$message.info("呼叫失败");
        console.log(
          "--------------------------session呼叫失败-------------------------------",
          data
        );
      });
      ua.on("wlInfo", (session, data) => {
        console.log(
          "--------------------------session收到info-------------------------------",
          data
        );
      });
      ua.on("wlReceive", (e) => {
        console.log("--------------------------session收到通话-------------------------------", e);
        this.session = e.session;
        this.incallVisible = true;
      });
      ua.on("wlReceiveStream", (session, connection) => {
        console.log(
          "--------------------------session收到通话stream-------------------------------",
          connection
        );
        document.getElementById("remoteVideo").srcObject = connection.getRemoteStreams()[0];
      });
      ua.on("wlCall", (e) => {
        console.log("--------------------------拨出电话回调-------------------------------");
        this.session = e.session;
      });
      ua.on("wlCallStream", (session, connection) => {
        console.log(
          "--------------------------播出流媒体回调-------------------------------",
          session
        );
        // 喊话状态不渲染远端媒体，业务上不用听到设备在讲什么，也可不渲染本地媒体，本地没必要听到自己说什么
        if (this.communicateMode !== "talk") {
          document.getElementById("remoteVideo").srcObject = connection.getRemoteStreams()[0];
        }
      });
      ua.on("wlNewMessage", (e) => {
        console.log(
          "-------------------------------收到message回调（全局）-------------------------------",
          e
        );
      });
    },
    // 拨打电话
    call(videoCall) {
      this.communicateMode = "phone";
      this.loading = true;
      if (videoCall) {
        //视频
        let data = { useNumber: 1, useType: 2 };
        Ajax.addUseNumber(data).then(() => {});
        this.dialogFlag = 2;
        this.ua.wrCallVideo(this.target, {});
      } else {
        //通话
        let data = { useNumber: 1, useType: 1 };
        Ajax.addUseNumber(data).then(() => {});
        this.dialogFlag = 1;
        this.ua.wrCallPhone(this.target, {});
      }
    },

    // 查看轨迹详情
    checkDetail() {
      var data = { useNumber: 1, useType: 4 };
      Ajax.addUseNumber(data).then(() => {});
      window.GLOBAL_OBJ.eventHub.publish("saveVisualAngle");
      this.fillPopShow(false);
      this.fillIsShowCommonTrial(true);
      this.fillCommonTrialObj({
        title: "执法仪",
        type: "安保",
        name: this.title,
        trialType: "instrument",
        did: this.did,
      });
    },
    resetMedia() {
      if (document.getElementById("remoteVideo")) {
        document.getElementById("remoteVideo").srcObject = null;
      }
      this.dialogFlag = 0;
    },
  },
  beforeDestroy() {
    if (this.session && this.session.terminate) this.session.terminate();
    if (this.ua) this.ua.stop();
  },
};
</script>

<style lang="less" scoped>
.coer {
  background: #2f3853;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 999;
}
.coer2 {
  background: #2f3853;
  width: 30px;
  height: 30px;
  top: 0px;
  right: 0px;
  z-index: 999;
}
.alarm-pop {
  width: 360px;
  position: relative;
  text-align: left;
  line-height: 1.2;
  color: var(--common-white);
  backdrop-filter: blur(10px);
  &::before {
    border: 1.5px solid transparent;
    border-radius: 8px;
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
      linear-gradient(to bottom, #05f5df, #43adf9);
    opacity: 0.6;
    z-index: -1;
  }
  .noData {
    height: 160px;
    text-align: center;
    line-height: 160px;
  }
  .alarm-content {
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      position: relative;
      font-family: var(--font-title);
      color: var(--common-white);
      &:before {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 100%;
        left: 0;
        height: 1px;
        background: linear-gradient(to right, transparent, var(--common-white), transparent);
      }
      .name {
        font-size: 14px;
        padding-bottom: 10px;
      }
      .address {
        display: flex;
        justify-content: space-between;
        .dian {
          width: 5px;
          height: 5px;
          border-radius: 50% 50%;
          background: #32e0a6;
          margin-top: 6px;
        }
        .text {
          color: #32e0a6;
          margin-left: 10px;
        }
        .dian2 {
          width: 5px;
          height: 5px;
          border-radius: 50% 50%;
          background: #607390;
          margin-top: 6px;
        }
        .text2 {
          color: #607390;
          margin-left: 10px;
        }
        font-size: 14px;
        color: var(--common-secondary);
        margin-right: 20px;
      }
    }
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 16px;
    .divflexborder {
      padding-bottom: 20px;
    }

    .divflex {
      color: var(--common-white);
      display: flex;
      margin-top: 10px;
      font-size: 12px;
      .lable {
        width: 100px;
        text-align: right;
        margin-right: 10px;
      }
      .text {
        width: 180px;
      }
    }

    .btn {
      margin-top: 15px;
      width: 96px;
      height: 26px;
      background-color: var(--button-bg-color);
      border-radius: 2px;
      line-height: 26px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }
    .operation {
      border-top: 1px solid var(--border-common-color);
      padding: 10px 0px 0px 0px;
      display: flex;
      justify-content: space-between;
      .between {
        display: flex;
        .img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        .img2 {
          width: 30px;
          height: 30px;
          margin-left: 10px;
          cursor: pointer;
        }
        .text {
          color: var(--button-bg-color);
          font-size: 12px;
          line-height: 30px;
          margin-right: 20px;
          cursor: pointer;
        }
        .text2 {
          width: 70px;
          height: 24px;
          background-color: var(--button-bg-color);
          border-radius: 3px;
          padding: 2px 10px;
          text-align: center;
          line-height: 26px;
          font-size: 12px;
          margin-top: 2px;
          cursor: pointer;
        }
      }
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
.videoDiv {
  width: 374px;
  height: 320px;
  background-color: #2f3853;
  border-radius: 2px;
  padding: 15px 10px;
  box-sizing: border-box;
  .content {
    width: 340px;
    height: 222px;
    background: #000;
    margin-top: 15px;
    text-align: center;
    line-height: 222px;
  }
  .title {
    img {
      width: 17px;
      height: 17px;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -3px;
    }
    .img2 {
      width: 18px;
      height: 13px;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -3px;
    }
    min-width: 66px;
    max-width: 66px;
    height: 25px;
    background-color: #3a7aea;
    border-radius: 12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 25px;
  }
  .but {
    width: 340px;
    text-align: center;
    line-height: 30px;
    height: 30px;
    margin-top: 5px;

    .overline {
      .img {
        width: 25px;
        height: 8px;
      }
      font-size: 14px;
      background: rgb(231, 79, 79);
      width: 75px;
      height: 33px;
      text-align: center;
      margin: auto;
      cursor: pointer;
    }
  }
  .main-video {
    width: 340px;
    height: 222px;
    background: #000;
    margin-top: 15px;
    text-align: center;
    line-height: 222px;
    .main-video__remote {
      width: 340px;
      height: 222px;
    }
  }
}
/deep/ .amap-info-close {
  display: none;
}
</style>
