import Layout from "@/layout";
import { I18N } from "@/i18n.js";

export default [
  {
    path: "/",
    redirect: "/show",
  },
  {
    path: "/show",
    meta: {
      title: I18N.common.i18nKey_cockpit_98, // 综合态势
    },
    // eslint-disable-next-line no-undef
    redirect: (isDanTianPark && isDanTianPark(localStorage.getItem("lay_selectedPosCode")))
      ? "/show/comprehensiveSituation"
      : "/show/global",
    component: Layout,
    children: [
      {
        path: "global",
        meta: {
          title: I18N.common.i18nKey_cockpit_48, // 全局态势
          keepAlive: true,
        },
        component: () =>
          import(/* webpackChunkName: "cockpitOverview" */ "@/views/overview"),
      },
      {
        path: "security",
        meta: {
          title: I18N.common.i18nKey_cockpit_49, // 安全态势
          keepAlive: true,
        },
        component: () =>
          import(/* webpackChunkName: "cockpitsecurity" */ "@/views/security"),
      },
      {
        path: "event-center",
        meta: {
          title: I18N.common.i18nKey_cockpit_58, // 事件中心
          keepAlive: true,
        },
        component: () =>
          // eslint-disable-next-line no-undef
          (isDanTianPark && isDanTianPark(localStorage.getItem("lay_selectedPosCode")))
            ? import(
                /* webpackChunkName: "cockpitevent-centerDT" */ "@/views/eventCenterDT"
              )
            : import(
                /* webpackChunkName: "cockpitevent-center" */ "@/views/eventCenter"
              ),
      },
      {
        path: "efficiency",
        meta: {
          title: I18N.common.i18nKey_cockpit_59, // 能耗态势
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitefficiency" */ "@/views/envFriendly"
          ),
      },
      {
        path: "facilities",
        meta: {
          title: I18N.common.i18nKey_cockpit_73, // 设施态势
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitfacilities" */ "@/views/facilities"
          ),
      },

      {
        path: "passthrough",
        meta: {
          title: I18N.common.i18nKey_cockpit_77, // 通行态势
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitfacilities" */ "@/views/passthrough"
          ),
      },
      {
        path: "smartBusiness",
        meta: {
          title: I18N.common.i18nKey_cockpit_86, // 智慧商业
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "smartBusiness" */ "@/views/smartBusiness"
          ),
      },
      {
        path: "assetManagement",
        meta: {
          title: "资产经营", // 智慧商业
          keepAlive: true,
        },
        component: () => import("@/views/assetManagement"),
      },
      //  {
      //   path: "blank",
      //   meta: {
      //     title: '空白页', // 空白页
      //     keepAlive: true,
      //   },
      //   component: () => import(/* webpackChunkName: "blank" */ "@/views/blank"),
      // },

      /**
       * 此为丹田石油大学项目路由
       */

      {
        path: "comprehensiveSituation",
        meta: {
          title: "综合态势",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "comprehensiveSituation" */ "@/views/comprehensiveSituation"
          ),
      },
      {
        path: "onlineReport",
        meta: {
          title: "在线报修",
          keepAlive: true,
        },
        component: () =>
          import(/* webpackChunkName: "onlineReport" */ "@/views/onlineReport"),
      },
      {
        path: "greeningManagement",
        meta: {
          title: "绿化管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "greeningManagement" */ "@/views/greeningManagement"
          ),
      },
      {
        path: "campusSecurity",
        meta: {
          title: "校园安保",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "campusSecurity" */ "@/views/campusSecurity"
          ),
      },
      {
        path: "energyManagement",
        meta: {
          title: "能源管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "energyManagement" */ "@/views/energyManagement"
          ),
      },
      {
        path: "apartmentManagement",
        meta: {
          title: "公寓管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "apartmentManagement" */ "@/views/apartmentManagement"
          ),
      },
      {
        path: "deviceManagement",
        meta: {
          title: "设备管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "deviceManagement" */ "@/views/deviceManagement"
          ),
      },
      /**
       * 丹田石油大学项目路由结束
       */
    ],
  },
  {
    path: "/operationReport",
    meta: {
      title: I18N.common.i18nKey_cockpit_99, // 运营报表
    },
    component: () =>
      import(
        /* webpackChunkName: "operationReport" */ "@/views/operationReport"
      ),
    children: [
      {
        path: "moreCleaning",
        name: "moreCleaning",
        component: () =>
          import(
            /* webpackChunkName: "largeDataScreen" */ "@/views/operationReport/moreClean.vue"
          ),
      },
    ],
  },
  {
    path: "/dataCockpit",
    meta: {
      title: "数据驾驶舱",
    },
    component: () =>
      import(
        /* webpackChunkName: "cockpitdataCockpit" */ "@/views/dataCockpit/index.vue"
      ),
    children: [
      {
        path: "/dataCockpit/home",
        name: "home",
        meta: {
          title: "首页",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataHome" */ "@/views/dataCockpit/home.vue"
          ),
      },
      {
        path: "/dataCockpit/portal",
        name: "portal",
        meta: {
          title: "招商门户",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataHome" */ "@/views/dataCockpit/portal.vue"
          ),
      },
      {
        path: "/dataCockpit/safe",
        name: "safe",
        meta: {
          title: "安全消防",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataSafe" */ "@/views/dataCockpit/safe.vue"
          ),
      },
      {
        path: "/dataCockpit/parking",
        name: "parking",
        meta: {
          title: "停车场管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataParking" */ "@/views/dataCockpit/parking.vue"
          ),
      },
      {
        path: "/dataCockpit/energy",
        name: "energy",
        meta: {
          title: "能源管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataEnergy" */ "@/views/dataCockpit/energy.vue"
          ),
      },
      {
        path: "/dataCockpit/business",
        name: "business",
        meta: {
          title: "商业管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataBusiness" */ "@/views/dataCockpit/business.vue"
          ),
      },
      {
        path: "/dataCockpit/property",
        name: "property",
        meta: {
          title: "物业管理",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataProperty" */ "@/views/dataCockpit/property.vue"
          ),
      },
      {
        path: "/dataCockpit/device",
        name: "device",
        meta: {
          title: "设备资产",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataDevice" */ "@/views/dataCockpit/device.vue"
          ),
      },
      {
        path: "/dataCockpit/lease",
        name: "lease",
        meta: {
          title: "资产经营",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataLease" */ "@/views/dataCockpit/lease.vue"
          ),
      },
      {
        path: "/dataCockpit/person",
        name: "person",
        meta: {
          title: "人员分析",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataLease" */ "@/views/dataCockpit/person.vue"
          ),
      },
      {
        path: "/dataCockpit/smartRestaurant",
        name: "smartRestaurant",
        meta: {
          title: "智慧食堂",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "cockpitdataLease" */ "@/views/dataCockpit/smartRestaurant.vue"
          ),
      },
    ],
  },
  {
    path: "/largeDataScreen",
    component: () =>
      import(
        /* webpackChunkName: "largeDataScreen" */ "@/views/largeDataScreen"
      ),
  },
  {
    path: "/futureBigScreen",
    component: () =>
      import(
        /* webpackChunkName: "largeDataScreen" */ "@/views/futureBigScreen"
      ),
  },
  // 机场卫星厅
  {
    path: "/airPortTerminal",
    component: () =>
      import(
        /* webpackChunkName: "airPortTerminal" */ "@/views/airPortTerminal/index.vue"
      ),
    redirect: "/airPortTerminal/business",
    children: [
      // 一体化运营
      {
        path: "/airPortTerminal/business",
        name: "/airPortTerminal/business",
        meta: {
          title: "一体化运营",
          keepAlive: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "airPortTerminalBusiness" */ "@/views/airPortTerminal/business.vue"
          ),
      },
      // 报事报修
      {
        path: "/airPortTerminal/propertyReport",
        name: "/airPortTerminal/propertyReport",
        meta: {
          title: "报事报修",
        },
        component: () =>
          import(
            /* webpackChunkName: "airPortTerminalPropertyReport" */ "@/views/airPortTerminal/propertyReport.vue"
          ),
      },
      // IOT管理
      {
        path: "/airPortTerminal/iotManage",
        name: "/airPortTerminal/iotManage",
        meta: {
          title: "IOT管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "airPortTerminalIotManage" */ "@/views/airPortTerminal/iotManage.vue"
          ),
      },
      // 现场管理
      {
        path: "/airPortTerminal/sceneManage",
        name: "/airPortTerminal/sceneManage",
        meta: {
          title: "现场管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "airPortTerminalSceneManage" */ "@/views/airPortTerminal/sceneManage.vue"
          ),
      },
    ],
  },
];
