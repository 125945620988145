<template>
  <div @click.stop v-show="isShow">
    <div class="giveAnAlarmPopup" v-if="selectList.length === 1">
      <div class="triangle"></div>
      <div class="give-title">
        <span class="title-headline">
          {{ selectList[0].typeNameCn }}
          (<span class="headline-type">{{ $t("i18nKey_cockpit_29") }}</span
          >)
          <!-- levelNameCn -->
        </span>
        <span class="headline-state">{{ selectList[0].statusNameCn }}</span>
        <span class="headline-close">
          <span class="iconfont icon-guanbi close" @click="close()"></span>
        </span>
      </div>
      <div class="give-center">
        <div class="center-img" v-if="false">
          <img src="" alt="" />
        </div>
        <div class="center-text">
          <div class="center-text-details padding-bottom-3">
            <p>{{ selectList[0].incidentDetail }}</p>
          </div>
          <div class="center-text-equip">
            <p class="padding-bottom-3" v-if="selectList[0].deviceNameCn">
              {{ selectList[0].deviceNameCn }}
            </p>
            <p class="padding-bottom-3">
              {{ selectList[0].locationPathFullCn }}
            </p>
          </div>
          <div class="center-text-date">{{ selectList[0].openDate }}</div>
        </div>
      </div>
      <div class="give-btn">
        <span v-if="false">{{ $t("i18nKey_cockpit_30") }}</span>
        <span @click="clickGoDetail(selectList[0])">{{
          $t("i18nKey_cockpit_31")
        }}</span>
      </div>
    </div>
    <div ref="popup" class="security" :style="{ width: boxWith }" v-else>
      <div class="pop-header">
        <span class="event-local">{{ eventLocation }}</span>
        <span class="iconfont icon-guanbi close" @click="close()"></span>
      </div>
      <div class="event-content">
        <div
          class="event-item"
          v-for="(item, index) in selectList"
          :key="index"
        >
          <div class="item-left">
            <img class="item-img" :src="getLevelImg(item.levelCode)" />
          </div>
          <div class="item-center">
            <el-tooltip
              :content="item.incidentTitle"
              :disabled="isTipShow(item)"
              effect="light"
              placement="top"
            >
              <span class="item-title">{{ item.incidentTitle }}</span>
            </el-tooltip>
            <span class="item-date">
              {{ item.openDate }}
              <span class="item-status">{{ item.statusNameCn }}</span>
            </span>
          </div>
          <div class="item-right" @click="clickGoDetail(item)">
            <span class="go-detail">{{ $t("i18nKey_cockpit_32") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import alarmEmergent from "@/assets/images/newSecurity/alarm_emergent.png";
import alarmImportant from "@/assets/images/newSecurity/alarm_important.png";
import alarmNormal from "@/assets/images/newSecurity/alarm_normal.png";
import alarmTip from "@/assets/images/newSecurity/alarm_tip.png";

export default {
  name: "securityEvenetPopup",
  components: {},
  props: {
    // 显示属性
    title: null,
    time: null,
    caption: null,
    // 事件属性
    id: null,
    incidentNumber: null,
    incidentTitle: null,
    levelNameCn: null,
    levelCode: null,
    locationPathFullCn: null, // 事件地址
    longitude: null,
    latitude: null,
    index: null,
    data: null,
    deviceNumber: null, // 设备事件ID
    // 通用属性
    boxWith: null,
    layerId: null,
    buttonText: null,
    cameraName: null,
    personId: null,
    code: null, // 门禁设备编码
    mobilePhoneEnc: null,
    personCnEnc: null,
  },
  data() {
    return {
      isShow: true,
      eventLocation: "",
      errorMessage: {
        showError: false, // 弹框显示
        errorShow: "1", // 1 成功  2 失败   3 提示是否确认  String
        messAgeText: null,
      },
      selectList: [],
    };
  },
  computed: {
    ...mapGetters("security", ["getterEventList"]),
    ...mapState("security", ["currentLayerEventList"]),
  },
  methods: {
    ...mapMutations("security", [
      "securityChange",
      "cameraChange",
      "patrolChange",
      "incidentNumberChange",
      "resetMarkerTypeChange",
    ]),
    isTipShow(item) {
      return item.incidentTitle.length < 16;
    },
    getLevelImg(levelCode) {
      // 根据事件等级获取左边图片
      let imgSrc = "";
      switch (levelCode) {
        case "SERIOUS":
          imgSrc = alarmEmergent;
          break;
        case "HIGH":
          imgSrc = alarmImportant;
          break;
        case "TIPS":
          imgSrc = alarmTip;
          break;
        default:
          imgSrc = alarmNormal;
          break;
      }
      return imgSrc;
    },
    clickGoDetail(item) {
      window.history.pushState(
        null,
        null,
        `/securitygis/pcv/securityEventCenter/securityEventDetail?incidentNumber=${item.incidentNumber}&equipCode=${item.deviceNumber}`
      );
    },
    securityDetail(item) {
      this.isShow = false;
      let security = item;
      this.securityChange({ security });
    },
    close() {
      this.isShow = false;
      this.resetMarkerTypeChange("true");
    },
    // 循环数据列表中同一个设施下的多个告警事件
    forList() {
      this.selectList = this.currentLayerEventList;
      this.selectList.length > 0 &&
        (this.eventLocation = this.selectList[0].incidentLocation);
    },
  },
  mounted() {
    this.forList(this.deviceNumber);
  },
};
</script>
<style lang="less" scoped>
.padding-bottom-3 {
  padding-bottom: 3px;
}
.security {
  text-align: left;
  background: #272f48;
  color: #fff;
  position: relative;
  opacity: 0.9;
  .pop-header {
    padding: 13px 15px 10px 15px;
    border-bottom: 1px solid #2e3859;
    .event-local {
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .event-content {
    padding: 0 20px 19px 15px;
    max-height: 14rem;
    overflow-y: auto;
    .event-item {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      .item-left {
        .item-img {
          height: 32px;
          width: 32px;
        }
      }
      .item-center {
        width: 15.25rem;
        display: flex;
        flex-direction: column;
        margin-left: 0.5625rem;
        .item-title {
          font-size: 14px;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .item-date {
          font-size: 14px;
          color: #a2b0cb;
          margin-top: 5px;
          .item-status {
            margin-left: 1.5rem;
          }
        }
      }
      .item-right {
        width: 3.8rem;
        margin-left: 0.625rem;
        .go-detail {
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #3a7aea;
        }
      }
    }
  }
}
.close {
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #a2b0cb;
  font-size: 12px;
}
.giveAnAlarmPopup {
  width: 340px;
  background-color: rgba(26, 36, 65, 0.79);
  position: relative;
  display: flex;
  flex-direction: column;
  .triangle {
    border: 6px solid #1a2441;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 10px;
    left: -12px;
  }
  .give-title {
    padding: 0px 10px;
    border-bottom: 1px solid #465278;
    display: flex;
    color: #fff;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    .headline-type {
      color: #f00;
    }
    .headline-state {
      background-color: #3a7aea;
      border-radius: 13px;
      height: 26px;
      line-height: 26px;
      padding: 0 4px;
      margin-left: 10px;
    }
    .headline-close {
      flex: 1;
      text-align: right;
    }
  }
  .give-center {
    flex: 1;
    padding: 12px;
    display: flex;
    .center-img {
      width: 104px;
      height: 138px;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .center-text {
      flex: 1;
      height: 100%;
      font-size: 14px;
      color: #a2b0cb;
      margin-left: 10px;
      .center-text-title {
        color: #fff;
      }
      .center-text-details {
        height: 74px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
      .center-text-equip {
        font-size: 12px;
        p {
          height: 12px;
        }
      }
      .center-text-date {
        font-size: 12px;
      }
    }
  }
  .give-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: #3a7aea;
    font-size: 14px;
    span {
      margin: 10px;
      cursor: pointer;
    }
  }
}
</style>
