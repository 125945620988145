<template>
  <el-row>
    <div v-if="workOrder.workType === 1">
      <div class="divtext">推送方式</div>
      <div class="divradio">
        <el-radio v-model="workOrder.workName" :label="workOrder.workName">{{
          workOrder.workName
        }}</el-radio>
      </div>

      <el-form class="form" ref="TRIRIGA" :rules="rules" :model="form">
        <el-form-item label="派发类型" prop="requestTypeCode">
          <el-cascader
            filterable
            :show-all-levels="false"
            v-model="form.requestTypeCode"
            :options="TreeList"
            :props="{
              expandTrigger: 'hover',
              value: 'requestCode',
              label: 'requestCn',
            }"
          ></el-cascader>
        </el-form-item>

        <el-form-item prop="planStartTime" label="计划开始时间" class="remake">
          <el-date-picker
            v-model="form.planStartTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          prop="planEndTime"
          v-if="
            workOrder.workCode !== 'CHUANCLOUD' &&
              workOrder.workCode !== 'KINDDEE'
          "
          class="remake"
          label="计划结束时间"
        >
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.planEndTime"
            placeholder="选择日期时间"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" class="remake">
          <el-input
            type="textarea"
            maxlength="200"
            show-word-limit
            resize="none"
            :rows="8"
            placeholder="请输入内容"
            v-model="form.incidentRemark"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <el-col class="left" :span="12">
        <div class="divtext">推送方式</div>
        <div class="divradio">
          <el-radio v-model="workOrder.workName" :label="workOrder.workName">{{
            workOrder.workName
          }}</el-radio>
        </div>

        <el-form class="form" :rules="rules2" ref="formother" :model="form">
          <el-form-item
            label="计划开始时间"
            class="remake"
            prop="planStartTime"
          >
            <el-date-picker
              v-model="form.planStartTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              type="datetime"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="remake" label="计划结束时间" prop="planEndTime">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.planEndTime"
              type="datetime"
              placeholder="选择日期时间"
              :picker-options="pickerOptions2"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" class="remake">
            <el-input
              type="textarea"
              maxlength="200"
              show-word-limit
              resize="none"
              :rows="8"
              placeholder="请输入内容"
              v-model="form.incidentRemark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col class="right" :span="12">
        <div>
          <proprtyWorkRight
            ref="right"
            :tableData="tableData"
          ></proprtyWorkRight>
        </div>
      </el-col>
    </div>
  </el-row>
</template>

<script>
import proprtyWorkRight from "./proprtyWorkRight.vue";
import Api from "@/dataApi/operationReport.js";
import { mapMutations } from "vuex";
export default {
  name: "proprtyWork",
  components: { proprtyWorkRight },
  data() {
    return {
      pickerOptions: this.beginDate(),
      pickerOptions2: this.processDate(),
      form: {
        incidentNumber: "",
        incidentType: "",
        incidentRemark: "",
        planStartTime: "",
        planEndTime: "",
        incidentDocuments: [],
        requestTypeCode: [],
        timeSheet: [],
      },
      workOrder: {},
      TreeList: [],
      rules: {
        requestTypeCode: [
          { required: true, message: "请选择派发类型", trigger: "change" },
        ],
        planStartTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        planEndTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      rules2: {
        planStartTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        planEndTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      tableData: [],
    };
  },
  props: {
    eventInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    incidentType: {
      type: String,
      default: () => {
        return "";
      },
    },
    incidentDocuments: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    eventInfo: {
      immediate: true,
      deep: true,
      handler(v) {
        this.form.incidentNumber = v.incidentNumber;
      },
    },
  },
  created() {
    this.workOrder = JSON.parse(sessionStorage.getItem("workOrder"));
    if (this.workOrder.workType === 1) {
      const { workCode, requestlist, usList } = this.workOrder;
      if (workCode === "US") {
        this.TreeList =
          usList.filter((item) => item.showHideFlag === 0) || [];
      } else {
        this.TreeList =
          requestlist.filter((item) => item.showHideFlag === 0) || [];
      }
    } else {
      this.tableData = this.workOrder.persionList;
    }
  },
  mounted() {
    this.form.incidentType = this.incidentType;
    this.incidentDocuments.forEach((element) => {
      this.form.incidentDocuments.push({
        bucketName: element.bucketName,
        fileName: element.fileName,
        fileType: element.fileType || "",
      });
    });
  },
  methods: {
    ...mapMutations(["fillWorkShow"]),
    beginDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.form.planEndTime) {
            // 如果结束时间不为空，则小于结束时间
            return new Date(self.form.planEndTime).getTime() < time.getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7; //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    processDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.form.planStartTime) {
            // 如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.form.planStartTime).getTime() > time.getTime();
          } else {
            return time.getTime() < Date.now() - 8.64e7; //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    // 提交工单
    workHandle(params, refs) {
      Api.workHandle(params)
        .then(() => {
          this.fillWorkShow(false);
          this.$refs[refs].resetFields();
          this.form.incidentRemark = "";
          this.$refs.right && this.$refs.right.reset();
          this.$message({
            message: "工单派发成功",
            type: "success",
          });
        })
        .catch((err) => {
          this.$message.error(err);
          this.fillWorkShow(false);
        });
    },
    submit() {
      let self = this;
      if (self.workOrder.workType === 1) {
        this.$refs["TRIRIGA"].validate((valid) => {
          if (valid) {
            let params = {
              ...this.form,
              requestTypeCode: this.form.requestTypeCode[
                this.form.requestTypeCode.length - 1
              ],
            };
            this.workHandle(params, "TRIRIGA");
          } else {
            return false;
          }
        });
      } else {
        this.$refs["formother"].validate((valid) => {
          if (valid) {
            let params = {
              ...self.form,
              requestTypeCode: "",
              timeSheet: [],
            };
            if (this.$refs.right.selectedpeoples.selected6) {
              this.$refs.right.selectedpeoples.selected6.forEach((item) => {
                params.timeSheet.push({
                  dealPerson: item.personCn,
                  dealPersonId: item.personId,
                  dealPhone: item.mobilePhone,
                });
              });
            }
            if (this.$refs.right.selectedpeoples.selected7) {
              this.$refs.right.selectedpeoples.selected7.forEach((item) => {
                params.timeSheet.push({
                  dealPerson: item.personCn,
                  dealPersonId: item.personId,
                  dealPhone: item.mobilePhone,
                });
              });
            }
            if (this.$refs.right.selectedpeoples.selected8) {
              this.$refs.right.selectedpeoples.selected8.forEach((item) => {
                params.timeSheet.push({
                  dealPerson: item.personCn,
                  dealPersonId: item.personId,
                  dealPhone: item.mobilePhone,
                });
              });
            }
            this.workHandle(params, "formother");
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style lang="less">
.el-date-table td.disabled div {
  background: #101b35;
}
.el-picker-panel__body {
  background: #1a213a;
}
.el-picker-panel {
  background: #1a213a !important;
}
.el-picker-panel__footer {
  background: #1a213a;
}
.el-date-table td.disabled {
  background: #101b35;
}
.available {
  color: #c0c4cc;
}
.el-time-panel {
  background: #1a213a;
}
.el-time-spinner__item.active:not(.disabled) {
  color: #c0c4cc;
}
</style>
<style scoped lang="less">
.left {
  border-right: solid 1px #2e3859;
  height: 400px;
  box-sizing: border-box;
}
.right {
  height: 400px;
}
.divtext {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}
.divradio {
  margin-top: 20px;
}
.form {
  .remake {
    display: flex;
  }
  /deep/ .el-input__inner {
    background: #101b35 !important;
    border: solid 0.0625rem #343f62;
  }
  margin-top: 20px;
  /deep/ .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #a2b0cb;
      width: 150px;
      text-align: right;
    }
    .el-form-item__content {
      flex: 1;
      margin-right: 15px;
      .el-date-editor {
        .el-input__prefix {
          .el-input__icon {
            color: #4d5a74;
          }
        }
        width: 100%;
        .el-input__inner {
          background-color: #101b35;
          border: solid 1px #343f62;
          height: 34px;
          &::placeholder {
            color: #4d5a74;
          }

          &::-webkit-input-placeholder {
            /* WebKit browsers 适配谷歌 */
            color: #4d5a74;
          }

          &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 适配火狐 */
            color: #4d5a74;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ 适配火狐 */
            color: #4d5a74;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10+  适配ie*/
            color: #4d5a74;
          }
        }
      }

      .el-textarea__inner {
        width: 100%;
        // display: inline-block;
        background-color: #101b35;
        border: solid 1px #343f62;
        &::placeholder {
          color: #4d5a74;
        }

        &::-webkit-input-placeholder {
          /* WebKit browsers 适配谷歌 */
          color: #4d5a74;
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 适配火狐 */
          color: #4d5a74;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ 适配火狐 */
          color: #4d5a74;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+  适配ie*/
          color: #4d5a74;
        }
      }
    }
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background: #101b35;
}
/deep/.el-picker-panel__body {
  background: #101b35;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-right: 0px;
}
/deep/.el-picker-panel {
  background: #101b35 !important;
}

/deep/.el-input__inner {
  height: 34px;
  line-height: 34px;
}
/deep/.el-input__inner {
  &::placeholder {
    color: #4d5a74;
  }

  &::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    color: #4d5a74;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 适配火狐 */
    color: #4d5a74;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ 适配火狐 */
    color: #4d5a74;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+  适配ie*/
    color: #4d5a74;
  }
}
/deep/.el-cascader .el-input,
body .el-cascader .el-input {
  width: 100%;
}
.form /deep/ .el-form-item {
  display: flex;
}
/deep/.el-cascader {
  width: 100%;
}
/deep/.el-cascader__label span {
  color: #606266;
}
.remake {
  /deep/ .el-date-table td.disabled div {
    background: red;
  }
}
</style>
