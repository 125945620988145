<template>
  <div class="dialog-box" v-if="visible">
    <div class="streetScapeModule">
      <div class="header">
        <p>{{ $t('i18nKey_cockpit_136') }}</p>
        <span class="close" @click="closeSelectModule">
          <i class="icon iconfont icon-guanbi"></i>
        </span>
      </div>
      <div class="body">
        <ul>
          <li v-for="(item,index) in moduleList" :key="index" :class="[item.isSelect ? 'selectItem' : '']" @click="selectModuleFn(item.type,index)">
            <img :src="item.src"/>
            <p>{{item.name}}</p>
          </li>
        </ul>
      </div>
      <div class="foot">
      </div>
    </div>
  </div>
</template>
<script>
import long from "../../assets/images/streetScapeModule/long.jpg";
import yong from "../../assets/images/streetScapeModule/yong.jpg"
import main from "../../assets/images/streetScapeModule/zhu.jpg"
import {mapMutations} from 'vuex'
export default {
  name: "streetScapeModule",
  props: {
    visible: {
      type: Boolean,
      default(){
        return false
      }
    }
  },
  data() {
    return {
      moduleList: [
        {
          src: long,
          name: this.$t('i18nKey_cockpit_137'),
          type: "RoadLine1",
          isSelect: false
        },
        {
          src: main,
          name: this.$t('i18nKey_cockpit_138'),
          type: "RoadLine2",
          isSelect: false          
        },
        {
           src: yong,
          name: this.$t('i18nKey_cockpit_139'),
          type: "RoadLine3",
          isSelect: false          
        }
      ]
    }
  },
  created() {

    let remarkInfo = JSON.parse(sessionStorage.getItem("selectModuleInfo"));
    if (remarkInfo) {
      this.moduleList = remarkInfo;
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['fillVrmapParadeName']),
    closeSelectModule() {
      this.$emit('update:visible', false)
    },
    selectModuleFn(type,index) {
      for (let i = 0; i < this.moduleList.length; i++) {
        this.moduleList[i].isSelect = false;
      }
      this.moduleList[index].isSelect = true;

      sessionStorage.setItem("selectModuleInfo", JSON.stringify(this.moduleList));
      this.fillVrmapParadeName(type)
      this.$emit('selectModuleFn', type)
    }
  }
}
</script>
<style lang="less" scoped>
.dialog-box {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 22000;
  background: rgba(4, 7, 13, 0);

  .streetScapeModule {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 732px;
    height: 408px;
    border-radius: 3px 3px 0 0;

    .header {
      width: 100%;
      height: 60px;
      background-color: #202C4E;
      position: relative;

      p {
        width: 100px;
        line-height: 60px;
        color: #fff;
        padding: 0 20px;
      }

      .close {
        position: absolute;
        top: 6px;
        right: 14px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;

        .iconfont {
          font-size: 12px;
        }
      }
    }

    .body {
      width: 100%;
      height: 263px;
      background-color: #1D2745;

      ul {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        li {
          width: 200px;
          height: 200px;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          img {
            width: 100%;
            height: 152px;
          }

          p {
            color: #fff;
            height: 53px;
            line-height: 45px;
            text-align: center;
            background-color: #101B35;
          }
  
        }

        .selectItem {
          border: 2px solid #409EFF;
        }
      }
    }

    // .foot {
    //   // width: 100%;
    //   // height: 85px;
    //   // background-color: #202C4E;
    // }
  }
}
</style>