<template>
  <div class="layer-wapper">
    <div class="layer-tab">
      <div
        class="tab-item"
        :class="{'isSelect': item.isSelect}"
        @click="clickLayerTab(item)"
        v-for="(item, index) in layerList"
        :key="index"
      >{{item.name}}</div>
    </div>
    <div class="layer-content" v-if="layerConfigList.length">
      <div class="layer-item" v-for="(itemLayer) in layerConfigList" :key="itemLayer.id">
        <span class="item-title">{{itemLayer.vlookupCn}}</span>
        <div class="config-box">
          <div
            class="item-config"
            v-for="configItem in itemLayer.layerSceneSubtype"
            :class="{'isactive': configItem.isShow === 'Y'}"
            :key="configItem.id"
            @click="clickConfigItem(configItem)"
          >{{configItem.vlookupCn}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="nothingMore">
        <div class="nothingMoreContent">
          <div class="tipBgImg"></div>
          <br />{{ $t('i18nKey_cockpit_103') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "layer",
  props: {
    layer: Array,
    layerConfigList: Array
  },
  watch: {
    layer(val) {
      this.layerList = val;
    }
  },
  data() {
    return {
      layerItem: {},
      layerList: []
    };
  },
  mounted() {},
  methods: {
    clickConfigItem(configItem) {
      configItem.isShow = configItem.isShow === "Y" ? "N" : "Y";
      let {id, isShow, isCheck} = configItem
      this.$emit("clickConfigItem", {id, isShow, isCheck})
    },
    clickLayerTab(layerItem) {
      this.layerList = this.layerList.map(item => {
        item.isSelect = false;
        return item;
      });
      layerItem.isSelect = true;
      this.$emit("clickLayerTab", layerItem.path);
    }
  }
};
</script>

<style lang="less" scoped>
.layer-wapper {
  padding: 1.56rem 2rem;
  .layer-tab {
    display: flex;
    flex-direction: row;
    .tab-item {
      font-size: 14px;
      color: #a2b0cb;
      width: 6.875rem;
      height: 2.4375rem;
      background: #1d2745;
      border: 1px solid #343f62;
      line-height: 2.4375rem;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #3a7aea;
      }
      &:focus {
        color: #fff;
      }
      &.isSelect {
        color: #f2f6fd;
        background: #3a7aea;
      }
    }
  }
  .layer-content {
    height: 33rem;
    overflow: auto;
    .layer-item {
      margin-top: 2rem;
      .item-title {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #ffffff;
      }
      .config-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .item-config {
          min-width: 6.25rem;
          padding: 0.3rem 0.6rem;
          line-height: 2rem;
          margin-top: 1rem;
          text-align: center;
          cursor: pointer;
          border: 1px solid #343f62;
          border-radius: 16px;
          border-radius: 16px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          &:not(:first-child) {
            margin-left: 1.5rem;
          }
          &.isactive {
            background: #3a7aea;
            color: #f2f6fd;
          }
        }
      }
    }
  }
  .nothingMore {
    margin-top: 16%;
    .tipBgImg {
      width: 3.625rem;
      height: 3.625rem;
    }
  }
}
</style>