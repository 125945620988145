import Vue from "vue";
import VueI18n from "vue-i18n";
import KiwiIntl from "kiwi-intl";
import enUsLangs from "../.kiwi/en-US/index.ts";
import zhCNLangs from "../.kiwi/zh-CN/index.ts";
import thLangs from "../.kiwi/th/index.ts";

// 当前环境的语言状态
const iocWebLang = localStorage.getItem("IOC_WEB_LANG") || "zh_CN";
const langObj = {
  zh_CN: "zh",
  en_US: "en",
  th_TH: "th"
};

const curLang = langObj[iocWebLang];

const langs = {
  "en": enUsLangs,
  "zh": zhCNLangs,
  "th": thLangs
};

const I18N = KiwiIntl.init(curLang, langs);

// i18n 配置
// 项目中的语言配置，.kiwi 文件中，内容包含了，模块名称
const langMessage = {
  en: {
    ...enUsLangs.cockpit,
    ...enUsLangs.common,
    ...enUsLangs.supplementary
  },
  zh: {
    ...zhCNLangs.cockpit,
    ...zhCNLangs.common,
    ...zhCNLangs.supplementary
  },
  th: {
    ...thLangs.cockpit,
    ...thLangs.common,
    ...thLangs.supplementary
  }
};
// Create VueI18n instance with options
const vueI18n = new VueI18n({
  locale: curLang, // set locale
  messages: langMessage // set locale messages
});

Vue.prototype.I18N = I18N;
export {
  I18N,
  vueI18n
};