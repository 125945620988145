<template>
  <div ref="popup" class="video-pop">
    <div class="video-content">
      <div class="name">{{ title }}</div>
      <div class="address">{{ address }}</div>
      <div class="detail">
        <div class="btn" @click="checkDetail">
          {{ buttonText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "videoPop",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    }, // 标题
    address: {
      type: String,
      default: "",
    },
    equipCode: {
      type: String,
      default: "",
    }, // 摄像机编码
    buttonText: null,
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["fillPopShow", "fillVideoObj"]),
    // 查看视频详情
    checkDetail() {
      this.fillPopShow(false);
      this.fillVideoObj({
        showStatus: true,
        cameraName: this.title,
        incidentNumber: this.equipCode,
        cameraCode: this.equipCode,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.video-pop {
  width: 360px;
  min-height: 120px;
  background: #1a2441;
  color: #fff;
  position: relative;
  text-align: left;
  line-height: 1.2;
  .video-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 16px;
    .name {
      font-size: 16px;
      color: #fff;
    }
    .address {
      font-size: 14px;
      color: #607390;
      padding-top: 10px;
    }
    .detail {
      margin-top: 5px;
      border-top: 1px solid #465278;
      display: flex;
      justify-content: flex-end;
      .btn {
        margin-top: 10px;
        width: 96px;
        height: 33px;
        background-color: #3a7aea;
        border-radius: 2px;
        line-height: 33px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
</style>
