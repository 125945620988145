<template>
  <div ref="popup" class="other-equip-pop">
    <div class="other-equip-content">
      <div class="name">
        <div class="code">{{ title }}</div>
      </div>
      <div class="addres">{{ address }}</div>
    </div>
    <div class="tofllorAndDetail">
      <el-button type="primary" plain v-show="intoFloorShow" @click="intoFloor">进入楼层</el-button>
      <el-button type="primary" @click="toDetail({ dialogVisible: true })"
        >查看详情</el-button
      >
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import Api from "@/dataApi/operationReport.js";
export default {
  name: "otherEquipPop",
  components: {},
  props: {
    title: {
      // 标题
      type: String,
      default: () => {
        return "";
      },
    },
    address: {
      //位置
      type: String,
      default: () => {
        return "";
      },
    },
    equipCode: {
      //编码
      type: String,
      default: () => {
        return "";
      },
    },
    levelEquipCode: {
      //等级编码
      type: String,
      default: () => {
        return "";
      },
    },
    downSpaceCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("equipInfo", ["equipDetail"]),
    ...mapMutations(["fillPopShow","fillDownSpaceCode"]),
    ...mapState(["isInFloor"]),
    toDetail(val) {
      this.fillPopShow(false);
      this.getEquipDetail(val);
    },
    getEquipDetail(val) {
      let params = {
        flag: true,
        equipCode: this.equipCode,
      };
      Api.getEquipDetail(params)
        .then((res) => {
          if (res) {
            this.equipDetail({
              ...val,
              ...res.entity,
            });
          }
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    intoFloor(){
      this.fillPopShow(false);
      this.fillDownSpaceCode(this.downSpaceCode)
    }
  },
  computed: {
    intoFloorShow() {
      if (window.sessionStorage.getItem("gisType") === "vrbim" &&
        !this.isInFloor) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.other-equip-pop {
  width: 360px;
  background: #1a2441;
  color: #fff;
  position: relative;
  text-align: left;
  line-height: 1.2;
  padding-top: 5px;
  .other-equip-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    .name {
      font-size: 14px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      .status {
        .on {
          display: inline-block;
          color: rgba(50, 224, 166, 0.81);
          &:before {
            display: inline-block;
            content: " ";
            width: 7px;
            height: 7px;
            background-color: rgba(50, 224, 166, 0.81);
            border-radius: 50%;
            position: relative;
            left: -5px;
            top: -2px;
          }
        }
        .off {
          display: inline-block;
          color: rgba(254, 120, 120, 0.81);
          &:before {
            display: inline-block;
            content: " ";
            width: 4px;
            height: 4px;
            background-color: rgba(254, 120, 120, 0.81);
            border-radius: 50%;
            position: relative;
            left: -5px;
            top: -3px;
          }
        }
      }
    }
    .title {
      margin: 15px 0;
    }
    .time {
      color: rgba(162, 176, 203, 0.81);
      font-size: 12px;
      margin: 10px 0;
    }
    .addres {
      margin-top: 10px;
      color: rgba(162, 176, 203, 0.81);
      font-size: 12px;
    }
  }
  .tofllorAndDetail {
    box-sizing: border-box;
    border-top: 1px solid #465278;
    padding: 10px 10px;
    display: flex;
    justify-content: flex-end;
    .el-button {
      width: 76px;
      height: 33px;
      line-height: 33px;
      font-size: 14px;
    }
    .el-button--primary.is-plain {
      background-color: transparent;
      color: #3a7aea;
      border-color: #3a7aea;
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
</style>
