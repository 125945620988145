(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("echarts"), require("Vuex"), require("ELEMENT"), require("VueRouter"), require("comComponents"), require("Vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["echarts", "Vuex", "ELEMENT", "VueRouter", "comComponents", "Vue", "axios"], factory);
	else if(typeof exports === 'object')
		exports["cockpitApp"] = factory(require("echarts"), require("Vuex"), require("ELEMENT"), require("VueRouter"), require("comComponents"), require("Vue"), require("axios"));
	else
		root["cockpitApp"] = factory(root["echarts"], root["Vuex"], root["ELEMENT"], root["VueRouter"], root["comComponents"], root["Vue"], root["axios"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__164e__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__5f72__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__875a__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 