<template>
  <div class="dialog-box" v-if="visible">
    <div class="cardConfiguration" v-loading="loading">
      <div class="static-dialog-title">
        {{ $t("i18nKey_cockpit_131") }}
        <span class="close" @click="closeSelectCard">
          <i class="icon iconfont icon-guanbi"></i>
        </span>
      </div>
      <div class="cardConfiguration-main">
        <div class="static-dialog-maintitle">
          <div class="static_title" v-if="isGlobalPage">
            <div
              v-for="item in tabs"
              :key="item.path"
              class="static_color"
              :class="{ checkColor: activeCardPath === item.path }"
              @click="activeCardPath = item.path"
            >
              {{ item.name }}
            </div>
          </div>

          <span v-show="showPrompt">
            <span>{{ $t("i18nKey_cockpit_132") }}</span>
            <span class="titleActive">{{ maxChosenCount }}</span>
            <span>{{ $t("i18nKey_cockpit_133") }}</span>
          </span>
        </div>
        <div class="static-dialog-imglist">
          <ul class="static-imglist-items">
            <li
              v-for="(item, index) in allCardList"
              :key="index"
              class="static-imglist-item"
              v-show="!item.isHide"
              :class="{ isChecked: item.isChecked }"
              :title="`${item.statisticalCardName}`"
              @click="checkboxSelectFunc(index)"
            >
              <div class="static-imglist-items-div">
                <el-checkbox
                  :value="item.isChecked"
                  @change="checkboxSelectFunc(index)"
                ></el-checkbox>
              </div>
              <div
                :class="[
                  'static-imglist-items-img',
                  item.isChecked && 'active',
                ]"
              >
                <img :src="item.imgurl" alt />
              </div>
            </li>
          </ul>
        </div>
        <div class="configuration-bottom">
          <button class="buttonBorColor buttonClass" @click="submit()">
            {{ $t("i18nKey_cockpit_25") }}
          </button>
          <button
            class="buttonBorColoeColse buttonClass"
            @click="closeSelectCard"
          >
            {{ $t("i18nKey_cockpit_17") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import Ajax from "@/utils/request.js";
const MAX_CHOSEN_COUNT = 8;
const PLAIN_BUSINESS_CODE = "CARD_SITUATION_2D";
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "CardConfigDialog",
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      loading: false,
      showPrompt: false,
      btnAuth: {},
      businessCode: "",
      allCardList: [],
      activeCardPath: "",
      chosenKeys: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler({ path }) {
        if (path) {
          if (this.is2DPage) {
            this.businessCode = PLAIN_BUSINESS_CODE;
          } else {
            let target = this.tabsAndCardsList.find(
              (item) => item.path === path
            );
            if (target) {
              this.businessCode = target.businessCode;
            }

            if (path !== "/show/global") {
              this.activeCardPath = path;
            }
          }
        }
      },
    },
    activeCardPath: {
      immediate: true,
      handler(path) {
        if (path) {
          let target = this.tabsAndCardsList.find((item) => item.path === path);
          if (target) {
            let allCardList = cloneDeep(target.allCardList || []);
            if (this.isGlobalPage) {
              allCardList.map((item) => {
                item.isChecked = this.chosenKeys.includes(
                  item.statisticalCardCode
                );
              });
            }
            this.allCardList = [
              ...allCardList,
              // {
              //   componentName: "IocChartScienceVisitorStatistics",
              //   imgurl: "/cockpit/img/passingOverview.e0df3b05.png",
              //   isChecked: true,
              //   sortIndex: 0,
              //   statisticalCardCode: "cardThrough09",
              //   statisticalCardName: "科技馆客流统计",
              // },
              // {
              //   componentName: "IocChartMeetingRoomStatistics",
              //   imgurl: "/cockpit/img/passingOverview.e0df3b05.png",
              //   isChecked: true,
              //   sortIndex: 0,
              //   statisticalCardCode: "cardThrough10",
              //   statisticalCardName: "会议室使用统计",
              // },
              // {
              //   componentName: "IocChartChargingPileStatistics",
              //   imgurl: "/cockpit/img/passingOverview.e0df3b05.png",
              //   isChecked: true,
              //   sortIndex: 0,
              //   statisticalCardCode: "cardThrough11",
              //   statisticalCardName: "充电桩统计",
              // },
            ];
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapGetters(["tabsAndCardsList", "globalChosenCardAuthKeys"]),
    ...mapState(["btnAuthObj", "userInfo", "validCardList"]),
    isGlobalPage() {
      return this.$route.path.indexOf("/show/global") === 0;
    },
    is2DPage() {
      return (
        this.$route.path.indexOf("/show/global") === 0 &&
        (this.$route.query.mapType === "2D" ||
          sessionStorage.getItem("gisType") === "2D")
      );
    },
    tabs() {
      let arr = [];
      this.tabsAndCardsList.forEach(
        ({ path, name, businessCode, allCardList }) => {
          if (path !== "/show/global" && path !== "/show/event-center") {
            arr.push({
              name,
              path,
              businessCode,
              allCardList,
            });
          }
        }
      );

      return arr;
    },
    rawChosenCardsPerPage() {
      if (this.businessCode && this.validCardList) {
        return (
          (
            this.validCardList.find(
              ({ businessCode }) => businessCode === this.businessCode
            ) || {}
          ).list || []
        );
      } else {
        return [];
      }
    },
    maxChosenCount() {
      return this.is2DPage ? 6 : MAX_CHOSEN_COUNT;
    },
  },
  created() {
    this.activeCardPath = this.activeCardPath || this.tabs[0].path;
    if (this.isGlobalPage) {
      if (this.is2DPage) {
        this.chosenKeys = this.$store.getters.tabs2CardsList
          .find(({ businessCode }) => businessCode === "CARD_SITUATION_2D")
          .list.map(({ statisticalCardCode }) => statisticalCardCode);
      } else {
        this.chosenKeys = [...this.globalChosenCardAuthKeys];
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions(["getCardList"]),
    checkboxSelectFunc(index) {
      let i = 0;

      this.allCardList.forEach(({ isChecked }) => {
        isChecked && i++;
      });

      let thatCheckStatus = !this.allCardList[index].isChecked;

      if (
        thatCheckStatus &&
        (i >= this.maxChosenCount ||
          this.chosenKeys.length >= this.maxChosenCount)
      ) {
        // 错误提示
        this.$message({
          message: `${this.$t("i18nKey_cockpit_132")} ${
            this.maxChosenCount
          } ${this.$t("i18nKey_cockpit_133")}`,
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 3000,
        });
      } else {
        let thatCard = {
          ...this.allCardList[index],
          isChecked: thatCheckStatus,
        };

        this.allCardList.splice(index, 1, thatCard);
        if (thatCheckStatus) {
          this.chosenKeys.push(this.allCardList[index].statisticalCardCode);
        } else {
          this.chosenKeys = this.chosenKeys.filter(
            (i) => i !== this.allCardList[index].statisticalCardCode
          );
        }
      }
    },
    getBigAllCardList(data) {
      if (!this.isGlobalPage) {
        return { allCardList: data };
      } else {
        let list = [];
        let cards = cloneDeep(this.tabs);
        cards.forEach(({ allCardList }) => {
          list = list.concat(allCardList);
        });
        let delKeys = [],
          result = [];
        for (let code1 of this.globalChosenCardAuthKeys) {
          if (!this.chosenKeys.includes(code1)) {
            delKeys.push(code1);
          }
        }

        for (let card of list) {
          if (this.chosenKeys.includes(card.statisticalCardCode)) {
            card.isChecked = true;
            result.push(card);
          }
        }
        return { allCardList: result, delKeys };
      }
    },
    submit() {
      let i = 0;
      let { allCardList, delKeys = [] } = this.getBigAllCardList(
        this.allCardList
      );
      let params = allCardList.map((item) => {
        item.isChecked && i++;
        return {
          userCode: this.userInfo.uid,
          businessCode: this.businessCode,
          statisticalCardCode: item.statisticalCardCode,
          statisticalCardName: item.statisticalCardName,
          enableFlag: item.isChecked ? "y" : "n",
          remarks: "",
          posCode: this.positionInfo.posCode,
        };
      });

      let delParams = cloneDeep(this.rawChosenCardsPerPage)
        .filter(({ statisticalCardCode }) =>
          delKeys.includes(statisticalCardCode)
        )
        .map((item) => {
          item.enableFlag = "n";
          item.posCode = this.positionInfo.posCode;
          delete item.id;
          for (let attr in item) {
            if (!item[attr]) delete item[attr];
          }
          return item;
        });

      params = params.concat(delParams);
      if (i === 0) {
        this.$message({
          message: `${this.$t("i18nKey_cockpit_134")} 1 ${this.$t(
            "i18nKey_cockpit_133"
          )}`,
          type: "error",
          customClass: "statisConfigTipClass",
          duration: 3000,
        });
        this.loading = false;
        return;
      }
      // 保存用户卡片配置
      Ajax(
        {
          url: "/ioc/atomiccommon/bascardparkconfig/updatecard",
          method: "post",
          data: params,
        },
        { showErrTip: true }
      )
        .then(() => {
          this.loading = false;

          this.getCardList().then(() => {
            this.$message({
              message: this.$t("i18nKey_cockpit_135"),
              type: "success",
              duration: 3000,
            });
            let fullPath = this.$route.fullPath;
            this.$router.push({
              path: "blank",
            });
            setTimeout(() => {
              this.$router.push({
                path: fullPath,
              });
            }, 50);
            this.$emit("update:visible", false);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeSelectCard() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/less/overview/cardConfiguration.less";
.dialog-box {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 22000;
}
</style>
