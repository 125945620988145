<template>
  <el-dialog title="异常详情" :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
    <div ref="popup" class="alarm-detail-pop">
      <div class="titleTop">
        <span>{{ alarmDetail.incidentTitle }}</span
        ><span class="jinji">{{ alarmDetail.levelNameCn }}</span>
      </div>
      <div class="alarm-detail-content">
        <div class="title">
          <span>{{ alarmDetail.sourceCodeCn }}</span
          ><span>{{ alarmDetail.statusNameCn }}</span>
        </div>
        <div class="time">{{ alarmDetail.lastUpdateDate }}</div>
        <div class="address">{{ alarmDetail.locationPathFullCn }}</div>
      </div>
      <div class="detail">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="4" class="essential">基本信息</el-col>
          <el-col :span="8"></el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="3" class="name">子系统</el-col>
          <el-col :span="8"></el-col>
          <el-col :span="12" class="value">{{ alarmDetail.sourceCodeCn }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="3" class="name">子类</el-col>
          <el-col :span="8"></el-col>
          <el-col :span="12" class="value">{{ alarmDetail.typeNameCn }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="3" class="name">状态</el-col>
          <el-col :span="8"></el-col>
          <el-col :span="12" class="value">
            {{ alarmDetail.statusNameCn }}
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="3" class="name">描述</el-col>
          <el-col :span="8"></el-col>
          <el-col :span="12" class="value">{{ alarmDetail.remark || "-" }} </el-col>
        </el-row>
      </div>
      <div class="tofllorAndDetail">
        <el-button type="primary" plain @click="handleClose()">返回</el-button>
        <el-button type="primary" plain v-show="intoFloorShow" @click="intoFloor"
          >进入楼层</el-button
        >
        <el-button type="primary" v-if="showDisPatch" @click="distribute(alarmDetail)"
          >派发</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import Api from "@/dataApi/operationReport.js";

export default {
  name: "alarmDetailPop",
  data() {
    return {
      dialogVisible: true,
    };
  },
  computed: {
    ...mapState(["alarmDetail", "isShowAlarmDetail"]),
    ...mapState(["isInFloor"]),
    intoFloorShow() {
      if (window.sessionStorage.getItem("gisType") === "vrbim" && !this.isInFloor) {
        return true;
      } else {
        return false;
      }
    },
    showDisPatch() {
      return true;
      // if (localStorage.lay_selectedPosCode === "CN_44_003_SZGJCXG_P1") {
      //   return false;
      // } else {
      //   return true;
      // }
    },
  },
  methods: {
    ...mapMutations([
      "fillWorkShow",
      "fillPopShow",
      "fillproprtyworker",
      "fillDownSpaceCode",
      "fillIsShowAlarmDetail",
    ]),
    handleClose() {
      this.dialogVisible = false;
      this.fillIsShowAlarmDetail(false);
    },
    getWorkOption() {
      let params = {
        pageNumber: 1,
        pageSize: 500,
        roleType: 6,
      };
      Api.workOption(params)
        .then((res) => {
          sessionStorage.setItem("workOrder", JSON.stringify(res));

          this.fillproprtyworker(res);
          this.fillPopShow(false);
          this.fillWorkShow(true);
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    distribute(d) {
      this.getWorkOption();
      this.$store.commit("workerInfodata", d);
    },
    intoFloor() {
      this.dialogVisible = false;
      this.fillDownSpaceCode(this.equipAbnormalInfo.downSpaceCode);
    },
  },
  watch: {
    isShowAlarmDetail: {
      handler(val) {
        if (val) {
          this.dialogVisible = val;
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog__body {
  .el-dialog__body {
    padding: 0;
  }
  .alarm-detail-pop {
    color: #fff;
    font-size: 14px;
    .titleTop {
      display: flex;
      justify-content: space-between;
      .jinji {
        width: 80px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background-color: #fa5252;
        border-radius: 11px;
        border: solid 1px #fa5252;
      }
    }
    .alarm-detail-content {
      border-top: 1px solid #465278;
      margin-top: 10px;
      padding: 10px 0;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: rgba(162, 176, 203, 0.81);
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        span {
          &:last-child {
            font-family: PingFangSC-Semibold;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #3a7aea;
          }
        }
      }
      .time,
      .address {
        margin-bottom: 5px;
      }
    }
    .detail {
      border-top: 3px solid #465278;
      padding: 20px 0;
      .essential {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
      }
      .name {
        text-align: right;
        color: rgba(162, 176, 203, 0.81);
      }
      .value {
        text-align: right;
        color: #a2b0cb;
      }
      .distribute {
        color: #3a7aea;
      }
      .row-bg {
        margin-bottom: 15px;
      }
      .el-select {
        width: 100px;
        left: 10px;
        .el-input__inner {
          background-color: transparent;
          color: #a2b0cb;
        }
      }
    }
    .tofllorAndDetail {
      border-top: 3px solid #465278;
      padding: 10px 10px;
      display: flex;
      justify-content: flex-end;
      .el-button {
        width: 76px;
        height: 33px;
        line-height: 33px;
        font-size: 14px;
      }
      .el-button--primary.is-plain {
        background-color: transparent;
        color: #3a7aea;
        border-color: #3a7aea;
      }
    }
  }
}
</style>
