<template>
  <div class="common-trial">
    <div class="top">
      <div class="left">
        <i class="iconfont icon-guanbi" @click="goback"></i>
        <div class="btn">
          <img class="img" src="@/assets/images/common/car_trial.png" alt="" />
          {{ commonTrialObj.title }}
        </div>
        <div class="name">{{ commonTrialObj.type }}-{{ commonTrialObj.name || "" }}</div>
      </div>
    </div>
    <div class="right-content">
      <div class="head-title">
        <span class="name">查看明细</span>
        <span v-if="commonTrialObj.trialType == 'person'" class="export-btn" @click="exportExcel"
          ><i class="iconfont icon-daochu"></i>导出</span
        >
      </div>
      <div class="middle-right-bottom" v-loading="getPersonTrailListLoading">
        <div class="right-bottom-content" v-if="trackInformation.length > 0">
          <div
            v-for="(item, index) in trackInformation"
            :key="index"
            class="bottom-content-content"
          >
            <div class="bottom-content-content-left">
              <div v-if="isFirstEvent(index)" class="bottom-content-content-left-line"></div>
              <div class="bottom-content-content-left-icon"></div>
            </div>
            <div class="bottom-content-content-right">
              <div class="time" v-if="commonTrialObj.trialType === 'instrument'">
                {{ timestamp(item.createdAt) }}
              </div>
              <div class="time" v-else-if="commonTrialObj.trialType === 'person'">
                {{ item.time | timeFormat }}
              </div>
              <div class="address">
                <i class="iconfont icon-weizhi"></i>
                <div class="location">
                  <p v-if="item.longitude">{{ item.longitude }},{{ item.latitude }}</p>
                  <p v-else-if="item.coordinateX">
                    {{ item.coordinateX }},{{ item.coordinateY }},{{ item.coordinateZ }}
                  </p>
                  <p>{{ item.locationCn || item.address }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nothingMore" v-else>
          <div class="nothingMoreContent">
            <i class="iconfont icon-juxing"></i>
            <br />
            无符合查询条件的数据
          </div>
        </div>
      </div>
    </div>
    <div class="time-bottom">
      <div>
        <span class="demonstration">追踪时间</span>
        <el-date-picker
          v-model="datatime"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
          @change="changetime"
        >
        </el-date-picker>
      </div>
      <div class="picker">
        <el-time-picker
          @change="changetime2"
          type="datetime"
          value-format="HH:mm:ss"
          is-range
          v-model="dataarr"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
      </div>
      <el-button class="button" @click="getCurrentDate">当天</el-button>
      <el-button v-if="flagswitch" type="primary" @click="track">开始追踪</el-button>
      <el-button v-if="!flagswitch" type="primary" @click="pauseAnimation">暂停</el-button>
    </div>
  </div>
</template>

<script>
import Ajax from "@/dataApi/operationReport.js";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "commonTrial",
  props: {
    isShowCommonTrial: {
      type: Boolean,
      default: false,
    },
    commonTrialObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      getPersonTrailListLoading: false,
      dataarr: ["00:00:00", "23:59:59"],
      datatime: "",
      form: {
        startTime: "",
        endTime: "",
      },
      trackInformation: [],
      flagswitch: true,
      pickerOptions: {
        // 限制预约时间
        // - 24 * 60 * 60 * 1000
        disabledDate(time) {
          return (
            time.getTime() > Date.now() || time.getTime() < Date.now() - 24 * 60 * 60 * 1000 * 15
          );
        },
      },
    };
  },
  mounted() {
    if (this.isShowCommonTrial) {
      this.handerTime();
      this.getTrail();
    }
  },
  computed: {
    ...mapState("equipInfo", ["searchDetail"]),
    ...mapState(["floor"]),
  },
  methods: {
    timestamp(time) {
      let d = new Date(time);
      let date =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      return date;
    },
    changetime() {
      this.form.startTime = this.datatime + " " + this.dataarr[0];
      this.form.endTime = this.datatime + " " + this.dataarr[1];
      this.getTrail();
    },
    changetime2() {
      this.form.startTime = this.datatime + " " + this.dataarr[0];
      this.form.endTime = this.datatime + " " + this.dataarr[1];
      this.getTrail();
    },
    // 当天日期行车轨迹
    getCurrentDate() {
      this.handerTime();
      this.getTrail();
    },
    // 暂停
    pauseAnimation() {
      this.$emit("pauseAnimation");
      this.flagswitch = true;
    },
    // 开始追踪
    track() {
      this.flagswitch = false;
      console.log("this.trackInformation1", this.trackInformation);
      if (this.trackInformation.length > 0) {
        let data = [];
        let data1 = [];
        if (sessionStorage.gisType === "gaode") {
          this.trackInformation.forEach((item) => {
            if (item.longitude && item.latitude) {
              data.push([parseFloat(item.longitude), parseFloat(item.latitude)]);
            }
          });
          this.$emit("trackPlayback", data.reverse());
        } else if (sessionStorage.gisType === "vrbim" || sessionStorage.gisType === "earth") {
          if (this.commonTrialObj.trialType === "instrument") {
            if (this.floor.length > 0) {
              this.trackInformation.forEach((item) => {
                if (item.coordinateX && item.coordinateY && item.coordinateZ) {
                  data.push([item.coordinateX, item.coordinateY, item.coordinateZ]);
                }
              });
              if (data.length > 0) {
                this.$emit("trackPlayback", data.reverse());
              }
            } else {
              this.trackInformation.forEach((item) => {
                if (item.longitude && item.latitude) {
                  data1.push([item.longitude, item.latitude]);
                }
              });
              if (data1.length > 0) {
                this.$emit("trackPlayback", data1.reverse(), "instrument");
              }
            }
          } else {
            this.trackInformation.forEach((item) => {
              if (item.coordinateX && item.coordinateY && item.coordinateZ) {
                data.push([item.coordinateX, item.coordinateY, item.coordinateZ]);
              }
            });
            this.$emit("trackPlayback", data.reverse());
          }
        } else if (sessionStorage.gisType === "earth") {
          this.trackInformation.forEach((item) => {
            if (item.longitude && item.latitude) {
              data.push({
                longitude: parseFloat(item.longitude),
                latitude: parseFloat(item.latitude),
              });
            }
          });
          this.$emit("trackPlayback", data.reverse());
        }
      } else {
        this.trackInformation = [];
      }
    },
    // 是否为第一个事件
    isFirstEvent(index) {
      return this.trackInformation.length !== index + 1;
    },
    // 获取当天时间
    handerTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let day = date.getDate();
      this.form.startTime = `${year}-${month}-${day} 00:00:00`;
      this.form.endTime = `${year}-${month}-${day} 23:59:59`;
      this.datatime = `${year}-${month}-${day} 00:00:00`;
      this.dataarr = ["00:00:00", "23:59:59"];
    },
    // 返回
    goback() {
      this.$emit("closeCommonTrial");
    },
    // 获取轨迹详情
    getTrail() {
      this.$emit("clearAll");
      // this.commonTrialObj.trialType 根据此字段判断是什么轨迹，person： 保洁人员轨迹
      switch (this.commonTrialObj.trialType) {
        case "person":
          this.getPersonTrail();
          break;
        case "instrument":
          this.instrument();
          break;
        case "carLocus":
          this.getAiPatrolCarLocus();
          break;
      }
    },
    // 获取执法仪
    instrument() {
      let params = {
        endTime: this.form.endTime,
        did: this.commonTrialObj.did,
        startTime: this.form.startTime,
      };

      Ajax.instrument(params).then((res) => {
        if (res.length > 0) {
          let rs = res.filter((item) => {
            return item.longitude && item.latitude;
          });
          rs = this.unique(rs, "latitude");
          setTimeout(() => {
            this.trackInformation = rs;
            if (this.trackInformation.length > 0) {
              let data = [];
              this.trackInformation.forEach((item, index) => {
                data[index] = [parseFloat(item.longitude), parseFloat(item.latitude)];
              });
              this.$emit("createPath", data.reverse());
            }
          }, 300);
        } else {
          this.trackInformation = [];
          this.$emit("clearAll");
        }
      });
    },
    // 获取保洁人员轨迹
    getPersonTrail() {
      let params = {
        endTime: this.form.endTime,
        personId: this.commonTrialObj.personId,
        startTime: this.form.startTime,
        downSpaceCodes: this.commonTrialObj.floor
          ? this.commonTrialObj.floor.split(",")
          : [localStorage.lay_selectedPosCode],
      };
      this.getPersonTrailListLoading = true;
      Ajax.getPersonTrail(params).then((res) => {
        if (res.length > 0) {
          setTimeout(() => {
            this.getPersonTrailListLoading = false;
            this.trackInformation = res;
            if (this.trackInformation.length > 0) {
              let data = [];
              this.trackInformation.forEach((item) => {
                if (item.longitude && item.latitude) {
                  data.push([item.longitude, item.latitude]);
                }
              });
              this.$emit("createPath", data.reverse());
            }
          }, 300);
        } else {
          this.getPersonTrailListLoading = true;
          this.trackInformation = [];
          this.$emit("clearAll");
        }
      });
    },
    // 获取AI巡逻车轨迹
    getAiPatrolCarLocus() {
      let params = {
        startDate: this.form.startTime,
        endDate: this.form.endTime,
        equipId: this.commonTrialObj.equipmentCode,
      };
      Ajax.getAiPatrolCarLocus(params).then((res) => {
        if (res.length > 0) {
          res.forEach((item, index) => {
            this.trackInformation[index] = {
              longitude: Number(item.split(",")[0]),
              latitude: Number(item.split(",")[1]),
            };
          });
          setTimeout(() => {
            if (this.trackInformation.length > 0) {
              let data = [];
              this.trackInformation.forEach((item) => {
                if (item.longitude && item.latitude) {
                  data.push([item.longitude, item.latitude]);
                }
              });
              console.log("this.trackInformation2", this.trackInformation);
              this.$emit("createPath", data.reverse());
            }
          }, 300);
        } else {
          this.trackInformation = [];
          this.$emit("clearAll");
        }
      });
    },
    // 导出轨迹
    exportExcel: _.throttle(async function () {
      await this.getPersonTrail();
      if (this.trackInformation && this.trackInformation.length < 1) {
        this.$message({ message: "暂无数据可导出", type: "error", duration: 3000 });
        return;
      }
      let url = "/ioc/personmgmt/cleaner/trailExport";
      let data = {};
      let fileName = "轨迹导出";
      //this.commonTrialObj.trialType 根据此字段判断是什么轨迹，person： 保洁人员轨迹,instrument执法仪
      if (this.commonTrialObj.trialType == "person") {
        //目前只做保洁人员的，执法仪的暂时不做
        data = {
          endTime: this.form.endTime,
          personId: this.commonTrialObj.personId,
          startTime: this.form.startTime,
          downSpaceCodes: this.commonTrialObj.floor
            ? this.commonTrialObj.floor.split(",")
            : [localStorage.lay_selectedPosCode],
        };
      }
      const params = {
        ...data,
        config: {
          isExport: "blob",
        },
      };
      Ajax.exportAction(url, params)
        .then((res) => {
          // 处理返回的文件流;
          this.texcelPublic(res, fileName + ".xlsx");
        })
        .catch((error) => {
          // 处理返回的文件流;
          if (error.type === "application/json") {
            let state = "2";
            let msg = this.$t("i18nKey_cockpit_370"); //导出失败
            this.handleResponse({ state, msg });
          } else {
            this.texcelPublic(error, fileName + ".xlsx");
          }
        });
    }, 3000),
    // 文档导出公用
    texcelPublic(res, documentName) {
      const content = res;
      const blob = new Blob([content], { type: "application/vnd.ms-excel" });
      const fileName = documentName;
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    setTimer() {
      this.timer = setTimeout(() => {
        this.errorMessage.visible = false;
      }, 3000);
    },
    // 处理弹框提示
    handleResponse({ state, msg }) {
      this.errorMessage.errorShow = state;
      this.errorMessage.messAgeText = msg;
      this.errorMessage.visible = true;
      this.setTimer();
    },
    // 日期选择确认
    confirm() {
      if (this.form.startTime && this.form.endTime) {
        this.getTrail();
      }
    },
    unique(list, name) {
      let result = [];
      let obj = {};
      for (let i = 0; i < list.length; i++) {
        if (!obj[list[i][name]]) {
          result.push(list[i]);
          obj[list[i][name]] = true;
        }
      }
      return result;
    },
  },
  filters: {
    timeFormat(time) {
      return time.substr(11);
    },
  },
};
</script>

<style lang="less" scoped>
.common-trial {
  .top {
    height: 48px;
    width: 28%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 100px;
    z-index: 1;
    color: var(--common-white);
    backdrop-filter: blur(2px);
    &::before {
      border: 1.5px solid transparent;
      border-radius: 8px;
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
        linear-gradient(to bottom, #05f5df, #43adf9);
      opacity: 0.6;
      z-index: -1;
    }
    .left {
      padding-left: 32px;
      display: flex;
      flex-flow: row;
      align-items: center;
      .btn {
        width: 96px;
        height: 26px;
        background-color: #3a7aea;
        border-radius: 2px;
        line-height: 26px;
        text-align: center;
        font-size: 14px;
        .img {
          width: 18px;
          height: 18px;
        }
      }
      .name {
        font-size: 18px;
        padding-left: 32px;
      }
    }
    .icon-guanbi {
      padding-right: 27px;
      cursor: pointer;
    }
  }
  .right-content {
    width: 450px;
    height: 85%;
    overflow: auto;
    color: var(--common-white);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    z-index: 99;
    position: absolute;
    right: 24px;
    top: 100px;
    &::before {
      border: 1.5px solid transparent;
      border-radius: 8px;
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
        linear-gradient(to bottom, #05f5df, #43adf9);
      opacity: 0.6;
      z-index: -1;
    }
    .head-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .name {
      font-size: 16px;
      padding: 0 15px;
      font-family: var(--font-title);
      height: 2rem;
      line-height: 2rem;
    }
    .export-btn {
      padding-right: 10px;
      cursor: pointer;
    }
    .middle-right-bottom {
      height: calc(100% - 2rem);
      .right-bottom-content::-webkit-scrollbar {
        width: 6px; // 高宽分别对应横竖滚动条的尺寸
        height: 4px;
      }
      .right-bottom-content::-webkit-scrollbar-thumb {
        border-radius: 6px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }
      /*滚动条里面轨道*/
      .right-bottom-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.1);
      }
      .right-bottom-content {
        font-size: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        padding: 10px 15px;
        /*滚动条样式*/
        .bottom-content-content {
          display: flex;
          flex-basis: 50%;
          .bottom-content-content-left {
            position: relative;
            height: 6rem;
            flex-grow: 0;
            .bottom-content-content-left-line {
              background-color: var(--button-bg-color);
              position: absolute;
              width: 2px;
              top: 0;
              bottom: 0;
              left: 7px;
            }
            .bottom-content-content-left-icon {
              width: 18px;
              height: 18px;
              z-index: 1;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: relative;
              text-align: center;
              background-color: var(--button-bg-color);
              border-radius: 50%;
            }
          }
          .bottom-content-content-right {
            padding-left: 10px;
            flex-grow: 1;
            width: 0;
            margin-bottom: 15px;
            font-size: 14px;
            display: flex;
            flex-flow: row;
            .time {
              color: var(--common-white);
              font-family: var(--font-title);
            }
            .address {
              color: var(--common-secondary);
              display: flex;
              flex-flow: row;
              padding-left: 27px;
              .location {
                padding-left: 10px;
                p {
                  width: 260px;
                  overflow-wrap: anywhere;
                }
              }
            }
          }
        }
      }
    }
  }
  .time-bottom {
    .demonstration {
      margin-left: 20px;
      margin-right: 20px;
    }
    /deep/ .picker {
      .el-range-editor .el-range-input {
        color: #fff;
      }
      .el-date-editor .el-range-separator {
        color: #fff;
        line-height: 2rem;
        font-size: 14px;
      }
      .el-input__inner {
        padding: 0 0;
        color: #fff;
        border-radius: 4px;
      }
      .el-date-editor--daterange.el-input,
      .el-date-editor--daterange.el-input__inner,
      .el-date-editor--timerange.el-input,
      .el-date-editor--timerange.el-input__inner {
        width: 250px;
        margin-right: 20px;
      }
      .el-range-editor .el-range-input {
        background-color: #121933;
        width: 100px;
      }
      .el-date-editor .el-range__icon {
        display: none;
      }
    }
    color: var(--common-white);
    backdrop-filter: blur(10px);
    color: #8091ac;
    font-size: 14px;
    border-radius: 8px;
    z-index: 99;
    position: fixed;
    left: 150px;
    bottom: 60px;
    width: 1048px;
    height: 64px;
    display: flex;
    flex-flow: row;
    align-items: center;
    &::before {
      border: 1.5px solid transparent;
      border-radius: 8px;
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
        linear-gradient(to bottom, #05f5df, #43adf9);
      opacity: 0.6;
      z-index: -1;
    }

    /deep/ .mx-datepicker {
      width: 265px;
      cursor: pointer;
    }
    /deep/ .el-button {
      height: 34px;
      line-height: 34px;
    }
    /deep/.el-input__inner {
      background: #121933;
      height: 32px;
      padding: 0 0 0 35px;
      color: #fff;
    }
    /deep/.el-date-editor.el-input__inner {
      margin-left: 20px;
    }
  }
}
</style>
