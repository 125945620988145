<template>
  <div>
    <div class="rightwarp" v-if="show">
      <div class="close" v-if="isGis" @click="closePopup">
        <i class="iconfont icon-guanbi"></i>
      </div>
      <div class="padding">
        <div class="flex">
          <div class="column"></div>
          <div class="shu">
            {{ equipmentobj.basEquipmentInfoEntity.equipCn }}
          </div>
        </div>
        <div class="double">
          <img class="img1" src="/security-gis/static/imagesMap/dianwei.png" alt="" />
          <div class="doudiv" :title="equipmentobj.basEquipmentInfoEntity.locationFullPathCn || ''">
            {{ equipmentobj.basEquipmentInfoEntity.locationFullPathCn || "" }}
          </div>
          <div class="text" @click="toDetail">设备详情</div>
        </div>
        <div class="double">
          <img class="img2" src="/security-gis/static/imagesMap/shebei2.png" alt="" />
          <div class="doudiv">
            {{ equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv1Cn }}/{{
              equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv2Cn
            }}/{{ equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn }}
          </div>
          <div
            class="but"
            v-if="
              equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '摄像头' ||
              equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '高清监控摄像头' ||
              equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '人脸识别摄像头' ||
              equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '车牌识别摄像头' ||
              equipmentobj.basEquipmentInfoEntity.iocEquipTypeLv3Cn === '视频周界摄像头'
            "
            @click="moving"
          >
            实时视频
          </div>
        </div>
        <div class="double">
          <span class="tetxspan">今日告警数{{ equipmentobj.todayNumber || 0 }}个</span>
          <span class="tetxspan2">未处理告警数{{ equipmentobj.untreatedNumber || 0 }}个</span>
        </div>
      </div>

      <div class="doublebottom" v-if="equipmentobj.alarmList.length">
        <div class="flex" v-for="(d, i) in equipmentobj.alarmList" :key="i">
          <div class="flextoo">
            <div class="flexdiv" v-if="d.incidentLevelCode === 10041001">紧急</div>
            <div
              class="flexdiv"
              style="background: #ff7e05"
              v-else-if="d.incidentLevelCode === 10041002"
            >
              重要
            </div>
            <div
              class="flexdiv"
              style="background: #ffde73"
              v-else-if="d.incidentLevelCode === 10041003"
            >
              一般
            </div>
            <div class="flexdiv" style="background: #32e0a6" v-else>提示</div>
            <div>
              <div class="centertext">{{ d.incidentTitle }}</div>
              <div class="centertime">{{ d.openDate }}</div>
            </div>
          </div>

          <div class="righttext" v-if="showDisPatch" @click="toworke(d)">派发</div>
        </div>
      </div>
    </div>
    <!-- 工单派发 -->
    <ioc-dispatch-workorder
      title="工单派发"
      width="30%"
      :eventInfo="eventInfo"
      :incidentType="incidentType"
      :isDispatchWorkorderDialogShow="isDispatchWorkorderDialogShow"
      @handleListInquiry="handleListInquiry"
      @handleDialogClose="handleDialogClose"
    ></ioc-dispatch-workorder>
    <el-dialog
      class="g-dialog"
      title="实时视频"
      :visible.sync="movingDialogVisibleTririga"
      width="30%"
      append-to-body
      :modal-append-to-body="true"
      :before-close="handleClose2"
    >
      <ioc-video
        class="default-camera"
        :cameraCode="equipmentobj.basEquipmentInfoEntity.equipCode"
        :videoParams="videoParams"
        ref="videoRef"
        :hash="hash"
      ></ioc-video>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/utils/request.js";
import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      isDispatchWorkorderDialogShow: false,
      equipmentobj: { basEquipmentInfoEntity: {}, alarmList: [] },
      eventInfo: {},
      incidentType: "SAFETY",
      movingDialogVisibleTririga: false,
      videoParams: {
        urlparams: {},
      },
      show: false,
      hash: new Date().getTime(),
    };
  },
  props: {
    equipCodebim: {
      type: String,
      default: "",
    },
  },
  created() {
    this.rootdata();
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapState(["userInfo"]),
    parkCode() {
      return this.positionInfo.posCode;
    },
    isGis() {
      return sessionStorage.getItem("gisType") === "gis";
    },
    showDisPatch() {
      return true;
      // if (localStorage.lay_selectedPosCode === "CN_44_003_SZGJCXG_P1") {
      //   return false;
      // } else {
      //   return true;
      // }
    },
  },
  methods: {
    ...mapMutations(["fillVideoObj", "fillPopShow"]),
    ...mapMutations("equipInfo", ["equipDetail"]),
    handleClose2() {
      this.fillVideoObj({
        showStatus: false,
      });
      this.movingDialogVisibleTririga = false;
      this.$refs.videoRef.closeVideo();
    },
    moving() {
      this.videoParams.urlparams = {
        posCode: this.parkCode,
        userCode: this.userInfo.uid,
      };
      this.hash = new Date().getTime();
      this.movingDialogVisibleTririga = true;
    },
    toworke(node) {
      if (node.incidentType === "equip") {
        this.incidentType = "EQUIP";
      } else if (node.incidentType === "safety") {
        this.incidentType = "SAFETY";
      } else {
        this.incidentType = "REPAIR";
      }
      this.eventInfo = node;
      this.addNewTask();
    },
    handleListInquiry() {
      this.isDispatchWorkorderDialogShow = false;
    },
    handleDialogClose() {
      this.isDispatchWorkorderDialogShow = false;
    },
    rootdata() {
      api(
        {
          url: "/ioc/equipmentmgmt/polymerization/getEquipAndAlarm",
          method: "post",
          data: { equipCode: this.equipCodebim },
        },
        { handleErr: false }
      )
        .then((res) => {
          this.equipmentobj = res.data;
          this.show = true;
          if (!this.equipmentobj.alarmList.length) {
            window.GLOBAL_OBJ.eventHub.publish("styleofftop", 0);
          } else {
            window.GLOBAL_OBJ.eventHub.publish("styleofftop", 1);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    toDetail() {
      this.getEquipDetail();
    },
    getEquipDetail() {
      let params = {
        flag: true,
        equipCode: this.equipmentobj.basEquipmentInfoEntity.equipCode,
      };
      api(
        {
          url: "/ioc/equipmentmgmt/equipmentInfo/selectEquipByEquipCode",
          method: "get",
          data: params,
        },
        { handleErr: false }
      )
        .then((res) => {
          this.equipDetail({
            ...res.data.entity,
            dialogVisible: true,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    addNewTask() {
      var params = { pageNumber: 1, pageSize: 500, roleType: 6, type: 0 };
      api(
        {
          url: "/ioc/property/v1/workorder/workOption",
          method: "post",
          data: params,
        },
        { handleErr: false }
      )
        .then((res) => {
          sessionStorage.setItem("workOrder", JSON.stringify(res.data));
          this.$store.commit("getproprtyworker", res.data);
          this.isDispatchWorkorderDialogShow = true;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    closePopup() {
      this.fillPopShow(false);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .vanke-dialog-bodycontent {
  height: 400px !important;
  width: 100% !important;
}
.default-camera {
  height: 400px;
  width: 100%;
}
.vrbim-container .bim-popup {
  background: unset;
  .close {
    .iconfont {
      margin-right: -5px !important;
    }
  }
}
.rightwarp {
  position: relative;
  border-radius: 8px;
  color: var(--common-white);
  padding: 0 0 10px 0;
  backdrop-filter: blur(10px);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  width: 100%;
  line-height: 2;
  &::before {
    border: 1.5px solid transparent;
    border-radius: 8px;
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)),
      linear-gradient(to bottom, #05f5df, #43adf9);
    opacity: 0.6;
    z-index: -1;
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    line-height: 2;
    cursor: pointer;
    font-size: 16px;
    .iconfont {
      font-size: 12px;
    }
  }
  .padding {
    position: relative;
    .el-icon-close {
      float: right;
      margin-right: -15px;
      margin-top: -5px;
      font-size: 14px;
      cursor: pointer;
    }
    padding: 10px 20px;
    .flex {
      margin-bottom: 10px;
      .shu {
        font-family: var(--font-title);
        font-size: 20px;
        line-height: 2.5;
        font-weight: normal;
        text-align: center;
        padding-right: 20px;
        width: 100%;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          bottom: -2px;
          width: 100%;
          left: 0;
          height: 1px;
          background: linear-gradient(to right, transparent, var(--common-white), transparent);
        }
      }
    }
    .double {
      display: flex;
      line-height: 30px;
      .tetxspan2 {
        margin-left: 100px;
      }

      .doudiv {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 320px;
      }
      .text {
        width: 80px;
        height: 30px;
        color: var(--button-bg-color);
        float: right;
        cursor: pointer;
      }
      .but {
        width: 80px;
        height: 30px;
        background-color: var(--button-bg-color);
        border-radius: 4px;
        float: right;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        cursor: pointer;
      }
      .img2,
      .img1 {
        margin-right: 8px;
        margin-left: 0;
        width: 14px;
        height: 16px;
        margin-top: 8px;
      }
    }
  }

  .doublebottom {
    padding: 20px 20px;
    width: 100%;
    border-top: solid 1px #465278;
    margin-top: 4px;
    box-sizing: border-box;
    overflow: auto;
    height: 237px;
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .righttext {
        font-family: var(--font-title);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #3a7aea;
        cursor: pointer;
        width: 50px;
      }
      .flextoo {
        display: flex;
      }
      .flexdiv {
        width: 50px;
        height: 23px;
        line-height: 23px;
        background-color: #fa5252;
        border-radius: 11px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: var(--common-white);
        text-align: center;
        margin-right: 10px;
        margin-top: 10px;
      }

      .centertext {
        font-family: var(--font-title);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: var(--common-white);
      }
      .centertime {
        font-family: var(--font-title);
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: var(--common-secondary);
      }
    }
  }
}
</style>
<style lang="less" scoped>
/deep/ .el-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
