<template>
  <div class="change-wrapper">
    <div class="bubble"></div>
    <div class="op-content">
      <span class="chart-config"  @click="clickMapLayer">
        <i class="iconfont icon-ditu"></i>
        <span class="layer-text">{{ $t('i18nKey_cockpit_20') }}/{{ $t('i18nKey_cockpit_22') }}</span>
      </span>
      <span class="chart-config" v-if="needChartConfig" @click="clickChartConfig">
        <i class="iconfont icon-tubiao"></i>
        <span class="layer-text">{{ $t('i18nKey_cockpit_126') }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "layerConfig",
  props: {
    isShowVrComfig:Boolean,
    needChartConfig:Boolean
  },
  watch: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    clickMapLayer() {
      this.$emit("clickMapLayer")
    },
    clickChartConfig() {
      this.$emit("clickChartConfig")
    }
  }
};
</script>

<style lang="less" scoped>
.change-wrapper {
  position: absolute;
  top: 2.1rem;
  left: -4.325rem;
  display: flex;
  flex-direction: column;
  cursor: default;
  border-radius: 0.35rem;
  .bubble {
    position: absolute;
    top: -0.5rem;
    right: 0.5rem;
    width: 0;
    height: 0;
    border-bottom: 0.5rem solid #1d2745;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
  .op-content {
    background: #1d2745;
    border-radius: .1875rem .1875rem 0 .1875rem .1875rem .1875rem .1875rem;
    padding: 0 .8125rem;
    font-family: MicrosoftYaHei;
    font-size: .75rem;
    color: #fff;
    line-height: 1;
    min-width: 6rem;
    // align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    .layer-text {
      margin-left: .375rem;
    }
    .chart-config {
      display: block;
      cursor: pointer;
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}
</style>