<template>
  <div class="top-tool-bar">
    <div class="bar-body">
      <span class="bar-title">{{ routeName }}</span>
      <span class="bar-modify">
        <span
          v-show="!fullScreen && isShowVrComfig"
          class="time-flow"
          @click="timeFlowHandler"
        >
          <i class="icon iconfont icon-shijianliudong"></i>
          {{ $t("i18nKey_cockpit_34") }}
        </span>
        <div class="sound">
          <el-switch
            v-model="soundValue"
            :width="38"
            active-color="#3A7AEA"
            inactive-color="#495873"
            @change="soundChange"
            >
          </el-switch>
          <div>{{$t("i18nKey_cockpit_392")}}</div>
        </div>
        <div class="weather-flow" :class="{ isShowVrComfig: isShowVrComfig }">
          {{ weatherText }}
          <img class="weather-img" :src="weatherImg" />
          <span class="weather-change">
            <i class="el-icon-more" v-if="isShowVrComfig"></i>
          </span>
        </div>
        <span
          v-show="fullScreen && isShowVrComfig"
          class="time-flow"
          @click="timeFlowHandler"
        >
          <i class="icon iconfont icon-shijianliudong"></i>
        </span>
        <span class="modify-text" v-show="needConfig && !fullScreen">
          <i class="iconfont icon-shezhi1"></i>
          <span>{{ $t("i18nKey_cockpit_36") }}</span>
          <layerConfig
            class="layer-content"
            :needChartConfig="needChartConfig"
            @clickMapLayer="clickMapLayer()"
            @clickChartConfig="showSelectCard()"
          />
        </span>
        <el-switch
          v-model="isFullScreen"
          :width="38"
          active-color="#3A7AEA"
          inactive-color="#495873"
        ></el-switch>
        <span>{{ $t("i18nKey_cockpit_37") }}</span>
      </span>
    </div>
    <div class="vanke-mask" v-if="isMapConfigShow">
      <mapConfiguration
        :isShowVrComfig="isShowVrComfig"
        :isShowConfig="isShowConfig"
        @closeSelectCard="closeMapDialog"
      />
    </div>

    <CardConfigDialog :visible.sync="canRenderCards" v-if="canRenderCards" />
    <streetScapeModule
      @selectModuleFn="selectModuleFn"
      :visible.sync="canSelectModule"
      v-if="canSelectModule"
    ></streetScapeModule>
  </div>
</template>

<script>
import mapConfiguration from "@/components/mapConfiguration"; // 地图配置
import layerConfig from "@/components/topToolLayer/layerConfig";
import sun from "@/assets/images/common/we_sunny.png";
import { mapGetters, mapState, mapMutations } from "vuex";
import CardConfigDialog from "@/layout/components/CardConfigDialog";
import streetScapeModule from "@/layout/components/streetScapeModule";
import tabsConfig from "@/config/overview-tabs-cards.js";
export default {
  name: "topToolBar",
  components: {
    layerConfig,
    mapConfiguration,
    CardConfigDialog,
    streetScapeModule,
  },
  props: {
    needConfig: {
      type: Boolean,
      default: true,
    },
    needChartConfig: {
      type: Boolean,
      default: true,
    },
    isShowConfig:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      soundValue:true,//websocket声音按钮
      isFullScreen: false,
      isMapConfigShow: false,
      weatherText: this.$t("i18nKey_cockpit_38"),
      weatherImg: sun,
      canRenderCards: false,
      canSelectModule: false, // 街景选择模式
      // routeName: "",
      isShowVrComfig: false
    };
  },
  computed: {
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapState(["fullScreen"]),
    posCode() {
      return this.positionInfo.posCode;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        let item = tabsConfig.find(
          ({ path }) => this.$route.path.indexOf(path) === 0
        );
        this.routeName = item ? item.name : "";
      },
    },
    isFullScreen(v) {
      this.fillFullScreen(v);
      let className = document.body.className;
      if (v) {
        document.body.className += `${className && " "}full-screen`;
        document.body.focus();
        document.documentElement.webkitRequestFullScreen &&
          document.documentElement.webkitRequestFullScreen();
        document.documentElement.requestFullScreen &&
          document.documentElement.requestFullScreen();
      } else {
        document.body.className = className.replace(/\s*(full-screen)/g, "");
        document.webkitCancelFullScreen && document.webkitCancelFullScreen();
        document.cancelFullScreen && document.cancelFullScreen();
      }
      document.querySelector("#cockpit-page-box").scrollTop = 0;
      this.$emit("screenChange", v);
    },
    fullScreen(newVal) {
      if (newVal !== this.isFullScreen) {
        this.isFullScreen = newVal;
      }
    },
  },
  created(){
    this.soundValue=(sessionStorage.getItem("soundValue")==="true"?true:false);
  },
  mounted() {
    this.$nextTick(() => {
      let cancelFullScreen = () => {
        // 时间流动模式下不允许esc退出全屏
        if (document.body.classList.contains("time-flow-open")) {
          return;
        }
        this.isFullScreen = false;
        this.fillFullScreen(this.isFullScreen);
      };
      document.body.addEventListener("keyup", (e) => {
        if (e.keyCode === 27) {
          cancelFullScreen();
        }
      });

      document.addEventListener("webkitfullscreenchange", function () {
        if (!document.webkitFullscreenElement) {
          cancelFullScreen();
        }
      });
      document.addEventListener("fullscreenchange", function () {
        if (!document.fullscreenElement) {
          cancelFullScreen();
        }
      });
    });
    this.onStreetscape();
  },
  beforeDestroy() {
    this.$vuePubSub.$off("streetscapeFn");
  },
  activated() {
    this.isFullScreen = this.fullScreen;
  },
  methods: {
    soundChange(val){
      sessionStorage.removeItem("soundValue"); 
      sessionStorage.setItem("soundValue",val)
    },
    onStreetscape() {
      this.$vuePubSub.$on("streetscapeFn", () => {
        this.canSelectModule = true;
      });
    },
    selectModuleFn(type) {
      this.canSelectModule = false;
      this.fullScreenFn();
      this.$vuePubSub.$emit("modifyParadeModel", {
        model: "Character",
        paradeName: type,
      });
    },
    clickMapLayer() {
      this.isMapConfigShow = true;
    },
    closeMapDialog() {
      this.isMapConfigShow = false;
    },
    ...mapMutations(["fillFullScreen", "fillTimeFlowOpen"]),
    showSelectCard() {
      this.canRenderCards = true;
    },
    timeFlowHandler() {
      this.fullScreenFn();
      this.$vuePubSub.$emit("closePupop");
      let mainPerspective = JSON.parse(
        localStorage.getItem("vr-main-perspective")
      );
      this.fillTimeFlowOpen(true);
      this.$vuePubSub.$emit("mapMethods", "modifyCameraMove", mainPerspective);

      // 切换到主视角
      this.$vuePubSub.$emit("mapMethods", "getCameraTransform");
      this.$vuePubSub.$emit("mapMethods", "modifyCameraMove", mainPerspective);
    },
    fullScreenFn() {
      this.isFullScreen = true;
      this.fillFullScreen(this.isFullScreen);
      document.body.classList.add("time-flow-open");
      document.body.classList.add("full-screen");
      this.$vuePubSub.$emit("layerShowFn", false);

      // 将地图工具条移动到右侧隐藏
      this.$vuePubSub.$emit("mapToolBarChange", "-10rem");
      this.$vuePubSub.$emit("mapMethods", "showPOI", "ALL", false);
    },
  },
};
</script>

<style lang="less" scoped>
.bar-body {
  position: fixed;
  top: 3.9375rem;
  right: 0;
  left: 5.25rem;
  height: 2.4rem;
  line-height: 2.4rem;
  // padding: 0 2.5rem 0 1.75rem;
  display: flex;
  color: var(--header-white);
  // background-color: #12182f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 109;
  .bar-title {
    padding-left:50px;
    color: var(--common-white);
    font-size: 18px;
    font-family: var(--font-title);
    padding-right: 150px;
    background-image: linear-gradient(180deg, #244B84 20%, #001940 99%);
    position: relative;
    &::before{
      content:'';
      position: absolute;
      background-image: linear-gradient(180deg, #244B84 20%, #001940 99%);
      left: 99.5%;
      bottom: 0;
      height: 2.4rem;
      width: 2.4rem;
      clip-path: polygon(0 0, 0 100%, 100% 0);
    }
    &:after{
      content: '';
      position: absolute;
      bottom: 1px;
      right: 60px;
      left: 10px;
      height: 4px;
      background: url(../assets/images/bottom-title.svg);
      background-size: 100% 100%; 
    }
  }
  .sound{
    display: flex;
    align-items: center;
  }
  .bar-modify {
    padding-left: 30px;
    color: var(--header-white);
    font-size: 14px;
    display: flex;
    align-items: center;
    background:#244B84 ;
    padding-right:50px;
    position: relative;
    &::before{
      content:'';
      position: absolute;
      background: #244B84;
      right: 100%;
      top: 0;
      height: 2.4rem;
      width: 2.4rem;
      clip-path: polygon(0 0, 101% 0, 100% 101%);
    }
    .time-flow {
      margin-right: 5px;
      cursor: pointer;
    }
    .weather-flow {
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      .isShowVrComfig {
        cursor: pointer;
      }
      &:hover {
        .change-content {
          display: block !important;
        }
      }
      .weather-img {
        width: 16px;
        height: 16px;
      }
    }
    .weather-change {
      margin-left: 10px;
      i {
        transform: rotate(90deg);
      }
    }
    .change-content {
      display: none;
      &:hover {
        display: block;
      }
    }
    .modify-text {
      cursor: pointer;
      position: relative;
      &:hover {
        .layer-content {
          display: block;
        }
      }
      .layer-content {
        display: none;
      }
    }
    .icon-icon_edit:before {
      font-size: 8px;
    }
    /deep/ .el-switch {
      margin-left: 20px;
      margin-right: 5px;
      .el-switch__core {
        height: 16px;
      }
      .el-switch__core:after {
        width: 12px;
        height: 12px;
      }
    }
    /deep/ .el-switch.is-checked .el-switch__core::after {
      left: 100%;
      margin-left: -13px;
    }
  }
}
.vanke-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2200;
  background: rgba(4, 7, 13, 0.5);
}
</style>
<style lang="less" scoped>
.full-screen {
  .headerwrapper,
  #page-container-left,
  .navBreadCrumb {
    display: none;
  }
  .vr-bim-container {
    top: 4.025rem !important;
    left: 0 !important;
  }
  .page-box {
    top: 4.125rem;
    left: 0;
    height: calc(100% - 4.125rem) !important;
  }
  .float-layer-body {
    z-index: 2100;
  }
  .bar-body {
    top: 0;
    z-index: 2999;
    left: auto;
    right: 30px;
    height: 4.1rem;
    background: transparent;
    .bar-title {
      display: none;
    }
    span {
      color: #fff !important;
      &.bar-modify{
        background: transparent;
        &::before{
          display: none;
        }
      }

    }
  }
}
</style>