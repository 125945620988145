<template>
  <div>
    <div class="time-flow-top">
      <span class="exit-btn" @click="exitPlay">
        <i class="icon iconfont icon-mask_icon_tag_stoped"></i>
        {{ $t('i18nKey_cockpit_125') }}
      </span>
    </div>
    <div class="time-flow-bottom">
      <div class="control-btn" @click="toggleStop">
        <i class="iconfont icon-ai07" v-if="!isStop"></i>
        <i class="iconfont icon---icon_bofang" v-else></i>
      </div>
      <el-slider class="m-slider" v-model="curTime" :show-tooltip="false" :min="1" :max="steps"></el-slider>
      <span class="current-time-txt">{{currentTime}}</span>
    </div>
  </div>
</template>
<script>
// 1小时播放5s,总共120s 修改为一小时播放1s 总共24s
// 1秒走10次 共1200 次
import moment from 'moment'
import { mapMutations } from "vuex";
 const STEPS = 24, PIN_LV = 1000; // const STEPS = 1200, PIN_LV = 100;
// let prevHours = -1;
export default {
  data() {
    return {
      curTime: 0,
      isStop: false,
      steps: STEPS
    };
  },
  computed: {
    currentTime(){
      // return moment('2020-01-01 00:00:00').add(this.curTime *1.2, 'minutes').format('HH:mm:ss')
      return moment('2020-01-01 00:00:00').add(this.curTime, 'hours').format('HH:mm:ss')
    },
  },
  watch: {
    curTime(newVal) {
      // let time = Math.round((newVal * 1.2) / 60);
      
      // if( time !== prevHours){
      //   this.$emit("timeChange", time);
      //   prevHours = time;
      // }
      let time = newVal;
       this.$emit("timeChange", time);
    }
  },
  mounted() {
    this.startTravel();
  },
  beforeDestroy() {
    this.stopTravel();
  },
  methods: {
    ...mapMutations(["fillFullScreen", "fillTimeFlowOpen"]),
    exitPlay() {
      this.fillTimeFlowOpen(false);
      this.stopTravel();
      this.fillFullScreen(false);
      document.body.classList.remove("time-flow-open");
      document.body.classList.remove("full-screen");
      // 离开的时候重置为默认时间
      this.$emit("timeChange", 13);
      this.$emit("exitTimeFlowPlay");
      this.$vuePubSub.$emit("mapToolBarChange", "30rem");
      this.$vuePubSub.$emit("layerShowFn", true);
      // 恢复隐藏的poi点位
      // 恢复视角
    },
    toggleStop() {
      this.isStop = !this.isStop;
      if (!this.isStop) {
        this.startTravel();
      } else {
        this.stopTravel();
      }
    },
    startTravel() {
      this.curTime++
      this.timerId = setInterval(() => {
        if (this.curTime >= STEPS) {
          this.curTime = 0;
        }
        this.curTime++;
      }, PIN_LV);
    },
    stopTravel() {
      clearInterval(this.timerId);
    }
  }
};
</script>
<style lang="less" scoped>
.current-time-txt {
  color: #fff; 
  margin-left: 14px; 
  letter-spacing: 2px;
}
.time-flow-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  line-height: 48px;
  z-index: 10000;
  background: url(../../assets/images/overview/top.png);
  background-size: contain;
  background-repeat: no-repeat;
  .exit-btn {
    font-size: 16px;
    color: #a2b0cb;
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translate(-50%, 50%);
    cursor: pointer;
  }
}
.time-flow-bottom {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 10000;
  transform: translateX(-50%);
  width: 1042px;
  height: 67px;
  background: #1d2745;
  border-radius: 8px;
  padding: 0 27px 0 39px;
  display: flex;
  align-items: center;
  .control-btn {
    background: #101b35;
    border: 1px solid #2e3859;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  .m-slider {
    flex: 1;
  }
}
</style>
<style lang="less">
.time-flow-bottom {
  .m-slider {
    .el-slider__runway {
      background: #101b35;
      .el-slider__bar {
        background-image: linear-gradient(90deg, #0075ff 0%, #3239cd 100%);
      }
    }
    .el-slider__button {
      width: 12px;
      height: 12px;
    }
  }
}
</style>