export default {
  executionTime: 'TH_执行总时长',
  cumulativeHappened: 'TH_累计发生',
  cumulativeProcessing: 'TH_累计处理',
  treatmentRate: 'TH_处理完成率',
  processingTimeliness: 'TH_处理及时率',
  timeoutProcessed: 'TH_超时未处理',
  processedTimeout: 'TH_超时后已处理',
  energyAIDiagnostics: 'การวินิจฉัย AI ประหยัดพลังงาน',

}