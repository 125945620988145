<template>
  <div class="car-pop">
    <div class="car-content">
      <div class="car-top">
        <div class="name">AI巡逻车预设名称</div>
        <div class="status">
          <div class="online" v-if="equipStatusCode === '1'">
            <div class="green"></div>
            <div class="text-green">在线</div>
          </div>
          <div class="offline" v-else-if="equipStatusCode === '0'">
            <div class="grey"></div>
            <div class="text-grey">离线</div>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="title">当前位置</div>
        <div class="detail">{{ address }}</div>
      </div>
      <div class="speed">
        <div class="title">当前速度</div>
        <div class="detail">{{ speed }} m/s</div>
      </div>
      <div class="mileage">
        <div class="title">当前里程</div>
        <div class="detail">{{ mileage }} m</div>
      </div>
      <div class="time">
        <div class="title">最近更新时间</div>
        <div class="detail">{{ time }}</div>
      </div>
      <div class="toTrial">
        <div class="trial-btn" @click="checkDetail">轨迹查看</div>
        <!-- <div class="btn" @click="checkDetail">视频查看</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "carPop",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    equipmentCode: {
      type: String,
      default: "",
    },
    equipStatusCode: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
    speed: {
      type: String,
      default: "",
    },
    mileage: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    floor: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapMutations(["fillIsShowCommonTrial", "fillCommonTrialObj", "fillPopShow"]),
    ...mapMutations("equipInfo", ["searchDetail"]),
    checkDetail() {
      window.GLOBAL_OBJ.eventHub.publish("saveVisualAngle");
      this.searchDetail(true);
      this.fillIsShowCommonTrial(true);
      this.fillCommonTrialObj({
        title: "AI巡逻轨迹",
        type: "AI巡逻",
        name: this.title,
        trialType: "carLocus",
        floor: this.floor,
        equipmentCode: this.equipmentCode
      });
      this.fillPopShow(false);
    },
  },
};
</script>
<style lang="less" scoped>
.car-pop {
  width: 360px;
  min-height: 180px;
  background: #1a2441;
  color: #fff;
  position: relative;
  text-align: left;
  line-height: 1.2;
  .car-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    color: #607390;
    font-size: 14px;
    .car-top {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding: 5px 0;
      align-items: center;
      .name {
        font-size: 16px;
        color: #fff;
      }
      .status {
        .online,
        .offline {
          display: flex;
          flex-flow: row;
          align-items: center;
        }
        .green,
        .grey {
          width: 10px;
          height: 10px;
          border-radius: 50% 50%;
        }
        .green {
          background: #32e0a6;
        }
        .grey {
          background: #607390;
        }
        .text-green {
          color: #32e0a6;
          padding-left: 5px;
        }
        .text-grey {
          color: #607390;
          padding-left: 5px;
        }
      }
    }

    .address,.speed,.mileage,.time{
      display: flex;
      flex-flow: row;
      padding: 5px 0;
      .title{
        flex: 1;
      }
      .detail{
        flex: 2;
      }
    }

    .toTrial {
      margin-top: 5px;
      border-top: 1px solid #465278;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .trial-btn{
        margin-top: 10px;
        color: #3a7aea;
        padding-right: 10px;
        cursor: pointer;
      }
      .btn {
        margin-top: 10px;
        width: 96px;
        height: 33px;
        background-color: #3a7aea;
        border-radius: 2px;
        line-height: 33px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        color: #fff;
      }
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
</style>
