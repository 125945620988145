<template>
  <el-dialog
    append-to-body
    :modal-append-to-body="true"
    :close-on-click-modal="false"
    class="g-dialog-juhe"
    title="设备详情"
    :visible.sync="dialogVisible"
    width="45%"
    :before-close="handleClose"
  >
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="12">
        <div class="title">{{ equipInfo.locationFullPathCn || "" }}</div>
      </el-col>
      <el-col :span="12">
        <div class="status">
          <span class="name">设备状态</span
          ><span class="on" v-show="equipInfo.equipStatusCode === '1'"
            >在线</span
          >
          <span class="off" v-show="equipInfo.equipStatusCode === '0'"
            >离线</span
          >
          <span class="off" v-show="equipInfo.equipStatusCode === '2'"
            >休眠</span
          >
          <span class="off" v-show="equipInfo.equipStatusCode === '3'"
            >异常</span
          >
          <span class="off" v-show="equipInfo.equipStatusCode === '4'"
            >停用</span
          >
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="12">
        <div class="listLeft">
          <span class="name">设备编码</span
          ><span class="value">{{ equipInfo.equipCode }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="listRight">
          <span class="name">运行参数时间</span
          ><span class="value">{{ equipInfo.lastUpdateDate }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="12">
        <div class="listLeft">
          <span class="name">子系统</span
          ><span class="value">{{ equipInfo.iocEquipTypeLv2Cn }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="listRight">
          <span class="name">所属区域</span
          ><span class="value">{{ equipInfo.absoluteLocationFullPathCn }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="12">
        <div class="listLeft">
          <span class="name">所属系统</span
          ><span class="value">{{ equipInfo.iocEquipTypeLv1Cn }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="listRight">
          <span class="name">应用系统编码</span
          ><span class="value">{{ equipInfo.iocEquipTypeLv1Code }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="12">
        <div class="listLeft">
          <span class="name">子类</span
          ><span class="value">{{ equipInfo.iocEquipTypeLv3Cn }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="listRight">
          <span class="name">设备等级</span
          ><span class="value">{{ equipInfo.equipImprtLevel }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col>
        <el-radio-group v-model="radio1" @change="tabChangeClick">
          <el-radio-button style="border-right: none" label="1"
            >设备运行参数</el-radio-button
          >
          <el-radio-button class="butbor" label="2"
            >告警处理记录</el-radio-button
          >
          <el-radio-button class="butbor" label="3"
            >周期性工单记录</el-radio-button
          >
        </el-radio-group>
      </el-col>
    </el-row>
    <div class="dataChange">
      <div class="cards" v-if="radio1 === '1'">
        <div class="cardTop">
          <div class="card" v-for="(d, i) in statusList" :key="i">
            <img
              v-if="matching(d.showValue) == '正常'"
              class="img"
              src="@/assets/images/runKeys/bianzu17.png"
              alt=""
            />
            <img
              v-if="matching(d.showValue) == '异常'"
              class="img"
              src="@/assets/images/runKeys/bianzhu18.png"
              alt=""
            />
            <img
              v-if="matching(d.showValue) == '默认'"
              class="img"
              src="@/assets/images/runKeys/bianzhu19.png"
              alt=""
            />
            <span class="status" :title="d.attributeName">
              {{ d.attributeName }}
            </span>
            <span
              v-if="matching(d.showValue) == '正常'"
              :class="['spanstatic', 'normal']"
              >{{ d.showValue }}</span
            >
            <span
              v-if="matching(d.showValue) == '异常'"
              :class="['spanstatic', 'abnormal']"
              >{{ d.showValue }}</span
            >
            <span
              v-if="matching(d.showValue) == '默认'"
              :class="['spanstatic', 'default']"
              >{{ d.showValue }}</span
            >
          </div>
        </div>
        <div class="cardBottom">
          <div class="card" v-for="(d, i) in InfoListobj" :key="i">
            <div class="value">{{ d.showValue }}</div>
            <div class="name">{{ d.attributeName }}</div>
          </div>
        </div>
      </div>
      <div class="table" v-else-if="radio1 === '2'">
        <el-table :data="tableData" width="100%" max-height="200">
          <template slot="empty">
            <div class="nothingMore">
              <div class="nothingMoreContent">
                <i class="iconfont icon-juxing"></i>
                <br />无符合查询条件的数据
              </div>
            </div>
          </template>
          <template v-for="(item, index) in tableHead">
            <el-table-column v-bind="item" :key="index"> </el-table-column>
          </template>
          <el-table-column label="告警操作" prop="oparate" align="center">
            <template slot-scope="scope">
              <span
                :class="{ chuli: scope.row.incidentStatusCn === '待处理' }"
                >{{ scope.row.incidentStatusCn }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table" v-else-if="radio1 === '3'">
        <el-table :data="tableOrderData" width="100%" max-height="200">
          <template slot="empty">
            <div class="nothingMore">
              <div class="nothingMoreContent">
                <i class="iconfont icon-juxing"></i>
                <br />无符合查询条件的数据
              </div>
            </div>
          </template>
          <el-table-column label="任务ID">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.taskSpecId" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.taskSpecId || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="任务名称">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.taskname" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.taskname || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="计划开始时间">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.taskplannedstarttime" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.taskplannedstarttime || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="计划完成时间">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.taskplannedendtime" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.taskplannedendtime || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="作业人员">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.taskresponsiblepeo" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.taskresponsiblepeo || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="作业位置">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.tasklocation" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.tasklocation || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="作业内容">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.taskcontentname" placement="top-start" effect="dark">
                <div>
                  <span>{{scope.row.taskcontentname || '-'}}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="完成情况">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.finish | transformFinish }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" type="primary">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/dataApi/operationReport.js";
import { getDetailDay } from "@/commonJs/utils.js";
export default {
  name: "equipDetail",
  components: {},
  data() {
    return {
      dialogVisible: false,
      radio1: "1",
      tableHead: [
        {
          label: "告警时间",
          prop: "openDate",
          align: "center",
          showOverflowTooltip: true,
          width: "180",
        },
        {
          label: "告警级别",
          prop: "incidentLevelCn",
          align: "center",
          showOverflowTooltip: true,
        },
        {
          label: "设施系统",
          prop: "eqpType1Cn",
          align: "center",
          showOverflowTooltip: true,
        },
        {
          label: "子系统",
          prop: "eqpType2Cn",
          align: "center",
          showOverflowTooltip: true,
        },
        {
          label: "设施名称",
          prop: "equipmentCn",
          align: "center",
          showOverflowTooltip: true,
        },
      ],
      tableData: [],
      tableOrderData: [],
      statusList: [],
      statusNormal: ["开灯", "启动", "正常", "运行", "开", "在线"],
      statusAbnormal: ["告警", "故障", "离线", "缺水", "当前告警的通道号"],
      statusDefault: [
        "关灯",
        "关闭",
        "维修",
        "停止",
        "关",
        "时间表控制",
        "手动",
        "自动",
        "时间",
        "BA模式",
        "阿里模式",
        "夏季",
        "冬季",
        "定频",
        "变频",
        "制冷",
        "制热",
        "单机模式",
        "联机模式",
        "断线、防拆",
        "星期六",
        "星期天",
      ],
      InfoListobj: [],
    };
  },
  filters: {
    transformFinish(val) {
      return val ? "是" : "否";
    }
  },
  methods: {
    ...mapMutations("equipInfo", ["equipDetail"]),
    handleClose() {
      this.radio1 = "1";
      this.equipDetail({ ...this.equipInfo, dialogVisible: false });
    },
    matching(val) {
      if (this.statusNormal.indexOf(val) !== -1) {
        return "正常";
      }
      if (this.statusAbnormal.indexOf(val) !== -1) {
        return "异常";
      }
      if (this.statusDefault.indexOf(val) !== -1) {
        return "默认";
      }
    },
    //tabChange
    tabChangeClick(val) {
      switch (val) {
        case "1":
          this.getRunkeyStatus();
          this.getRunkeyData();
          break;
        case "2":
          this.alamRecords();
          break;
        case "3":
          this.orderRecords();
          break;
      }
    },
    getRunkeyStatus() {
      let params = {
        equipCode: this.equipInfo.equipCode,
      };
      Api.getRunkeyStatus(params)
        .then((res) => {
          this.statusList = res;
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    getRunkeyData() {
      let params = {
        equipCode: this.equipInfo.equipCode,
      };
      Api.getRunkeyData(params)
        .then((res) => {
          this.InfoListobj = res;
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    //告警记录
    alamRecords() {
      let params = {
        equipmentCode: this.equipInfo.equipCode,
        pageSize: 10000,
        pageNum: 1,
      };
      Api.alamRecords(params)
        .then((res) => {
          if (res.list && res.list.length > 0) {
            this.tableData = res.list;
          } else {
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
    async getEquipList() {
      const params = {
        "equipCode": this.equipInfo.equipCode,
        "equipStatusCodeList": [],
        "equipImprtLevelList": [],
        "srcSysCodeList": [],
        "iocEquipTypeLv3CodeList": []
      };
      const res = await Api.getEquipList(params);
      const { chuanAssetId = "" } = (res && res.list && res.list[0]) || {};
      this.chuanAssetId = chuanAssetId;
    },
    //周期性工单记录
    async orderRecords() {
      await this.getEquipList()
      if (!this.chuanAssetId) {
        this.tableOrderData = [];
        return false
      }

      let params = {
        assertId: this.chuanAssetId,
        pageSize: 10000,
        pageNum: 1,
        startTime: getDetailDay(-3),
        endTime: getDetailDay(3)
      };
      Api.orderRecords(params)
        .then((res) => {
          if (res.list && res.list.length > 0) {
            this.tableOrderData = res.list;
          } else {
            this.tableOrderData = [];
          }
        })
        .catch((err) => {
          this.$emit("on-error", err);
        });
    },
  },
  computed: {
    ...mapState("equipInfo", ["equipInfo"]),
  },
  watch: {
    equipInfo: {
      deep: true,
      immediate: true,
      handler(val) {
        this.dialogVisible = val.dialogVisible;
        if (this.dialogVisible) {
          this.getRunkeyStatus();
          this.getRunkeyData();
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
.butbor {
  span {
    border-right: none !important;
  }
}
/deep/.el-dialog__body {
    // background: none !important;
  padding: 30px 20px 0 20px !important;
  box-sizing: border-box;
  overflow: hidden;

  background: -webkit-linear-gradient(
    rgb(12, 37, 108, 0.85),
    rgb(2, 14, 39, 0.69)
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    rgb(12, 37, 108, 0.85),
    rgb(2, 14, 39, 0.69)
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    rgb(12, 37, 108, 0.85),
    rgb(2, 14, 39, 0.69)
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    rgb(12, 37, 108, 0.85),
    rgb(2, 14, 39, 0.69)
  ); /* 标准的语法（必须放在最后） */
  // background: none!important;
  opacity: 0.8 !important;
  // box-shadow: 0px 2px 21px 0px rgba(0, 11, 25, 0.69);
  border: solid 1px #0f399d;
  border-bottom: none;
  border-top: none;
  .row-bg {
    margin-bottom: 10px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 16px;
    }
    .status {
      font-size: 14px;
      display: flex;
      // justify-content: flex-end;
      .name {
        width: 90px;
        text-align: right;
        margin-right: 15px;
      }
      .on {
        display: inline-block;
        color: rgba(50, 224, 166, 0.81);
        margin-left: 15px;
        &:before {
          display: inline-block;
          content: " ";
          width: 7px;
          height: 7px;
          background-color: rgba(50, 224, 166, 0.81);
          border-radius: 50%;
          position: relative;
          left: -5px;
          top: -2px;
        }
      }
      .off {
        display: inline-block;
        color: rgba(254, 120, 120, 0.81);
        margin-left: 15px;
        &:before {
          display: inline-block;
          content: " ";
          width: 4px;
          height: 4px;
          background-color: rgba(254, 120, 120, 0.81);
          border-radius: 50%;
          position: relative;
          left: -5px;
          top: -3px;
        }
      }
    }
    .listLeft {
      display: flex;
      .name {
        width: 90px;
        text-align: right;
        margin-right: 15px;
      }
      .value {
        height: 19px;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
    .listRight {
      display: flex;
      .name {
        width: 90px;
        text-align: right;
        margin-right: 15px;
        flex-shrink: 0;
      }
      .value {
        height: 30px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    .el-radio-group {
      margin-top: 10px;
      .el-radio-button__inner {
        background: unset;
        // border: none;
        font-weight: 500;
        // border-left: 0;
        color: #a2b0cb;
        border: 1px solid #2e3859;
        border-radius: 0 0 0 0;
      }
      .is-active {
        .el-radio-button__inner {
          background: #388bff !important;
          color: #fff;
          border-left: none;
          border-radius: 0 0 0 0;
          // height: 40px;
          border-color: #388bff;
          // border-radius: 4px 0 0 4px ;
        }
      }
    }
  }
  .dataChange {
    margin: 15px 0;
    .cardTop {
      color: #a2b0cb;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      // justify-content:;
      justify-content: start;
      flex-wrap: wrap;
      .card {
        width: 30%;
        height: 70px;
        line-height: 70px;
        margin: 10px 29px 0 0;
        background-color: #2e3859;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5%;
        &:nth-child(3n) {
          margin-right: 0;
        }
        margin-bottom: 20px;
        img {
          margin-right: 5px;
          display: inline-block;
          width: 33px;
          height: 33px;
        }
        .status {
          width: 47%;
          color: #a2b0cb;
          margin-right: 10px;
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .normal {
          font-size: 20px;
          color: #41c789;
          font-weight: bold;
        }
        .abnormal {
          font-size: 20px;
          color: #fa5252;
          font-weight: bold;
        }
        .default {
          font-size: 20px;
          color: #3a7aea;
          font-weight: bold;
        }
      }
    }
    .cardBottom {
      margin-top: 20px;
      color: #a2b0cb;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      .card {
        width: 30%;
        height: 70px;
        background-color: #2e3859;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 5%;
        &:nth-child(3n) {
          margin-right: 0;
        }
        margin-bottom: 20px;
        .value {
          font-family: "BebasNeue-Regular";
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
    .el-table {
      /deep/.gutter {
        width: 0 !important;
      }
      color: #a2b0cb;
      &::before {
        background-color: transparent;
      }
      background-color: transparent;
      th.is-leaf {
        background-color: #112763;
      }
      td,
      th.is-leaf {
        border-bottom: unset;
        padding: 8px 0;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #a2b0cb;
      }
      .el-table--enable-row-hover,
      .el-table__body tr:hover > td {
        background-color: transparent;
      }
      .chuli {
        color: #3a7aea;
      }
    }
  }
}


/deep/.el-dialog{
  backdrop-filter: blur(10px);
  &::before{
    border: 1.5px solid transparent;
    border-radius: 16px;
    pointer-events: none;
    content :'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)), linear-gradient(to bottom, #05F5DF, #43ADF9);
    opacity: 0.6;
  }
}
</style>
<style>
.el-table th,
.el-table tr {
  background-color: #112763;
}
</style>
