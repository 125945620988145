
export default {
  namespaced: true,
  state: {
    imgConfig:{
      bucketName: null,
      objectName: null
    }

  },
  mutations: {
    setImg(state,obj={bucketName:null,objectName:null}) {
      state.imgConfig = obj;
    },
  },
  getters: {
    getImgUrl(state) {
      return state.imgConfig;
    },
  }
};