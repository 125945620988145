
import store from "@/store";
import comComponents from "comComponents";

let getRequest = comComponents.utils.getRequest;
export default getRequest({
  beforeRequest(config, {addPositionParams = true,}) {
    let {data = {}} = config;
    if(data.config&&data.config.isExport){
      config.responseType="blob";
    }

    let isDataObject = Object.prototype.toString.call(data) === "[object Object]";
    if (addPositionParams && isDataObject) {
      let {posCode = "", region = "", spaceModelId = ""} = store.getters["basePosition/positionInfo"];
      if (posCode && region) {
        config.data = {posCode, region, spaceModelId, ...data};
      }
    }
    store.commit("fillNowToXHRTimestamp");
    return config;
  }
});
