export default {
  executionTime: 'EN_执行总时长',
  cumulativeHappened: 'EN_累计发生',
  cumulativeProcessing: 'EN_累计处理',
  treatmentRate: 'EN_处理完成率',
  processingTimeliness: 'EN_处理及时率',
  timeoutProcessed: 'EN_超时未处理',
  processedTimeout: 'EN_超时后已处理',
  energyAIDiagnostics: 'Energy efficiency AI diagnosis',


}