<template>
  <div class="layer-btn" v-if="isShowVrComfig">
    <p class="layer-btn-icon" @click="layerOpen = !layerOpen">
      <i class="iconfont icon-xingzhuang3"></i>
    </p>
    <div class="layer-div" v-if="layerOpen">
      <div class="layer-div-triangle"></div>
      <div class="layer-calss" v-for="childs in layerList" :key="childs.id">
        <p class="layer-calss-title">{{ childs.vlookupCn }}</p>
        <div class="layer-calss-btn">
          <div
            class="calss-btn-item"
            :class="{ 'item-active': item.isCheck === 'Y' }"
            v-for="item in childs.layerSceneSubtype"
            :key="item.id"
            @click="selectLayerItem(item, childs.vlookupCode)"
          >
            {{ item.vlookupCn }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Ajax from "@/dataApi/operationReport.js";
import api from "@/utils/request.js";
import emitter from "@/mixins/emitter.js";
import mapImg from "@/utils/mapImg.js";
import polymerizationMapImg from "@/utils/polymerizationMapImg.js";

import comComponents from "comComponents";
let cryptoJS = comComponents.utils.cryptoJS;
import { mapGetters, mapMutations, mapState } from "vuex";
// const HEAT_AREA_COLOR = "Red";
const poiEventType2style = {
  event: {
    type: "Sign_Red",
    icon: "&#xe6cb;",
    iconNMColor: "#ff0000",
    iconHLColor: "#ff0000",
  },
  equip: {
    type: "Sign_ Blue",
    icon: "&#xe6d0;",
    iconNMColor: "#ffffff",
    iconHLColor: "#ffffff",
  },
  person: {
    type: "Sign_ Blue",
    icon: "&#xe6c7;",
    iconNMColor: "#ffffff",
    iconHLColor: "#ffffff",
  },
};
const cameraList = [
  "IGS_002_008",
  "IGS_002_012",
  "IGS_002_014",
  "IGS_002_017",
  "IGS_002_018",
  "IGS_002_019",
  "IGS_002_020",
  "IGS_002_021",
  "IGS_002_022",
];

export default {
  props: {
    isShowConfig: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layerList: [],
      layerOpen: false,
      cacheExistPOIObj: {}, // 缓存内置的点位
      cacheNewPOIObj: {}, // 缓存新建的点位
      cacheHeatAreaObj: [],
      okcheck: true,

      isShowVrComfig: false,
    };
  },
  computed: {
    ...mapState(["vrCameraLevel", "equipmentPOIList", "floor"]),
    ...mapGetters("basePosition", ["positionInfo"]),
  },
  created() {},
  mixins: [emitter],
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fillEquipmentPOIList([]);
        if (this.isShowConfig) {
          this.commonGetLayerFn(this.floor);
        } else {
          this.getLayerFn();
        }
      },
    },
    // 恩宁路
    vrCameraLevel() {
      let landMarkObj = this.layerList.find((item) => item.vlookupCode === "LANDMARK");
      if (landMarkObj && landMarkObj.layerSceneSubtype) {
        landMarkObj.layerSceneSubtype
          .filter((item) => item.isCheck === "Y")
          .forEach((item) => {
            this.layerDataFn(item, "LANDMARK");
          });
      }
    },
  },
  methods: {
    ...mapMutations(["fillEquipmentPOIList", "fillalarmList"]),
    ...mapMutations("security", [
      "layerSecuritList",
      "deleteLayerSecuritListItem",
      "fillLayerEventMap",
    ]),
    // 恩宁路 获取图层列表并且初始化地图状态
    async getLayerFn() {
      this.cacheExistPOIObj = {};
      this.cacheNewPOIObj = {};
      this.cacheHeatAreaObj = [];
      this.dispatch("CockpitLayout", "mapFn", "clearAll");
      let list = await api({
        url: "/ioc/safetymgmt/config/getLayerSceneSubtypeConfig",
        method: "post",
        data: {
          parentVlookupCode: this.$route.path,
          posCode: this.positionInfo.posCode,
        },
      });
      this.layerList = list;
      for (let level of list) {
        level.layerSceneSubtype.forEach((item) => {
          if (item.isCheck) {
            this.layerDataFn(item, level.vlookupCode);
          }
        });
      }
    },
    //进来初始化打点
    // 通用配置 获取通用图层列表并且初始化地图状态 floor 为vr-bim地图中的楼层
    commonGetLayerFn(floor) {
      //柯臣
      this.dispatch("CockpitLayout", "mapFn", "clearAll");
      api({
        url: "/ioc/safetymgmt/config/queryCoverageConfig",
        method: "post",
        data: {
          parentVlookupCode: this.$route.path,
          source: "1",
        },
      }).then((list) => {
        let isShow = "0";
        list.forEach((firstLevel) => {
          firstLevel.children.forEach((secondLevel) => {
            secondLevel.children.forEach((thirdLevel) => {
              if (thirdLevel.code === "IGS_002_022") {
                isShow = thirdLevel.isShow;
              }
            });
          });
        });
        window.GLOBAL_OBJ.eventHub.publish("instrumentflag", isShow);
        this.$vuePubSub.$emit("instrumentflag", isShow);
        let equipmentList = [];
        let cameraCodeList = [];
        let checkList = { equipTypeLv3Codes: [] };
        list.forEach((firstLevel) => {
          if (firstLevel.code === "EQ") {
            firstLevel.children.forEach((secondLevel) => {
              secondLevel.children.forEach((thirdLevel) => {
                if (thirdLevel.isShow === 1) {
                  checkList.equipTypeLv3Codes.push(thirdLevel.code);
                  if (thirdLevel.code === "CAR_POINT") {
                    this.commonLayerDataFn("CAR_POINT", [], true, floor);
                  } else if (thirdLevel.code === "IGS_002_022") {
                    this.commonLayerDataFn("IGS_002_022", [], true, floor);
                  } else if (cameraList.includes(thirdLevel.code)) {
                    cameraCodeList.push(thirdLevel.code);
                  } else if (thirdLevel.code === "IGS_002_025") {
                    this.commonLayerDataFn("IGS_002_025", [], true, floor,thirdLevel.code);
                  } else {
                    equipmentList.push(thirdLevel.code);
                  }
                }
              });
            });
          } else if (firstLevel.code === "PL") {
            firstLevel.children.forEach((secondLevel) => {
              secondLevel.children.forEach((thirdLevel) => {
                if (thirdLevel.isShow === 1) {
                  if (thirdLevel.code === "LSL02002_001") {
                    this.commonLayerDataFn("PERSON", [], true, floor, thirdLevel.code);
                  }
                }
              });
            });
          }
        });
        if (equipmentList.length > 0) {
          this.commonLayerDataFn("EQUIPMENT", equipmentList, false, floor);
        }
        if (cameraCodeList.length > 0) {
          this.commonLayerDataFn("EQUIPMENT", cameraCodeList, true, floor);
        }

        sessionStorage.setItem("checkList", JSON.stringify(checkList));

        //这个是高德的聚合
        if (
          sessionStorage.getItem("gisType") === "gaode" &&
          checkList.equipTypeLv3Codes.length > 0
        ) {
          this.polymerization(checkList);
        }
      });
    },
    // 恩宁路 被点击的图层标签
    selectLayerItem(item, level1Code) {
      this.setLayerSceneConfig(item);
      this.layerDataFn(item, level1Code);
    },
    // 恩宁路 图层标签开关请求
    async setLayerSceneConfig(item) {
      item.isCheck = item.isCheck === "Y" ? "N" : "Y";
      await api({
        url: "/ioc/safetymgmt/config/updateLayerSceneConfig",
        method: "post",
        data: [
          {
            isShow: item.isShow,
            isCheck: item.isCheck,
            id: item.id,
          },
        ],
      });
    },
    // 恩宁路 图层标签点击判断函数
    layerDataFn(item, level1Code) {
      let isCheckBool = item.isCheck === "Y";
      let level1Obj = {
        // 按钮是否为高亮状态 ？ 请求数据进行操作 ：删除高亮时的操作
        ALARM: isCheckBool ? this.getLayerEventList : this.hidePOI, // 告警 创建POI
        EQUIPMENT: isCheckBool ? this.setEquipmarker : this.hidePOI, // 设备
        FACILITIES: isCheckBool ? this.setEquipmarker : this.hidePOI, // 设施
        PERSON: isCheckBool ? this.setPerson : this.hidePOI, // 人员
        LANDMARK: isCheckBool ? this.getPoiId : this.hidePOI, // 地标
        CUSTOM_FLOW: this.getCustomFlowClass, // 客流
      };
      level1Obj[level1Code] && level1Obj[level1Code](item, level1Code);
    },
    // 通用配置 图层标签点击判断函数 isUnique是否有单独的接口
    commonLayerDataFn(code, list, isUnique = false, floor, code2) {
      //如果是高德展示聚合
      if (sessionStorage.getItem("gisType") !== "gaode") {
        if (isUnique) {
          switch (code) {
            case "CAR_POINT":
              // 车辆点位
              this.carPoint(code, floor);
              break;
            case "BUSINESS_INCIDENT":
              // 业务告警事件
              this.getBusinessIncident(floor);
              break;
            case "EQUIPMENT_INCIDENT":
              // 设施告警事件
              this.getEquipmentIncident(floor);
              break;
            case "PERSON":
              // 保洁人员点位
              this.getPersonPoint(code2, floor);
              break;
            case "IGS_002_022":
              //执法仪
              this.enforcement(code, floor);
              break;
            case "IGS_002_025":
              // AI巡逻车
              this.getAiPatrolCarList(code2,floor);
              break;
            default:
              // 摄像机点位
              this.getCameraPoint(list, floor);
              break;
          }
        } else {
          if (code === "EQUIPMENT") {
            // 其他设备
            this.getEquipmentPoint(list, floor);
          }
        }
      } else {
        switch (code) {
          // 保洁人员点位
          case "PERSON":
            this.getPersonPoint(code2, floor);
            break;
          case "IGS_002_022":
            this.enforcement(code, floor);
            break;
          case "IGS_002_025":
            // AI巡逻车
            this.getAiPatrolCarList(code2,floor);
            break;
        }
      }
    },
    // 通用配置 获取业务告警事件
    async getBusinessIncident(floor) {
      let rs = await this.getAlarmQuery();
      this.getAlarmPoint(rs, floor);
    },
    // 通用配置 获取设施告警事件
    getEquipmentIncident(floor) {
      let params = {
        incidentStatusCodeList: ["10051001"],
        type: "equip",
        downSpaceCodes: floor ? floor : [localStorage.lay_selectedPosCode],
      };
      Ajax.getEquipmentIncident(params).then((res) => {
        let list = res.list.filter((item) => {
          return (
            (item.latitude && item.longitude) ||
            (item.coordinateX && item.coordinateY && item.coordinateZ)
          );
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            latitude: item.latitude,
            longitude: item.longitude,
            title: item.incidentTitle,
            locationPathFullCn: item.locationPathFullCn,
            buttonText: "查看详情",
            incidentNumber: item.incidentNumber,
            levelEquipCode: item.iocEquipTypeLv3Code,
            equipCode: item.equipCode,
            downSpaceCode: item.downSpaceCode,
            equipmentCode: item.equipmentCode,
          };
          item.eventType = "otherEquipAbnormalPop";
          item.image = mapImg[`${item.eqpType3Code}-alarm`] || mapImg["SAFETY_INCIDENT"];
          item.zIndex = 200;
        });

        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
    // 恩宁路 获取告警事件
    async getLayerEventList(obj) {
      let list = await api({
        url: "/ioc/safetymgmt/layer/data/getLayerIncidentData",
        method: "post",
        data: {
          subtypeCode: obj.vlookupCode,
          statusCode: [10, 20, 30],
        },
      });
      this.layerSecuritList(list);
      this.fillLayerEventMap({ type: obj.vlookupCode, list });
      let style = poiEventType2style["event"];
      this.createPOIMulti(obj.vlookupCode, list, style, {
        eventType: "securityPopup",
        typeCode: obj.vlookupCode,
      });
    },
    // 恩宁路 删除poi
    hidePOI(arg) {
      let code = typeof arg === "object" ? arg.vlookupCode : arg;
      if (code in this.cacheExistPOIObj) {
        this.cacheExistPOIObj[code].forEach((id) => {
          this.dispatch("CockpitLayout", "mapFn", "showPOI", id, "false");
        });
        delete this.cacheExistPOIObj[code];
      } else if (code in this.cacheNewPOIObj) {
        this.cacheNewPOIObj[code].forEach((id) => {
          this.dispatch("CockpitLayout", "mapFn", "deletePOI", id);
        });
        delete this.cacheNewPOIObj[code];
      }
    },
    // 恩宁路 客流小类
    getCustomFlowClass(item) {
      let isCheckBool = item.isCheck === "Y";
      let level2Obj = {
        REGION_CUSTOM_FLOW: isCheckBool ? this.getRegionLineation : this.deleteRegionLineation, //  区域客流
      };
      level2Obj[item.vlookupCode] && level2Obj[item.vlookupCode](item);
    },
    // 恩宁路 设施及设备
    setEquipmarker(level2, level1Code) {
      level2.description === "POI" ? this.getPoiId(level2, level1Code) : this.getLayerEquip(level2);
    },
    // 通用配置 获取车辆打点信息
    carPoint(code) {
      Ajax.getVehcleInfo({}).then((res) => {
        let list = res.filter((item) => {
          return item.longitude && item.latitude;
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            noPoup: false,
            buttonText: "车辆轨迹",
            type: item.type,
            plateNumber: item.plateNumber,
            latitude: item.latitude,
            longitude: item.longitude,
            title: `${item.type} ${item.plateNumber}`,
          };
          item.eventType = "carTrialPop";
          item.image = mapImg[code];
        });
        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
    // 通用配置 获取摄像机打点信息
    getCameraPoint(list, floor) {
      let params = {
        equipTypeLv3Codes: list,
        downSpaceCodes: floor.length ? floor : null,
      };
      Ajax.getEquipmentPoint(params).then((res) => {
        let list = res.filter((item) => {
          return (
            (item.latitude && item.longitude) ||
            (item.coordinateX && item.coordinateY && item.coordinateZ)
          );
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            buttonText: "查看摄像机",
            latitude: item.latitude,
            longitude: item.longitude,
            title: item.equipCn,
            address: item.locationFullPathCn,
            cameraName: item.positionId,
            equipCode: item.equipCode,
          };
          item.eventType = "bimmarkesAlarm";
          if (item.alarmNums !== 0) {
            item.image =
              polymerizationMapImg[item.iocEquipTypeLv3Code + "_alarm"] ||
              "/security-gis/static/polymerizationMapImg/tongyong-alarm.svg";
          } else if (item.alarmNums === 0) {
            if (item.equipStatusCode === "1") {
              item.image =
                polymerizationMapImg[item.iocEquipTypeLv3Code] ||
                "/security-gis/static/polymerizationMapImg/tongyong.svg";
            } else {
              item.image =
                polymerizationMapImg[item.iocEquipTypeLv3Code + "_off_line"] ||
                "/security-gis/static/polymerizationMapImg/tongyong-off-line.svg";
            }
          }
        });
        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
    // 恩宁路 获取人员数据
    async setPerson(level2) {
      let list = await api({
        url: "/ioc/safetymgmt/atomicperson/query/location/by",
        method: "post",
        data: {
          roleType: level2.vlookupCode,
        },
      });
      let style = poiEventType2style["person"];
      this.createPOIMulti(level2.vlookupCode, list, style);
    },
    // 恩宁路 批量新建POI
    createPOIMulti(code, list, style, note = {}) {
      this.hidePOI(code);
      if (list && list.length > 0) {
        let poiObjList = [];
        list.forEach((item) => {
          if (item.longitude && item.latitude) {
            poiObjList.push({
              POIID: item.deviceNumber || item.incidentNumber || item.equipCode || item.personId,
              lonLat: `${item.longitude}, ${item.latitude}`,
              content: item.subtypeNameCn,
              style,
              note: JSON.stringify(note),
            });
          }
        });
        this.cacheNewPOIObj[code] = poiObjList.map(({ POIID }) => POIID);
        poiObjList.forEach((poiObj) => {
          this.dispatch("CockpitLayout", "mapFn", "createPOI", poiObj);
        });
      }
    },
    // 恩宁路 获取poiid
    async getPoiId(level2, level1Code) {
      this.hidePOI(level2.vlookupCode);
      let list =
        (await api({
          url: "/ioc/safetymgmt/layer/data/getLayerPositionData",
          method: "post",
          data: {
            typeCode: level2.vlookupCode,
            region: this.positionInfo.region,
            posCode: this.positionInfo.posCode,
            level: this.vrCameraLevel,
            layerGroupCode: level1Code,
          },
        })) || [];

      if (level1Code === "EQUIPMENT") {
        let _list = this.equipmentPOIList.concat(list);
        this.fillEquipmentPOIList(_list);
      }
      let poiIds = list.map(({ poiId }) => poiId);
      if (poiIds.length > 0) {
        this.cacheExistPOIObj[level2.vlookupCode] = poiIds;
        this.dispatch("CockpitLayout", "mapFn", "showPOIMulti", poiIds);
      }
    },
    // 恩宁路 获取设备数据
    async getLayerEquip(level2) {
      let list =
        (await api({
          url: "/ioc/safetymgmt/atomicequipment/list/withIncident",
          method: "post",
          data: {
            equipTypeCodes: [level2.vlookupCode],
            equipStatusCode: 1,
          },
        })) || [];

      let style = poiEventType2style["equip"];
      this.createPOIMulti(level2.vlookupCode, list, style);
    },
    // 恩宁路 新建POI的参数转化
    markersDataFn(list, level2, style, tag) {
      list = list.map((item) => {
        let tag1 = {};
        if (tag) {
          for (let key in tag) {
            tag1[key] = item[tag[key]] || tag[key];
          }
        }
        return {
          equipCode: item.deviceNumber || item.equipCode || item.personId,
          position: [item.latitude, item.longitude],
          titleCn: level2.vlookupCn,
          style: style,
          tag: tag ? tag1 : { noPoup: true },
        };
      });
      let markerObj = {
        layerId: level2.vlookupCode,
        layerIndex: 2100,
        markers: list,
      };
      return markerObj;
    },
    // 恩宁路 区域热力
    async getRegionLineation() {
      // let list = await api({
      //   url: "/datacenter/api/datacenter/V4.0/getRegionLineationPersonNum",
      //   method: "post",
      //   data: {
      //     posCode: "CN_SC_44_001_PARK000007",
      //   },
      // });
      // this.cacheHeatAreaObj = list.map((item) => {
      //   let HeatAreaObj = {
      //     areaID: item.bim_area,
      //     areaColor: HEAT_AREA_COLOR,
      //     state: "true",
      //   };
      // });
    },
    // 恩宁路 删除区域热力
    deleteRegionLineation() {
      this.cacheHeatAreaObj.forEach((item) => {
        item.state = "false";
        this.dispatch("CockpitLayout", "mapFn", "showHeatArea", item);
      });
      this.cacheHeatAreaObj = [];
    },
    // 通用配置 获取设备打点信息集合
    getEquipmentPoint(equipmentList, floor) {
      let params = {
        equipTypeLv3Codes: equipmentList,
        downSpaceCodes: floor.length ? floor : null,
      };
      Ajax.getEquipmentPoint(params).then((res) => {
        let list = res.filter((item) => {
          return (
            (item.latitude && item.longitude) ||
            (item.coordinateX && item.coordinateY && item.coordinateZ)
          );
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            latitude: item.latitude,
            longitude: item.longitude,
            title: item.equipCn,
            address: item.locationFullPathCn,
            levelEquipCode: item.iocEquipTypeLv3Code,
            equipCode: item.equipCode,
            downSpaceCode: item.downSpaceCode,
            levelEquipName: item.iocEquipTypeLv3Cn,
          };
          item.eventType = "bimmarkesAlarm";
          if (item.alarmNums !== 0) {
            item.image =
              polymerizationMapImg[item.iocEquipTypeLv3Code + "_alarm"] ||
              "/security-gis/static/polymerizationMapImg/tongyong-alarm.svg";
          } else if (item.alarmNums === 0) {
            if (item.equipStatusCode === "1") {
              item.image =
                polymerizationMapImg[item.iocEquipTypeLv3Code] ||
                "/security-gis/static/polymerizationMapImg/tongyong.svg";
            } else {
              item.image =
                polymerizationMapImg[item.iocEquipTypeLv3Code + "_off_line"] ||
                "/security-gis/static/polymerizationMapImg/tongyong-off-line.svg";
            }
          }
        });

        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
    // 通用配置 获取告警可选择类型
    getAlarmQuery() {
      let phone = cryptoJS.decrypt(
        JSON.parse(sessionStorage.getItem("GLOBAL_IOC")).userInfo.phoneEnc
      );
      let params = {
        phone: phone,
        vlookupTypeCode: "SAF_INCIDENT_TYPE",
      };
      return new Promise((resolve, reject) => {
        Ajax.getAlarmQuery(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 通用配置 获取告警打点信息集合
    getAlarmPoint(list, floor) {
      let typeCode = list.length ? list.join(",") : null;
      let params = {
        levelCode: "SERIOUS,HIGH",
        statusCode: "10,20,30",
        typeCode: typeCode,
        pageNumber: 1,
        pageSize: 500,
        downSpaceCodes: floor ? floor : [localStorage.lay_selectedPosCode],
      };
      api({
        url: "/ioc/safetymgmt/incident/getIncidentList",
        method: "GET",
        data: params,
      }).then((res) => {
        let list = res.list.filter((item) => {
          return (item.latitude && item.longitude) || (item.px && item.py && item.pz);
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            latitude: item.latitude,
            longitude: item.longitude,
            title: item.incidentTitle,
            locationPathFullCn: item.locationPathFullCn,
            downSpaceCode: item.parentDownSpaceCode,
          };
          item.coordinateX = item.px;
          item.coordinateY = item.py;
          item.coordinateZ = item.pz;
          item.eventType = "alarmPop";
          item.image = mapImg[item.typeCode] || mapImg["SAFETY_INCIDENT"];
          item.zIndex = 200;
        });
        this.fillalarmList(list);

        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
    // 通用配置 获取执法仪打点信息集合
    enforcement(code, floor) {
      Ajax.enforcement({
        equipType: code,
        parkCode: localStorage.getItem("lay_selectedPosCode"),
        downSpaceCodes: floor.length > 0 ? encodeURI(floor) : "",
      })
        .then((res) => {
          window.GLOBAL_OBJ.eventHub.publish("deviceList", res);
          this.$vuePubSub.$emit("deviceList", res);
          let list = res.filter((item) => {
            return (
              (item.latitude && item.longitude) ||
              (item.coordinateX && item.coordinateY && item.coordinateZ)
            );
          });
          list.forEach((item) => {
            item.position = [item.latitude, item.longitude];
            item.tag = {
              latitude: item.latitude,
              longitude: item.longitude,
              did: item.did,
              networkStatus: item.networkStatus
            };
            item.eventType = "instrument";
            item.image =
              item.networkStatus === 1
                ? mapImg[code]
                : "/security-gis/static/imagesMap/a-Law-unline.svg";
          });
          let marker = {
            markers: list,
          };
          if (list.length > 0) {
            this.$emit("addMarker", marker, "instrument");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 通用配置 获取高德地图聚合点位
    polymerization(data) {
      Ajax.polymerization({
        parkCode: this.positionInfo.posCode,
        ...data,
      })
        .then((res) => {
          let list = [];
          list = res.filter((item) => {
            return item.centerLatitude && item.centerLongitude;
          });
          let listLength = 0;
          list.forEach((item, prantIndex) => {
            item.position = [item.centerLatitude, item.centerLongitude];
            item.tag = {
              latitude: item.centerLatitude,
              longitude: item.centerLongitude,
              equmentNums: item.equmentNums,
            };
            item.eventType = "polymerization";

            //如果有多个一级分类
            if (!item.equipTypeLv1Code) {
              //在判断是否有告警
              if (item.alarmNums > 0) {
                item.image = "/security-gis/static/polymerizationMapImg/juhe-alarm.svg";
                //展示聚合告警图标
              } else {
                //展示聚合图标
                item.image = "/security-gis/static/polymerizationMapImg/juhe.svg";
              }
              listLength++;
            } else {
              //只有一个一级分类，一个三级的时候
              if (item.equipTypeLv3Code) {
                // 有告警
                if (item.alarmNums > 0) {
                  if (polymerizationMapImg[item.equipTypeLv3Code]) {
                    item.image = polymerizationMapImg[item.equipTypeLv3Code + "_alarm"];
                  } else {
                    item.image = "/security-gis/static/polymerizationMapImg/tongyong-alarm.svg";
                  }
                  listLength++;
                } else {
                  //请求得到设备编码 调接口看是否在线
                  api(
                    {
                      url: "/ioc/equipmentmgmt/polymerization/getEquipmentList",
                      method: "post",
                      data: {
                        equipTypeCode: item.equipTypeLv1Code,
                        centerLatitude: item.centerLatitude,
                        centerLongitude: item.centerLongitude,
                        equipTypeLv3Codes: data.equipTypeLv3Codes,
                      },
                    },
                    { handleErr: false }
                  )
                    .then((res1) => {
                      if (res1.data.length) {
                        let errnum = "0";
                        res1.data.some((d) => {
                          //停用不显示
                          if (d.equipStatusCode === "1") {
                            if (polymerizationMapImg[item.equipTypeLv3Code]) {
                              item.image = polymerizationMapImg[item.equipTypeLv3Code];
                            } else {
                              item.image = "/security-gis/static/polymerizationMapImg/tongyong.svg";
                            }
                            errnum = "1";
                            return true;
                          } else if (
                            d.equipStatusCode === "0" ||
                            d.equipStatusCode === "2" ||
                            d.equipStatusCode === "3"
                          ) {
                            if (polymerizationMapImg[item.equipTypeLv3Code]) {
                              item.image =
                                polymerizationMapImg[item.equipTypeLv3Code + "_off_line"];
                            } else {
                              item.image =
                                "/security-gis/static/polymerizationMapImg/tongyong-off-line.svg";
                            }
                            errnum = "2";
                          }
                        });
                        listLength++;
                        if (errnum === "0") {
                          //如果是停用删除点位
                          if (list.length > 0) {
                            list.splice(prantIndex, 1);
                            this.listLength = this.listLength - 1;
                          }
                        }
                      } else {
                        listLength++;
                      }
                    })
                    .catch(() => {
                      listLength++;
                    });
                }
              }

              //只有一个一级分类，多个三级
              else {
                // 有告警
                if (item.alarmNums > 0) {
                  item.image = "/security-gis/static/polymerizationMapImg/juhe-alarm.svg";
                } else {
                  item.image = "/security-gis/static/polymerizationMapImg/juhe.svg";
                }
                listLength++;
              }
            }
          });

          this.timemarkers(list, listLength);

          var settime = setInterval(() => {
            var okcheck = true;
            if (list.length > listLength) {
              okcheck = false;
            }
            // list.forEach((item) => {
            //   if (!item.image) {
            //     okcheck = false;
            //     return;
            //   }
            // });
            if (okcheck) {
              clearInterval(settime);
              let marker = {
                markers: list,
              };
              if (list.length > 0) {
                this.$emit("addMarker", marker, "polymerization");
              }
            }
          }, 500);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    timemarkers(list, listLength) {
      var settime = setInterval(() => {
        let okcheck = true;
        if (list.length > listLength) {
          okcheck = false;
        }
        if (okcheck) {
          clearInterval(settime);

          Array.from(new Set(list));
          let marker = {
            markers: list,
          };
          if (list.length > 0) {
            // this.$emit("clearAll");
            this.$emit("addMarker", marker, "polymerization");
          }
        }
      }, 500);
    },
    // 通用配置 获取保洁人员打点信息集合
    getPersonPoint(code, floor) {
      Ajax.getPersonPoint({
        downSpaceCodes: floor ? floor : [localStorage.lay_selectedPosCode],
      }).then((res) => {
        let list = res.filter((item) => {
          return (
            (item.latitude && item.longitude) ||
            (item.coordinateX && item.coordinateY && item.coordinateZ)
          );
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            latitude: item.latitude || '',
            longitude: item.longitude || '',
            title: item.personName || '',
            phone: item.personPhone || '',
            time: item.time || '',
            address: item.locationCn || '',
            personId: item.personId || '',
            postTypeName: item.postTypeName || '保洁员',
            postTypeId: item.postTypeId || '',
            floor: floor ? floor.join(",") : "",
          };
          item.eventType = "personPop";
          item.image = mapImg[`CLEANING${item.postTypeId}`] || mapImg[code] || mapImg["SECURITY_STAFF"];
        });

        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
    // 通用配置 获取AI巡逻车打点信息集合
    getAiPatrolCarList(code,floor) {
      Ajax.getAiPatrolCarList({}).then((res) => {
        let list = res.filter((item) => {
          return (
            (item.latitude && item.longitude) ||
            (item.coordinateX && item.coordinateY && item.coordinateZ)
          );
        });
        list.forEach((item) => {
          item.position = [item.latitude, item.longitude];
          item.tag = {
            latitude: item.latitude,
            longitude: item.longitude,
            title: item.equipCn,                    // 巡逻车名称
            equipmentCode: item.equipId,            // 巡逻车设备code
            equipStatusCode: item.equipStatusCode,  // 巡逻车状态 0离线 1在线
            address: item.locationFullPathCn,       // 当前位置
            speed: item.speed,                      // 当前速度
            mileage: item.mileage,                  // 当前里程
            time: item.receiveTime,                 // 最新更新时间
            floor: floor ? floor.join(",") : "",    // BIM中当前楼层
          };
          item.eventType = "carPop";
          item.image = mapImg[code] || mapImg["IGS_002_025"];
        });

        let marker = {
          markers: list,
        };
        if (list.length > 0) {
          this.$emit("addMarker", marker);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
