import Router from 'vue-router'
import routes from './routes'
import store from '@/store'
window.GLOBAL_OBJ.eventHub.publish("route-detect", {
  base: '/overview/',
  routes
});

const router = new Router({
  mode: 'history',
  base: '/overview/',
  routes
})
router.afterEach((to) =>{
  if(to.path.indexOf('/show') === -1 && store.state.fullScreen){
    document.body.className = document.body.className.replace(/\s*(full-screen)/g, "");
    store.commit('fillFullScreen', false)
  }
})
/**
* 在路由切换之前检测来中断上个页面的请求
*/
router.beforeEach((to, from, next) => { 
  clearHttpRequestingList();
  next();
});

// 清空cancelToken中的cancel函数
function clearHttpRequestingList() {
  // 路由切换检测是否强行中断， 强行中断时才向下执行
  if (Vue.$httpRequestList.length > 0) {
    Vue.$httpRequestList.forEach((item) => {
      // 给个标志，中断请求
      item('interrupt');
    })
    Vue.$httpRequestList = [];
  }
}

export default router