export default {
  mapType: "",
  mapList: [],
  userInfo: {},
  btnAuthObj: {},
  fullScreen: false,
  corporationVO: {},
  timeFlowOpen: false, // 时间流动
  overviewTabs: null, // 综合态势底部tabs
  validCardList: null, // 勾选卡片的集合
  chosenCardsPerPage: [], // 每一页勾选的卡片
  businessCodePerPage: "",
  vrmapParadeName: "",
  vrCameraLevel: "",
  equipmentPOIList: [],
  carTrialObj: {},
  isShowCarTrial: false, // 是否显示车辆轨迹
  popShow: false,
  videoObj: {},
  workShow: false, // 工单派发弹框是否显示
  proprtyworker: {},
  alarmList: [], // 所有告警列表
  commonTrialObj: {}, // 通用轨迹
  isShowCommonTrial: false, // 是否显示通用轨迹
  downSpaceCode: "", // 当前楼层
  workerInfodata: {}, //设施事件点击的对象
  isShowAlarmDetail: false, // 时候弹出业务告警弹框
  alarmDetail: {}, // 业务告警详情
  floor: [], // 当前楼层
  reloadEchart: false,
  isInFloor: false,
  eventCenter: "service",
  graphData: {},
};
