import cloneDeep from 'lodash/cloneDeep'

export default {
  //第二步
  // 有效的tab页和对应的卡片
  tabsAndCardsList(state) {
    if (!state.overviewTabs || !state.validCardList) return [];
    let overviewTabs = cloneDeep(state.overviewTabs).filter(({
        notTabPage
      }) => !notTabPage) //前端写死卡片过滤出notTabPage为true 或者 path等于overview/show下面的所有子路由
      let validCardList = cloneDeep(state.validCardList) //接口得到的所有的卡片列表
    for (let {
        businessCode = '', allCardList = []
      } of overviewTabs) { //overviewTabs 前端匹配的
      let target = validCardList.find(item => {
        return item.businessCode === businessCode
      }) //接口得到的businessCode 去匹配前端写死的 businessCode
      if (target) {
        let chosenCardArr = target.list || [];
        let len = allCardList.length,
          i = 0;
        allCardList.forEach(cardItem => {
          let thatIndex = chosenCardArr.findIndex(chosenCard => chosenCard.statisticalCardCode === cardItem.statisticalCardCode)
          if (thatIndex > -1) {
            cardItem.isChecked = true
            cardItem.sortIndex = thatIndex
          } else {
            i++
            cardItem.isChecked = false
            cardItem.sortIndex = 99
          }
        })
        allCardList.sort((a, b) => a.sortIndex - b.sortIndex)
        // 如何tabs页选中卡片为空，默认让该页全选
        if (i === len) {
          allCardList.forEach((item, index) => {
            if (index < 8) {
              item.isChecked = true
            }
          })
        }

      } else {
        for (let cardItem of allCardList) {
          cardItem.isChecked = false
        }
      }
    }

    return overviewTabs; //前端写死卡片过滤出notTabPage为true 或者 path等于overview/show下面notTabPage为true的所有子路由
  },
  globalChosenCardAuthKeys({
    validCardList
  }) {
    if (!validCardList) return []
    // eslint-disable-next-line
    if (isDanTianPark && isDanTianPark(localStorage.getItem("lay_selectedPosCode"))) {
      let {
        list = []
      } = validCardList.find(({
        businessCode
      }) => businessCode === 'CARD_SITUATION_DT') || {}
      return list.map(({
        statisticalCardCode
      }) => statisticalCardCode)
    } else {
      let {
        list = []
      } = validCardList.find(({
        businessCode
      }) => businessCode === 'CARD_SITUATION') || {}
      return list.map(({
        statisticalCardCode
      }) => statisticalCardCode)
    }

  },

  // 有效的tab页和对应的卡片
  tabs2CardsList(state, getters) {
    const businessCodeOfhasDefaultCards = ['CARD_SITUATION_2D', 'CARD_SITUATION', 'CARD_SITUATION_DT'];
    if (!state.overviewTabs || !state.validCardList || getters.bigAllCardList.length === 0) return [];
    let overviewTabs = cloneDeep(state.overviewTabs),
      validCardList = cloneDeep(state.validCardList)
    let newTarget = []
    for (let {
        businessCode = '', businessName = '', list = []
      } of validCardList) {
      let target = overviewTabs.find(item => item.businessCode === businessCode)
      if (target) {
        let obj = {
          businessCode,
          businessName,
          path: target.path,
          list: []
        }
        let newList = []
        if (list.length === 0) {
          if (businessCodeOfhasDefaultCards.includes(businessCode)) {
            newList = overviewTabs.find(item => !businessCodeOfhasDefaultCards.includes(item.businessCode)).allCardList
          } else {
            newList = target.allCardList
          }
        } else {
          for (let {
              statisticalCardCode
            } of list) {
            let validCard = getters.bigAllCardList.find(item => item.statisticalCardCode === statisticalCardCode)
            validCard && newList.push(validCard)
          }
        }

        obj.list = newList
        newTarget.push(obj)
      }
    }

    return newTarget;
  },

  bigAllCardList(state) {
    if (!state.overviewTabs) return [];
    let list = [];
    for (let {
        allCardList = []
      } of state.overviewTabs) {
      allCardList.forEach(item => {
        list.push(item)
      })
    }
    return list;
  }
}