<template>
  <div class="dark page-wrapper">
    <FullscreenHeader v-if="$store.state.fullScreen" />

    <div class="h-100" id="cockpit-page-container">
      <topToolBar :isShowConfig="isShowConfig"></topToolBar>
      <!-- 地图 -->
      <ioc-map
        v-if="isRenderMap"
        class="ioc-map"
        ref="mapContainer"
        v-bind="statisMapOptions"
        @loadMap="loadMap"
        @markerClick="markerClick"
        @floorClick="floorClick"
        @builtInPOI="builtInPOI"
        @streetscape="streetscape"
        @cameraLevelChange="fillVrCameraLevel"
        @stateInit="mapStateInit"
        @createPath="createPath"
        @trackPlayback="trackPlayback"
        @getFloorPoint="getFloorPoint"
        @changeFloor="changeFloor"
        :isShowConfig="isShowConfig"
        @changeIsInFloor="changeIsInFloor"
      ></ioc-map>
      <!-- 地图结束 -->
      <LayerSelect
        ref="layerSelect"
        v-if="isMapLoad"
        v-show="!$store.state.fullScreen"
        @addMarker="addMarker"
        @clearAll="clearAll"
        :isShowConfig="isShowConfig"
      />
      <div class="videoArea no-show-in-time-flow" v-if="videoPlay.showStatus">
        <!-- 视频组件 -->
        <ioc-video
          class="default-camera"
          :cameraCode="videoPlay.cameraCode"
          :videoParams="videoParams"
        ></ioc-video>
      </div>
      <span
        class="no-show-in-time-flow"
        v-if="canRenderCards && !isShowCarTrial && !isShowCommonTrial"
      >
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            @addMarker="addMarker"
            ref="ocxFive"
          ></router-view>
        </keep-alive>
        <router-view
          v-if="!$route.meta.keepAlive"
          @addMarker="addMarker"
          ref="ocxFive"
        ></router-view>
        <!-- 导航 -->
        <footer-nav
          v-if="!isShow2DModel && !isShowCarTrial"
          :menuList="overviewTabs"
          @setCenter="setCenter"
        />
      </span>
      <timeFlow
        class="time-flow-container"
        v-if="timeFlowOpen"
        @timeChange="timeChange"
        @exitTimeFlowPlay="exitTimeFlowPlay"
      ></timeFlow>
      <streetscapeAndVideoMix class="time-flow-container" v-if="isStreetscapeAndVideoMix" />
      <!-- 车辆轨迹 -->
      <carTrial
        v-if="isShowCarTrial"
        @closeTrial="closeTrial"
        @createPath="createPath"
        @clearAll="clearAll"
        @closePopup="closePopup"
        @trackPlayback="trackPlayback"
        @createMap="createMap"
        @destoryMap="destoryMap"
        @pauseAnimation="pauseAnimation"
        :isShowCarTrial="isShowCarTrial"
        :carTrialObj="carTrialObj"
      ></carTrial>
      <!-- 车辆轨迹结束 -->
      <!-- 工单派发 -->
      <el-dialog
        class="g-dialog"
        title="工单派发"
        :visible.sync="workShow"
        width="40%"
        append-to-body
        :modal-append-to-body="true"
        :before-close="workClose"
      >
        <proprtyWork
          ref="proprtyWorks"
          :eventInfo="eventInfo"
          :incidentType="incidentType"
          :incidentDocuments="incidentDocuments"
        ></proprtyWork>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelWorkSend">取 消</el-button>
          <el-button type="primary" @click="confirmWorkSend">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 工单派发结束 -->
      <!-- 设备详情 -->
      <equipDetail />
      <!-- 设备详情结束 -->
      <!-- 设备详情异常 -->
      <equipAbnormalDetail />
      <!-- 设备详情异常结束 -->
      <!-- 业务告警详情 -->
      <alarmDetailPop v-if="isShowAlarmDetail" />
      <!-- 业务告警详情结束 -->
      <!-- 其他通用轨迹 -->
      <commonTrial
        v-if="isShowCommonTrial"
        @closeCommonTrial="closeCommonTrial"
        @createPath="createPath"
        @clearAll="clearAll"
        @trackPlayback="trackPlayback"
        @pauseAnimation="pauseAnimation"
        :isShowCommonTrial="isShowCommonTrial"
        :commonTrialObj="commonTrialObj"
      ></commonTrial>
      <!-- 其他通用轨迹结束 -->
      <!-- 执法仪图标及列表开始 -->
      <template>
        <img
          v-if="deviceList.length > 0 && $route.path === '/show/global' && insflag"
          class="instrumenticon"
          @click="imgclick"
          :src="instrumentFlase ? require('./img/zhifayi2.png') : require('./img/zhifayi.png')"
          alt="执法仪"
        />
        <div class="instrument" v-if="instrumentFlase">
          <instrument @close="close" :deviceList="deviceList"></instrument>
        </div>
      </template>
      <!-- 执法仪图标及列表结束 -->
      <!-- 保洁人员列表开始 -->
      <template>
        <img
          v-if="onDutyPersonnelDevicelList.length > 0 && $route.path === '/show/global'"
          class="onDutyPersonnelImg"
          @click="handleOnDutyPersonnel"
          :src="
            onDutyPersonnelFlag
              ? require('./img/zaigangrenyuan_active.png')
              : require('./img/zaigangrenyuan.png')
          "
          alt="保洁人员"
        />
        <div class="onDutyPersonnel" v-if="onDutyPersonnelFlag">
          <onDutyPersonnel
            @close="handleOnDutyPersonnelClose"
            :onDutyPersonnelDevicelList="onDutyPersonnelDevicelList"
          ></onDutyPersonnel>
        </div>
      </template>
      <!-- 保洁人员列表结束 -->
    </div>
  </div>
</template>

<script>
import LayerSelect from "./components/LayerSelect";
import FullscreenHeader from "./fullscreen-header";
import footerNav from "./footer-nav";
import timeFlow from "@/components/timeFlow/timeFlow";
import streetscapeAndVideoMix from "@/components/timeFlow/streetscapeAndVideoMix";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import emitter from "@/mixins/emitter";
import securityEvenetPopup from "../components/securityEvenetPopup"; // 事件详情头弹窗
import carTrialPop from "../components/carTrialPop"; // 车辆详情弹框
import videoPop from "@/components/commonMarker/videoPop"; // 车辆详情弹框
import otherEquipPop from "@/components/commonMarker/otherEquipPop"; // 消防栓其他设备弹框
import equipDetail from "@/components/commonMarker/equipDetail"; // 消防栓其他设备弹框详情
import otherEquipAbnormalPop from "@/components/commonMarker/otherEquipAbnormalPop"; // 消防栓其他设备异常弹框
import equipAbnormalDetail from "@/components/commonMarker/equipAbnormalDetail"; // 消防栓其他设备弹框详情
import alarmDetailPop from "@/components/commonMarker/alarmDetailPop"; // 业务告警详情
import personPop from "@/components/commonMarker/personPop"; // 保洁人员弹框
import carPop from "@/components/commonMarker/carPop"; // AI巡逻车弹框
import alarmPop from "@/components/commonMarker/alarmPop"; // 告警弹框
import proprtyWork from "@/components/commonMarker/proprtyWork"; // 告警弹框
import enforcement from "@/components/commonMarker/enforcement"; // 执法仪弹框
import markesarrs from "@/components/commonMarker/markesarrs"; // 聚合弹窗
import bimmarkesAlarm from "@/components/commonMarker/bimmarkesAlarm"; //
import mapInit from "@/mixins/mapInit.js";
import overviewTabArr from "@/config/overview-tabs-cards.js";
import topToolBar from "@/components/topToolBar";
import carTrial from "@/components/carTrial";
import commonTrial from "@/components/commonMarker/commonTrial";
import instrument from "./components/instrument";
import onDutyPersonnel from "./components/onDutyPersonnel";
import Ajax from "@/dataApi/operationReport.js";
export default {
  name: "CockpitLayout",
  mixins: [emitter, mapInit],
  components: {
    footerNav,
    timeFlow,
    FullscreenHeader,
    streetscapeAndVideoMix,
    LayerSelect,
    topToolBar,
    carTrial,
    proprtyWork,
    equipDetail,
    equipAbnormalDetail,
    commonTrial,
    alarmDetailPop,
    instrument,
    onDutyPersonnel,
  },
  data() {
    return {
      isMapLoad: false, // 地图是否加载
      videoPlay: {
        showStatus: false,
        cameraName: "",
        incidentNumber: "",
        cameraCode: "",
      },
      canRenderCards: false,
      path: "",
      builtInData: {},
      isStreetscapeAndVideoMix: false,
      eventInfo: {},
      incidentType: "SAFETY",
      incidentDocuments: [],
      isShowConfig: true,
      videoParams: {
        urlparams: {},
      },
      onDutyPersonnelFlag: false,
      onDutyPersonnelDevicelList: [],
      instrumentFlase: false,
      deviceList: [],
      insflag: false,
    };
  },
  computed: {
    ...mapState([
      "userInfo",
      "btnAuthObj",
      "timeFlowOpen",
      "overviewTabs",
      "validCardList",
      "equipmentPOIList",
      "isShowCarTrial",
      "carTrialObj",
      "popShow",
      "videoObj",
      "workShow",
      "isShowCommonTrial",
      "commonTrialObj",
      "workerInfodata",
      "downSpaceCode",
      "isShowAlarmDetail",
      "floor",
    ]),
    ...mapGetters("basePosition", ["positionInfo"]),
    ...mapGetters(["tabsAndCardsList"]),
    isShow2DModel() {
      let routeType = this.$route.query.mapType;
      let routePath = this.$route.path;
      return (
        (routeType === "2D" || sessionStorage.getItem("gisType") === "2D") &&
        routePath === "/show/global"
      );
    },
    statisMapOptions() {
      let obj = this.mapOptions;
      let rPath = this.$route.path;
      let rMapType = this.$route.query.mapType;
      if (
        rPath === "/show/global" &&
        (rMapType === "2D" || sessionStorage.getItem("gisType") === "2D")
      ) {
        obj.isMapPattern = false;
        // obj.show2DImg = false;
        this.mapFn("getShowWhitchImg", rPath);
      } else {
        obj.isMapPattern = true;
        // obj.show2DImg = true;
        this.mapFn("getShowWhitchImg", rPath);
      }
      obj.selectBuilding = false;
      return obj;
    },
    is2DPage() {
      return (
        this.$route.path.indexOf("/show/global") === 0 &&
        (this.$route.query.mapType === "2D" || sessionStorage.getItem("gisType") === "2D")
      );
    },
  },
  watch: {
    tabsAndCardsList() {
      if (this.$route.fullPath !== "/overview/largeDataScreen") {
        this.getChosenCards();
      }
    },
    $route: {
      immediate: true,
      handler(newPath) {
        this.getChosenCards();
        this.path = this.$route.path;
        this.mapFn("getShowWhitchImg", newPath.path);
      },
    },
    popShow: {
      handler(newPopShow) {
        if (!newPopShow) {
          this.closePopup();
        }
      },
    },
    videoObj: {
      immediate: true,
      handler(newObj) {
        this.videoPlay.showStatus = newObj.showStatus;
        this.videoPlay.cameraName = newObj.cameraName;
        this.videoPlay.incidentNumber = newObj.incidentNumber;
        this.videoPlay.cameraCode = newObj.cameraCode;
      },
    },
    downSpaceCode: {
      handler(newVal) {
        if (newVal) {
          this.mapFn("changeFloor", newVal);
        }
      },
    },
    workShow: {
      immediate: true,
      handler(v, newPath) {
        if (v) {
          this.eventInfo = this.workerInfodata;
          if (newPath.fullPath !== "/largeDataScreen") {
            this.getChosenCards();
            this.path = this.$route.path;
            this.mapFn("getShowWhitchImg", newPath.path);
          }
        }
      },
    },
  },
  created() {
    this.getOnDutyPersonnelDetailInfo();
    /**
     * 丹田项目获取数据
     */
    const lay_selectedPosCode = localStorage.getItem("lay_selectedPosCode");
    // eslint-disable-next-line
    if (isDanTianPark && isDanTianPark(lay_selectedPosCode)) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `/js/dantian/${lay_selectedPosCode}.js`;
      document.getElementById("indexHead").appendChild(script);
    }
    /**
     * 丹田项目获取数据结束
     */
    let btnAuth = this.btnAuthObj["/overview/show"] || {};
    let realTabs = overviewTabArr.filter((ele) => ele.notTabPage || ele.path in btnAuth);
    for (let item of realTabs) {
      let { allCardList } = item;
      item.allCardList = allCardList.filter(
        ({ statisticalCardCode }) => statisticalCardCode in btnAuth
      );
    }
    this.fillOverviewTabs(realTabs);
    this.getCardList().then(() => {
      this.canRenderCards = true;
    });
  },
  mounted() {
    // window.GLOBAL_OBJ.eventHub.subscribe("deviceList", (res) => {
    //   this.deviceList = res;
    // });
    this.$vuePubSub.$on("deviceList", (res) => {
      this.deviceList = res;
    });
    // window.GLOBAL_OBJ.eventHub.subscribe("instrumentflag", (res) => {
    //   if (res === 1) {
    //     this.insflag = true;
    //   } else {
    //     this.insflag = false;
    //   }
    // });
    this.$vuePubSub.$on("instrumentflag", (res) => {
      if (res === 1) {
        this.insflag = true;
      } else {
        this.insflag = false;
      }
    });
    this.path = this.$route.path;
    this.videoParams.urlparams = {
      posCode: this.positionInfo.posCode,
      userCode: this.userInfo.uid,
    };
    this._bindEvent();
  },
  beforeDestroy() {
    this.$vuePubSub.$off("mapToolBarChange");
    this.$vuePubSub.$off("mapMethods");
    this.$vuePubSub.$off("cameraChange");
    this.$vuePubSub.$off("timeModelChange");
    this.$vuePubSub.$off("weatherModelChange");
    this.$vuePubSub.$off("addMarker");
    this.$vuePubSub.$off("setCenter");
    this.$vuePubSub.$off("flashMarker");
    this.$vuePubSub.$off("enterPartitionMode");
    this.$vuePubSub.$off("layerConfigChange");
    this.$vuePubSub.$off("commonLayerConfigChange");
    this.$vuePubSub.$off("modifyParadeModel");
    this.$vuePubSub.$off("startParade");
    this.$vuePubSub.$off("pauseParade");
    this.$vuePubSub.$off("closePupop");
    if (this.isShowCarTrial) {
      this.closeTrial();
    } else if (this.isShowCommonTrial) {
      this.closeCommonTrial();
    }
  },
  methods: {
    ...mapMutations([
      "fillOverviewTabs",
      "fillChosenCardsPerPage",
      "fillBusinessCodePerPage",
      "fillVrCameraLevel",
      "fillIsShowCarTrial",
      "fillPopShow",
      "fillWorkShow",
      "fillIsShowCommonTrial",
      "fillDownSpaceCode",
      "fillFloor",
      "fillVideoObj",
      "fillisInFloor",
    ]),
    ...mapMutations("security", ["fillCurrentLayerEventList"]),
    ...mapActions(["getCardList"]),
    ...mapMutations("equipInfo", ["searchDetail"]),
    // 恩宁路独有的方法
    mapStateInit() {
      this.$refs.layerSelect.getLayerFn();
    },
    getOnDutyPersonnelDetailInfo() {
      Ajax.getOnDutyPersonnelDetailInfo({})
        .then((result) => {
          this.onDutyPersonnelDevicelList =
            result.map((item) => {
              return { ...item, networkStatus: item.onlineStatus, deviceName: item.employeeName };
            }) || [];
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 获取已勾选卡片
    getChosenCards() {
      let routePath = this.$route.path;
      if (routePath && this.tabsAndCardsList && this.tabsAndCardsList.length > 0) {
        let result = this.tabsAndCardsList.find(({ path }) => routePath === path);
        if (result) {
          let { allCardList, businessCode } = result;
          this.fillBusinessCodePerPage(this.is2DPage ? "CARD_SITUATION_2D" : businessCode);
          if (routePath === "/show/global") {
            let { list = [] } =
              this.validCardList.find((item) => item.businessCode === businessCode) || {};
            if (list && list.length > 0) {
              let keyList = list.map(({ statisticalCardCode }) => statisticalCardCode);
              let bigCards = [];
              this.tabsAndCardsList.forEach(({ allCardList }) => {
                bigCards = bigCards.concat(allCardList || []);
              });
              let chosenList = [];
              for (let card of bigCards) {
                let thatIndex = keyList.findIndex((key) => key === card.statisticalCardCode);
                if (thatIndex > -1) {
                  card.sortIndex = thatIndex;
                  chosenList.push(card);
                }
              }
              chosenList.sort((a, b) => a.sortIndex - b.sortIndex);
              this.fillChosenCardsPerPage(chosenList || []);
            } else {
              let thatCards =
                this.tabsAndCardsList.find(
                  ({ path, allCardList = [] }) => path !== routePath && allCardList.length > 0
                ) || {};
              let chosenList = thatCards.allCardList || [];
              this.fillChosenCardsPerPage(chosenList);
            }
          } else {
            let validCardList = allCardList.filter(({ isChecked }) => !!isChecked);
            this.fillChosenCardsPerPage(validCardList);
          }
        }
      }
    },
    _bindEvent() {
      this.$vuePubSub.$on("mapToolBarChange", (val) => {
        this.mapOptions.mapOptions.toolbarRight = val;
      });
      this.$vuePubSub.$on("mapMethods", (method, ...args) => {
        this.mapFn(method, ...args);
      });
      this.$vuePubSub.$on("cameraChange", (res) => {
        this.videoPlay = res;
      });
      this.$vuePubSub.$on("timeModelChange", (time) => {
        this.mapFn("modifyTime", time === "day" ? 13 : 1);
      });
      this.$vuePubSub.$on("weatherModelChange", (weather) => {
        this.mapFn("modifyWeather", weather);
      });
      this.$vuePubSub.$on("addMarker", (obj) => {
        this.mapFn("addMarker", obj);
      });
      this.$vuePubSub.$on("clearMarker", (obj) => {
        this.mapFn("clearMarker", obj);
      });
      this.$vuePubSub.$on("setCenter", (obj) => {
        this.mapFn("setCenter", obj);
      });
      this.$vuePubSub.$on("flashMarker", (obj) => {
        this.mapFn("flashMarker", obj);
      });
      this.$vuePubSub.$on("enterPartitionMode", (obj) => {
        this.mapFn("enterPartitionMode", obj);
      });
      this.$vuePubSub.$on("layerConfigChange", () => {
        this.$refs.layerSelect.getLayerFn();
      });
      this.$vuePubSub.$on("commonLayerConfigChange", () => {
        this.$refs.layerSelect.commonGetLayerFn(this.floor);
      });
      this.$vuePubSub.$on("modifyParadeModel", (val) => {
        this.mapFn("modifyParadeModel", val);
        if (val.model === "Character") this.isStreetscapeAndVideoMix = true;
        if (val.model !== "Character") this.isStreetscapeAndVideoMix = false;
      });
      this.$vuePubSub.$on("startParade", (value) => {
        this.mapFn("startParade", value);
      });
      this.$vuePubSub.$on("pauseParade", (bool) => {
        this.mapFn("pauseParade", bool);
      });
      this.$vuePubSub.$on("closePupop", () => {
        this.mapFn("closePupop");
      });
      this.$vuePubSub.$on("clearAll", () => {
        this.mapFn("clearAll");
      });
    },
    streetscape() {
      this.$vuePubSub.$emit("streetscapeFn");
    },
    loadMap() {
      this.isMapLoad = true;
      this.commonLoadMap();
    },
    floorClick() {
      this.fillDownSpaceCode("");
    },
    // 时间流动
    timeChange(val) {
      this.mapFn("modifyTime", val);
    },
    // 退出时间流动p
    exitTimeFlowPlay() {
      let perspective = sessionStorage.getItem("CUR_VISUAL_ANGLE");
      if (perspective) {
        perspective = JSON.parse(perspective);
        perspective = perspective.pawn;
        perspective = {
          location: perspective.location,
          rotator: perspective.rotation,
          armLenght: perspective.armLenght,
          time: "1",
        };
      } else {
        perspective = JSON.parse(localStorage.getItem("vr-main-perspective"));
      }
      this.mapFn("modifyCameraMove", perspective);
      this.mapFn("showPOI", "ALL", true);
    },
    builtInPOI(val) {
      let dataList = this.builtInData[val.key] || [];
      let data = dataList.find((item) => item.positionId === val.labelName);
      if (data) {
        this.videoPlay.showStatus = true;
        this.videoPlay.cameraName = data.positionId;
        this.videoPlay.incidentNumber = data.equipCode;
        this.videoPlay.cameraCode = data.equipCode;
      }
    },
    markerClick(marker, marker2, e) {
      this.searchDetail(false);
      let obj = null;
      /**此处为新增弹框，上面的为之前的逻辑 */
      switch (marker.eventType) {
        // 车辆轨迹
        case "carTrialPop":
          obj = {
            marker,
            popup: carTrialPop,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        // 如果是摄像头
        case "videoPop":
          obj = {
            marker,
            popup: videoPop,
            store: this.$store,
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        // 如果是其他设备
        case "otherEquipPop":
          obj = {
            marker,
            popup: otherEquipPop,
            store: this.$store,
            location: [114, 56],
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        // 如果是其他设备-----异常
        case "otherEquipAbnormalPop":
          obj = {
            marker,
            popup: otherEquipAbnormalPop,
            store: this.$store,
            location: [114, 56],
            position: [marker.longitude, marker.latitude],
            userInfo: this.userInfo,
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        // 如果是告警
        case "alarmPop":
          obj = {
            marker,
            popup: alarmPop,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        case "instrument": //执法仪
          obj = {
            marker,
            popup: enforcement,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        case "polymerization": //聚合
          obj = {
            marker,
            popup: markesarrs,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        case "bimmarkesAlarm": //bim通用弹窗
          obj = {
            marker,
            popup: bimmarkesAlarm,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        // 如果是人员
        case "personPop":
          obj = {
            marker,
            popup: personPop,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
        case "carPop":
          obj = {
            marker,
            popup: carPop,
            store: this.$store,
            position: [marker.longitude, marker.latitude],
          };
          this.fillPopShow(true);
          this.mapFn("popupFn", obj, marker2, e);
          break;
      }
      /**此处为新增弹框，上面的为之前的逻辑 */
      let { labelNote = "{}", labelName = "" } = marker;
      var noteObj = {};
      try {
        noteObj = JSON.parse(labelNote) || {};
      } catch (err) {
        console.log("err", err);
      }
      let { eventType, typeCode } = noteObj;
      marker.tag = marker.tag || {};
      if (marker.tag.noPoup) return; // noPoup为true 则不进行弹框
      if (eventType === "securityPopup") {
        this.fillCurrentLayerEventList(typeCode);
        // 普通点位图标
        let popupType = securityEvenetPopup;
        let obj = {
          marker,
          popup: popupType,
          store: this.$store,
          location: [114, 56],
          position: this.positionInfo,
          userInfo: this.userInfo,
        };
        this.mapFn("popupFn", obj, marker2, e);
      }

      if (labelNote === "CAMERA") {
        let target = this.equipmentPOIList.find(({ poiId }) => poiId === labelName);
        if (target) {
          this.videoPlay.showStatus = true;
          this.videoPlay.cameraName = labelName;
          this.videoPlay.incidentNumber = target.equipCode;
          this.videoPlay.cameraCode = target.equipCode;
        }
      }
    },
    addMarker(...args) {
      this.mapFn("addMarker", ...args);
    },
    videoPlayClose() {
      this.fillVideoObj({
        showStatus: false,
      });
      this.videoPlay.showStatus = false;
    },
    // 高德地图车辆轨迹
    createPath(path) {
      this.mapFn("createPath", path);
    },
    // 清除高德地图所有打点
    clearAll() {
      this.mapFn("clearAll");
    },
    // 关闭高德地图窗体
    closePopup() {
      this.mapFn("closePopup");
    },
    // 暂停车辆轨迹追踪
    pauseAnimation() {
      this.mapFn("pauseAnimation");
    },
    // 车辆轨迹追踪
    trackPlayback(data, type) {
      this.mapFn("trackPlayback", data, type);
    },
    // 关闭车辆轨迹
    closeTrial() {
      this.fillIsShowCarTrial(false);
      this.mapFn("clearAll");
      this.mapFn("clearAllPolyline");
      this.$refs.layerSelect && this.$refs.layerSelect.commonGetLayerFn(this.floor);
    },
    // 关闭通用轨迹
    closeCommonTrial() {
      this.fillIsShowCommonTrial(false);
      this.mapFn("clearAll");
      this.mapFn("clearAllPolyline");
      this.$refs.layerSelect && this.$refs.layerSelect.commonGetLayerFn(this.floor);
      this.$refs.layerSelect.getLayerFn();
      window.GLOBAL_OBJ.eventHub.publish("resetVisualAngle");
    },

    // 设置中心位置
    setCenter(data) {
      this.mapFn("setCenter", data);
    },
    // 取消工单派发
    cancelWorkSend() {
      this.fillWorkShow(false);
    },
    // 确定工单派发
    confirmWorkSend() {
      this.$refs.proprtyWorks.submit();
    },
    workClose() {
      this.fillWorkShow(false);
    },
    // 获取楼层设备打点信息
    getFloorPoint(floor) {
      this.fillFloor(floor);
      this.$refs.layerSelect && this.$refs.layerSelect.commonGetLayerFn(floor);
      this.$refs.ocxFive &&
        this.$refs.ocxFive.getFloorPoint &&
        this.$refs.ocxFive.getFloorPoint(floor);
    },
    // 清除高德地图指定打点信息
    clearMarker(data) {
      this.mapFn("clearMarker", data);
    },
    // 高德地图重新渲染
    createMap() {
      this.mapFn("createMap");
    },
    // 高德地图销毁
    destoryMap() {
      this.mapFn("destoryMap");
    },
    changeFloor() {
      this.fillPopShow(false);
    },
    // 是否已经在楼层中，如果已经在楼层中，则不显示进入楼层按钮
    changeIsInFloor(data) {
      this.fillisInFloor(data);
    },
    handleOnDutyPersonnelClose() {
      this.onDutyPersonnelFlag = false;
    },
    handleOnDutyPersonnel() {
      this.onDutyPersonnelFlag = true;
    },
    close() {
      this.instrumentFlase = false;
    },
    imgclick() {
      this.instrumentFlase = true;
    },
  },
  activated() {
    // this.isRenderMap = true
    this.mapInitFn();
  },
};
</script>
<style lang="less" scoped>
.page-wrapper {
  height: 100%;
  margin-top: 0;
}
.ioc-map {
  position: absolute;
  left: 0;
  top: -4rem;
  width: 100%;
  bottom: 0;
  height: auto;
  z-index: 1;
  overflow: auto;
}
.videoArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}
.no-show-in-time-flow {
  // position: relative;
  // z-index: 1;
  /deep/.main > .float-layer,
  /deep/.pos-a > .float-layer {
    position: absolute;
    left: 0;
    width: 100% !important;
    top: 3rem;
    bottom: 0;
    overflow: auto;
    height: auto !important;
  }
}
</style>
<style lang="less">
@import "~@/less/show.less";

#cockpit-page-container {
  min-width: 0;
}

.time-flow-container {
  display: none;
}
.time-flow-open {
  .page-box {
    overflow: hidden;
  }
  .no-show-in-time-flow {
    display: none;
  }
  .time-flow-container {
    display: block;
  }
}
.img-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.el-dialog__footer {
  text-align: center;
  background: #202c4e;
}
.onDutyPersonnelImg {
  position: absolute;
  top: 148px;
  right: 440px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.onDutyPersonnel {
  position: absolute;
  top: 17%;
  right: 260px;
  z-index: 1;
  .el-icon-close {
    height: 20px;
    width: 20px;
  }
}
.instrumenticon {
  position: absolute;
  top: 97px;
  right: 440px;
  width: 50px;
  height: 45px;
  cursor: pointer;
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.instrument {
  position: absolute;
  top: 11%;
  right: 260px;
  z-index: 1;
  .el-icon-close {
    height: 20px;
    width: 20px;
  }
}
</style>
