export default {
  data() {
    return {
      mapOptions: {},
      loadMapNode: false,
      mapCacheList: [],
      isRenderMap: false,
      mapType: sessionStorage.getItem("gisType"),
    };
  },
  created() {
    this.mapInitFn();
    window.GLOBAL_OBJ.eventHub.subscribe("changeShowMap", (res) => {
      this.isRenderMap = res;
    });
  },
  methods: {
    async mapInitFn() {
      let positionInfo = await this.$store.getters["basePosition/positionInfo"];
      let userInfo = this.$store.state.userInfo;

      let mapList = this.$store.state.mapList;
      // let BIMType = this.$route.query.BIMType || "1";
      // if (sessionStorage.getItem("IOC_FROM_CODE") === "SXLP") {
      //   BIMType = this.$route.query.BIMType || "3";
      // }
      // let bimMapOpts = {
      //   isSPK: true,
      //   BIMType,
      //   isStatistics: false,
      //   BIMServer: GLOBAL_CONFIG.BIMServer,
      // };
      // let gisMapOpts = {};
      // let vrMapOpts = {
      //   toolbarShow: true,
      // };
      let imgMapOpts = {
        show2DImg: true,
        positionInfo,
      };
      let opts = {
        // bim: bimMapOpts,
        // gis: gisMapOpts,
        // vr: vrMapOpts,
        "2D": imgMapOpts,
      };
      this.mapOptions = {
        mapOptions: {
          ...opts[this.mapType],
          toolbarTop: "90px",
          toolbarRight: "27.5rem",
          isCockpit: true,
          toBuilding: false
        },
        positionInfo,
        userInfo,
        mapType: this.mapType,
        mapList,
        isMapPattern: true,
      };
      this.isRenderMap = true;
    },
    async mapFn(fnName, ...params) {
      if (this.loadMapNode) {
        if (this.$refs.mapContainer && fnName in this.$refs.mapContainer) {
          this.$refs.mapContainer[fnName](...params);
        }
      } else {
        this.mapCacheList.push({
          fnName,
          params: [...params],
        });
      }
    },
    commonLoadMap() {
      this.loadMapNode = true;
      this.mapCacheList.forEach((item) => {
        if (this.$refs.mapContainer && item.fnName in this.$refs.mapContainer) {
          this.$refs.mapContainer[item.fnName](...item.params);
        }
      });
    },
  },
};
