<template>
  <div ref="popup" class="person-pop">
    <div class="person-content">
      <div class="name">{{ title }} {{postTypeName}}</div>
      <div class="phone">{{ phone }}</div>
      <div class="time">{{ time }}</div>
      <div class="address">{{ showAddress }}</div>
      <div class="toTrial">
        <div class="btn" @click="checkDetail">人员轨迹</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "personPop",
  components: {},
  props: {
    title: {
      default: "",
    },
    phone: {
      default: "",
    },
    address: {
      default: "",
    },
    time: {
      default: "",
    },
    personId: {
      default: "",
    },
    floor: {
      default: "",
    },
    postTypeName:{
      default: ''
    },
    postTypeId:{
      default: ''
    },
    buttonText: {
      default: "",
    },
  },
  computed: {
    showAddress() {
      if (this.address && this.address !== "undefined") {
        return this.address;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapMutations(["fillIsShowCommonTrial", "fillCommonTrialObj", "fillPopShow"]),
    ...mapMutations("equipInfo", ["searchDetail"]),
    checkDetail() {
      window.GLOBAL_OBJ.eventHub.publish("saveVisualAngle");
      this.searchDetail(true);
      this.fillIsShowCommonTrial(true);
      this.fillCommonTrialObj({
        title: "人员轨迹",
        type: this.postTypeName,
        name: this.title,
        personId: this.personId,
        trialType: "person",
        floor: this.floor,
      });
      this.fillPopShow(false);
    },
  },
};
</script>
<style lang="less" scoped>
.person-pop {
  width: 100%;
  min-height: 180px;
  position: relative;
  text-align: left;
  line-height: 1.2;
  color: var(--common-white);
  backdrop-filter: blur(10px);
  &::before{
    border: 1.5px solid transparent;
    border-radius: 8px;
    pointer-events: none;
    content :'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, var(--dialog-bg-color), var(--dialog-bg-color)), linear-gradient(to bottom, #05F5DF, #43ADF9);
    opacity: 0.6;
    z-index: -1;
  }
  .person-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    .name {
      font-size: 16px;
      font-family: var(--font-title);
      position: relative;
      line-height: 2;
      text-align: center;
      &:before{
        content: '';
        position: absolute;
        bottom: -2px;
        width: 100%;
        left: 0;
        height: 1px;
        background: linear-gradient(to right, transparent, var(--common-white), transparent)
      }
    }
    .phone,
    .time {
      font-size: 12px;
      padding-top: 10px;
      font-family: var(--font-number);
    }
    .address {
      padding-top: 10px;
      min-height: 20px;
      font-size: 12px;
      font-family: var(--font-title);
      line-height: 2;
    }
    .toTrial {
      margin-top: 5px;
      border-top: 1px solid var(--border-common-color);
      display: flex;
      justify-content: flex-end;
      .btn {
        margin-top: 10px;
        width: 96px;
        height: 33px;
        background-color: var(--button-bg-color);
        border-radius: 2px;
        line-height: 33px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        color: var(--common-white);
      }
    }
  }
}
/deep/ .amap-info-sharp {
  display: none;
}
</style>
