import Ajax from "@/utils/request.js";

export default {
  namespaced: true,
  state: {
    deviceList:null,
    newDeviceList:null,
    loading:false
  },
  mutations: {
    setDevice(state, list = []) {
      state.deviceList = list;
    },
    setNewDevice(state, list = []) {
      state.newDeviceList = list;
    },
    setLoading(state, status=false){
      state.loading =status;
    }
  },
  getters: {
    device(state) {
      return state.deviceList ||[];
    },
    newDevice(state) {
      return state.newDeviceList||[];
    }
  },
  actions:{
    //获取系统统计的列表
    async getDevice({commit, state},positionInfo={}){
      if(state.deviceList && state.deviceList.length){
        return;
      }
      // 2021/12/20 接口入参去掉timeSpan，默认查所有
      const param ={
        parkCode: positionInfo.posCode,
        posCode: positionInfo.posCode,
        region: positionInfo.region,
        spaceModelId: positionInfo.spaceModelId
      }
      commit('setLoading', true);
      try{
        let res = await Ajax({
          url: "/ioc/equipmentmgmt/dmIocEquipAlarm/findIntegratedByTimeNew",
          method: "post",
          data:param,
        }, {
          showErrTip: true
        });
        commit('setLoading', false);
        if(res && res.length){
          res.forEach(r => {
            r.availabilityRatio =r.availabilityRatio||0;
            r.equipTotalNum =r.equipTotalNum||0;
            r.faultRate =r.faultRate||0;
            r.usableEquipNum =r.usableEquipNum||0;
          });        
        }
        commit('setDevice', res || [])
      }catch(e){
        commit('setLoading', false);
      }

    },
    //获取新的获取剩余新的3个系统的数据
    async getNewDevice({commit, state},positionInfo={}){
      if(state.newDeviceList && state.newDeviceList.length){
        return;
      }
      // 2021/12/20 接口入参去掉timeSpan，默认查所有
      const param ={
        //timeSpan: 'week',
        parkCode: positionInfo.posCode,
        posCode: positionInfo.posCode,
        region: positionInfo.region,
        spaceModelId: positionInfo.spaceModelId
      }
      let res = await Ajax({
        url: "/ioc/equipmentmgmt/dmIocEquipAlarm/findIntegratedByTimeNew3",
        method: "post",
        data:param,
      }, {
        showErrTip: true
      });
      commit('setNewDevice',res ||[])
    }
  }
};