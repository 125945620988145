// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/overview/top.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".current-time-txt[data-v-f493903c]{color:#fff;margin-left:.875rem;letter-spacing:.125rem}.time-flow-top[data-v-f493903c]{position:fixed;top:0;left:0;right:0;height:6.25rem;line-height:3rem;z-index:10000;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat}.time-flow-top .exit-btn[data-v-f493903c]{font-size:1rem;color:#a2b0cb;position:absolute;left:50%;top:15%;transform:translate(-50%,50%);cursor:pointer}.time-flow-bottom[data-v-f493903c]{position:fixed;bottom:.625rem;left:50%;z-index:10000;transform:translateX(-50%);width:65.125rem;height:4.1875rem;background:#1d2745;border-radius:.5rem;padding:0 1.6875rem 0 2.4375rem;display:flex;align-items:center}.time-flow-bottom .control-btn[data-v-f493903c]{background:#101b35;border:.0625rem solid #2e3859;border-radius:.375rem;width:1.875rem;height:1.875rem;display:flex;justify-content:center;align-items:center;color:#fff;margin-right:.625rem;font-size:.75rem;cursor:pointer}.time-flow-bottom .m-slider[data-v-f493903c]{flex:1}", ""]);
// Exports
module.exports = exports;
