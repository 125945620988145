export default {
    namespaced: true,
    state: {
        equipInfo: {},
        equipInfoAir: {},
        equipAbnormalInfo: {},
        searchDetail:false
    },
    mutations: {
        equipDetail(state, data) {
            state.equipInfo = { ...data };
        },
        equipDetailAir(state, data) {
            state.equipInfoAir = { ...data };
        },
        equipAbnormalDetail(state, data) {
            state.equipAbnormalInfo = data;
        },
        searchDetail(state, data) {
            state.searchDetail = data;
        },
    }
};
