import Ajax from "@/utils/request.js";

export default {
  // 任务验收时长统计查询
  getWorkCheckTimeDuration(data) {
    return Ajax(
      {
        url: "/ioc/report/workOrderStatistics/getWorkCheckTimeDuration",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 任务验收时长统计导出
  exportWorkCheckTimeDuration(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 用电概览饼状图统计
  getEnergySurveyBelow(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流统计
  getPassengerFlowStatistics(url, data) {
    return Ajax(
      {
        url,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 男女比例
  getGendorlist(url, data) {
    return Ajax(
      {
        url,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 用电概览饼状图title信息
  getEnergySurveyTop(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 用电统计柱状图
  getEnergyStatistics(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 用电统计柱状图---租户用电--日
  tenantElectricityD(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 用电统计柱状图---公区用电--日
  publicAreaElectricityD(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 用电统计柱状图---总用电--日
  totalElectricityD(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 头部电表统计
  getEnergyDeviceStatD(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getEnergyDeviceStatD",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 头部本月节能潜力
  getEnergyAIPotentialM(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getEnergyAIPotentialM",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 故障设施类型统计
  getEquipmentFaultTypeStatistics(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getEquipmentFaultTypeStatistics",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 设施管理设备统计（头部）
  getEquipmentOnlineRate(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getEquipmentOnlineRate",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本月设施异常（头部）
  getEquipmentAlarmLevelMonth(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getEquipmentAlarmLevelMonth",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本月设施异常处理（头部）
  getRepaireStatusNumByMonth(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getRepaireStatusNumByMonth",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //设施保修柱状图概览
  getRepaireStat(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //设施保修饼图概览
  getRepaireStatusNum(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 安防告警概览统计
  getIncidentAlarmEvent(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 视频巡逻概览统计
  getEventStatusNum(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 告警分类统计
  getIncidentAlarmClassify(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getIncidentAlarmClassifyDay",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 黑名单事件处理时长
  getTop3ResponseIncident(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 人流折线图统计
  getPersonFlowData(url, data) {
    return Ajax(
      {
        url,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 车流折线图统计
  getCarFlowData(url, data) {
    return Ajax(
      {
        url,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 洗手间人流折线图统计
  getToiletPersonFlowData(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 商场客流饼图统计
  getThisMonthPersonNum(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 临停收费柱状图统计
  getTempParkChargeStat(url, data, method) {
    return Ajax(
      {
        url,
        method: method,
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 商场客流热度折线图统计
  getRegionPersonFlow(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 时段客流柱状图统计
  getPasserFlowHourNum(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getPasserFlowHourNum",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 视频巡逻告警统计(柱状图)
  getPatolWaningData(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 能耗监测
  alarmTrendCarddata(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 告警事件趋势(柱状图)
  getIncidentAlarmEventTrend(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 黑名单概览统计
  getBackListStatus(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },

  // 配套服务模块
  // 会议室预订统计接口
  getMeetingRoomReserveData(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会议室使用接口
  getMeetingRoomUseData(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 乘车统计
  busTakenStat(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 订餐营收
  getCateringOrdersProfit(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 搭乘公司Top5
  getBusTakentop5(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 菜品销量Top5
  getCateringGoodsTop5(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 店铺销量Top5
  getCateringOrdersTop5(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会议室预订Top5
  getMeetingRoomBookTop5(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本月会议室预订
  getMeetingRoomRateOnMonth(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getMeetingRoomRateOnMonth",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会议室列表
  getMeetingroomList(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getMeetingroomList",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本月会议室使用
  getMeetingTimeAndIncomeMonth(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getMeetingTimeAndIncomeMonth",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会议室服务企业
  getMeetingCompanyNum(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getMeetingCompanyNum",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本月园区订餐
  getCateringOrdersProfitStatisCuurentM(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getCateringOrdersProfitStatisCuurentM",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本月巴士乘车、本月巴士满座率
  getBusTakenRatioToMonth(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getBusTakenRatioToMonth",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本周安防告警事件发生数，本周告警事件处理
  getIncidentAlarmAvgTimeWeek(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getIncidentAlarmAvgTimeWeek",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本周黑名单事件数
  getBackListStatusTotalCurrWeek(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getBackListStatusTotalCurrWeek",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 本周视频巡逻执行次数
  getPatrolTaskReport(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/patrolReport/V1.2/getPatrolTaskReport",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 今日园区人数人次
  getTodayPersonNumAndTime(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getTodayPersonNumAndTime",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 缴费金额
  getTodayCarNumAndMoney(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getTodayCarNumAndMoney",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 今日停车数
  getVehTodayStat(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getVehTodayStat",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 日视图人流总数
  getMonthPersonFlowNum(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getMonthPersonFlowNum",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 车流日视图人数
  getMonthVehFlowNum(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getMonthVehFlowNum",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 今日停车场使用率
  getUserPercentByShift(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getUserPercentByShift",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 区域热度TOP10
  getRegionHeatTop10(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V3.0/getRegionHeatTop10",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 时段平均用电
  getEnergyCostByPeriodD(data, url) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 门禁通行方式
  getGuestVisitType(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 受访公司Top5
  getGuestVisitCompTop5(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 门禁通行统计
  getGuestVisit(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取车辆打点信息
  getVehcleInfo(data) {
    return Ajax(
      {
        url: "/ioc/vehiclemgmt/vehicle/location/info",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单总数统计
  WorkOrderNum(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单概况统计
  WorkOrderOverview(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单分类统计
  WorkOrderClassiStatistics(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单趋势统计
  WorkOrderTrendStatistics(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 绿色节能--冷量统计
  coolTrendStatistics(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单分类类型
  WorkOrderClassiType(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V1.0/getWorkOrderClassification",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单状态
  WorkOrderStatus(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V1.0/getWorkOrderStatus",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单分类详情
  WorkOrderClassiDetails(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V1.0/getWorkOrderClassificationDetails",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单分类详情--昨日
  getWorkOrderClassificationDetailsYes(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V1.0/getWorkOrderClassificationDetailsYes",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--导出
  exportAction(url, data) {
    return Ajax(
      {
        url,
        method: "post",
        data,
        // configHead
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取车辆轨迹信息
  getVehcleTrail(data) {
    return Ajax(
      {
        url: "/ioc/vehiclemgmt/vehicle/location/trail",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取通用设备打点信息集合
  getEquipmentPoint(data) {
    return Ajax(
      {
        url: "/ioc/atomicequipment/basEquipmentInfo/location/query",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取摄像头打点信息
  getCameraPoint(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/atomicequipment/list/withIncident",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取执法仪打点信息
  enforcement(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/getSipDeviceList",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取高德设备聚合点位
  polymerization(data) {
    return Ajax(
      {
        url: "/ioc/equipmentmgmt/polymerization/list",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取执法仪轨迹
  instrument(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/getWorkingHistorys",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取执法仪详情
  getSipDeviceDetails(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/getSipDeviceDetails",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //获取用户SipCode
  getUserSipCode(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/getUserSipCode",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //获取执法仪SipCode
  getDeviceSipCode(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/getDeviceSipCode",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 全局物业工单相关信息
  workOption(data) {
    return Ajax(
      {
        url: "/ioc/property/v1/workorder/workOption",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 提交工单
  workHandle(data) {
    return Ajax(
      {
        url: "/ioc/property/v1/workorder/workHandle",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取告警可选择类型
  getAlarmQuery(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/user/selected/v1.0/query",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取保洁人员列表
  getPersonPoint(data) {
    return Ajax(
      {
        url: "/ioc/personmgmt/cleaner/current",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    )
  },
  // 获取保洁人员轨迹
  getPersonTrail(data) {
    return Ajax(
      {
        url: "/ioc/personmgmt/cleaner/trail",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取AI巡逻车列表
  getAiPatrolCarList(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/patrol/car/getAiPatrolCarList",
        method: "GET",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取AI巡逻车轨迹
  getAiPatrolCarLocus(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/patrol/car/locus",
        method: "GET",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取图层配置
  getLayerSceneSubtypeConfig(data) {
    return Ajax(
      {
        url: "/ioc/safetymgmt/config/getLayerSceneSubtypeConfig",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取报修事件
  getReportIncident(data) {
    return Ajax(
      {
        url: "/ioc/repair/appUserRepair/queryPcPropertyRepairList",
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施告警事件
  getEquipmentIncident(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipmentIncidentRecords/getalarmList?pageNumber=1&pageSize=500`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施详情---消防栓
  getEquipDetail(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipmentInfo/selectEquipByEquipCode`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施详情--设备运行参数获取状态---消防栓
  getRunkeyStatus(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipPoint/getPointLatestStatusList`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施详情--设备运行参数获取数据---消防栓
  getRunkeyData(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipPoint/getPointLatestInfoList`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施详情--设施告警记录---消防栓
  alamRecords(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipmentIncidentRecords/getEquipAlarmList`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施详情--周期性工单记录--获取设备ID
  getEquipList(data) {
    return Ajax(
      {
        url: `/ioc/atomicequipment/basEquipmentInfo/getEquipList?pageNum=1&pageSize=500`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设施详情--周期性工单记录
  orderRecords(data) {
    return Ajax(
      {
        url: `/ioc/repair/daas/selectPeriodicity`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //设施弹框信息
  listData(data, page) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipmentIncidentRecords/getalarmList?${page}`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //会员销售----------------------------------------------------------------------------
  // 获取获取销售数据
  getSaleData(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getSaleData`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取总经营面积
  getRentOperateInfo(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getRentOperateInfo`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 业态面积占比
  getLayoutArea(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getLayoutAreaProportion`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 业态销售当月排行
  getBusinessCurMonSale(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getBusinessCurMonSale`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 业态销售占比
  getLayoutSaleProportion(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getLayoutSaleProportion`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 当月楼栋排名列表
  getBlockFloorList(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getBlockFloorListNO1`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 楼层签约率分析列表
  getFloorSignRateList(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/ylVerify/getSelectSigningRate`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 租售比分析
  getRentSellRatio(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getRentSellRatio`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // p坪效分析
  getPlateauEffectRatio(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getPlateauEffectRatio`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 租金收益分析
  getRentIncomeRatio(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getRentIncomeRatio`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // NOI分析
  getNoiAnalysis(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getNoiAnalysis`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 逾期租户排行
  getOverdueRentRank(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getOverdueRentRank`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 租赁概况
  getRentOverview(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/1.0/getRentOverview`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 租户行业分布
  distribution(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/selectTenantIndustryDistribution`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 租期结构
  getRentTermStructure(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getRentTermStructure`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //合同到期分布
  getContractExpireDistribution(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getContractExpireDistribution`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //获取楼栋
  getBlockList(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getBlockList`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  //获取楼层
  getFloorList(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getFloorList`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 上报埋点数据
  locus(data) {
    return Ajax(
      {
        url: `/ioc/security/pointlog/locus`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会员销售  会员数量分析
  getMembershipAnalysis(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/member/getMembershipAnalysis`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会员销售  会员积分分析
  getPointsAnalysis(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/member/getPointsAnalysis`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会员销售  当前会员卡片
  getCurrentMembers(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/member/getCurrentMembers`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 会员销售 新增会员数量
  getNewMembers(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/member/getNewMembers`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 当前客流统计(卡片)
  getCurrentPassengerFlow(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getCurrentPassengerFlow`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 当月排行
  getEntranceRanking(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getEntranceRanking`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 客流总体统计 下拉数据
  getAreas(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getDevices`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 客流总体统计
  getOverallStatistics(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getOverallStatistics`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 客流总体统计 年下拉数据
  passengerFlowGetYear(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getYear`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 客流时段统计
  getTimeFrameStatistics(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getTimeFrameStatistics`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流车辆 爬梯率统计
  getLadderStandStatistics(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getLadderStandStatistics`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 统计商铺客流等汇总
  getSaleSummary(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getCurrentAllStatistics`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取商户销售排行榜
  getSaleRanking(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/getCtSort`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取驾驶舱数据
  getCockpitData(data) {
    return Ajax(
      {
        url: `/ioc/report/visualSense/satacockpit/query`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取车辆统计信息
  getCarInfo(data) {
    return Ajax(
      {
        url: `/ioc/report/vehicle/getKetuoParkFreeSpace`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 保存驾驶舱数据
  saveCockpitData(data) {
    return Ajax(
      {
        url: `/ioc/report/visualSense/satacockpit`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 修改驾驶舱数据
  updateCockpitData(data) {
    return Ajax(
      {
        url: `/ioc/report/visualSense/satacockpit/update`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 客流总体 左侧下拉的数据源
  getDevice(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getDevices`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取车场入口和出口
  getParkingLotPlaces(data) {
    return Ajax(
      {
        url: `/ioc/report/vehicle/v1.0/getParkingLotPlaces`,
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },

  // 执法仪点击记录
  addUseNumber(data) {
    return Ajax(
      {
        url: `/ioc/safetymgmt/addUseNumber`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取保洁第三方接口
  getUrlData(data) {
    return Ajax(
      {
        url: `/ioc/personmgmt/cleanAlarm/getUrlData`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 调用保洁第三方接口
  allsUrlData(data) {
    return Ajax(
      {
        url: data.url,
        method: "get",
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 查询高德地图边界数据
  getMapBoundaryData(data) {
    return Ajax(
      {
        url: `/ioc/atomiccommon/bim/border/query`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 保存高德地图边界数据
  saveMapBoundaryData(data) {
    return Ajax(
      {
        url: `/ioc/atomiccommon/bim/border/save`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 根据用户查询数据驾驶舱图标设置
  getGraphData(data) {
    return Ajax(
      {
        url: `/ioc/report/visualSense/graphSettings/query`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 报存用户修改数据驾驶舱图标设置
  updateGraphData(data) {
    return Ajax(
      {
        url: `/ioc/report/visualSense/graphSettings/update`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取区域到访排行数据
  getReturnedPersonSelectInfo(data) {
    return Ajax(
      {
        url: `/ioc/vcommercemgmt/returnedPerson/selectInfo`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取配置的区域信息
  selectAllByTree(data) {
    return Ajax(
      {
        // url: `/ioc/vcommercemgmt/configregion/selectAllByTree`,
        url: `/ioc/vcommercemgmt/configregion/selectLv3Region`,
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取员工通行排行
  getTrafficRankingData(data) {
    return Ajax(
      {
        url: `/ioc/guest/staff/statistics/ranking`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取访客公司排行人数
  getCompanyRankData(data) {
    return Ajax(
      {
        url: `/ioc/guest/visitor/statistics/company`,
        method: "GET",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取客流/场内人次
  getInOutStatistics(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/passengerFlow/getInOutStatistics`,
        method: "POST",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 全局告警等级统计
  getGlobalAlarmLevelStatisticsData(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/camera/getGlobalAlarmLevelStatisticsData`,
        method: "GET",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },

  //整体收缴概况
  collectionRateStatistics(data) {
    return Ajax(
      {
        url: `/ioc/aggregate/reportForWuHan/queryCollectionRateStatistics`,
        method: "GET",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 全局告警状态统计
  getGlobalAlarmStatusStatisticsData(data) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/camera/getGlobalAlarmStatusStatisticsData`,
        method: "GET",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 设备统计饼图数据
  getAllEquipmentProductTypeNum(data) {
    return Ajax(
      {
        url: "/datacenter/api/datacenter/V1.0/getAllEquipmentProductTypeNum",
        method: "post",
        data: data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 根据业务转换url的接口
  postMessageByUrl(url, data, loading = false) {
    return Ajax(
      {
        url,
        method: "post",
        data,
      },
      { showLoading: loading }
    ).then((data) => data);
  },

  // 智慧城市第三方接口
  getWisdomMeteorologicalList(params) {
    return Ajax(
      {
        url: "/ioc/equipmentmgmt/dmIocEquipAlarm/wisdomMeteorologicalList",
        data: params,
        method: "post",
      },
      { handleErr: false }
    ).then((data) => data);
  },
  // 获取业态面积分布
  businessTypeAreaDistribution(data) {
    return Ajax(
      {
        url: "/ioc/aggregate/reportForWuHan/businessTypeAreaDistribution",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取楼栋签约率
  getBuildingSignRateList(data) {
    return Ajax(
      {
        url: "/ioc/aggregate/reportForWuHan/getBuildingSignRateList",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 上海御河项目-综合态势地图新增保洁人员在线离线列表
  getOnDutyPersonnelDetailInfo(data) {
    return Ajax(
      {
        url: "/ioc/report/cleanBusiness/currentPosition/getDetailInfo",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取机器人列表
  getRobotList(params) {
    return Ajax(
      {
        url: "/ioc/equipmentmgmt/robot/list",
        data: params,
        method: "GET",
      },
      { handleErr: false }
    ).then((data) => {
      return data;
    });
  },
  // 获取机器人点位详情
  getRobotDetail(params) {
    return Ajax(
      {
        url: "/ioc/equipmentmgmt/robot/detail",
        data: params,
        method: "GET",
      },
      { handleErr: false }
    ).then((data) => {
      return data;
    });
  },
  // 获取洗手间设备的列表
  getEquimentListByManyContidion(params, { pageSize = 20, pageNumber = 1 }) {
    return Ajax(
      {
        url: `/ioc/equipmentmgmt/equipmentInfo/getEquimentListByManyContidion?pageSize=${pageSize}&pageNumber=${pageNumber}`,
        data: params,
        method: "POST",
      },
      { handleErr: false }
    ).then((data) => {
      return data;
    });
  },
  // 获取洗手间设备详情
  getPointLatestInfoList(params) {
    return Ajax(
      {
        url: "/ioc/equipmentmgmt/equipPoint/getPointLatestInfoList",
        data: params,
        method: "GET",
      },
      { handleErr: false }
    ).then((data) => {
      return data;
    });
  },
  // 合并保洁请求三方接口
  mergeGetUrlData(params) {
    return Ajax(
      {
        url: "/ioc/personmgmt/cleanAlarm/mergeGetUrlData",
        data: params,
        method: "post",
      },
      { handleErr: false }
    ).then((data) => {
      return data;
    });
  },
  // 获取工单分类统计其他数据
  getSelectWorkOrderClassificationStatistics(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderClassificationStatistics",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取客户评价Tab切换
  selectWorkOrderDepartment(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderDepartment",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取工单分类详情部门
  selectDepartment(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectDepartment",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取客户评价列表
  selectWorkOrderWrenchStatistics(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderWrenchStatistics",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 导出客户评价列表
  exportWorkOrderWrenchStatistics(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/exportWorkOrderWrenchStatistics",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单详情部门接口
  selectWorkOrderDetail(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderDetail",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单详情导出
  exportWorkOrderDetail(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/exportWorkOrderDetail",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取驶入驶出记录
  getAccessRecordStatistics(data) {
    return Ajax(
      {
        url: "/ioc/vehiclemgmt/trafficStatistics/getAccessRecordStatistics",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 获取设备列表
  getDeviceList(data) {
    return Ajax(
      {
        url: "/ioc/equipmentmgmt/dmIocEquipAlarm/findIntegratedByTimeNew",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单总数统计--上海御河项目
  WorkOrderNumSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/getWorkOrderTotalNum",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--智慧园区管理平台工单类型分布--上海御河项目
  getIocWorkOrderClassifyNumSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/getIocWorkOrderClassifyNum",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--整体工单类型分布--上海御河项目
  getWholeWordOrderClassifyNumSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/getWholeWordOrderClassifyNum",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--任务验收时长统计查询--上海御河项目
  getWorkCheckTimeDurationSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/getWorkCheckTimeDuration",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--任务验收时长统计导出--上海御河项目
  // url: '/ioc/repair/daas/exportWorkCheckTimeDuration',

  // 工单统计--工单分类统计 列表数据--上海御河项目
  getSelectWorkOrderClassificationStatisticsSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderClassificationStatistics",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },

  // 工单统计--工单分类统计 导出--上海御河项目
  // url: '/ioc/repair/daas/exportWorkOrderClassificationStatisticsExcel',

  // 工单统计--工单趋势统计 图表数据 --上海御河项目
  WorkOrderTrendStatisticsSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/getWorkOrderTrend",
        method: "post",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
  // 工单统计--工单趋势统计 导出--上海御河项目
  // url: '/ioc/repair/daas/exportWorkOrderTrendExcel',

  /**
   * 工单工程分析
   */
  // 工单分类概况
  getSelectWorkOrderCategorySummarySHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderCategorySummary",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },

  // 工单分类统计
  getSelectWorkOrderCategoryStatisticsSHYH(data) {
    return Ajax(
      {
        url: "/ioc/repair/daas/selectWorkOrderCategoryStatistics",
        method: "get",
        data,
      },
      {
        showLoading: false,
      }
    ).then((data) => {
      return data;
    });
  },
};
