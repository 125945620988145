import { render, staticRenderFns } from "./personPop.vue?vue&type=template&id=fc70fd94&scoped=true"
import script from "./personPop.vue?vue&type=script&lang=js"
export * from "./personPop.vue?vue&type=script&lang=js"
import style0 from "./personPop.vue?vue&type=style&index=0&id=fc70fd94&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc70fd94",
  null
  
)

export default component.exports